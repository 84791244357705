import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Dictionary } from '@ngrx/entity/src/models';
import { EdgeCamera } from '../../../../cameras/camera.model';
import { AlertV2Document } from '@models/alerts-v2.model';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { SelectedCamera } from '@models/alert-events.model';
import { WallModelV2 } from '@models/wall.model';
import WallPositionEvent = WallModelV2.WallPositionEvent;

export declare interface AlertEventSelectionResult {
  selectedAlertEvent: AlertV2Document,
  selectedCameras: SelectedCamera[],
  isAlertSelection: boolean // flag that shows is it checkbox alert emit value or camera selector
}

@Component({
  selector: 'app-alert-row',
  templateUrl: './alert-row.component.html',
  styleUrls: ['./alert-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertRowComponent implements OnInit {

  @Input() alertEvent: AlertV2Document;
  @Input() alertsLookup: Dictionary<AlertV2Document>;
  @Input() camerasLookup: Dictionary<EdgeCamera.CameraItem>;
  @Input() selectedAlertEvents: { [eventId: string]: WallPositionEvent[] } = {};

  @Output() onAlertSelected: EventEmitter<AlertEventSelectionResult> = new EventEmitter<AlertEventSelectionResult>();

  public camerasFilterControl: UntypedFormControl = new UntypedFormControl();
  public alertEventCameras$: BehaviorSubject<SelectedCamera[]> = new BehaviorSubject<SelectedCamera[]>([]);

  public isShowMore: boolean = false;

  constructor() {
  }


  ngOnInit(): void {
    /**
     * Needs for case if nothing selected => select all automatically
     */
    const availableCamerasForSelect: string[] = [];
    const cameras: SelectedCamera[] = Object.keys(this.alertEvent.cameraSync)
      .filter(cameraId => this.camerasLookup[cameraId])
      .map(cameraId => {
        const camera: SelectedCamera = this.alertEvent.cameraSync[cameraId];
        const result = {
          cameraId,
          locationId: camera.locationId,
          edgeId: camera.edgeId,
        };
        availableCamerasForSelect.push(cameraId);
        return result;
      });
    this.alertEventCameras$.next(cameras);

    const selectedCameras = this.selectedAlertEvents[this.alertEvent._id]?.map(alertEvent => alertEvent.cameraId);
    if (!selectedCameras?.length) {
      this.camerasFilterControl.patchValue(availableCamerasForSelect);
    } else {
      this.camerasFilterControl.patchValue(selectedCameras);
    }

    this.camerasFilterControl.valueChanges
      .subscribe(res => {
        this.selectAlert(false);
      });

  }


  public selectAlert(isAlertSelection: boolean) {
    this.onAlertSelected.emit({
      selectedAlertEvent: this.alertEvent,
      selectedCameras: this.camerasFilterControl.value.map(cameraId => {
        const cameraItem = this.camerasLookup[cameraId];
        return {
          cameraId: cameraItem.edgeOnly.cameraId,
          edgeId: cameraItem.edgeId,
          locationId: cameraItem.locationId,
        };
      }),
      isAlertSelection: isAlertSelection,
    });
  }

  public showMore() {
    this.isShowMore = !this.isShowMore;
  }

  public closeModal() {
    this.isShowMore = false;
  }

}
