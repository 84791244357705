import { Injectable } from '@angular/core';
import { Auth0 } from './auth0.model';
import { Auth0Service } from './auth0.service';
import { LocalStorageService } from '../core/local-storage.service';
import { FirebaseService } from './firebase.service';
import { Firebase } from './firebase.model';
import { concatMap, filter, from, lastValueFrom, mergeMap, Subject, Subscription, switchMap, takeUntil, tap, timer } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { UserCredential } from '@angular/fire/auth';
import { UserService } from '../user/user.service';
import { AppState } from '../store/app.state';
import { OrganizationsActions } from '@states/organization/organization.actions-types';
import { SessionStorageService } from '../core/session-storage.service';
import { ServiceWorkerHandlerService } from '../services/service-worker-handler.service';
import { PermissionActions } from '@states/permissions/permissions.action-types';
import { CookieStorageService } from '../core/cookie-storage.service';
import { AuthenticationActions } from '@states/authentication/authentication.action-types';
import { AuthenticationSelectors } from '@states/authentication/authentication.selector-types';
import { AuthResponseFromUrl } from '@enums/shared.enum';
import { SocketMainService } from '../socket/socket-main.service';
import { calculateExpiryDate, parseJwt } from '../helpers/common.helpers';
import { routerSegments } from '@consts/routes';

export const ID_TOKEN_KEY = 'id_token';
export const ACCESS_TOKEN_KEY = 'access_token';
export const EXPIRES_IN_KEY = 'expires_at';
export const AUTH_PROVIDER_ID_KEY = 'authProviderId';
export const SHARE_ACCESS_TOKEN_KEY = 'share_access_token_key';
export const REFRESH_TOKEN_KEY = 'refresh_token';

const SILENT_REFRESH = 28800000;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private stopRefreshTimeout = new Subject();
  private stopRefreshToken: Subscription;

  constructor(
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private cookieStorageService: CookieStorageService,
    private auth0Service: Auth0Service,
    private firebaseService: FirebaseService,
    private userService: UserService,
    private socketMainService: SocketMainService,
    private serviceWorkerHandlerService: ServiceWorkerHandlerService,
  ) {
  }

  user$ = this.auth0Service.user$;


  extractAuthResponseFromUrlPromise(): Promise<{ type: AuthResponseFromUrl, msg: string, code: number, data?: Auth0.AuthenticationResponse }> {
    return this.auth0Service.extractAuthResponseFromUrlPromise();
  }

  isLoggedIn(): boolean {
    return this.auth0Service.isLoggedIn();
  }

  async logout(msg = null, code = null) {
    this.stopRefreshTimeout.next(true);
    this.disconnectSocket();
    this.store.dispatch(AuthenticationActions.Logout());
    await this.firebaseService.signOutFromFirebase();
    this.serviceWorkerHandlerService.sendMessage('LOGOUT', {}, false);
    this.auth0Service.logout(msg, code);
  }


  resetPassword(email: string) {
    this.auth0Service.resetPassword(email);
  }

  onSwUpdate() {
    return this.userService.onSwUpdate();
  }

  async switchTenant(orgId: string, post = false) {
    this.store.dispatch(OrganizationsActions.emitLeaveActiveOrganizationEvent({ activeOrganization: { orgId } }));
    const authResult = await lastValueFrom(this.auth0Service.switchTenant(orgId));
    /**
     * refresh token return variables with different conventions than login.
     */
    if (authResult && authResult.access_token && authResult.id_token) {
      const transformedAuthResult: Auth0.AuthenticationResponse = {
        expiresIn: undefined, idTokenPayload: undefined, state: '', tokenType: '', ...authResult, idToken: authResult.id_token, accessToken: authResult.access_token,
        refreshToken: authResult.refresh_token,
      };
      const idTokenPayload = parseJwt(transformedAuthResult.idToken);
      const expiresAt = idTokenPayload.exp - idTokenPayload.iat;
      const authData: Partial<Auth0.AuthenticationResponse> = {
        ...transformedAuthResult,
        expiresAt: calculateExpiryDate(expiresAt),
        authProviderId: idTokenPayload.sub,
      };

      this.storeSession({
        idToken: authData.idToken!,
        accessToken: authData.accessToken!,
        expiresAt: authData.expiresAt!,
        authProviderId: authData.authProviderId!,
        refreshToken: authData.refreshToken,
      });
      /**
       * Protection if browser not supports SW or if it's automatically invite case.
       */
      setTimeout(() => {
        window.location.href = routerSegments.home;
      }, 300);
    }
    if (!!post) {
      this.serviceWorkerHandlerService.sendMessage('SWITCH_TENANT', { orgId }, false);
    }
  }

  storeSession(data: Auth0.LocalStorageSession): void {
    this.store.dispatch(PermissionActions.getPermissions());
    this.localStorageService.setItem(ID_TOKEN_KEY, data.idToken);
    this.localStorageService.setItem(ACCESS_TOKEN_KEY, data.accessToken);
    this.localStorageService.setItem(EXPIRES_IN_KEY, data.expiresAt);
    this.localStorageService.setItem(AUTH_PROVIDER_ID_KEY, data.authProviderId);
    this.localStorageService.setItem(REFRESH_TOKEN_KEY, data.refreshToken);
  }

  getSession(): Auth0.LocalStorageSession {
    return {
      idToken: this.getIdTokenFromLocalStorage(),
      accessToken: this.getAccessTokenFromLocalStorage(),
      expiresAt: this.getExpirationFromLocalStorage(),
      authProviderId: this.getAuthProviderIdFromLocalStorage(),
      refreshToken: this.getRefreshTokenFromLocalStorage(),
    };
  }

  deleteSession() {
    this.localStorageService.removeItem(ID_TOKEN_KEY);
    this.localStorageService.removeItem(EXPIRES_IN_KEY);
    this.localStorageService.removeItem(ACCESS_TOKEN_KEY);
    this.localStorageService.removeItem(AUTH_PROVIDER_ID_KEY);
  }

  deleteLocalStorage() {
    this.localStorageService.clear();
  }

  getExpirationFromLocalStorage(): string | null {
    return this.localStorageService.getItem(EXPIRES_IN_KEY);
  }

  getIdTokenFromLocalStorage(): string | null {
    return this.localStorageService.getItem(ID_TOKEN_KEY);
  }

  getAccessTokenFromLocalStorage(): string | null {
    return this.localStorageService.getItem(ACCESS_TOKEN_KEY);
  }

  getAuthProviderIdFromLocalStorage(): string | null {
    return this.localStorageService.getItem(AUTH_PROVIDER_ID_KEY);
  }

  getRefreshTokenFromLocalStorage(): string | null {
    return this.localStorageService.getItem(REFRESH_TOKEN_KEY);
  }

  storeShareAccessTokenSession(token: string): void {
    this.sessionStorageService.setItem(SHARE_ACCESS_TOKEN_KEY, token);
  }

  getShareAccessTokenFromSessionStorage(): string | null {
    return this.sessionStorageService.getItem(SHARE_ACCESS_TOKEN_KEY);
  }

  clear() {
    this.localStorageService.clear();
    this.sessionStorageService.clear();
    this.cookieStorageService.clear();
    return;
  }

  getFirebaseToken(auth: Firebase.GenerateFirebaseCustomTokenRequest) {
    return this.firebaseService.getFirebaseToken(auth);
  }

  authenticateToFirebase(auth: Firebase.GenerateFirebaseCustomTokenRequest): Promise<UserCredential | null> {
    return this.firebaseService.authenticateToFirebase(auth);
  }

  authenticateGuestToFirebase(auth: Firebase.GenerateFirebaseCustomTokenRequest, authToken: string): Promise<UserCredential | null> {
    return this.firebaseService.authenticateToFirebase(auth, { persist: false, notifyLocalObserver: true, authToken });
  }

  startRefreshTimeout() {
    timer(10000, 900000)
      .pipe(
        mergeMap(_ => this.store.pipe(select(AuthenticationSelectors.authProviderId))),
        tap(res => {
          if (!res) {
            const errMsg = 'connection has expired';
            this.logout(errMsg);
          }
        }),
        filter(res => {
          return !!res;
        }),
        concatMap(res => from(this.authenticateToFirebase({ userId: res! }))),
        tap(res => {
          if (!res) {
            const errMsg = 'login is required';
            this.logout(errMsg);
          }
        }),
        takeUntil(this.stopRefreshTimeout),
      )
      .subscribe();
  }

  initializeSilentAuth(orgId: string): void {
    this.stopRefreshToken = timer(SILENT_REFRESH, SILENT_REFRESH)
      .pipe(
        switchMap(() => this.auth0Service.silentAuth(true, orgId)),
      )
      .subscribe({
        complete: () => {
        },
        next: val => {
        },
        error: (err: any) => {
          let msg = err?.message ?? 'session expired';
          this.logout(msg);
        },
      });

  }

  parseJwt(token: string): Auth0.JwtUserData {
    if (!token) {
      return null;
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+')
      .replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0)
            .toString(16)).slice(-2);
        })
        .join(''),
    );

    const payload = JSON.parse(jsonPayload);
    return payload as Auth0.JwtUserData;
  }

  connectSocket() {
    if (!this.socketMainService?.isValid()) {
      this.socketMainService.connect();
    }
  }

  disconnectSocket() {
    this.socketMainService.disconnect();
  }

  ngOnDestroy(): void {
    this.stopRefreshTimeout.next({});

    if (this.stopRefreshToken) {
      this.stopRefreshToken.unsubscribe();
    }
  }
}
