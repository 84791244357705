import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as WallActions from '@states/wall/wall.actions';
import { catchError, debounceTime, delay, exhaustMap, of, share, switchMap } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { WallService } from '../../development/wall.service';
import { AlertEntry, AlertsService } from '../../development/alerts.service';
import { WallAlert, WallModel } from '@models/wall.model';
import * as _ from 'lodash';
import { AlertMonitoringService } from '../../development/alert-monitoring.service';
import { defaultAlertTileConfig } from '@consts/wall.const';
import { AlertOrdering } from '@enums/wall.enum';
import { sortArrByField } from '../../helpers/common.helpers';
import { WebrtcService } from '../../development/webrtc.service';
import { AppleTvService } from '../../services/apple-tv.service';
import { configureAppleTv, configureAppleTvSuccess } from '@states/wall/wall.actions';

@Injectable()
export class WallEffects {
  private delays: { [tile: number]: any } = {};
  private delaysByAlertId: { [alertId: string]: any } = {};

  public saveWall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.saveWall),
      exhaustMap(() => [SharedActions.setIsSaving({ isSaving: true }), WallActions.sendWall()]),
    ),
  );

  public sendWall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.sendWall),
      withLatestFrom(this.store$.pipe(select(state => state.wallState))),
      switchMap(([, { selectedWall }]) => {
        if (selectedWall._id) {
          const beforeSendWall = this.beforeSaveWallValidation(selectedWall);
          return this.wallService.saveView(beforeSendWall)
            .pipe(
              switchMap(res => {
                return [
                  SharedActions.setIsSaving({ isSaving: false }), //loader off
                  SharedActions.showMessage({ success: 'Wall has been saved' }),
                  WallActions.getWalls(),
                  WallActions.sendWallSuccess({ id: res._id }),
                ];
              }),
              catchError(response => {
                return [
                  SharedActions.setIsSaving({ isSaving: false }), //loader off
                  this.catchError(response),
                ];
              }),
            );
        } else {
          return this.wallService.createView(selectedWall)
            .pipe(
              switchMap(res => {
                return [
                  SharedActions.setIsSaving({ isSaving: false }), //loader off
                  SharedActions.showMessage({ success: 'Wall has been created' }),
                  WallActions.getWalls(),
                  WallActions.sendWallSuccess({ id: res._id }),
                ];
              }),
              catchError(response => {
                return [
                  SharedActions.setIsSaving({ isSaving: false }), //loader off
                  this.catchError(response),
                ];
              }),
            );
        }
      }),
      share(),
    ),
  );


  public getWalls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.getWalls),
      exhaustMap(() => {
        return [WallActions.sendGetWallsRequest()];
      }),
    ),
  );

  public sendGetWallsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.sendGetWallsRequest),
      withLatestFrom(this.store$.pipe(select(state => state.wallState))),
      switchMap(([, { filters, lastWallTimestamp, limit, isLastPage }]) => {
        if (!isLastPage) {
          return this.wallService.get({ ...filters, timestamp: lastWallTimestamp, limit: limit })
            .pipe(
              switchMap(res => {
                return [
                  WallActions.setWalls({ walls: res }),
                  WallActions.setLoader({ isLoading: false }),
                ];
              }),
              catchError(() => {
                return of(WallActions.setLoader({ isLoading: false }));
              }),
            );
        } else {
          return of(SharedActions.doNothing());
        }
      }),
    ),
  );

  public deleteWallById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.deleteWallById),
      switchMap(({ id }) => {
        return this.wallService.delete(id)
          .pipe(
            switchMap(() => {
              return [
                SharedActions.setIsDeleting({ isDeleting: false }), //loader off
                SharedActions.showMessage({ success: 'Wall has been deleted' }),
                WallActions.getWalls(),
                WallActions.deleteWallSuccess(),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.setIsDeleting({ isDeleting: false }), //loader off
                this.catchError(response),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getWallById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.getWallById),
      exhaustMap(({ id }) => [SharedActions.setSomethingWentWrong({ somethingWentWrong: false }), WallActions.sendWallById({ id })]),
    ),
  );

  public getWall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.sendWallById),
      withLatestFrom(this.store$.pipe(select(state => state.wallState))),
      switchMap(([{ id }, { selectedWall }]) => {
        return this.wallService.one(id)
          .pipe(
            switchMap(res => {
              return of(WallActions.setSelectedWall({ selectedWall: res }));
            }),
            catchError(response => {
              return [
                WallActions.sendWallByIdFail(),
                SharedActions.setSomethingWentWrong({ somethingWentWrong: true }), //loader off
                this.catchError(response),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getAlertsMonitoring = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.getAlertsMonitoring),
      withLatestFrom(this.store$.pipe(select(state => state.wallState))),
      switchMap(([, { selectedWall, alertsMonitoringFilters, selectedSetIndex }]) => {
        const cameraEvents = [];
        selectedWall.sets[selectedSetIndex].tiles.forEach(tile => {
          const position = tile?.data;
          if (position?.events) {
            position.events.forEach(event => {
              const cameraEvent = {
                eventId: event.eventId,
                cameraId: event.cameraId,
              };
              cameraEvents.push(cameraEvent);
            });
          }
        });

        if (selectedWall?.alerts?.data) {
          selectedWall.alerts.data?.events?.forEach(event => {
            const cameraEvent = {
              eventId: event.eventId,
              cameraId: event.cameraId,
            };
            cameraEvents.push(cameraEvent);
          });
        }

        return this.alertsService.getAlertMonitoring({ ...alertsMonitoringFilters, eventCameraIds: cameraEvents })
          .pipe(
            switchMap(alerts => {
              const alertLookup = {};

              alerts.forEach(alert => {
                const selectedAt = alert.timestamp;
                alertLookup[alert._id] = {
                  ...alert,
                  tile: null,
                  selectedAt,
                };
              });
              return [
                WallActions.getAlertsMonitoringSuccess({ alerts: alertLookup, tilesAlerts: {} }),
              ];
            }),
            catchError(response => {
              return [
                WallActions.getAlertsMonitoringFail(),
                this.catchError(response),
              ];
            }),
          );
      }),
    ),
  );

  public newAlertSocketReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.newAlertSocketReceived),
      withLatestFrom(this.store$.pipe(select(state => state.wallState))),

      exhaustMap(([{ receivedAlerts }, { selectedWall }]) => {
          if (selectedWall.alertOrdering === AlertOrdering.FIFO) {
            return of(WallActions.newAlertSocketReceivedOrdered({ receivedAlerts }));
          } else {
            return of(WallActions.newAlertSocketReceivedDefault({ receivedAlerts }));
          }
        },
      ),
    ),
  );


  public newAlertSocketReceivedDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.newAlertSocketReceivedDefault),
      withLatestFrom(this.store$.pipe(select(state => state.wallState))),
      switchMap(([{ receivedAlerts }, { selectedWall, alertsMonitoringFilters, selectedSetIndex, alerts, tilesAlerts, isMuted }]) => {
        const { eventPositionLookup } = this.getEventTilesLookup(selectedWall, selectedSetIndex);
        const newAlertsLookup = {};
        const tilesAlertsNew = { ...tilesAlerts };
        const recentTiles: { [key: number]: string } = {};

        receivedAlerts.forEach(receivedAlert => {
          const tile = eventPositionLookup[receivedAlert.eventId];
          if (tile) {
            const selectedAt = receivedAlert.timestamp;
            let isOccup = false;
            let latestAlertTile = null;

            /**
             * Fill empty tiles if exist
             */
            tile.forEach(_tile => {
              if (!tilesAlerts[_tile] && !isOccup) {
                latestAlertTile = _tile;
                recentTiles[_tile] = _tile.toString();
                isOccup = true;
              }
            });
            let latestAlertTimestamp;
            /**
             * If empty tile was not found
             * Look tile with the oldest alert
             */
            // if (!isOccup) {
            //   Object.keys(tilesAlerts)
            //     .forEach(_tile => {
            //       /**
            //        * Check only between tiles that alert allowed
            //        */
            //       if (tile.includes(parseInt(_tile))) {
            //         const alert = tilesAlerts[_tile];
            //         if (!latestAlertTimestamp) {
            //           latestAlertTimestamp = alert.timestamp;
            //           latestAlertTile = _tile;
            //         } else {
            //           if (latestAlertTimestamp > alert.timestamp) {
            //             latestAlertTimestamp = alert.timestamp;
            //             latestAlertTile = _tile;
            //           }
            //         }
            //       }
            //     });
            // }
            // set tile only if tile number is exists
            if (latestAlertTile !== null) {
              tilesAlertsNew[latestAlertTile] = { ...receivedAlert };
            }
            const wallAlert: WallAlert = {
              ...receivedAlert,
              tile: latestAlertTile,
            };
            newAlertsLookup[receivedAlert._id] = wallAlert;
            const playSound = selectedWall.sets[selectedSetIndex]?.tiles[latestAlertTile]?.alertTileConfig?.sound ?? true;
            if (playSound && !isMuted) {
              let audio = new Audio();
              audio.src = '../assets/audio/new-alert.mp3';
              audio.load();
              audio.play();
            }
          }

        });
        if (Object.values(newAlertsLookup)) {
          const concatenatedAlerts = { ...newAlertsLookup, ...alerts };
          const recentTilesArray = Object.values(recentTiles);
          recentTilesArray.forEach(tile => {
            if (this.delays[tile]) {
              clearTimeout(this.delays[tile]);
            }
            const liveViewDuration = selectedWall.sets[selectedSetIndex]?.tiles[tile]?.alertTileConfig?.duration ?? defaultAlertTileConfig.duration;
            this.delays[tile] = setTimeout(() => {
              this.store$.dispatch(WallActions.clearTileInThreshold({ tile: parseInt(tile) }));
            }, liveViewDuration);
          });
          return [
            WallActions.getAlertsMonitoringSuccess({ alerts: concatenatedAlerts, tilesAlerts: tilesAlertsNew }),
            WallActions.refreshAlertsTiles(),
          ];
        } else {
          return of(SharedActions.doNothing());
        }
      }),
    ),
  );

  public newAlertSocketReceivedOrdered$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.newAlertSocketReceivedOrdered),
      withLatestFrom(this.store$.pipe(select(state => state.wallState))),
      mergeMap(([{ receivedAlerts }, { selectedWall, alertsMonitoringFilters, selectedSetIndex, alerts, tilesAlerts, isMuted }]) => {
        const currentSetTiles = selectedWall.sets[selectedSetIndex].tiles;
        const tilesAlertsNew: { [tile: number]: AlertEntry } = {};
        /**
         * Take all tiles from set and fill empty tiles by existing alerts
         */
        currentSetTiles.forEach((tile, index) => {
          // check if it's not camera tile
          if (!tile.data?.cameraId) {
            // const alert = tilesAlerts[index] ? tilesAlerts[index] : null;
            // flow for not replacing occupated tile
            // if (!alert) {
            tilesAlertsNew[index] = null;
            // }
          }
        });

        const tilesKeyAlert = Object.keys(tilesAlertsNew);

        let newAlertsLookup = { ...alerts };

        /**
         * Reverse received alerts cause new alerts will be in the end always.
         */
        [...receivedAlerts].reverse()
          .forEach((alert, index) => {
            /**
             * empty tile is NULL, if undefined -> means received alerts array bigger than tiles array
             */
            const tileKeys = Object.keys(tilesAlertsNew);
            const emptyTile = tileKeys[index];
            newAlertsLookup = {
              [alert._id]: { ...alert, tile: null },
              ...newAlertsLookup,
            };
            if (typeof tilesAlertsNew[emptyTile] !== 'undefined') {
              tilesAlertsNew[emptyTile] = alert;
              const playSound = selectedWall.alerts?.alertTileConfig?.sound ?? true;
              if (playSound && !isMuted) {
                let audio = new Audio();
                audio.src = '../assets/audio/new-alert.mp3';
                audio.load();
                audio.play();
              }

              const liveViewDuration = selectedWall.alerts?.alertTileConfig?.duration ?? defaultAlertTileConfig.duration;
              this.delaysByAlertId[alert?._id] = setTimeout(() => {
                this.store$.dispatch(WallActions.findAndCleanTileByAlertId({ alertId: alert._id }));
              }, liveViewDuration);
            }

          });
        /**
         * Check if new tiles has empty tiles
         * Fill them from old tiles alerts.
         */
        const prevTilesAlerts = Object.values(tilesAlerts);
        let countEmptyTiles = 0;
        tilesKeyAlert.forEach(tile => {
          if (!tilesAlertsNew[tile]) {
            tilesAlertsNew[tile] = prevTilesAlerts[countEmptyTiles];
            countEmptyTiles++;
          }
        });

        /**
         * No refresh tiles if nothing changed inside it
         */
        if (Object.values(tilesAlertsNew)?.length) {
          return [
            WallActions.getAlertsMonitoringSuccess({ alerts: newAlertsLookup, tilesAlerts: tilesAlertsNew }),
            WallActions.refreshAlertsTiles(),
          ];
        } else {
          return [
            WallActions.getAlertsMonitoringTableSuccess({ alerts: newAlertsLookup }),
            WallActions.refreshAlertsTiles(),
          ];
        }

      }),
    ),
  );


  public archiveAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.archiveAlert),
      mergeMap(({ alert, isArchive }) => {
        return this.alertMonitoringService.archiveAlert(alert._id, isArchive)
          .pipe(
            mergeMap(() => {
              if (isArchive) {
                return [
                  WallActions.archiveAlertSuccess({ alertId: alert._id, eventId: alert.eventId }),
                  WallActions.refreshTile({ tile: alert.tile, alertId: alert._id }),
                ];
              } else
                return [
                  WallActions.archiveAlertCanceled({ alertId: alert._id, eventId: alert.eventId }),
                ];

            }),
            catchError(response => {
              return [
                WallActions.archiveAlertFail(),
                this.catchError(response)];
            }),
          );
      }),
    ),
  );


  public wallFilterChanges = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WallActions.setAlertFilterFrequency,
        WallActions.setSelectedSetIndex,
        WallActions.refreshAlerts,
      ),
      switchMap(() => {
        return [
          WallActions.resetTiles(),
          WallActions.getAlertsMonitoring(),
        ];
      }),
    ),
  );

  public getWallsTriggers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WallActions.setSearchFilters,
        WallActions.rmSearchFilters,
        WallActions.nextPage,
      ),
      switchMap(() => {
        return [
          WallActions.getWalls(),
        ];
      }),
      catchError((err) => {
        return of(SharedActions.doNothing());
      }),
    ),
  );

  public refreshTile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.refreshTile),
      withLatestFrom(this.store$.pipe(select(state => state.wallState))),
      switchMap(([{ tile, alertId }, { selectedWall, alertsMonitoringFilters, selectedSetIndex, alerts, tilesAlerts }]) => {

        let shiftedAlerts = {};
        const filterArraysOfTile = Object.values(alerts)
          .filter(alert => {
            return alert.tile === tile;
          });
        const sortedAlerts = sortArrByField(filterArraysOfTile, 'selectedAt');
        const tilesAlertsNew = { ...tilesAlerts };

        shiftedAlerts = alerts;
        /**
         * Refresh tile if alert is archived or removed from list
         * Only if this alert wos selected on tile
         */
        if (tilesAlertsNew[tile]?._id == alertId) {
          tilesAlertsNew[tile] = sortedAlerts.length ? sortedAlerts[0] : null;
        }
        /**
         * When click on specific alert we MUST clean threshold for this tile
         */
        if (this.delays[tile]) {
          clearTimeout(this.delays[tile]);
        }
        /**
         * In case of sorting shift tiles from right to left
         */
        if (selectedWall.alertOrdering !== AlertOrdering.FIFO) {
          const liveViewDuration = selectedWall.sets[selectedSetIndex]?.tiles[tile]?.alertTileConfig?.duration ?? defaultAlertTileConfig.duration;
          this.delays[tile] = setTimeout(() => {
            this.store$.dispatch(WallActions.clearTileInThreshold({ tile: tile }));
          }, liveViewDuration);
        }
        return [
          WallActions.selectAlertSuccess({ tilesAlerts: tilesAlertsNew, alerts: shiftedAlerts }),
          WallActions.checkWebRtcConnections(),
        ];
      }),
    ),
  );

  public changeAndSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.changeAndSave),
      switchMap(({ wall }) => {
        return this.wallService.saveView(wall)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }), //loader off
                SharedActions.showMessage({ success: 'Wall has been updated' }),
                WallActions.getWalls(),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.setIsSaving({ isSaving: false }), //loader off
                this.catchError(response),
              ];
            }),
          );

      }),
      share(),
    ),
  );


  public validationTrigger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WallActions.runValidation,
        WallActions.setWallName,
      ),
      withLatestFrom(this.store$.pipe(select(state => state.wallState))),
      switchMap(([, { selectedWall }]) => {
        if (selectedWall.name) {
          return [
            WallActions.setValidation({ isValid: true }),
          ];
        } else {
          return [
            WallActions.setValidation({ isValid: false }),
          ];
        }

      }),
    ),
  );

  public refreshIsLastPageTrigger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WallActions.changeAndSave,
        WallActions.deleteWallById,
      ),
      switchMap(() => {
        return [
          WallActions.resetToInitialState(),
        ];
      }),
    ),
  );


  public saveQuickView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.saveQuickView),
      exhaustMap(({ name }) => [
        WallActions.setWallName({ name }),
        SharedActions.setIsSaving({ isSaving: true }),
        WallActions.sendWall()],
      ),
    ),
  );


  public findAndCleanTileByAlertId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.findAndCleanTileByAlertId),
      withLatestFrom(this.store$.pipe(select(state => state.wallState))),
      switchMap(([{ alertId }, { alerts }]) => {
          const tilesAlertsArray = alerts ? Object.values(alerts) : [];
          const alert = tilesAlertsArray.find(alert => alert._id === alertId);
          // could be 0
          if (typeof alert?.tile != 'undefined') {
            return [
              WallActions.clearTileInThreshold({ tile: alert.tile }),
              WallActions.refreshAlertsTiles(),
            ];
          } else {
            return [SharedActions.doNothing()];
          }
        },
      ),
    ),
  );


  public refreshAlertsTiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.refreshAlertsTiles),
      withLatestFrom(this.store$.pipe(select(state => state.wallState))),
      mergeMap(([, { alerts, tilesAlerts, selectedWall, selectedSetIndex }]) => {
          const updatedAlerts: { [alertId: string]: WallAlert } = {};
          const alertIdTileMap = {};
          const tileAlertConfigMap = {};
          selectedWall.sets[selectedSetIndex].tiles.forEach((tile, index) => {
            tileAlertConfigMap[index] = tile.alertTileConfig;
          });
          Object.keys(tilesAlerts)
            .forEach(tile => {
              const tileAlert = tilesAlerts[tile];
              if (tileAlert) {
                alertIdTileMap[tileAlert._id] = tile;
              }
            });
          const alertIds = Object.keys(alerts) ?? [];
          alertIds.forEach(alertId => {
            const alert = alerts[alertId];
            const tile = alertIdTileMap[alertId] ?? null;
            let alertTileConfig;
            if (tile) {
              if (selectedWall.alertOrdering === AlertOrdering.FIFO) {
                alertTileConfig = selectedWall.alerts.alertTileConfig;
              } else {
                alertTileConfig = tileAlertConfigMap[tile];
              }
            } else {
              if (this.delaysByAlertId[alertId]) {
                clearTimeout(this.delaysByAlertId[alertId]);
                delete this.delaysByAlertId[alertId];
              }
            }
            updatedAlerts[alertId] = { ...alert, tile, alertTileConfig };
          });
        return [
          WallActions.refreshAlertsTilesSuccess({ alerts: updatedAlerts }),
          WallActions.checkWebRtcConnections(),
        ];
        },
      ),
    ),
  );


  public checkWebRtcConnections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.checkWebRtcConnections),
      withLatestFrom(this.store$.pipe(select(state => state.wallState))),
      mergeMap(([, { alerts, tilesAlerts, selectedWall, selectedSetIndex }]) => {
        let cameraTiles = selectedWall.sets[selectedSetIndex].tiles;
        if (!cameraTiles) {
          throw Error('No tiles in wall');
        }
          const edgeCameraIds = [];
        cameraTiles.forEach(tile => {
          if (tile?.data?.cameraId) {
            edgeCameraIds.push({ edgeId: tile.data.edgeId, cameraId: tile.data.cameraId });
            }
          });

        const tileAlertsArray = Object.values(tilesAlerts);
        tileAlertsArray.forEach(alert => {
          if (alert?.cameraId) {
            edgeCameraIds.push({ edgeId: alert.edgeId, cameraId: alert.cameraId });
          }
        });
          this.webRtcService.cleanExcept(edgeCameraIds);
          return [
            SharedActions.doNothing(),
          ];
        },
      ),
    ),
  );


  public getAppleTv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.getAppleTv),
      switchMap(() => {
        return this.appleTvService.getAll({})
          .pipe(
            switchMap(res => {
              return [
                WallActions.getAppleTvSuccess({ appleTvs: res }),
              ];
            }),
            catchError(() => {
              return of(
                WallActions.getAppleTvFail(),
              );
            }),
          );
      }),
    ),
  );


  public configureAppleTv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallActions.configureAppleTv),
      withLatestFrom(this.store$.pipe(select(state => state.wallState))),
      switchMap(([{ edgeId }, { selectedWall }]) => {
        return this.appleTvService.configureWall({ edgeId, wallId: selectedWall?._id })
          .pipe(
            switchMap(res => {
              return [
                WallActions.configureAppleTvSuccess(),
              ];
            }),
            catchError(() => {
              return of(
                WallActions.getAppleTvFail(),
              );
            }),
          );
      }),
    ),
  );

  constructor(private actions$: Actions,
              private store$: Store<AppState>,
              private wallService: WallService,
              private alertsService: AlertsService,
              private alertMonitoringService: AlertMonitoringService,
              private webRtcService: WebrtcService,
              private appleTvService: AppleTvService,
  ) {
  }

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

  /**
   * Return tiles for specific events
   * @param selectedWall
   * @param selectedSetIndex
   * @private
   */
  private getEventTilesLookup(selectedWall: WallModel, selectedSetIndex: number): {
    eventPositionLookup: { [eventId: string]: number[] };
  } {
    const eventPositionLookup: { [eventId: string]: number[] } = {};
    selectedWall.sets[selectedSetIndex].tiles.forEach((position, tile) => {
      if (position?.data?.events) {
        position?.data.events.forEach(event => {
          const eventId = event?.eventId;
          if (eventId) {
            if (eventPositionLookup[eventId]) {
              eventPositionLookup[eventId].push(tile);
            } else {
              eventPositionLookup[eventId] = [tile];
            }
          }
        });
      }
    });
    return {
      eventPositionLookup,
    };
  }


  private beforeSaveWallValidation(wall: WallModel): WallModel {
    const copyWall = _.cloneDeep(wall);
    return {
      ...copyWall,
      sets: copyWall.sets.map(set => {
        return {
          ...set,
          tiles: set.tiles.map(position => {
            // if cameraEvents selected, do not need to send events
            if (position?.data?.camerasEvents) {
              delete position.data.events;
            }
            return position;
          }),
        };
      }),
    };

  }
}

