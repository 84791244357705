import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PersonSelectionFormFields, SearchObjectTypes, VehicleSelectionFormFields } from '@enums/search.enum';
import { carMadeModel } from '@consts/carMadeModel';
import { forkliftValue, SearchSelectorCarTypes, SearchSelectorOperators } from '../../../advanced-search/search-selector/search-selector.model';
import { accessoryTypeArray, carryingTypeArray, lowerBodyTypeArray, personSelectionFormFieldsTitles, protectivegearTypeArray, searchPropertyMap, upperBodyTypeArray } from '@consts/alert-events.const';
import { Observable } from 'rxjs';
import * as MultiSearchSelectors from '@states/multi-search/multi-search.selectors';
import { select, Store } from '@ngrx/store';
import { UIInputStyle } from '@enums/shared.enum';
import { PeopleUploadDialogComponent, PeopleUploadDialogData, PeopleUploadDialogDataResult } from '../../../../pages/database/pages/people/dialogs/people-upload-dialog/people-upload-dialog.component';
import { GroupModels } from '@models/people.model';
import { MatDialog } from '@angular/material/dialog';
import { UserSelectors } from '@states/user/user.selector-types';
import { CameraEditSelectors } from '@states/camera-edit/camera-edit.selector-types';
import { CameraEditActions } from '@states/camera-edit/camera-edit.action-types';

@Component({
  selector: 'app-attribute-values',
  templateUrl: './attribute-values.component.html',
  styleUrls: ['./attribute-values.component.scss'],
})
export class AttributeValuesComponent implements OnInit, OnChanges, OnDestroy {
  public selectCameraSearchSettingsFilters$: Observable<{ [key: string]: boolean }> = this.store$.select(
    MultiSearchSelectors.selectCameraSearchSettingsFilters,
  );
  public selectIsDeveloper$: Observable<boolean> = this.store$.pipe(select(UserSelectors.isDeveloper));

  // private isHasOrgForklift$ = false;
  public inputStyle = UIInputStyle;

  @Input() displayOperator = true;
  @Input() selectedObject: UntypedFormGroup;
  @Input() panel = false;
  @Input() standalone = false;
  @Input() searchPerson = false;
  public searchObjectTypes = SearchObjectTypes;


  @Input() facesHidden = false;
  @Input() showFaceAttribute = true;

  public groups: GroupModels.GroupSearchItem[] = [];


  public carMadeOptions: string[] = Object.keys(carMadeModel);
  public searchSettingsMap = searchPropertyMap;
  public searchSelectorCarTypes: { name: string; value: string }[] = [];
  public carModelsOptions: string[] = [];
  public personSelectionFormFields = PersonSelectionFormFields;
  public vehicleSelectionFormFields = VehicleSelectionFormFields;
  public searchSelectorsOperators = SearchSelectorOperators;
  public personSelectionFormFieldsTitles = personSelectionFormFieldsTitles;

  public licensePlateInput = '';
  public isHasOrgForklift$ = this.store$.pipe(select(CameraEditSelectors.selectOrgForklift));

  constructor(private store$: Store, private dialog: MatDialog) {

  }

  ngOnDestroy(): void {
    if (this.licensePlateInput) {
      this.addPlate();
    }
  }

  ngOnInit(): void {
    this.groups = this.selectedObject.get('groups')?.value ? [...this.selectedObject.get('groups')?.value] : [];
    this.store$.dispatch(CameraEditActions.getHasOrgForklift());
    this.isHasOrgForklift$.subscribe((value) => {
      const updatedSearchSelectorCarTypes = value ? SearchSelectorCarTypes : SearchSelectorCarTypes.filter(item => item.value !== forkliftValue);
      this.searchSelectorCarTypes = updatedSearchSelectorCarTypes.map(item => {
        return { name: item.name.replace('_', ' '), value: item.value };
      });
    });

  }

  toggleFace() {
    this.selectedObject.controls['groupIdCollapsed'].setValue(this.facesHidden);
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.facesHidden = this.selectedObject?.controls['groupIdCollapsed']?.value;
    if (this.selectedObject.controls['type'].value === SearchObjectTypes.VEHICLE) {
      const makeForm = this.selectedObject.controls['properties']['controls']['make'] as UntypedFormGroup;
      const modelForm = this.selectedObject.controls['properties']['controls']['model'] as UntypedFormGroup;
      if (makeForm) {
        this.carModelsOptions = [];
        const selectedCarMade = makeForm.value?.value ?? [];
        if (!makeForm.value.enabled) {
          this.carMadeOptions.map(make => {
            this.carModelsOptions = this.carModelsOptions.concat(carMadeModel[make]);
          });
        } else {
          selectedCarMade.map(make => {
            this.carModelsOptions = this.carModelsOptions.concat(carMadeModel[make]);
          });
        }
      }
    }
  }

  public getKeys(obj: any) {
    return Object.keys(obj);
  }

  public unselectCarMade(option: string): void {
    const makeForm = this.selectedObject.controls['properties']['controls']['make'] as UntypedFormGroup;
    const carMakesArray = [...makeForm.value.value];
    const index = carMakesArray.indexOf(option);
    if (index > -1) {
      carMakesArray.splice(index, 1);
    }
    this.makeChanged(carMakesArray);
    // this.selectedObject.controls['properties']['controls']['make'].patchValue({ value: carMakesArray });
  }

  public getChipsArray(property: string): { name: string; value: string }[] {
    switch (property) {
      case PersonSelectionFormFields.lowerbodyType:
        return lowerBodyTypeArray;
      case PersonSelectionFormFields.upperbodyType:
        return upperBodyTypeArray;
      case PersonSelectionFormFields.accessoryType:
        return accessoryTypeArray;
      case PersonSelectionFormFields.carryingType:
        return carryingTypeArray;
      case PersonSelectionFormFields.protectiveGear:
        return protectivegearTypeArray;
      default:
        return [];
    }
  }

  public makeChanged(ev) {
    this.carModelsOptions = [];

    const selectedCarMade = ev ?? [];
    if (ev.length) {
      selectedCarMade.map(make => {
        this.carModelsOptions = this.carModelsOptions.concat(carMadeModel[make]);
      });
    } else {
      this.carMadeOptions.map(make => {
        this.carModelsOptions = this.carModelsOptions.concat(carMadeModel[make]);
      });
    }

    const makeForm = this.selectedObject.controls['properties']['controls']['make'] as UntypedFormGroup;

    makeForm.patchValue({ value: ev });
  }

  public modelChanged(ev) {
    const modelForm = this.selectedObject.controls['properties']['controls']['model'] as UntypedFormGroup;
    modelForm.patchValue({ value: ev });
  }

  //
  // public get groups() {
  //   return this.selectedObject?.get('groups')?.value ?? [];
  // }

  public addGroup(item: GroupModels.GroupSearchItem, selector = false) {
    this.groups.push(item);
    const groupIds = this.groups.map(group => group.personIds)
      .flat()
      .map(groupId => +groupId);
    this.selectedObject.patchValue({
      groups: this.groups,
      groupId: groupIds,
    });
  }

  public deleteGroup(idx: number) {
    this.groups.splice(idx, 1);
    const groupIds = this.groups.map(group => group.personIds)
      .flat()
      .map(groupId => +groupId);
    this.selectedObject.patchValue({
      groups: !!this.groups.length ? this.groups : null,
      groupId: groupIds.length ? groupIds : null,
    });
  }

  public get plates() {
    return this.selectedObject.controls['properties'].value?.plate?.value ?? [];
  }

  public addPlate() {
    const current = [...this.selectedObject.controls['properties'].value?.plate?.value ?? []];
    if (!current) {
      this.selectedObject.controls['properties']['controls']['plate'].patchValue({ value: [this.licensePlateInput] });
    } else {
      current.push(this.licensePlateInput);
      this.selectedObject.controls['properties']['controls']['plate'].patchValue({ value: current });
    }
    this.licensePlateInput = '';
  }

  public removePlate(idx: number) {
    const current = [...this.selectedObject.controls['properties'].value?.plate?.value ?? []];
    current.splice(idx, 1);
    this.selectedObject.controls['properties']['controls']['plate'].patchValue({ value: current });
  }

  // public uploadImage() {
  //   const data: PeopleUploadDialogData = {
  //     isSearch: true,
  //   };
  //   this.dialog.open(PeopleUploadDialogComponent, {
  //     width: '400px',
  //     panelClass: 'modal-no-padding',
  //     data,
  //   })
  //     .afterClosed()
  //     .subscribe((group: PeopleUploadDialogDataResult) => {
  //       if (!!group) {
  //         this.addGroup(group);
  //       }
  //     });
  // }
}
