import { AppUser } from '../../user/user.model';
import User = AppUser.User;
import { Quality } from '../../shared/ui-kit/ui-quality-selector/quality-selector.component';

export declare interface CreateOrganizationParams {
  orgName: string;
  orgEmail: string;
  userId: string;
  userEmail: string;
}

export declare interface CreateOrganizationResponse {
  user: User;
  orgId: string;
  orgName: string;
  orgEmail: string;
}

export declare interface Organization {
  orgId: string;
  orgName: string;
  orgEmail: string;
  orgColor: string;
  mfa?: MfaSettings;
  ipRules: IpRules;
  videoQuality?: Quality;
  localLiveStream?: boolean;
  webSocketPlayback?: boolean;
  forceWebrtcRelay?: boolean;
  liveViewType?: LiveViewType;
  showCores?: boolean;
  mobileLocalLiveViewType?: MobileLocalLiveViewType;
}

export enum LiveViewType {
  Webrtc = 'webrtc',
  Livekit = 'livekit',
  CloudflareCalls = 'cloudflare-calls',
}


export enum MobileLocalLiveViewType {
  None = 'none',
  Rtsp = 'rtsp',
  Hls = 'hls',
}

export declare interface ActiveOrganization extends Organization {
  /**
   * @deprecated
   */
  roles: string[];
}

export declare interface ActiveOrganizationResponse {
  _id: string;
  settings: {
    orgName: string;
    orgEmail: string;
    orgColor: string;
    mfa?: MfaSettings;
    ipRules: IpRules;
    videoQuality: Quality;
    localLiveStream?: boolean;
    webSocketPlayback?: boolean;
    forceWebrtcRelay?: boolean;
    liveViewType?: LiveViewType;
    showCores?: boolean;
    mobileLocalLiveViewType?: MobileLocalLiveViewType
  },
  users: { [key: string]: User };
}

export declare interface OrganizationUser {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  phone?: string;
  sms?: boolean;
  mobile?: boolean;
  roles: string[];
}

export declare interface GetOrganizationUsersResponse {
  users: OrganizationUser[];
  totalItems: number;
}

export declare interface UserOrganizationDropDown {
  id: string;
  _id?: string;
  name: string;
  logo: string;
  color: string;
  roles?: string[];
  totalMembers?: number;
  authProviderId?: string;
  email?: string;
  isDeletePermission: boolean;
}

export declare interface UserFilters {
  orgIds?: string[];
  start?: string;
  end?: string;
  query?: string;
  orderBy?: string;
  orderDirection?: number; // 1 or -1
  groups?: string[];
}

export interface MfaSettings {
  enabled?: boolean;
}

export interface ApiKeyDto {
  expiresIn: number,
  name: string
}


export interface IpRules {
  public: boolean;
  private: boolean;
  ipRanges: string[];
}


export declare interface OrganizationUserFilters {
  query?: string;
  groups?: string[];
  orderBy?: string;
  orderDirection?: number; // 1 or -1
}
