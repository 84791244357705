<!--                  DEFAULT ALERT VIEW-->
<ng-container *ngIf="!byLocationEnabled">

  <div fxFlex>
    <app-alert-row *ngFor="let alert of availableAlerts"
                   [alertEvent]="alert"
                   [alertsLookup]="selectEventsLookup"
                   [selectedAlertEvents]="selectedAlertEventsV2"
                   [camerasLookup]="selectCamerasLookup"
                   (onAlertSelected)="onAlertSelected.emit($event)"
    >
    </app-alert-row>
  </div>
</ng-container>

<!--                  View By Location ENABLE-->
<ng-container *ngIf="byLocationEnabled">
  <div fxFlex>
    <app-location-row *ngFor="let location of allCamerasByLocation"
                      [location]="location"
                      [selectedCameras]="selectedCameras"
                      (onCameraSelected)="onCameraSelected.emit($event)"
                      (onLocationSelected)="onLocationSelected.emit($event)"
    >

    </app-location-row>
  </div>
</ng-container>
