<ng-container *ngrxLet="selectIsLocal$; let isLocal">
  <ng-container *ngrxLet="isDeveloper$; let isDeveloper">
    <div class="player-type-wrapper" *ngIf="isDeveloper">
      <ng-container *ngIf="!isLocal || forceRemote || playback; else localDisplay">
        <ng-container *ngIf="playback; else liveViewDisplay">
          {{ playbackType | titlecase }} playback
        </ng-container>
        <ng-template #liveViewDisplay>
          {{ liveViewType | titlecase }}
        </ng-template>
      </ng-container>
      <ng-template #localDisplay>
        Local HLS
      </ng-template>
    </div>

    <!-- PLAYBACK -->
    <ng-container *ngIf="playback; else liveView">
      <ng-container [ngSwitch]="playbackType">
        <ng-container *ngSwitchCase="PlaybackType.HLS">
          <video-hls-playback #player
                              [edgeId]="edgeId"
                              [cameraId]="cameraId"
                              [locationId]="locationId"
                              [resolution]="resolution"
                              [placeholder]="placeholder"
                              [inactive]="inactive"
                              [autostart]="autostart"
                              [accessToken]="accessToken"
                              (showRetry)="showRetry.emit()"
                              (playing)="playing.emit($event)"
                              (qualityChange)="qualityChange.emit($event)"
                              (_setPlaceholder)="setPlaceholder.emit($event)"
                              (setErrorMsg)="setErrorMsg.emit($event)"
                              (setLoader)="setLoader.emit($event)"
                              (setInactive)="setInactive.emit($event)"
                              [isRespectRatio]="isRespectRatio"
                              [cameraView]="cameraView"
          ></video-hls-playback>
        </ng-container>
        <ng-container *ngSwitchCase="PlaybackType.Webrtc">
          <video-webrtc #player
                        [edgeId]="edgeId"
                        [cameraId]="cameraId"
                        [locationId]="locationId"
                        [resolution]="resolution"
                        [placeholder]="placeholder"
                        [inactive]="inactive"
                        [autostart]="autostart"
                        [accessToken]="accessToken"
                        (showRetry)="showRetry.emit()"
                        (playing)="playing.emit($event)"
                        (qualityChange)="qualityChange.emit($event)"
                        (_setPlaceholder)="setPlaceholder.emit($event)"
                        (setErrorMsg)="setErrorMsg.emit($event)"
                        (setLoader)="setLoader.emit($event)"
                        (setInactive)="setInactive.emit($event)"
                        [cameraView]="cameraView"
                        [isRespectRatio]="isRespectRatio"
          ></video-webrtc>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- LIVE VIEW -->
    <ng-template #liveView>
      <!-- LOCAL -->
      <ng-container *ngIf="localLiveStreamEnabled && isLocal && !forceRemote; else notLocal">
        <video-hls-local #player
                         [edgeId]="edgeId"
                         [cameraId]="cameraId"
                         [locationId]="locationId"
                         [resolution]="resolution"
                         [placeholder]="placeholder"
                         [inactive]="inactive"
                         [autostart]="autostart"
                         [accessToken]="accessToken"
                         (showRetry)="showRetry.emit()"
                         (playing)="playing.emit($event)"
                         (qualityChange)="qualityChange.emit($event)"
                         (_setPlaceholder)="setPlaceholder.emit($event)"
                         (setErrorMsg)="setErrorMsg.emit($event)"
                         (setLoader)="setLoader.emit($event)"
                         (setInactive)="setInactive.emit($event)"
                         [cameraView]="cameraView"
                         [isRespectRatio]="isRespectRatio"
                         (fallback)="localFallback()"
        ></video-hls-local>
      </ng-container>
      <!-- NOT LOCAL -->
      <ng-template #notLocal>
        <ng-container [ngSwitch]="liveViewType">
          <ng-container *ngSwitchCase="LiveViewType.Webrtc">
            <video-webrtc #player
                          [edgeId]="edgeId"
                          [cameraId]="cameraId"
                          [locationId]="locationId"
                          [resolution]="resolution"
                          [placeholder]="placeholder"
                          [inactive]="inactive"
                          [autostart]="autostart"
                          [accessToken]="accessToken"
                          (showRetry)="showRetry.emit()"
                          (playing)="playing.emit($event)"
                          (qualityChange)="qualityChange.emit($event)"
                          (_setPlaceholder)="setPlaceholder.emit($event)"
                          (setErrorMsg)="setErrorMsg.emit($event)"
                          (setLoader)="setLoader.emit($event)"
                          (setInactive)="setInactive.emit($event)"
                          [isRespectRatio]="isRespectRatio"
                          [cameraView]="cameraView"

            ></video-webrtc>
          </ng-container>
          <ng-container *ngSwitchCase="LiveViewType.Livekit">
            <video-livekit #player
                           [edgeId]="edgeId"
                           [cameraId]="cameraId"
                           [locationId]="locationId"
                           [resolution]="resolution"
                           [placeholder]="placeholder"
                           [inactive]="inactive"
                           [autostart]="autostart"
                           [accessToken]="accessToken"
                           (showRetry)="showRetry.emit()"
                           (playing)="playing.emit($event)"
                           (qualityChange)="qualityChange.emit($event)"
                           (_setPlaceholder)="setPlaceholder.emit($event)"
                           (setErrorMsg)="setErrorMsg.emit($event)"
                           (setLoader)="setLoader.emit($event)"
                           (setInactive)="setInactive.emit($event)"
                           [isRespectRatio]="isRespectRatio"
                           [cameraView]="cameraView"

            ></video-livekit>
          </ng-container>
          <ng-container *ngSwitchCase="LiveViewType.CloudflareCalls"></ng-container>
        </ng-container>
      </ng-template>
    </ng-template>
  </ng-container>
</ng-container>
