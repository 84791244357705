import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'camera-tile-settings',
  templateUrl: './camera-tile-settings.component.html',
  styleUrls: ['./camera-tile-settings.component.scss', '../alert-tile-settings/alert-tile-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraTileSettingsComponent {
  @Input() settingsForm: UntypedFormGroup;

}
