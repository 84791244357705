import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, forkJoin, map, mergeMap, share, switchMap, withLatestFrom } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { UtilsService } from '../../edge/utils.service';
import { WallService } from '../../development/wall.service';
import { FloorActions } from '@states/floors/floor.action-types';
import { FloorService } from '../../development/floor.service';
import { CustomEventsActions } from '@states/custom-events/custom-events.action-types';
import { SharedActions } from '@states/shared/shared.action-types';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { GroupModels } from '@models/people.model';
import { ActiveOrganization } from '@models/organization.model';
import { FloorModel } from '@models/floor.model';
import BuildingDocument = FloorModel.BuildingDocument;

@Injectable()
export class FloorEffects {

  public pressSave$ = createEffect(() => this.actions$.pipe(ofType(CustomEventsActions.pressSave), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public getBuildings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.getBuildings),
      withLatestFrom(
        this.store$.pipe(select(state => state.floorState)),
      ),
      switchMap(([, { filters }]) => {
        return this.floorService
          .getBuildings()
          .pipe(
            switchMap(res => {
              return [
                FloorActions.getBuildingsSuccess({ documents: res }),
                SharedActions.setIsLoading({ isLoading: false }),
              ];
            }),
            catchError(response => {
              return [
                FloorActions.getBuildingsFail(),
                SharedActions.setIsLoading({ isLoading: false }),

              ];
            }),
            share(),
          );
      }),
    ),
  );

  // public setFilter$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(FloorActions.setQueryStringFilter),
  //     debounceTime(400),
  //     exhaustMap(() => [FloorActions.resetEntities(), FloorActions.getFloor()]),
  //   ),
  // );


  public startSaveBuilding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.startSaveBuilding),
      switchMap(({ building, addFloor }) => [FloorActions.setIsSaving({ isSaving: true }), FloorActions.saveBuildingRequest({ building, addFloor })]),
    ),
  );

  public saveBuildingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.saveBuildingRequest),
      switchMap(({ building, addFloor }) => {
        const op = building._id ? this.floorService.updateBuilding(building) : this.floorService.createBuilding(building);
        return op
          .pipe(
            switchMap((res) => {

              return [
                FloorActions.setIsSaving({ isSaving: false }),
                // FloorActions.resetToInitialState(),
                // FloorActions.getBuildings(),
                FloorActions.saveBuildingRequestSuccess({ building: building?._id ? building as BuildingDocument : res, addFloor }),
                SharedActions.showMessage({ success: addFloor ? 'Floor added' : (building._id ? 'Building updated' : 'Building created') }),
              ];
            }),
            catchError(response => {
              return [
                FloorActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                FloorActions.saveBuildingRequestFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public deleteFloor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.deleteFloor),
      switchMap(({ buildingId, floorId }) => [FloorActions.setIsSaving({ isSaving: true }), FloorActions.deleteFloorRequest({ buildingId, floorId })]),
    ),
  );

  public deleteFloorRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.deleteFloorRequest),
      switchMap(({ buildingId, floorId }) => {
        return this.floorService.deleteFloor(buildingId, floorId)
          .pipe(
            switchMap((res) => {
              return [
                FloorActions.setIsSaving({ isSaving: false }),
                FloorActions.deleteFloorRequestSuccess({ buildingId, floorId }),
                SharedActions.showMessage({ success: 'Floor deleted' }),
                // FloorActions.getBuildings(),
              ];
            }),
            catchError(response => {
              return [
                FloorActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                FloorActions.deleteFloorRequestFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public deleteBuilding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.deleteBuilding),
      switchMap(({ buildingId }) => [FloorActions.setIsSaving({ isSaving: true }), FloorActions.deleteBuildingRequest({ buildingId })]),
    ),
  );

  public deleteBuildingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.deleteBuildingRequest),
      switchMap(({ buildingId }) => {
        return this.floorService.deleteBuilding(buildingId)
          .pipe(
            switchMap((res) => {
              return [
                FloorActions.setIsSaving({ isSaving: false }),
                FloorActions.deleteBuildingRequestSuccess({ buildingId }),
                SharedActions.showMessage({ success: 'Building deleted' }),
                FloorActions.getBuildings(),
              ];
            }),
            catchError(response => {
              return [
                FloorActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                FloorActions.deleteBuildingRequestFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public editFloor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.editFloor),
      switchMap(({ buildingId, floorId, floor }) => [FloorActions.setIsSaving({ isSaving: true }), FloorActions.editFloorRequest({ buildingId, floorId, floor })]),
    ),
  );

  public editFloorRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.editFloorRequest),
      switchMap(({ buildingId, floorId, floor }) => {
        return this.floorService.editFloor(buildingId, floorId, floor)
          .pipe(
            switchMap((res) => {
              return [
                FloorActions.setIsSaving({ isSaving: false }),
                FloorActions.editFloorRequestSuccess({ buildingId, floorId, floor }),
                SharedActions.showMessage({ success: 'Floor saved' }),
                // FloorActions.getBuildings(),
              ];
            }),
            catchError(response => {
              return [
                FloorActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                FloorActions.editFloorRequestFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public createFloor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.createFloor),
      switchMap(({ buildingId, floor }) => [
        FloorActions.setIsSaving({ isSaving: true }),
        FloorActions.createFloorRequest({ buildingId, floor }),
      ]),
    ),
  );

  public createFloorRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.createFloorRequest),
      switchMap(({ buildingId, floor }) => {
        return this.floorService.createFloor(buildingId, floor)
          .pipe(
            switchMap((res) => {
              return [
                FloorActions.setIsSaving({ isSaving: false }),
                FloorActions.createFloorRequestSuccess({ buildingId, floor }),
                SharedActions.showMessage({ success: 'Floor created' }),
              ];
            }),
            catchError(response => {
              return [
                FloorActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessage({ error: this.utilsService.errMessage(response) }),
                FloorActions.createFloorRequestFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  UploadImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.UploadImages),
      map(res => res.request),
      exhaustMap((request) => {
        return [
          // SharedActions.setIsSaving({isSaving: true}),
          FloorActions.UploadImagesSend({
            request,
          }),
        ];
      }),
    ),
  );

  uploadImagesSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.UploadImagesSend),
      withLatestFrom(this.store$.pipe(select(OrganizationSelectors.selectActiveOrganization))),
      map((res) => {
        return {
          request: res[0].request,
          org: res[1],
        };
      }),
      switchMap((data: {
        request: GroupModels.FileAsset[],
        org: ActiveOrganization
      }) => {
        const orgId = data.org.orgId;

        const presignedReqeust$ = data.request.map(e => this.floorService.getImagePreSignedUrl({ asset: e.asset }, e.file));
        const presignedResponse$ = forkJoin(presignedReqeust$);

        return presignedResponse$
          .pipe(
            mergeMap(res => {
              const uploadReqeust$ = res.map(e => this.floorService.uploadImagePresignedUrl({ url: e.url, file: e.file, filename: e.filename }));
              const uploadResponse$ = forkJoin(uploadReqeust$);

              return uploadResponse$;
            }),
            switchMap(response => {
              return [FloorActions.UploadImagesSendSuccess({ response })];
            }),
            catchError(err => {
              return [FloorActions.UploadImagesSendError()];
            }),
          );

        return [];
      }),
      share(),
    ),
  );


  constructor(private floorService: FloorService,
              private actions$: Actions,
              private store$: Store<AppState>,
              private utilsService: UtilsService,
              private wallService: WallService) {
  }

}
