import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-filter-chip',
  templateUrl: './ui-filter-chip.component.html',
  styleUrls: ['./ui-filter-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiFilterChipComponent implements OnInit {
  @Input() title: string;
  @Input() field: string;
  @Input() value: any;
  @Input() allowDelete = true;

  @Output() onRemoveClicked: EventEmitter<{ value: string; field: string }> = new EventEmitter<{ value: string; field: string }>();

  constructor() {
  }

  ngOnInit(): void {
  }

}
