import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
;
import { AuthenticationService } from '../../../authentication/authentication.service';

@Component({
  selector: 'app-inactivity-modal',
  templateUrl: './inactivity-modal.component.html',
  styleUrls: ['./inactivity-modal.component.scss'],
})
export class InactivityModalComponent implements OnInit {

  public counterInterval;

  constructor(private dialogRef: MatDialogRef<InactivityModalComponent>,
              private authService: AuthenticationService,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                autoLogoutCounter: number
              }) {
  }

  ngOnInit(): void {
    this.counterInterval = setInterval(() => {
      this.data.autoLogoutCounter -= 1;
      if (!this.data.autoLogoutCounter) {
        this.authService.logout('session expired');
        clearInterval(this.counterInterval);
      }
    }, 1000);
  }

  public close() {
    if (this.counterInterval) {
      clearInterval(this.counterInterval);
    }
    this.dialogRef.close(true);
  }


}
