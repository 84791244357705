import { Injectable } from '@angular/core';
import { api } from '@consts/url.const';
import { HttpClient } from '@angular/common/http';
import { SearchConfigurationSendModel, VpnConfiguration } from '@models/search-configuration.model';
import { Observable } from 'rxjs';
import { SelectedCamera } from '@models/alert-events.model';
import { SQSMsgInfo } from '../core/interfaces';
import { CameraTestData } from '../cameras/camera.model';

@Injectable()
export class SearchConfigurationService {
  constructor(private http: HttpClient) {
  }

  public create(data: SearchConfigurationSendModel): Observable<SQSMsgInfo> {
    return this.http.post<SQSMsgInfo>(api.searchConfiguration.crud, data);
  }

  public getOne(locationId: string, edgeId: string, cameraId: string): Observable<SearchConfigurationSendModel> {
    return this.http.get<SearchConfigurationSendModel>(api.searchConfiguration.one(locationId, edgeId, cameraId));
  }

  public update(id: string, searchConfiguration: SearchConfigurationSendModel) {
    return this.http.put<SQSMsgInfo>(`${api.searchConfiguration.crud}/${id}`, searchConfiguration);
  }

  public remove(id: string, selectedCameraParams: SelectedCamera): Observable<any> {
    return this.http.post(api.searchConfiguration.delete(id), selectedCameraParams);
  }

  public getVpn(locationId: string, edgeId: string, cameraId: string): Observable<SQSMsgInfo> {
    return this.http.get<SQSMsgInfo>(api.searchConfiguration.vpn(locationId, edgeId, cameraId));
  }

  public updateVpn(locationId: string, edgeId: string, cameraId: string, vpnConfiguration?: VpnConfiguration, autoConfigure?: boolean): Observable<SQSMsgInfo> {
    return this.http.put<SQSMsgInfo>(api.searchConfiguration.vpn(locationId, edgeId, cameraId), { vpnConfiguration, autoConfigure });
  }

  public vpnReboot(locationId: string, edgeId: string, cameraId: string, restartStreamer?: boolean): Observable<SQSMsgInfo> {
    return this.http.post<SQSMsgInfo>(api.searchConfiguration.vpnReboot(locationId, edgeId, cameraId), { restartStreamer });
  }

  public vpnTest(locationId: string, edgeId: string, cameraId: string, width: number, height: number): Observable<SQSMsgInfo> {
    return this.http.get<SQSMsgInfo>(api.searchConfiguration.vpnTest(locationId, edgeId, cameraId, width, height));
  }

  public cameraTest(locationId: string, edgeId: string, width: number, height: number, data: CameraTestData): Observable<SQSMsgInfo> {
    return this.http.post<SQSMsgInfo>(api.searchConfiguration.cameraTest(locationId, edgeId, width, height), data);
  }

  public hasProtectiveGear(): Observable<boolean> {
    return this.http.get<boolean>(api.searchConfiguration.hasProtectiveGear);
  }

  public hasForklift(): Observable<boolean> {
    return this.http.get<boolean>(api.searchConfiguration.hasForklift);
  }

  public hasShoppingCart(): Observable<boolean> {
    return this.http.get<boolean>(api.searchConfiguration.hasShoppingCart);
  }

}
