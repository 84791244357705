export enum WallAlertListPosition {
  NONE,
  TOP,
  BOTTOM,
  LEFT,
  RIGHT,
}


export enum AlertOrdering {
  FIFO, // ordered Alerts
  FREE_TILE
}

export enum WallLayoutType {
  CAMERAS,
  CAMERAS_ALERTS
}
