import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, exhaustMap, of, share, switchMap, tap, throwError } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { withLatestFrom } from 'rxjs/operators';
import * as SharedActions from '@states/shared/shared.actions';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { MenuKey } from '@enums/menu.enum';
import { LocationSelectors } from '@states/location/location.selector-types';
import { SessionDataAction } from '@enums/session-data.enum';
import { VariableActions } from '@states/variable/variable.action-types';
import { VariableSendModel } from '@models/variable.model';
import { VariableService } from 'src/app/development/variable.service';
import { SearchSelection, SearchSelectionCarProperty, SearchSelectionPersonProperty } from '@models/search.model';
import { PersonSelectionFormFields, SearchObjectTypes, VehicleSelectionFormFields } from '@enums/search.enum';
import { ageTypeRadioValues } from '@consts/alert-events.const';
import { AlertType, AnalyticClasses, ConfigurationFilterType, DetectionType } from '@enums/alert-events.enum';
import { AlertEventsConfigurationFilter, AlertEventSendModel } from '@models/alert-events.model';
import * as AlertEventsActions from '@states/alert-events/alert-events.actions';
import { VariableType } from '@enums/variable.enum';
import { MenuActions } from '@states/menu/menu.action-types';
import { variableMenuLevel3AlertCreate, variableMenuLevel3SearchCreate } from '@consts/menu.const';
import { AlertEventsState } from '@states/alert-events/alert-events.reducer';
import TurndownService from 'turndown';
import { EdgeActions } from '@states/edge/edge.action-types';

@Injectable()
export class VariableEffect {
  public pressContinue$ = createEffect(() => this.actions$.pipe(ofType(VariableActions.continueVariable), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressBack$ = createEffect(() => this.actions$.pipe(ofType(VariableActions.backVariable), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public detailsValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.validateDetails),
      withLatestFrom(this.store$.pipe(select(state => state.variableState))),
      switchMap(([, { variableName, selectedVariableType }]) => {
        if (variableName && selectedVariableType !== null) {
          return of(VariableActions.addValidMenuPoint({ point: MenuKey.details }));
        } else {
          return [SharedActions.showMessage({ warning: 'All fields are required' })];
        }
      }),
      share(),
    ),
  );

  public dataValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.validateData),
      withLatestFrom(this.store$.pipe(select(state => state.variableState))),
      switchMap(([, { selectedCameras, selectedVariableType }]) => {
        if (!!selectedCameras.length || selectedVariableType === VariableType.compound) {
          return of(VariableActions.addValidMenuPoint({ point: MenuKey.data }));
        } else {
          return [SharedActions.showMessage({ warning: 'You must select at least one camera' })];
        }
      }),
      share(),
    ),
  );

  public saveVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.saveVariable),
      withLatestFrom(this.store$.pipe(select(state => state.variableState))),
      exhaustMap(([, { compoundFormulaInvalid, selectedVariableType }]) => {
        if (selectedVariableType === VariableType.compound && compoundFormulaInvalid) {
          return of(
            SharedActions.showMessage({
              error: 'Compound formula is invalid',
            }),
          );
        }
        return [SharedActions.setIsSaving({ isSaving: true }), VariableActions.sendVariable()];
      }),
    ),
  );

  public sendVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.sendVariable),
      withLatestFrom(
        this.store$.pipe(select(state => state.variableState)),
        this.store$.select(LocationSelectors.selectLocationLookup),
        this.store$.pipe(select(state => state.alertEventsState)),
      ),
      switchMap(([, variableState, locationLookup, alertEventState]) => {
        let variable: VariableSendModel;
        switch (variableState.selectedVariableType) {
          case VariableType.search:
            const searchSelections: SearchSelection[] = variableState.objectSelections?.map(selection => {
              let properties = [];
              let searchSelectionCarProperty: SearchSelectionCarProperty = {};
              const propsKeys = Object.keys(selection.properties ?? {});

              if (selection.type === SearchObjectTypes.PERSON) {
                propsKeys.forEach(propKey => {
                  let value = [];
                  let colors = [];
                  const props = selection.properties[propKey].props;
                  switch (propKey) {
                    case PersonSelectionFormFields.genderType:
                      const propsKeys = Object.keys(props);
                      propsKeys.forEach(_prop => {
                        if (props[_prop]) {
                          value.push(_prop);
                        }
                      });
                      break;
                    case PersonSelectionFormFields.ageType:
                      const ageKeys = Object.keys(props);
                      ageKeys.forEach(_prop => {
                        if (props[_prop]) {
                          value = value.concat(ageTypeRadioValues[_prop]);
                        }
                      });
                      break;
                    case PersonSelectionFormFields.footwearType:
                    case PersonSelectionFormFields.hairType:
                      colors = (props['colors'] as string[]) ?? [];
                      break;
                    case PersonSelectionFormFields.lowerbodyType:
                    case PersonSelectionFormFields.upperbodyType:
                      value = (props['type'] as string[]) ?? [];
                      colors = (props['colors'] as string[]) ?? [];
                      break;
                    case PersonSelectionFormFields.accessoryType:
                    case PersonSelectionFormFields.carryingType:
                      value = (props['type'] as string[]) ?? [];
                      break;
                  }
                  const prop: SearchSelectionPersonProperty = {
                    name: propKey,
                    enabled: selection.properties[propKey].enabled,
                    operator: selection.properties[propKey].operator,
                    value,
                    colors,
                  };
                  properties.push(prop);
                });
              } else if (selection.type === SearchObjectTypes.VEHICLE) {
                // const colors = selection.properties[VehicleSelectionFormFields.colors];
                // const model = selection.properties[VehicleSelectionFormFields.model];
                // const make = selection.properties[VehicleSelectionFormFields.make];
                const type = selection.properties[VehicleSelectionFormFields.type];
                // const additionalProperties = selection.properties[VehicleSelectionFormFields.additionalProperty]?.props;
                // const plate = additionalProperties['plate'] as string;
                // const region = additionalProperties['region'] as string;

                // if (selection.properties[VehicleSelectionFormFields.additionalProperty].enabled) {
                //   if (plate) {
                //     searchSelectionCarProperty.plate = plate;
                //   }
                //   if (region) {
                //     searchSelectionCarProperty.region = region;
                //   }
                // }
                // if (colors.enabled && colors.value) {
                //   searchSelectionCarProperty.colors = selection.properties[VehicleSelectionFormFields.colors].value;
                // }
                // if (model.enabled && model.value) {
                //   searchSelectionCarProperty.model = selection.properties[VehicleSelectionFormFields.model].value;
                // }
                // if (make.enabled && make.value) {
                //   searchSelectionCarProperty.make = selection.properties[VehicleSelectionFormFields.make].value;
                // }
                if (type.enabled && type.value) {
                  searchSelectionCarProperty.type = selection.properties[VehicleSelectionFormFields.type].value;
                }
              } else if (selection.type === SearchObjectTypes.PET) {
              }

              const result = {
                ...selection,
                properties: selection.type === SearchObjectTypes.PERSON ? properties : searchSelectionCarProperty,
              };
              if (selection.type === SearchObjectTypes.PET) {
                delete result.properties;
              }
              return result;
            });
            variable = {
              name: variableState.variableName,
              variableType: variableState.selectedVariableType,
              selectedCameras: variableState.selectedCameras,
              configuration: {
                object: variableState.selectedPersonObjectType ? AnalyticClasses.person : AnalyticClasses.vehicle,
                filters: filtersAdapter(searchSelections),
              },
              periodInMinutes: variableState.settings.periodInMinutes,
              objectSelections: variableState.objectSelections,
            };
            break;
          case VariableType.alert:
            const alertEvent: AlertEventSendModel = {
              name: alertEventState.alertName,
              alertType: alertEventState.selectedAlertType,
              configuration: {
                object: alertEventState.trackedObject,
                detection: alertEventState.detectionType,
                detectionAdditionalAttributes: alertEventState.detectionAdditionalAttributes,
                filters: loadConfigurationFilters(alertEventState),
                tresholdTime: alertEventState.tresholdTime,
              },
              settings: alertEventState.settings,
              notifications: alertEventState.notifications,
              timezone: alertEventState.timezone,
              actions: alertEventState.actions,
            };
            if (
              alertEventState.detectionType === DetectionType.LineCrossing ||
              alertEventState.detectionType === DetectionType.Tailgating
            ) {
              alertEvent.lineCrossing = alertEventState.lineCrossing;
            } else if (alertEventState.detectionType === DetectionType.TrafficControl) {
              alertEvent.trafficControl = alertEventState.trafficControl;
            } else {
              alertEvent.zones = alertEventState.zones;
              alertEvent.definedZones = alertEventState.definedZones;
              alertEvent.markedIdx = alertEventState.markedIdx;
              alertEvent.measureCrossZones = alertEventState.measureCrossZones;
            }

            if (alertEvent.alertType === AlertType.analytic) {
              alertEvent.selectedCamera = {
                locationId: alertEventState.selectedCamera.locationId,
                cameraId: alertEventState.selectedCamera.edgeOnly.cameraId,
                edgeId: alertEventState.selectedCamera.edgeId,
                timezone: locationLookup[alertEventState.selectedCamera.locationId]?.timezone,
              };
            }
            if (alertEvent.alertType !== AlertType.analytic) {
              alertEvent.multiCameras = alertEventState?.multiCameras;
            }

            variable = {
              name: variableState.variableName,
              variableType: variableState.selectedVariableType,
              selectedCameras:
                alertEventState.selectedAlertType === AlertType.cameraStatus || alertEventState.selectedAlertType === AlertType.edgeStatus
                  ? alertEventState.multiCameras
                  : [alertEventState.selectedCamera],
              event: {
                alertType: alertEvent.alertType,
                configuration: alertEvent.configuration,
                markedIdx: alertEvent.markedIdx,
                lineCrossing: alertEvent.lineCrossing,
                trafficControl: alertEvent.trafficControl,
                zones: alertEvent.zones,
                measureCrossZones: alertEvent.measureCrossZones,
                definedZones: alertEvent.definedZones,
              },
              configuration: {
                ...variableState.configuration,
              },
              periodInMinutes: variableState.settings.periodInMinutes,
            };
            break;
          case VariableType.compound:
            const turndownService = new TurndownService();
            const variablesLookup = {};
            const variablesFormula = [];
            const convertedHtmlToString = turndownService.turndown(variableState.compoundFormulaHTML);
            const sanitizedString = convertedHtmlToString.replaceAll(/\s\s+/g, ' ')
              .trim(); // remove all special symbols and double spaces
            let compoundFormula = sanitizedString.replaceAll(/\\/g, ''); // remove backsplashes
            variableState.variablesAutoComplete.map(item => {
              variablesLookup[`{${item.name}}`] = { id: item._id, idTemplate: `{${item._id}}` };
            });

            Object.keys(variablesLookup)
              .forEach(key => {
                if (compoundFormula.indexOf(key) > -1) {
                  variablesFormula.push(variablesLookup[key].id);
                }
                compoundFormula = compoundFormula.replaceAll(key, variablesLookup[key].idTemplate);
              });

            variable = {
              name: variableState.variableName,
              variableType: variableState.selectedVariableType,
              periodInMinutes: variableState.settings.periodInMinutes,
              compoundFormula: compoundFormula,
              compoundFormulaHTML: variableState.compoundFormulaHTML,
              variablesFormula,
            };
            break;
        }
        if (variableState.selectedVariable?._id) {
          return this.variableService.updateVariable(variableState.selectedVariable._id, variable)
            .pipe(
              switchMap(res => {
                const actions: Action[] = [
                  SharedActions.showMessage({
                    success: 'Variable has been updated',
                  }),
                  VariableActions.saveVariableSuccess(),
                  SharedActions.setIsSaving({ isSaving: false }),
                ];
                if (res) {
                  actions.push(
                    SharedActions.subscribeToSessionStatus({
                      token: res.token.session,
                      sessionDataAction: SessionDataAction.sendAlert,
                      params: {
                        msTimeout: 15000,
                      },
                    }),
                  );
                }
                return actions;
              }),
              catchError(response => {
                return [
                  SharedActions.setIsSaving({ isSaving: false }), //loader off
                  VariableActions.saveVariableError(),
                  this.catchError(response),
                ];
              }),
              share(),
            );
        } else {
          return this.variableService.createVariable(variable)
            .pipe(
              switchMap(res => {
                const subscribeToSessions = res.filter(msgInfo => msgInfo?.token?.session)
                  .map(msgInfo => {
                    return SharedActions.subscribeToSessionStatus({
                      token: msgInfo.token.session,
                      sessionDataAction: SessionDataAction.sendVariable,
                    });
                  });

                return [
                  SharedActions.showMessage({
                    success: 'Variable has been created',
                  }),
                  SharedActions.setIsSaving({ isSaving: false }),
                  VariableActions.saveVariableSuccess(),
                  ...subscribeToSessions,
                ];
              }),
              catchError(response => {
                return [
                  SharedActions.setIsSaving({ isSaving: false }), //loader off
                  VariableActions.saveVariableError(),
                  this.catchError(response),
                ];
              }),
              share(),
            );
        }
      }),
      share(),
    ),
  );

  public getVariables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.getVariables),
      withLatestFrom(this.store$.pipe(select(state => state.variableState))),
      switchMap(([, { page, perPage, orderBy, orderDirection, query }]) => {
        return this.variableService.getVariables(page, perPage, orderBy, orderDirection, query)
          .pipe(
            switchMap(result => {
              return [
                VariableActions.setVariables({
                  variables: result.items,
                }),
                VariableActions.setTotalItemsCount({
                  totalItemsCount: result.totalItemsCount,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );
  private variableEditMenuLevel2: any;

  public getSelectedVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.getSelectedVariable),
      withLatestFrom(this.store$.pipe(select(CameraSelectors.selectCameraState))),
      switchMap(([{ id }, allCameras]) => {
        return this.variableService.getSelectedVariable(id)
          .pipe(
            switchMap(res => {
              if (res.variableType === VariableType.alert) {
                return [
                  VariableActions.addValidMenuPoint({ point: MenuKey.details }),
                  VariableActions.addValidMenuPoint({ point: MenuKey.data }),
                  VariableActions.addValidMenuPoint({ point: MenuKey.settings }),
                  VariableActions.setSelectedVariable({
                    selectedVariable: {
                      ...res,
                      selectedCamera: {
                        ...allCameras.entities[res.selectedCamera.cameraId],
                        ...res.selectedCamera,
                      },
                    },
                  }),
                  AlertEventsActions.setSelectedAlert({
                    selectedAlertEvent: {
                      ...res.event,
                      name: '',
                      selectedCamera: allCameras.entities[res.selectedCamera.cameraId],
                      multiCameras: [allCameras.entities[res.selectedCamera.cameraId]],
                    },
                  }),
                  MenuActions.setLevel3MenuByKey({
                    key: MenuKey.data,
                    level3Menu: variableMenuLevel3AlertCreate,
                  }),
                  AlertEventsActions.setAlertType({ alertType: res.event?.alertType }),
                  MenuActions.setActiveLevel2Menu({
                    activeLevel2: MenuKey.details,
                  }),
                ];
              }
              return [
                VariableActions.setSelectedVariable({
                  selectedVariable: {
                    ...res,
                    selectedCamera: res.selectedCamera
                      ? {
                        ...allCameras.entities[res.selectedCamera.cameraId],
                        ...res.selectedCamera,
                      }
                      : null,
                  },
                }),
                MenuActions.setLevel3MenuByKey({
                  key: MenuKey.data,
                  level3Menu: null,
                }),
                MenuActions.setActiveLevel2Menu({
                  activeLevel2: MenuKey.details,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public removeVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.removeVariable),
      switchMap(({ id, cameraId, locationId, edgeId }) => {
        return this.variableService
          .remove(id, {
            cameraId,
            locationId,
            edgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.removeEvent,
                }),
                SharedActions.setIsDeleting({ isDeleting: false }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.setIsDeleting({ isDeleting: false }), //loader off
                this.catchError(response),
              ];
            }),
          );
      }),
      share(),
    ),
  );
  //
  // public enableAlertEvent$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(VariableActions.enableEvent),
  //     switchMap(({alert, enabled}) => {
  //       return this.variableService
  //         .updateAlertEvents(alert._id, {
  //           ...alert,
  //           enabled,
  //         })
  //         .pipe(
  //           switchMap((res) => {
  //             return [
  //               SharedActions.showMessage({
  //                 success: 'Alert has been updated',
  //               }),
  //             ];
  //           }),
  //         );
  //     }),
  //     share(),
  //   ),
  // );

  public syncVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.syncVariable),
      switchMap(({ variable, synced }) => {
        return this.variableService.sync(variable._id, variable)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.sync,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public setSearchQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.setQuery),
      debounceTime(400),
      switchMap(() => of(VariableActions.getVariables())),
    ),
  );

  public loadLevel3Menu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.loadLevel3Menu),
      withLatestFrom(this.store$.pipe(select(state => state.variableState))),
      switchMap(([{ copy }, { selectedVariableType }]) => {
        switch (selectedVariableType) {
          case VariableType.alert:
            return [MenuActions.setLevel3Menu({ level3Menu: variableMenuLevel3AlertCreate })];
          case VariableType.search:
            return [
              MenuActions.setLevel3Menu({ level3Menu: variableMenuLevel3SearchCreate }),
              MenuActions.enableLevel3Point({ level2Key: MenuKey.data, key: MenuKey.cameras }),
            ];
          case VariableType.compound:
            return [MenuActions.setLevel3Menu({ level3Menu: null })];
          default:
            return [SharedActions.doNothing()];
        }
      }),
    ),
  );

  public validateCompoundFormula$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.setCompoundFormulaHTML),
      switchMap(({ compoundFormulaHTML }) => {
        const turndownService = new TurndownService();
        const convertedHtmlToString = turndownService.turndown(compoundFormulaHTML);
        const sanitizedString = convertedHtmlToString.replaceAll(/\s\s+/g, ' ')
          .trim(); // remove all special symbols and double spaces
        let formula = sanitizedString.replaceAll(/\\/g, ''); // remove backsplashes
        const validationRegex = new RegExp(/[^1-9+\-*/\s()]+/, 'g'); //only digits, math operators and spaces and ()
        formula = formula.replaceAll(/\{(.*?)\}/g, ''); // remove all variables inside {}
        const invalid = validationRegex.test(formula);
        return of(VariableActions.setCompoundFormulaValidation({ compoundFormulaInvalid: invalid }));
      }),
    ),
  );

  public getVariablesAutocomplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VariableActions.getVariablesAutocomplete),
      switchMap(({ query, notType }) => {
        return this.variableService.autocomplete(query, notType)
          .pipe(
            switchMap(res => {
              return [VariableActions.getVariablesAutocompleteSuccess({ variablesAutoComplete: res })];
            }),
            catchError(err => of(VariableActions.getVariablesAutocompleteFail())),
          );
      }),
    ),
  );

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

  constructor(private actions$: Actions, private store$: Store<AppState>, private variableService: VariableService) {
  }
}

const typeFieldToColorField = (name: string) => {
  return name.replace('Type', 'Color');
};

const filtersAdapter = (searchSelections: SearchSelection[]): any => {
  let result: AlertEventsConfigurationFilter[] = searchSelections.map(searchSelection => {
    const configFilter: AlertEventsConfigurationFilter = {
      accessoryType: [],
      ageType: [],
      carryingType: [],
      colors: [],
      footwearColor: [],
      footwearType: [],
      genderType: [],
      greenList: '',
      hairColor: [],
      hairType: [],
      lowerbodyColor: [],
      lowerbodyType: [],
      make: [],
      model: [],
      redList: '',
      type: [],
      unrecognized: false,
      upperbodyColor: [],
      upperbodyType: [],
    };
    for(let property of Object.values(searchSelection.properties)) {
      if (searchSelection.type === SearchObjectTypes.PERSON) {
        if (!!property.enabled && !!property?.value?.length) {
          const propsToLower = property.value?.map(elem => elem.toLowerCase());
          configFilter[property.name] = propsToLower;
        }
        if (!!property.enabled && !!property.colors?.length) {
          const colorPropertyName = typeFieldToColorField(property.name);
          configFilter[colorPropertyName] = property.colors;
        }
      } else {
        for(let key of Object.keys(searchSelection.properties)) {
          configFilter[key] = searchSelection.properties[key];
        }
      }
    }
    return configFilter;
  });
  return result;
};

const loadConfigurationFilters = (state: AlertEventsState): any => {
  switch (state.trackedObject) {
    case AnalyticClasses.person:
      return {
        ageType: state.ageType,
        carryingType: state.carryingType,
        lowerbodyType: state.lowerBodyType,
        upperbodyType: state.upperBodyType,
        accessoryType: state.accessoryType,
        footwearType: state.footWearType,
        hairType: state.hairType,
        genderType: state.genderType,
        upperbodyColor: state.upperBodyColor,
        lowerbodyColor: state.lowerBodyColor,
        hairColor: state.hairColor,
        footwearColor: state.footWearColor,
      };
    case AnalyticClasses.vehicle:
      if (state.configurationFilterType === ConfigurationFilterType.specificAttributes) {
        return {
          make: state.carMake,
          model: state.carModel,
          type: state.carType,
          colors: state.carColor,
        };
      } else if (state.configurationFilterType === ConfigurationFilterType.licencePlate) {
        return {
          greenList: state.greenList,
          redList: state.redList,
          unrecognized: state.unrecognized,
        };
      }
      return {};
  }
};
