import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WallModel } from '@models/wall.model';
import { Dictionary, Update } from '@ngrx/entity/src/models';
import { FloorModel } from '@models/floor.model';
import { FloorActions } from '@states/floors/floor.action-types';
import { saveBuildingSetOne } from '@states/floors/floor.actions';

export interface FloorState extends EntityState<FloorModel.BuildingDocument> {
  isInitialLoaded: boolean;
  filters: {
    query: string
  };
  isSaving: boolean;
  selectedBuilding: FloorModel.BuildingDocument;
}

export const adapter: EntityAdapter<FloorModel.BuildingDocument> = createEntityAdapter<FloorModel.BuildingDocument>({
  selectId: (document: FloorModel.BuildingDocument) => document._id,
});


export const initialState: FloorState = adapter.getInitialState({
  isInitialLoaded: false,
  filters: {
    query: null,
  },
  isSaving: false,
  selectedBuilding: null,
});

export const floorStateReducer = createReducer(
  initialState,
  on(FloorActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(FloorActions.getBuildingsSuccess, (state, { documents }) => {
    return adapter.setMany(documents, {
      ...state,
      isInitialLoaded: true,
    });
  }),
  on(FloorActions.setQueryStringFilter, (state, { prop, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [prop]: value,
      },
    };
  }),
  on(FloorActions.resetEntities, (state) => {
    return {
      ...state,
      entities: initialState.entities,
    };
  }),
  on(FloorActions.setIsSaving, (state, { isSaving }) => {
    return {
      ...state,
      isSaving: isSaving,
    };
  }),
  on(FloorActions.saveBuildingSetOne, (state, { document }) => {
    return adapter.addOne(document, {
      ...state,
    });
  }),
  on(FloorActions.deleteBuildingRequestSuccess, (state, { buildingId }) => {
    return adapter.removeOne(buildingId, {
      ...state,
      isFirstLoaded: true,
    });
  }),
  on(FloorActions.deleteFloorRequestSuccess, (state, { buildingId, floorId }) => {
    const update: Update<FloorModel.BuildingDocument> = {
      id: buildingId,
      changes: {
        floors: state.entities[buildingId].floors.filter((floor) => floor.id !== floorId),
      },
    };
    return adapter.updateOne(update, {
      ...state,
      isFirstLoaded: true,
    });
  }),
  on(FloorActions.saveBuildingRequestSuccess, (state, { building }) => {
    if (state.entities[building._id]) {
      const update: Update<FloorModel.BuildingDocument> = {
        id: building._id,
        changes: {
          ...building,
        },
      };
      return adapter.updateOne(update, {
        ...state,
        isFirstLoaded: true,
      });
    } else {
      return adapter.addOne(building, {
        ...state,
        isFirstLoaded: true,
      });
    }

  }),
  on(FloorActions.editFloorRequestSuccess, (state, { buildingId, floorId, floor }) => {
    const update: Update<FloorModel.BuildingDocument> = {
      id: buildingId,
      changes: {
        floors: state.entities[buildingId].floors.map((f) => {
          return f.id === floorId ? floor : f;
        }),
      },
    };
    return adapter.updateOne(update, {
      ...state,
      isFirstLoaded: true,
    });
  }),
  on(FloorActions.createFloorRequestSuccess, (state, { buildingId, floor }) => {
    const update: Update<FloorModel.BuildingDocument> = {
      id: buildingId,
      changes: {
        floors: [...state.entities[buildingId].floors, floor],
      },
    };
    return adapter.updateOne(update, {
      ...state,
      isFirstLoaded: true,
    });
  }),
);
