import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActiveOrganization, LiveViewType } from '@models/organization.model';
import { select, Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import { LiveStreamModels } from '@models/live-stream.model';
import { VideoModel } from '@models/video.model';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { PlayerBaseComponent } from './player-base/player-base.component';
import { VideoService } from '../../development/video.service';
import { StatsService } from '../../development/stats.service';
import { WallSelectors } from '@states/wall/wall.selector-types';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { withLatestFrom } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrl: './video.component.scss',
})
export class VideoComponent implements OnInit {

  @ViewChild('player') public player: PlayerBaseComponent;

  public isDeveloper$ = this.store$.pipe(select(OrganizationSelectors.isDeveloper));

  public LiveViewType = LiveViewType;
  public PlaybackType = VideoModel.PlaybackType;

  @Input() edgeId: string;
  @Input() cameraId: string;
  @Input() locationId: string;
  @Input() placeholder = false;
  @Input() inactive = false;
  @Input() autostart = true;
  @Input() resolution = LiveStreamModels.StreamResolution.HQ;
  @Input() enableHealthCheck = true;
  @Input() accessToken: string;
  @Input() isRespectRatio: boolean = true;
  @Input() isWall: boolean = false;

  @Input() cameraView = false;

  @Output() setPlaceholder: EventEmitter<{ set: boolean, playerObj?: ElementRef }> = new EventEmitter<{ set: boolean, playerObj?: ElementRef }>();
  @Output() showRetry: EventEmitter<void> = new EventEmitter<void>();
  @Output() setErrorMsg: EventEmitter<string> = new EventEmitter<string>();
  @Output() setLoader: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setInactive: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() playing: EventEmitter<string> = new EventEmitter<string>();
  @Output() qualityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );
  public selectIsLocal$: Observable<boolean>;
  public selectLocalUrl$: Observable<string>;
  public selectEdgeSwVersion$: Observable<string>;
  public selectEdgeLastMp4Ts$: Observable<number>;

  public forceRemote = false;

  @Input() public liveViewType: LiveViewType = LiveViewType.Livekit;
  // @Input() public playbackType: VideoModel.PlaybackType = VideoModel.PlaybackType.Webrtc;
  public localLiveStreamEnabled = false;
  private forceWebrtcRelay = false;

  public forceMp4 = false;
  public lastMp4Ts: number;


  constructor(private store$: Store, private videoService: VideoService, private statsService: StatsService, private cd: ChangeDetectorRef) {
  }

  initLastMp4() {
    this.selectEdgeLastMp4Ts$ = this.store$.pipe(select(EdgeSelectors.selectEdgeLastMp4Ts(this.edgeId)));
    this.selectEdgeLastMp4Ts$.pipe(untilDestroyed(this))
      .subscribe(ts => {
        if (!!ts) {
          this.lastMp4Ts = ts;
        } else {
          this.statsService.getEdgeLastMp4(this.edgeId)
            .subscribe(lastMp4Ts => {
              this.lastMp4Ts = lastMp4Ts;
            });
        }
      });
  }

  public isHlsPlayback(ts?: number) {
    return !this.lastMp4Ts || ts > this.lastMp4Ts && !this.forceMp4;
  }

  public get ts() {
    return this.videoService.ts;
  }

  public get playbackType(): VideoModel.PlaybackType {
    return this.isHlsPlayback(this.ts) || !this.ts ? VideoModel.PlaybackType.HLS : VideoModel.PlaybackType.Webrtc;
  }

  public get isLive() {
    return this.videoService.isLive;
  }

  public get playback() {
    return !this.isLive;
  }

  public get playerObj() {
    return this.player?.playerObj;
  }

  public get isLiveKit() {
    return this.liveViewType === LiveViewType.Livekit;
  }


  ngOnInit(): void {
    this.setOrgSettings();
    this.setEdgeSettings();
    this.initLastMp4();
    if (this.isWall) {
      this.videoService.setLive(true);
    }
    // this.videoService.setLive(this.cameraView ? true : false);

    this.videoService.timelineDragStart$.pipe(untilDestroyed(this))
      .subscribe(_ => {
        this.videoService.setLive(false);
        this.cd.detectChanges();
      });


  }

  private setEdgeSettings() {
    this.selectIsLocal$ = this.store$.pipe(select(EdgeSelectors.selectLocalById(this.edgeId)));
    this.selectLocalUrl$ = this.store$.pipe(select(EdgeSelectors.selectLocalBaseUrlById(this.edgeId)));
    this.selectEdgeSwVersion$ = this.store$.pipe(select(EdgeSelectors.selectEdgeSwVersionById(this.edgeId)));
  }

  private setOrgSettings() {
    this.selectActiveOrganization$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(async org => {
        if (!!org?.localLiveStream) {
          this.localLiveStreamEnabled = true;
        }

        if (!!org?.forceWebrtcRelay) {
          this.forceWebrtcRelay = true;
        }

        if (!!org?.liveViewType) {
          this.liveViewType = org.liveViewType;
        }
      });
  }

  public localFallback() {
    this.forceRemote = true;
  }

}
