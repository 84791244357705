import { SelectedCamera } from '@models/alert-events.model';
import { UiLocationSelectItem } from '@models/ui.model';
import { AlertEventSelectionResult } from '../components/alert-row/alert-row.component';
import { Dictionary } from '@ngrx/entity/src/models';
import { AlertV2Document } from '@models/alerts-v2.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { WallModelV2 } from '@models/wall.model';
import WallPositionEvent = WallModelV2.WallPositionEvent;

export const selectCamera = (selectedCamera: SelectedCamera, selectedCameras: { [cameraId: string]: SelectedCamera }): { [cameraId: string]: SelectedCamera } => {
  let result = { ...selectedCameras };
  if (result[selectedCamera.cameraId]) {
    delete result[selectedCamera.cameraId];
    result = { ...result };
  } else {
    result = {
      ...result,
      [selectedCamera.cameraId]: selectedCamera,
    };
  }
  return result;
};


export const selectLocation = (selectedLocation: { location: UiLocationSelectItem, selected: boolean }, selectedCameras: { [cameraId: string]: SelectedCamera }): { [cameraId: string]: SelectedCamera } => {
  const location = selectedLocation.location;
  let result = { ...selectedCameras };
  location.cameras.forEach(camera => {
    if (selectedLocation.selected) {
      result =
        {
          ...result,
          [camera.edgeOnly.cameraId]: { cameraId: camera.edgeOnly.cameraId, edgeId: camera.edgeId, locationId: camera.locationId },
        };
    } else {
      delete result[camera.edgeOnly.cameraId];
      result = { ...result };
    }
  });

  return result;
};

export const selectAlert = (selectedAlert: AlertEventSelectionResult, selectedAlerts: { [eventId: string]: WallPositionEvent[] }): { [eventId: string]: WallPositionEvent[] } => {
  const alertEvent = selectedAlert.selectedAlertEvent;
  let result = { ...selectedAlerts };
  if (result[alertEvent._id] && selectedAlert.isAlertSelection) {
    delete result[alertEvent._id];
  } else {
    const selectedAlertEvents = [];
    selectedAlert.selectedCameras.forEach(camera => {
      const alertEventWallPosition: WallPositionEvent = {
        cameraId: camera.cameraId,
        edgeId: camera.edgeId,
        locationId: camera.locationId,
        eventId: alertEvent._id,
      };
      selectedAlertEvents.push(alertEventWallPosition);
    });
    if (selectedAlertEvents.length) {
      result[alertEvent._id] = selectedAlertEvents;
    } else {
      delete result[alertEvent._id];
    }
  }
  return result;
};


export const selectAlOrganizationAlerts = (event: MatCheckboxChange, alertsLookup: Dictionary<AlertV2Document>): { [eventId: string]: WallPositionEvent[] } => {
  const isSelected = event.checked;
  let result = {};
  if (!isSelected) {
    return {};
  }
  const alerts = Object.values(alertsLookup);
  alerts.forEach(alertEvent => {
    const selectedAlertEvents = [];
    Object.keys(alertEvent.cameraSync)
      .forEach(cameraId => {
        const selectedCamera = alertEvent.cameraSync[cameraId];
        const alertEventWallPosition: WallPositionEvent = {
          cameraId,
          edgeId: selectedCamera.edgeId,
          locationId: selectedCamera.locationId,
          eventId: alertEvent._id,
        };
        selectedAlertEvents.push(alertEventWallPosition);
      });
    /**
     * Always redefine object to enable change detection
     */
    result = {
      ...result,
      [alertEvent._id]: selectedAlertEvents,
    };
  });

  return result;
};
