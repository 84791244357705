import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, Observable, pairwise, startWith, Subject, Subscription, take } from 'rxjs';
import * as MultiSearchSelectors from '@states/multi-search/multi-search.selectors';
import { PersonSelectionFormFields, SearchObjectTypes, SearchQueryOperator, VehicleSelectionFormFields } from '@enums/search.enum';
import { SearchSelection } from '@models/search.model';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { SearchService } from '../../../search.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as MultiSearchActions from '@states/multi-search/multi-search.actions';
import { SearchSelectorOperators, SearchSelectorTypes } from 'src/app/shared/advanced-search/search-selector/search-selector.model';
import { VariableSelectors } from '@states/variable/variable.selector-types';
import { SharedService } from '../../../../development/shared.service';
import * as _ from 'lodash';
import { SharedActions } from '@states/shared/shared.action-types';
import { CameraEditSelectors } from '@states/camera-edit/camera-edit.selector-types';
import { PeopleUploadDialogComponent, PeopleUploadDialogData, PeopleUploadDialogDataResult } from '../../../../pages/database/pages/people/dialogs/people-upload-dialog/people-upload-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GroupModels, Person } from '@models/people.model';
import { Search } from 'src/app/shared/search.model';
import { PeopleSelectors } from '@states/people/people.selector-types';
import { selectPersonNameById } from '@states/people/people.selectors';
import { PeopleService } from 'src/app/development/people.service';
import { environment } from '../../../../../environments/environment';
import { ActiveOrganization } from '@models/organization.model';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import { VehicleModels } from '@models/vehicle.model';

export interface ObjectAttributesContentStandaloneData {
  selections?: UntypedFormArray;
  vehicleArray?: UntypedFormArray;
  petArray?: UntypedFormArray;
  shoppingCartArray?: UntypedFormArray;
  single?: boolean;
  type?: SearchObjectTypes;
  excludePeople?: GroupModels.GroupSearchItem[];
  includePeople?: GroupModels.GroupSearchItem[];
  excludeVehicles?: VehicleModels.Vehicle[];
  includeVehicles?: VehicleModels.Vehicle[];
}

export const SEARCH_DEBOUNCE_TIME = 1500;
export const TOTAL_OBJECTS_LIMIT = 5;

export enum VariableSearchFiltersSectionsEnum {
  Person,
  Vehicle,
  // Text
}

export enum PanelFiltersSectionsEnum {
  Person,
  Face,
  Vehicle,
  More,
}

const VariableSearchFiltersSections = Object.keys(VariableSearchFiltersSectionsEnum)
  .filter(v => isNaN(Number(v)));

const VariableSearchFiltersSectionsAsArray = Array.from(Array(Object.keys(VariableSearchFiltersSectionsEnum).length / 2)
  .keys());

const PanelFiltersSections = Object.keys(PanelFiltersSectionsEnum)
  .filter(v => isNaN(Number(v)));

const PanelFiltersSectionsAsArray = Array.from(Array(Object.keys(PanelFiltersSectionsEnum).length / 2)
  .keys());

@UntilDestroy()
@Component({
  selector: 'app-object-attributes-content',
  templateUrl: './object-attributes-content.component.html',
  styleUrls: ['./object-attributes-content.component.scss'],
})
export class ObjectAttributesContentComponent implements OnInit {
  @Input() inline = false;
  @Input() variable = false;
  @Input() panel = false;
  @Input() standalone = false;
  @Input() standaloneData: ObjectAttributesContentStandaloneData;
  @Input() displayOperator = true;
  @Input() searchPerson = false;
  // Inline view
  public VariableSearchFiltersSectionsEnum: typeof VariableSearchFiltersSectionsEnum = VariableSearchFiltersSectionsEnum;
  public VariableSearchFiltersSections = VariableSearchFiltersSections;
  public VariableSearchFiltersSectionsAsArray = VariableSearchFiltersSectionsAsArray;

  public PanelFiltersSectionsEnum: typeof PanelFiltersSectionsEnum = PanelFiltersSectionsEnum;
  public PanelFiltersSections = PanelFiltersSections;
  public PanelFiltersSectionsAsArray = PanelFiltersSectionsAsArray;

  isOpened = new Array<boolean>(VariableSearchFiltersSections.length).fill(true);

  @Output('update') update = new EventEmitter<{
    selections: UntypedFormArray;
    vehicleArray: UntypedFormArray;
    petArray: UntypedFormArray;
    shoppingCartArray: UntypedFormArray;
    textArray?: UntypedFormArray;
    selectedPersonObjectType?: boolean;
  }>();

  public selectObjectSelections$: Observable<any[]>;
  public selectSearchHighConfidence$: Observable<boolean> = this.store$.pipe(select(MultiSearchSelectors.selectSearchHighConfidence));

  public selectFaces$: Observable<Search.Faces> = this.store$.pipe(select(MultiSearchSelectors.selectFaces));
  public selectOuterOperator$: Observable<SearchQueryOperator> = this.store$.pipe(select(MultiSearchSelectors.selectOuterOperator));

  public selectOrgProtectiveGear$: Observable<boolean> = this.store$.pipe(select(CameraEditSelectors.selectOrgProtectiveGear));
  public selectOrgShoppingCarts$: Observable<boolean> = this.store$.pipe(select(CameraEditSelectors.selectOrgShoppingCart));


  public searchSelection: SearchSelection[] = [];
  selectedSelection: UntypedFormGroup;
  outerOperator = SearchQueryOperator.OR;
  SearchSelectorOperators: typeof SearchSelectorOperators = SearchSelectorOperators;
  SearchSelectorTypes: typeof SearchSelectorTypes = SearchSelectorTypes;
  highConfidence = true;
  public objectAttributeTypes: { name: string; value: SearchObjectTypes }[] = [
    {
      name: 'person',
      value: SearchObjectTypes.PERSON,
    },
    {
      name: 'vehicle',
      value: SearchObjectTypes.VEHICLE,
    },
    {
      name: 'pet',
      value: SearchObjectTypes.PET,
    },
  ];

  orgProtectiveGear = false;
  public searchObjectTypes = SearchObjectTypes;
  public selections: UntypedFormArray;
  public vehicleArray: UntypedFormArray;
  public petArray: UntypedFormArray;
  public shoppingCartArray: UntypedFormArray;
  public textArray: UntypedFormArray;

  public selectedIndex: number;
  public selectedObjectType: SearchObjectTypes;

  // Temp - allow only person or vehicle
  public selectedPersonObjectType = true;
  private selectedPersonObjectType$ = this.store$.select(VariableSelectors.selectSelectedPersonObjectType)
    .pipe(untilDestroyed(this));

  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  subscriptions: Subscription[] = [];

  singleSection: PanelFiltersSectionsEnum;

  public groups: GroupModels.GroupSearchItem[] = [];

  public facesInitialValue = {
    operation: Search.FacesOpeartion.Separate,
    objects: [],
  };

  public faces: Search.Faces = _.cloneDeep(this.facesInitialValue);

  public facesSeparate = true;
  public facesOperationOptions = [
    'Together',
    'One of',
  ];

  searchDebouncer: Subject<void> = new Subject<void>();

  orgId: string;

  constructor(
    private peopleService: PeopleService,
    private fb: UntypedFormBuilder, private store$: Store, private searchService: SearchService, private sharedService: SharedService, private dialog: MatDialog) {
  }


  checkEmit(array: UntypedFormArray) {
    const dirty: any = this.sharedService.getDirtyValues(array);
    if (!Object.keys(dirty).length) {
      return;
    }
    const dirtyKey = Object.keys(dirty)[0];
    const property = Object.keys(dirty[dirtyKey].properties)[0];
    const objectType = array.controls[dirtyKey].controls.type.value;
    const enabledChanged = JSON.stringify(dirty)
      .includes('enabled');
    let hasChanges = false;
    let propertyControls: UntypedFormControl[];
    switch (objectType) {
      case SearchObjectTypes.PERSON: {
        propertyControls = Object.values(array.controls[dirtyKey].controls.properties.controls[property].controls.props.controls);
        for(let control of propertyControls) {
          if (!!control.value) {
            hasChanges = true;
            break;
          }
        }
        break;
      }
      case SearchObjectTypes.VEHICLE: {
        propertyControls = array.controls[dirtyKey].controls.properties.controls[property].controls.value.value;
        if (!!propertyControls) {
          switch (property) {
            case 'model':
            case 'make':
            case 'colors': {
              if (propertyControls?.length) {
                hasChanges = true;
              }
            }
          }

        }
        break;
      }
    }

    if (!(enabledChanged && !hasChanges)) {
      this.emitUpdate();
    }
    array.markAsPristine();
  }

  ngOnInit(): void {
    this.selectOrgProtectiveGear$.pipe(untilDestroyed(this))
      .subscribe((orgProtectiveGear) => {
        this.orgProtectiveGear = orgProtectiveGear;
      });
    if (this.standalone) {
      this.selections = this.standaloneData.selections;
      this.vehicleArray = this.standaloneData.vehicleArray;
      this.petArray = this.standaloneData.petArray;
      this.shoppingCartArray = this.standaloneData.shoppingCartArray;
      switch (this.standaloneData.type) {
        case SearchObjectTypes.PERSON:
          this.singleSection = PanelFiltersSectionsEnum.Person;
          this.selections.valueChanges.pipe(untilDestroyed(this))
            .subscribe(_ => {
              this.emitUpdate();
            });
          break;
        case SearchObjectTypes.VEHICLE:
          this.singleSection = PanelFiltersSectionsEnum.Vehicle;
          this.vehicleArray.valueChanges.pipe(untilDestroyed(this))
            .subscribe(_ => {
              this.emitUpdate();
            });
          break;
        case SearchObjectTypes.PET:
          this.singleSection = PanelFiltersSectionsEnum.More;
          this.petArray.valueChanges.pipe(untilDestroyed(this))
            .subscribe(_ => {
              this.emitUpdate();
            });
          break;
        case SearchObjectTypes.SHOPPING_CART:
          this.singleSection = PanelFiltersSectionsEnum.More;
          this.shoppingCartArray.valueChanges.pipe(untilDestroyed(this))
            .subscribe(_ => {
              this.emitUpdate();
            });
          break;
      }
      return;
    }

    this.selectObjectSelections$ = this.variable
      ? this.store$.pipe(select(VariableSelectors.selectObjectSelections))
      : this.store$.pipe(select(MultiSearchSelectors.selectObjectSelections));

    this.selectedPersonObjectType$.subscribe(selectedPersonObjectType => {
      this.selectedPersonObjectType = selectedPersonObjectType;
    });

    this.selections = this.fb.array([]);
    this.vehicleArray = this.fb.array([]);
    this.petArray = this.fb.array([]);
    this.shoppingCartArray = this.fb.array([]);
    this.textArray = this.fb.array([]);

    this.selectObjectSelections$.pipe(untilDestroyed(this))
      .subscribe(res => {
        this.clearSubscriptions();
        this.selections = this.fb.array([]);
        this.vehicleArray = this.fb.array([]);
        this.petArray = this.fb.array([]);
        this.shoppingCartArray = this.fb.array([]);
        this.textArray = this.fb.array([]);
        if (res?.length) {
          let selectedForm;

          res.forEach((form, index) => {
            if (form.type === SearchObjectTypes.PERSON) {
              this.loadPersonForm(form);
              selectedForm = this.selections.controls[0] as UntypedFormGroup;
            } else if (form.type === SearchObjectTypes.VEHICLE) {
              this.loadVehicleForm(form);
              selectedForm = this.vehicleArray.controls[0] as UntypedFormGroup;
            } else if (form.type === SearchObjectTypes.PET) {
              this.loadPetForm(form);
              selectedForm = this.petArray.controls[0] as UntypedFormGroup;
            } else if (form.type === SearchObjectTypes.TEXT) {
              this.loadTextForm(form);
              selectedForm = this.textArray.controls[0] as UntypedFormGroup;
            } else if (form.type === SearchObjectTypes.SHOPPING_CART) {
              this.loadShoppingCartForm(form);
              selectedForm = this.shoppingCartArray.controls[0] as UntypedFormGroup;
            }
          });
          if (selectedForm.get('type').value === SearchObjectTypes.PERSON) {
            this.select(selectedForm, 0, SearchObjectTypes.PERSON);
          } else if (selectedForm.get('type').value === SearchObjectTypes.VEHICLE) {
            this.select(selectedForm, 0, SearchObjectTypes.VEHICLE);
          }
          if (this.inline && !this.selections.length && !this.vehicleArray.length) {
            this.add(SearchObjectTypes.PERSON);
            this.add(SearchObjectTypes.VEHICLE);
            this.emitUpdate();
          }
        } else {
          if (this.inline && !this.selections.length && !this.vehicleArray.length) {
            this.add(SearchObjectTypes.PERSON);
            this.add(SearchObjectTypes.VEHICLE);
            this.emitUpdate();
          }
        }
        this.subscriptions.push(
          this.vehicleArray.valueChanges.pipe(untilDestroyed(this), debounceTime(this.panel ? SEARCH_DEBOUNCE_TIME : 0),
              startWith(this.vehicleArray.value),
              pairwise())
            .subscribe(([prev, next]: [any, any]) => {
              if (prev.length !== next.length) {
                this.emitUpdate();
                return;
              }
              for(let i = 0; i < next.length; i++) {
                const diff =
                  _.differenceWith(_.toPairs(next[i].properties), _.toPairs(prev[i].properties), _.isEqual);

                const prop = diff?.length ? diff[0][0] : 'type'; // Work around for type diff not being recorded

                const valueDiff = _.differenceWith(_.toPairs(next[i].properties[<string>prop]), _.toPairs(prev[i].properties[<string>prop]), _.isEqual);

                const objDiff = _.fromPairs(diff);
                const objValueDiff = _.fromPairs(valueDiff);

                if (_.isEmpty(objDiff) && _.isEmpty(objValueDiff)) {
                  this.vehicleArray.markAsPristine();
                  continue;
                }

                const enabledChanged = objValueDiff['enabled'] !== undefined;

                let hasValue = false;

                const propValue = next[i].properties[<string>prop].value;
                switch (prop) {
                  case 'additionalProperties':
                    hasValue = !!objDiff[prop]['props']['region'] || !!objDiff[prop]['props']['plate'];
                    break;
                  case 'colors':
                  case 'make':
                  case 'model':
                  case 'type':
                    hasValue = propValue?.length > 0;
                    break;
                }
                if (!(enabledChanged && !hasValue)) {
                  this.emitUpdate();
                }
              }
              this.vehicleArray.markAsPristine();
              // this.checkEmit(this.vehicleArray);
              // this.emitUpdate();
            }),
          this.selections.valueChanges.pipe(untilDestroyed(this), debounceTime(this.panel ? SEARCH_DEBOUNCE_TIME : 0),
              startWith(this.selections.value),
              pairwise())
            .subscribe(([prev, next]: [any, any]) => {

              if (prev?.length !== next?.length) {
                this.emitUpdate();
                return;
              }
              for(let i = 0; i < next?.length; i++) {
                if (prev[i]?.groupId !== next[i]?.groupId ||
                  (next[i]?.groupId?.length && prev[i]?.groupIdCollapsed !== next[i]?.groupIdCollapsed)) {
                  this.emitUpdate();
                  return;
                }
              }
              this.checkEmit(this.selections);
              // this.emitUpdate();
            }),
          this.petArray.valueChanges.pipe(untilDestroyed(this), debounceTime(this.panel ? SEARCH_DEBOUNCE_TIME : 0))
            .subscribe(_ => {
              // this.checkEmit(this.petArray);
              this.emitUpdate();
            }),

          this.shoppingCartArray.valueChanges.pipe(untilDestroyed(this), debounceTime(this.panel ? SEARCH_DEBOUNCE_TIME : 0))
            .subscribe(_ => {
              // this.checkEmit(this.petArray);
              this.emitUpdate();
            }),
        );
      });

    if (this.panel) {
      this.searchService.reset$.pipe(untilDestroyed(this))
        .subscribe(_ => {
          this.selections = this.fb.array([]);
          this.vehicleArray = this.fb.array([]);
          this.petArray = this.fb.array([]);
          this.shoppingCartArray = this.fb.array([]);
        });
    }

    this.searchDebouncer.pipe(debounceTime(SEARCH_DEBOUNCE_TIME))
      .subscribe(() => {
        this.searchService.triggerSearch();
      });

    this.selectActiveOrganization$.pipe(take(1))
      .subscribe((org) => {
        this.orgId = org?.orgId;
      });
    this.selectFaces$.pipe(take(1))
      .subscribe(faces => {
        this.faces = _.cloneDeep(faces);
      });
    this.selectFaces$.pipe(untilDestroyed(this))
      .subscribe(faces => {
        if (!faces) {
          this.faces = _.cloneDeep(this.facesInitialValue);
        }
      });
  }

  clearSubscriptions() {
    for(let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  emitUpdate() {
    this.update.emit({
      selections: _.cloneDeep(this.selections),
      vehicleArray: _.cloneDeep(this.vehicleArray),
      petArray: _.cloneDeep(this.petArray),
      shoppingCartArray: _.cloneDeep(this.shoppingCartArray),
      textArray: _.cloneDeep(this.textArray),
      selectedPersonObjectType: _.cloneDeep(this.selectedPersonObjectType),
    });
  }

  get totalObject() {
    return this.selections.length + this.vehicleArray.length + this.petArray.length + this.shoppingCartArray.length;
  }

  public add(type: SearchObjectTypes) {
    if (this.totalObject >= TOTAL_OBJECTS_LIMIT) {
      this.store$.dispatch(
        SharedActions.showMessage({ warning: `You can add up to ${TOTAL_OBJECTS_LIMIT} objects to a search.` }),
      );
      return;
    }
    switch (type) {
      case SearchObjectTypes.PERSON:
        const searchSelection = this.fb.group({
          type: [type],
          operator: SearchQueryOperator.AND,
          properties: this.initPersonProperties(),
          groupId: null,
          groups: [],
          groupIdCollapsed: false,
          enabled: true,
          collapsed: true,
        });
        this.select(searchSelection, this.selections.length, SearchObjectTypes.PERSON);
        this.selections.push(searchSelection);
        break;
      case SearchObjectTypes.VEHICLE:
        const vehicleSearchSelection = this.fb.group({
          type: [type],
          operator: SearchQueryOperator.AND,
          properties: this.initVehicleProperties(),
          collapsed: true,
        });
        this.select(vehicleSearchSelection, this.vehicleArray.length, SearchObjectTypes.VEHICLE);
        this.vehicleArray.push(vehicleSearchSelection);
        break;
      case SearchObjectTypes.PET:
        const petSelection = this.fb.group({
          type: [type],
          operator: SearchQueryOperator.AND,
          collapsed: true,
        });
        this.select(petSelection, this.petArray.length, SearchObjectTypes.PET);
        this.petArray.push(petSelection);
        break;
      case SearchObjectTypes.SHOPPING_CART:
        const shoppingCartSelection = this.fb.group({
          type: [type],
          operator: SearchQueryOperator.AND,
          collapsed: true,
        });
        this.select(shoppingCartSelection, this.shoppingCartArray.length, SearchObjectTypes.SHOPPING_CART);
        this.shoppingCartArray.push(shoppingCartSelection);
        break;
      case SearchObjectTypes.TEXT:
        const textSelection = this.fb.group({
          type: [type],
          operator: SearchQueryOperator.AND,
          text: '',
          collapsed: true,
        });
        this.select(textSelection, this.selections.length, SearchObjectTypes.TEXT);
        this.textArray.push(textSelection);
        break;
    }
    this.emitUpdate();
  }

  public select(selection: UntypedFormGroup, index: number, type: SearchObjectTypes): void {
    this.selectedSelection = selection;
    this.selectedIndex = index;
    this.selectedObjectType = type;
  }

  searchDisabled() {
    return this.selections.length === 0 && this.vehicleArray.length === 0 && this.petArray.length === 0 && this.textArray.length === 0 && this.shoppingCartArray.length === 0;
  }

  public search() {
    const objectSelections = [...this.selections.value, ...this.vehicleArray.value, ...this.petArray.value, ...this.shoppingCartArray.value, ...this.textArray.value];
    this.store$.dispatch(MultiSearchActions.setObjectSelection({ objectSelections }));
    this.searchService.triggerSearch();
    // this.store$.dispatch(
    //   MultiSearchActions.startSearch(),
    // );
  }

  private loadPersonForm(form: any) {
    const searchSelection = this.fb.group({
      type: [],
      operator: SearchQueryOperator.AND,
      properties: this.initPersonProperties(),
      groupId: null,
      groups: [],
      groupIdCollapsed: false,
      enabled: true,
      collapsed: false,
    });
    searchSelection.patchValue(form);
    this.selections.push(searchSelection);
  }

  private loadVehicleForm(form: any) {
    const vehicleSearchSelection = this.fb.group({
      type: [],
      operator: SearchQueryOperator.AND,
      properties: this.initVehicleProperties(),
      enabled: true,
      collapsed: false,
    });
    vehicleSearchSelection.patchValue(form);
    this.vehicleArray.push(vehicleSearchSelection);
  }

  private loadPetForm(form: any) {
    const petSelection = this.fb.group({
      type: [],
      operator: SearchQueryOperator.AND,
      enabled: true,
      collapsed: false,
    });
    petSelection.patchValue(form);
    this.petArray.push(petSelection);
  }

  private loadShoppingCartForm(form: any) {
    const shoppingCartSelection = this.fb.group({
      type: [],
      operator: SearchQueryOperator.AND,
      enabled: true,
      collapsed: false,
    });
    shoppingCartSelection.patchValue(form);
    this.shoppingCartArray.push(shoppingCartSelection);
  }

  private loadTextForm(form: any) {
    const textSelection = this.fb.group({
      type: [],
      operator: SearchQueryOperator.AND,
    });
    textSelection.patchValue(form);
    this.textArray.push(textSelection);
  }

  private initPersonProperties(): UntypedFormGroup {
    const genderForm = this.fb.group({
      male: [false],
      female: [false],
    });
    const ageForm = this.fb.group({
      kids: [false],
      adults: [false],
    });
    const footWear = this.fb.group({
      colors: [],
    });
    const lowerBody = this.fb.group({
      type: [],
      colors: [],
    });
    const upperBody = this.fb.group({
      type: [],
      colors: [],
    });
    const hair = this.fb.group({
      colors: [],
    });
    const accessory = this.fb.group({
      type: [],
    });
    const carrying = this.fb.group({
      type: [],
    });
    const formGroup = this.fb.group({
      [PersonSelectionFormFields.genderType]: this.fb.group({
        enabled: [false],
        props: genderForm,
      }),
      [PersonSelectionFormFields.ageType]: this.fb.group({
        enabled: [false],
        props: ageForm,
      }),
      [PersonSelectionFormFields.footwearType]: this.fb.group({
        enabled: [false],
        props: footWear,
      }),
      [PersonSelectionFormFields.lowerbodyType]: this.fb.group({
        enabled: [false],
        props: lowerBody,
      }),
      [PersonSelectionFormFields.upperbodyType]: this.fb.group({
        enabled: [false],
        props: upperBody,
      }),
      [PersonSelectionFormFields.hairType]: this.fb.group({
        enabled: [false],
        props: hair,
      }),
      [PersonSelectionFormFields.accessoryType]: this.fb.group({
        enabled: [false],
        props: accessory,
      }),
      [PersonSelectionFormFields.carryingType]: this.fb.group({
        enabled: [false],
        props: carrying,
      }),
    });

    if (this.orgProtectiveGear) {
      const protectiveGear = this.fb.group({
        type: [],
      });
      formGroup.addControl(PersonSelectionFormFields.protectiveGear, this.fb.group({
        enabled: [false],
        beta: true,
        props: protectiveGear,
      }));
    }


    return formGroup;
  }

  public rmObjectAttribute(index: number, type: SearchObjectTypes) {
    switch (type) {
      case SearchObjectTypes.PERSON:
        this.selections.removeAt(index);
        break;
      case SearchObjectTypes.VEHICLE:
        this.vehicleArray.removeAt(index);
        break;
      case SearchObjectTypes.PET:
        this.petArray.removeAt(index);
        break;
      case SearchObjectTypes.SHOPPING_CART:
        this.shoppingCartArray.removeAt(index);
        break;
      case SearchObjectTypes.TEXT:
        this.textArray.removeAt(index);
        break;
    }
    this.selectedSelection = null;
    if (type === SearchObjectTypes.PERSON) {
      if (this.selections.controls[0]) {
        this.select(this.selections.controls[0] as UntypedFormGroup, 0, SearchObjectTypes.PERSON);
      } else {
        this.selectOnRemove();
      }
    } else if (type === SearchObjectTypes.VEHICLE) {
      if (this.vehicleArray.controls[0]) {
        this.select(this.vehicleArray.controls[0] as UntypedFormGroup, 0, SearchObjectTypes.VEHICLE);
      } else {
        this.selectOnRemove();
      }
    } else if (type === SearchObjectTypes.PET) {
      if (this.petArray.controls[0]) {
        this.select(this.petArray.controls[0] as UntypedFormGroup, 0, SearchObjectTypes.PET);
      } else {
        this.selectOnRemove();
      }
    } else if (type === SearchObjectTypes.SHOPPING_CART) {
      if (this.shoppingCartArray.controls[0]) {
        this.select(this.shoppingCartArray.controls[0] as UntypedFormGroup, 0, SearchObjectTypes.SHOPPING_CART);
      } else {
        this.selectOnRemove();
      }
    } else if (type === SearchObjectTypes.TEXT) {
      if (this.textArray.controls[0]) {
        this.select(this.textArray.controls[0] as UntypedFormGroup, 0, SearchObjectTypes.TEXT);
      } else {
        this.selectOnRemove();
      }
    }
    this.emitUpdate();
  }

  public highConfidenceChanged(value: boolean) {
    this.store$.dispatch(MultiSearchActions.setHighConfidence({ highConfidence: value }));
  }

  public outerOperatorChanged(value: SearchQueryOperator) {
    this.store$.dispatch(MultiSearchActions.setOuterOperator({ outerOperator: value }));
  }

  private initVehicleProperties(): UntypedFormGroup {
    const additionalProperty = this.fb.group({
      region: [],
      plate: [],
    });

    if (this.variable) {
      return this.fb.group({
        [VehicleSelectionFormFields.type]: this.fb.group({
          enabled: [false],
          value: [],
        }),
      });
    }

    const formGroup = this.fb.group({
      [VehicleSelectionFormFields.colors]: this.fb.group({
        enabled: [false],
        value: [],
      }),

      [VehicleSelectionFormFields.make]: this.fb.group({
        enabled: [false],
        value: [],
      }),
      [VehicleSelectionFormFields.model]: this.fb.group({
        enabled: [false],
        value: [],
      }),
      [VehicleSelectionFormFields.type]: this.fb.group({
        enabled: [false],
        value: [],
      }),
      [VehicleSelectionFormFields.additionalProperty]: this.fb.group({
        enabled: [false],
        props: additionalProperty,
      }),
    });

    if (this.standalone) {
      const licensePlates = this.fb.group({
        type: [],
      });
      formGroup.addControl(VehicleSelectionFormFields.plate, this.fb.group({
        enabled: [false],
        beta: true,
        props: licensePlates,
      }));
    }

    return formGroup;
  }


  private selectOnRemove() {
    if (this.selections.controls[0]) {
      this.select(this.selections.controls[0] as UntypedFormGroup, 0, SearchObjectTypes.PERSON);
    } else if (this.vehicleArray.controls[0]) {
      this.select(this.vehicleArray.controls[0] as UntypedFormGroup, 0, SearchObjectTypes.VEHICLE);
    } else if (this.petArray.controls[0]) {
      this.select(this.petArray.controls[0] as UntypedFormGroup, 0, SearchObjectTypes.PET);
    } else if (this.shoppingCartArray.controls[0]) {
      this.select(this.shoppingCartArray.controls[0] as UntypedFormGroup, 0, SearchObjectTypes.SHOPPING_CART);
    } else if (this.textArray.controls[0]) {
      this.select(this.textArray.controls[0] as UntypedFormGroup, 0, SearchObjectTypes.TEXT);
    }
  }

  isOpen(section: VariableSearchFiltersSectionsEnum) {
    const idx = VariableSearchFiltersSections.indexOf(this.sectionStr(section));
    return this.isOpened[idx] ?? false;
  }

  toggle(section: VariableSearchFiltersSectionsEnum) {
    const idx = VariableSearchFiltersSections.indexOf(this.sectionStr(section));
    this.isOpened[idx] = !this.isOpened[idx];
  }

  sectionStr(section: VariableSearchFiltersSectionsEnum) {
    return this.VariableSearchFiltersSections[section];
  }

  panelStr(section: PanelFiltersSectionsEnum) {
    return this.PanelFiltersSections[section];
  }

  toggleProperty(s: UntypedFormGroup) {
    const orig = s.get('collapsed')?.value;
    s.get('collapsed')
      .setValue(!orig);
  }

  public reset() {
    this.selections = this.fb.array([]);
    this.vehicleArray = this.fb.array([]);
    this.petArray = this.fb.array([]);
    this.shoppingCartArray = this.fb.array([]);
    this.textArray = this.fb.array([]);
    this.isOpened = new Array<boolean>(VariableSearchFiltersSections.length).fill(true);
    if (this.inline) {
      this.add(SearchObjectTypes.PERSON);
      this.add(SearchObjectTypes.VEHICLE);
      this.emitUpdate();
    }
  }

  selectInlineObjectType(section: VariableSearchFiltersSectionsEnum) {
    switch (section) {
      case VariableSearchFiltersSectionsEnum.Person:
        this.selectedPersonObjectType = !!this.selectedPersonObjectType ? false : true;
        break;
      case VariableSearchFiltersSectionsEnum.Vehicle:
        this.selectedPersonObjectType = !!this.selectedPersonObjectType ? false : true;
        break;
    }
    this.emitUpdate();
  }

  public hasEnabledProps(s: any) {
    const props = s?.controls?.properties?.controls;
    const faceEnabled = s?.controls?.groupIdCollapsed?.value;
    if (faceEnabled) {
      return true;
    }
    if (!props) {
      return false;
    }
    for(let prop of Object.keys(props)) {
      if (props[prop].controls.enabled.value) {
        return true;
      }
    }
    return false;
  }

  public get addDisabled() {
    return this.selections.length + this.vehicleArray.length + this.petArray.length + this.shoppingCartArray.length >= TOTAL_OBJECTS_LIMIT;
  }

  public uploadImage() {
    const data: PeopleUploadDialogData = {
      isSearch: true,
      multiple: true,
    };
    this.dialog.open(PeopleUploadDialogComponent, {
        width: '514px',
        panelClass: 'modal-no-padding',
        data,
      })
      .afterClosed()
      .subscribe((persons: GroupModels.GroupSearchItem[]) => {
        for(let person of persons) {
          this.addPerson(person);
        }
      });
  }

  public personOnList(personId: number) {
    return this.faces.objects.find(item => item.personId === personId);
  }

  public addPerson(item: GroupModels.GroupSearchItem, selector = false) {
    if (item.personIds) {
      if (this.personOnList(item.personIds[0])) {
        return;
      }
      const faceObjects = item.personIds.map(id => <Search.FaceObject>{ personId: id });
      this.faces.objects.push(...faceObjects);
      this.setFaces();
      return;
    }
    const faceObject: Search.FaceObject = { fileName: item.fileName, bestImage: item.bestImage, image: { descriptor: item.faceId, faceConfidence: item.faceConfidence } };
    this.faces.objects.push(faceObject);
    this.setFaces();
  }

  public deletePerson(idx: number) {
    this.faces.objects.splice(idx, 1);
    this.setFaces();
  }

  public personName(personId: number) {
    return this.store$.select(PeopleSelectors.selectPersonNameById(personId))
      .pipe(untilDestroyed(this));
  }

  public getPerson(personId: number) {
    return this.store$.select(PeopleSelectors.selectPersonById(personId));
  }

  public img(person: Person): string {
    return this.peopleService.getPersonImagePath(person);
  }

  public getBestImageUrl(bestImage?: string) {
    return `${environment.aws.faceIdBestImageUrlSavedGroup}/crop/${this.orgId}/${bestImage}`;
  }

  public updateFacesOperations() {
    this.faces.operation = !this.facesSeparate ? Search.FacesOpeartion.Together : Search.FacesOpeartion.Separate;
    this.setFaces();
  }

  public trimTs(fileName: string) {
    return fileName.replace(/^[0-9]+_/, '');
  }

  public setFaces() {
    this.store$.dispatch(MultiSearchActions.setFaces({ faces: _.cloneDeep(this.faces) }));
    this.searchDebouncer.next();
  }
}
