import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { catchError, debounce, debounceTime, Observable, of, Subject, throwError, timeout, timer } from 'rxjs';
import Hls, { ErrorData, Events, HlsListeners, ManifestParsedData } from 'hls.js';
import { CamerasService } from '../../../cameras/cameras.service';
import { VideoService } from '../../../development/video.service';
import { VisibilityChanged } from '../../directives/visibility-change.directive';
import { LiveStreamModels } from '@models/live-stream.model';
import { PlayerBaseComponent } from '../player-base/player-base.component';
import { routerSegments } from '@consts/routes';
import { Router } from '@angular/router';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { ConfirmDialogType } from '../../confirm-dialog/confirm-dialog.model';
import { withLatestFrom } from 'rxjs/operators';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { CameraSelectors } from '@states/camera/camera.selector-types';

const hlsConfig = {
  enableWorker: true,
  lowLatencyMode: true,
  liveSyncDurationCount: 1,
  liveMaxLatencyDurationCount: 2,
  initialLiveManifestSize: 1,
  backBufferLength: 30,
  highBufferWatchdogPeriod: 1,
  liveDurationInfinity: true,
};

@UntilDestroy()
@Component({
  selector: 'video-hls-playback-standalone',
  templateUrl: './hls-playback-standalone.component.html',
  styleUrl: './hls-playback-standalone.component.scss',
})
export class HlsPlaybackStandaloneComponent extends PlayerBaseComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() start: number;
  @Input() end: number;
  @Input() sessionId: string;
  @Input() url: string;

  @Input() loop = false;
  @Input() videoCurrentTime: number;

  @Output() forceMp4 = new EventEmitter<void>();

  private hlsEventListeners: Partial<HlsListeners> = {};
  public wallCamerasNumV2$: Observable<number> = this.store$.select(WallV2Selectors.selectCamerasNum);
  public selectHasSubstream$: Observable<boolean>;

  private hlsDebug = false;
  private hls: Hls | null = null;

  private hlsPlaybackTs: number;
  public hlsPlaybackDuration: number;
  public hlsPlaybackSessionId: string;
  private hlsErrorCounter = 0;

  public incompatibleCodec = false;


  constructor(
    cd: ChangeDetectorRef,
    private ngZone: NgZone,
    store$: Store,
    private camerasService: CamerasService,
    private confirm: ConfirmDialogService,
    videoService: VideoService) {
    super(store$, cd, videoService);
  }

  ngAfterViewInit(): void {
    this.initPlayer();
  }

  override ngOnDestroy(): void {
    console.info('ngOnDestroy');
    this.clearHls();
  }

  override ngOnInit(): void {
    console.info('ngOnInit', this.videoCurrentTime, this.sessionId);
    if (!this.edgeId || !this.cameraId || !this.locationId || !this.start || !this.end) {
      console.error('[HLS-PLAYBACK-STANDALONE] Missing required parameters');
    }
    if (this.sessionId) {
      this.hlsPlaybackSessionId = this.sessionId;
    }
    this.play();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['sessionId']?.currentValue !== changes['sessionId']?.previousValue) {
      this.pause();
      this.selectHasSubstream$ = this.store$.pipe(select(CameraSelectors.selectCameraHasSubstreamsById(this.cameraId)));
      this.wallCamerasNumV2$
        .pipe(untilDestroyed(this), withLatestFrom(this.selectHasSubstream$))
        .subscribe(([num, hasSubstream]) => {
          if (!this.cameraView && num > 1 && hasSubstream) {
            console.log(true);
            this.resolution = LiveStreamModels.StreamResolution.MQ;
          }
        });
      const connectionData: LiveStreamModels.ConnectionData = {
        cameraStatus: true,
        url: changes['url']?.currentValue,
        resolution: this.resolution,
      };
      this.setupHls(connectionData);

    }
  }

  pause(): void {
    this.video?.pause();
  }

  clearHls() {
    if (this.hls) {
      Object.keys(this.hlsEventListeners)
        .forEach((eventKey) => {
          const event = eventKey as keyof HlsListeners;
          const listener = this.hlsEventListeners[event];
          if (listener) {
            this.hls.off(event, listener);
          }
        });
      this.hlsEventListeners = {};
      this.hls.detachMedia();
      this.hls.destroy();
      this.hls = null;
    }
  }

  stop(): void {
    this.playerObj.nativeElement.pause();
    // Create a new video element
    this.clearVideo();
    this.clearHls();
    delete this.hlsPlaybackTs;
    delete this.hlsPlaybackDuration;

  }

  public async playbackStart(ts: number) {
    ts = Math.floor(ts);
    const request: LiveStreamModels.StartHlsPlaybackRequest = {
      cameraId: this.cameraId,
      edgeId: this.edgeId,
      locationId: this.locationId,
      smartStorage: false,
      start: this.start,
      end: this.end,
    };

    if (this.hlsPlaybackSessionId) {
      request.sessionId = this.hlsPlaybackSessionId;
    }

    this.setLoader.emit(true);
    this.camerasService.startHlsPlayback(request)
      .pipe(
        untilDestroyed(this),
        timeout(15000),
        catchError((err) => {
          console.log(err);
          const errObj = err?.error?.error;
          if (errObj?.statusCode === LiveStreamModels.HlsPlaybackErrors.NO_HLS) {
            this.forceMp4.emit();
            this.stop();
            if (request.start >= Date.now() - 60 * 1000) {
              this.play();
            }
            delete this.hlsPlaybackSessionId;
            delete this.hlsPlaybackTs;
            return of(err);
          }
          if (err?.name === 'TimeoutError') {
            this.playbackErrors.error = 'Timeout error';
            this.playbackErrors.statusCode = LiveStreamModels.HlsPlaybackErrors.FAILED_TO_JOIN;
          } else {
            this.playbackErrors.error = errObj?.error;
            this.playbackErrors.statusCode = errObj?.statusCode ?? LiveStreamModels.HlsPlaybackErrors.FAILED_TO_JOIN;
          }
          this.clearHealthCheck();
          this.setLoader.emit(false);
          return throwError(err);
        }))
      .subscribe((res) => {
        const connectionData: LiveStreamModels.ConnectionData = {
          cameraStatus: true,
          url: res.url,
          resolution: LiveStreamModels.StreamResolution.AUTO,
        };
        this.hlsPlaybackTs = ts;
        this.setupHls(connectionData);
        this.hlsPlaybackSessionId = res.sessionId;
        console.log('start playback response', res);
      });
    return;

  }

  async play(): Promise<void> {
    if (this.url) {
      // Received url - play an existing session, do not seek
      const connectionData: LiveStreamModels.ConnectionData = {
        cameraStatus: true,
        url: this.url,
        resolution: LiveStreamModels.StreamResolution.AUTO,
      };
      this.setupHls(connectionData);
      return;
    }
    this.playbackStart(this.start);
  }

  extend(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  resetState(): void {
    throw new Error('Method not implemented.');
  }

  checkHiddenDocument(visibilityChanged: VisibilityChanged): Promise<void> {
    throw new Error('Method not implemented.');
  }

  changeResolution(resolution: LiveStreamModels.StreamResolution): void {
    this.qualityChange.emit(true);
    this.resolution = resolution;
    this.stop();
    this.play();
  }

  override clearVideo(): void {
    const videoElement = this.playerObj.nativeElement;
    if (videoElement) {
      // Remove video event listeners
      Object.keys(this.videoEventListeners)
        .forEach(event => {
          videoElement.removeEventListener(event, this.videoEventListeners[event]);
        });
      this.videoEventListeners = {};

      // Reset video element state
      videoElement.pause();
      videoElement.src = '';
      videoElement.load();
      videoElement.srcObject = null;
      videoElement.currentTime = 0;
    }
  }

  // Define event listener methods
  private onManifestParsed = (event: Events.MANIFEST_PARSED, data: ManifestParsedData) => {
    this.hlsPlaybackDuration = this.hls.levels[0].details.totalduration;
    console.log('[HLS] Manifest parsed, duration:', this.hlsPlaybackDuration);

    this.ngZone.run(() => {
      this.playVideo();
    });
  };

  private onHlsError = (event: Events.ERROR, data: ErrorData) => {
    if (data.fatal) {
      console.error('[HLS] Fatal HLS.js error:', event, data);
      if (data.details === Hls.ErrorDetails.BUFFER_ADD_CODEC_ERROR || data.details === Hls.ErrorDetails.BUFFER_INCOMPATIBLE_CODECS_ERROR) {

        console.log('[HLS] Incompatible codecs error, show error confirm');
        this.playbackErrors.error = 'Not supported',
          this.playbackErrors.statusCode = LiveStreamModels.HlsPlaybackErrors.NOT_SUPPORTED;
        this.hlsErrorCounter++;
        console.log('[HLS] Error counter:', this.hlsErrorCounter);
        this.setLoader.emit(false);
        // if (!this.incompatibleCodec) {
        //   this.showCodecNotSupportedError();
        // }
        this.incompatibleCodec = true;
        return;
      }

      this.hlsErrorCounter++;
      console.log('[HLS] Error counter:', this.hlsErrorCounter);
      if (!this.incompatibleCodec) {
        this.recoverHls();
      }
    }
  };

  setupHls(connectionData: LiveStreamModels.ConnectionData): void {
    const { resolution, url } = connectionData;
    this.resolution = resolution;
    this.qualityChange.emit(false);
    if (this.hlsDebug) {
      console.log(`[HLS] starting hls stream at resolution:`, resolution, 'url:', url);
    }
    try {
      // Create a new video element
      this.clearVideo();

      const video = this.video;
      const hlsUrl = url;
      if (this.hlsDebug) {
        console.log(`[HLS] hlsUrl:`, hlsUrl);
      }

      this.clearHls();

      // Set loop property on video element
      video.loop = this.loop;

      // Create a copy of hlsConfig to modify
      const config = { ...hlsConfig };

      // Set startPosition in config if videoCurrentTime is provided
      if (this.videoCurrentTime !== undefined && this.videoCurrentTime !== null) {
        config['startPosition'] = this.videoCurrentTime;
        delete config['liveMaxLatencyDurationCount'];
      } else {
        const lastFiveMinutes = Date.now() - 5 * 60 * 1000;
        if (this.hlsPlaybackTs > lastFiveMinutes) {
          config['startPosition'] = 0;
          delete config['liveMaxLatencyDurationCount'];
        } else {
          config['liveMaxLatencyDurationCount'] = 2;
          delete config['startPosition'];
        }
      }

      if (Hls.isSupported()) {
        this.ngZone.runOutsideAngular(() => {
          this.hls = new Hls(config);
          this.hls.attachMedia(video);

          this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            this.hls.loadSource(url);
          });

          // Store event handlers with correct types
          this.hlsEventListeners[Events.MANIFEST_PARSED] = this.onManifestParsed;
          this.hls.on(Events.MANIFEST_PARSED, this.onManifestParsed);

          this.hlsEventListeners[Events.ERROR] = this.onHlsError;
          this.hls.on(Events.ERROR, this.onHlsError);
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = hlsUrl;

        // Set loop property on video element
        video.loop = this.loop;

        // Set currentTime after 'loadedmetadata' event
        this.videoEventListeners['loadedmetadata'] = () => {
          if (this.videoCurrentTime !== undefined && this.videoCurrentTime !== null) {
            video.currentTime = this.videoCurrentTime;
          }
          this.playVideo();
        };
        video.addEventListener('loadedmetadata', this.videoEventListeners['loadedmetadata']);

        this.videoEventListeners['error'] = (event) => {
          const error = video.error;
          console.error('[HLS] Video element error:', error);
          this.hlsErrorCounter++;
          this.recoverHls();
        };
        video.addEventListener('error', this.videoEventListeners['error'], { once: true });
      }
    } catch (error) {
      console.error('[HLS] Error setting up HLS:', error);
      this.hlsErrorCounter++;
      console.log('[HLS] Fatal error counter:', this.hlsErrorCounter);
      this.recoverHls();
    }
  }

  public async recoverHls() {
    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }
    this.stop();
    this.play();
  }

  public showCodecNotSupportedError() {
    this.confirm.open({
        title: `Browser not supported`,
        msg: `Browser does not support the codec required to play this video. Please use a different browser or device.`,
        type: ConfirmDialogType.WARN,
        confirm: 'OK',
      })
      .subscribe(res => {
      });
  }

}
