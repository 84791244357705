export const isEmailVerificationEmailRequired = (errorCode: string, errorDescription: string) => {
  return errorCode === 'access_denied' && errorDescription.indexOf('lumix-403') > -1;
};

export const isIpRulesRequired = (error: string, errorDescription: string) => {
  return error === 'access_denied' && errorDescription.indexOf('lumana-404') > -1;
};

export const isUserBlocked = (error: string, errorDescription: string) => {
  return error === 'invalid_grant' && errorDescription.indexOf('blocked') > -1;
};
