import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as SharedActions from '@states/shared/shared.actions';
import { StorageActions } from '@states/storage/storage.action-types';
import { StorageService } from '@states/storage/storage.service';
import { StorageModel } from '@models/storage.model';
import { StatsService } from '../../development/stats.service';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import { withLatestFrom } from 'rxjs/operators';
import { getCamerasStorageStatsAbly } from '@states/storage/storage.actions';

@Injectable()
export class StorageEffects {
  constructor(private actions$: Actions, private store$: Store<AppState>,
              private statsService: StatsService,
              private cameraThumbnailsService: CamerasThumbnailsService,
              private storageService: StorageService) {
  }

  // OLD IMPLEMENTATION - SQS
  // public getCamerasStorageStats$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(StorageActions.getCamerasStorageStats),
  //     mergeMap(({request}) => {
  //       return this.storageService.getStorageStats(request).pipe(
  //         mergeMap(res => {
  //           return [
  //             SharedActions.subscribeToSessionStatus({
  //               token: res.token.session,
  //               sessionDataAction: SessionDataAction.getStorageStats,
  //               params: {
  //                 msTimeout: 30000,
  //               },
  //             })
  //           ];
  //         }),
  //         catchError(err => {
  //           return of(SharedActions.showMessage({error: err.message || 'Error loading general state'}));
  //         })
  //       );
  //     })
  //   )
  // );
  //
  // public getCamerasStorageStatsSuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(StorageActions.getCamerasStorageStatsSuccess),
  //     mergeMap(({result}) => {
  //       const storageStats = result.storageStats;
  //       return [
  //         StorageActions.setStorageCache({storage: storageStats})
  //       ];
  //     })
  //   )
  // );

  // OLD IMPLEMENTATION - HEARTBEATS
  // public getCamerasStorageStats$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(StorageActions.getCamerasStorageStats),
  //     mergeMap(({request}) => {
  //       return this.statsService.getStorageStats(request).pipe(
  //         mergeMap(res => {
  //           const storageStats: StorageModel.StorageStatsOfDay[] = [];
  //           const result: StorageModel.StorageStatsTimeRangeResponse = {
  //             storageStats,
  //           }
  //           for (let key of Object.keys(res)) {
  //             const stats = res[key];
  //             const storageStatsOfDay: StorageModel.StorageStatsOfDay = {
  //               base: +key,
  //               edgeId: request.edgeId,
  //               cameraId: request.cameraId,
  //               cacheId: `${request.edgeId}:${request.cameraId}:${key}`,
  //               stats,
  //             }
  //             storageStats.push(storageStatsOfDay);
  //           }
  //           return [
  //             StorageActions.getCamerasStorageStatsSuccess({result}),
  //           ];
  //         }),
  //         catchError(err => {
  //           return of(SharedActions.showMessage({error: err.message || 'Error loading general state'}));
  //         })
  //       );
  //     })
  //   )
  // );

  public getCamerasStorageStatsAbly$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageActions.getCamerasStorageStatsAbly),
      mergeMap(({ request }) => {
        return this.statsService.getStorageStatsAbly(request)
          .pipe(
            mergeMap(res => {
              return [
                SharedActions.doNothing,
              ];
            }),
            catchError(err => {
              return of(SharedActions.showMessage({ error: err.message || 'Error loading general state' }));
            }),
          );
      }),
    ),
  );

  // /**
  //  * Get camera storage stats from Singlestore - to be enabled upon request
  //  */
  //
  public getCamerasStorageStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageActions.getCamerasStorageStats),
      mergeMap(({ request }) => {
        // return this.statsService.getStorageStatsAbly(request)
        // return this.statsService.getStorageStats(request)
        return this.statsService.getHlsStorageStats(request)
          .pipe(
            mergeMap(res => {
              const baseStart = this.cameraThumbnailsService.getBaseInLocale(new Date(request.start));
              const baseEnd = this.cameraThumbnailsService.getBaseInLocale(new Date(request.end));

              const storageStats: StorageModel.StorageStatsOfDay[] = Object.values(res)
                ?.map((item) => {
                  return {
                    edgeId: request.edgeId,
                    cameraId: request.cameraId,
                    base: item.base,
                    cacheId: `${request.edgeId}:${request.cameraId}:${item.base}`,
                    noStorage: item.noStorage,
                    smartStorage: item.smartStorage,
                  };
                });
              const result: StorageModel.StorageStatsTimeRangeResponse = {
                storageStats,
              };
              this.store$.dispatch(StorageActions.getCamerasStorageStatsSuccess({ result }));
              return [
                // SharedActions.doNothing,
                // StorageActions.getCamerasStorageStatsSuccess({result}),
              ];
            }),
            catchError(err => {
              // return of(SharedActions.showMessage({ error: err.message || 'Error loading general state' }));
              console.error(err.message);
              return of();
            }),
          );
      }),
    ),
  );

  // /**
  //  * Get HLS camera storage stats from Core - to be enabled upon request
  //  */
  //
  public getCamerasHlsStorageStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageActions.getCamerasHlsStorageStats),
      mergeMap(({ request }) => {
        // return this.statsService.getStorageStatsAbly(request)
        return this.statsService.getHlsStorageStats(request)
          .pipe(
            mergeMap(res => {
              const baseStart = this.cameraThumbnailsService.getBaseInLocale(new Date(request.start));
              const baseEnd = this.cameraThumbnailsService.getBaseInLocale(new Date(request.end));

              const storageStats: StorageModel.StorageStatsOfDay[] = res?.map((item) => {
                return {
                  edgeId: request.edgeId,
                  cameraId: request.cameraId,
                  base: item.base,
                  cacheId: `${request.edgeId}:${request.cameraId}:${item.base}`,
                  noStorageHls: item.noStorage,
                  smartStorageHls: item.smartStorage,
                };
              });
              const result: StorageModel.StorageStatsTimeRangeResponse = {
                storageStats,
              };
              this.store$.dispatch(StorageActions.getCamerasHlsStorageStatsSuccess({ result }));
              return [
                // SharedActions.doNothing,
                // StorageActions.getCamerasStorageStatsSuccess({result}),
              ];
            }),
            catchError(err => {
              return of(SharedActions.showMessage({ error: err.message || 'Error loading general state' }));
            }),
          );
      }),
    ),
  );

  public getCamersOldestVids$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageActions.getCamersOldestVids),
      mergeMap(({ request }) => {
        return this.statsService.getStorageOldestVidsAbly(request)
          .pipe(
            mergeMap(res => {
              return [
                SharedActions.doNothing,
                // StorageActions.getCamerasStorageStatsSuccess({result}),
              ];
            }),
            catchError(err => {
              return of(SharedActions.showMessage({ error: err.message || 'Error loading general state' }));
            }),
          );
      }),
    ),
  );

  public getCamerasStorageStatsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageActions.getCamerasStorageStatsSuccess),
      withLatestFrom(this.store$.select(state => state.cameraState)),
      mergeMap(([{ result }, cameraState]) => {
        const storageStats = result.storageStats;
        for(let stat of storageStats) {
          const { edgeId, cameraId } = stat;
          const oldestTs = +cameraState.entities[cameraId]?.cameraHealth?.oldestVideoTimestamp;
          const oldestTsBase = this.cameraThumbnailsService.getBaseInLocale(new Date(oldestTs));
          if (stat?.base === oldestTsBase) {
            const oldestOffset = oldestTs - oldestTsBase;
            // on the same day, we need to set offline until the oldest video ts
            for(let [index, interval] of stat.noStorage.entries()) {
              if (interval[0] < oldestOffset && interval[1] > oldestOffset) {
                interval[0] = oldestOffset;
                // remove all intervals before that index
                stat.noStorage.splice(0, index);
              }
            }
          }
          if (stat?.base < oldestTsBase) {
            // on the previous day, we need to set offline for the whole day
            // stat.noStorage = [[0, 86400000]];
            stat.noStorage = [];
            stat.smartStorage = [];
          }
        }
        return [
          StorageActions.setStorageCache({ storage: storageStats }),
        ];
      }),
    ),
  );

  public getCamerasHlsStorageStatsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageActions.getCamerasHlsStorageStatsSuccess),
      withLatestFrom(this.store$.select(state => state.cameraState)),
      mergeMap(([{ result }, cameraState]) => {
        const storageStats = result.storageStats;
        for(let stat of storageStats) {
          const { edgeId, cameraId } = stat;
          const oldestTs = +cameraState.entities[cameraId]?.cameraHealth?.oldestVideoTimestamp;
          const oldestTsBase = this.cameraThumbnailsService.getBaseInLocale(new Date(oldestTs));
          if (stat?.base === oldestTsBase) {
            const oldestOffset = oldestTs - oldestTsBase;
            // on the same day, we need to set offline until the oldest video ts
            for(let [index, interval] of stat.noStorageHls.entries()) {
              if (interval[0] < oldestOffset && interval[1] > oldestOffset) {
                interval[0] = oldestOffset;
                // remove all intervals before that index
                stat.noStorageHls.splice(0, index);
              }
            }
          }
          if (stat?.base < oldestTsBase) {
            // on the previous day, we need to set offline for the whole day
            stat.noStorageHls = [[0, 86400000]];
            stat.smartStorageHls = [];
          }
        }
        return [
          StorageActions.setStorageCache({ storage: storageStats }),
        ];
      }),
    ),
  );

  public getCamerasOldestVidsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageActions.getCamerasOldestVidsSuccess),
      mergeMap(({ result }) => {
        const storageStats = result.storageStats;
        return [
          StorageActions.setStorageCache({ storage: storageStats }),
          StorageActions.updateStorageCacheByOldest({ storage: storageStats as StorageModel.StorageOldest[] }),
        ];
      }),
    ),
  );
}
