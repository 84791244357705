import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, concatMap, filter, finalize, first, map, Observable, tap } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { LocationModel } from './location.model';
import { LocationActions } from '@states/location/location.action-types';
import { LocationSelectors } from '@states/location/location.selector-types';
import { SplashService } from '../framework/splash/splash.service';
import { AppState } from '../store/app.state';
import { UserService } from '../user/user.service';
import * as GeneralActions from '@states/general/general.actions';
import { UserSelectors } from '@states/user/user.selector-types';

export interface LocationsResolverResult {
  locations: LocationModel.LocationItem[];
}

@Injectable({
  providedIn: 'root',
})
export class LocationsResolver {
  loading = false;

  constructor(
    private authenticationService: AuthenticationService,
    private splashService: SplashService,
    private store: Store<AppState>,
    private userService: UserService,
    private router: Router,
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): LocationsResolverResult | Observable<LocationsResolverResult> | Promise<LocationsResolverResult> {
    return this.store.pipe(
      concatMap(() => {
        return combineLatest([
          this.store.pipe(select(UserSelectors.isProfileLoaded)),
          this.store.pipe(select(LocationSelectors.isFirstLocationLoaded)),
        ]);
      }),
      tap(([isProfileLoaded, isFirstLocationLoaded]) => {
        if (this.authenticationService.isLoggedIn() && !this.loading && !isFirstLocationLoaded && isProfileLoaded) {
          this.loading = true;
          if (this.userService.getUserFromLocalStorage()
            .roles
            .includes('operator')) {
            this.router.navigateByUrl('operator/create');
          }
          this.store.dispatch(LocationActions.GetLocations());
          this.store.dispatch(GeneralActions.getGeneralFromBackend());
        }
      }),
      filter(([isProfileLoaded, isFirstLocationLoaded]) => isFirstLocationLoaded),
      concatMap(res => this.store.pipe(select(LocationSelectors.selectAllLocations))),
      map(res => {
        return { locations: res };
      }),
      first(),
      finalize(() => {
        this.loading = false;
        this.splashService.hide();
      }),
    );
  }
}
