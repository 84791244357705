import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MsgBoxType } from '../shared/msg-box/msg-box.model';
import { UiCustomToastComponent } from '../shared/ui-kit/ui-custom-toast/ui-custom-toast.component';
import { select, Store } from '@ngrx/store';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import * as ArchiveAction from '@states/archive/archive.actions';
import { LocationSelectors } from '@states/location/location.selector-types';
import { lastValueFrom, take } from 'rxjs';
import { PlaybackPlayerDialogData } from '../shared/playback-player-dialog/playback-player-dialog.component';
import { CameraThumbnailsData, ThumbOptions } from '../cameras/camera-thumbnails/camera-thumbnails.model';
import { MultiPlaybackData } from '@models/multi-playback.model';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { ThumbnailDialogComponent, ThumbnailDialogData, ThumbnailDialogType } from '../shared/thumbnail/thumbnail-dialog/thumbnail-dialog.component';
import { AlertEntry } from './alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '../store/app.state';
import { CamerasThumbnailsService } from '../cameras/camera-thumbnails/camera-thumnails.service';
import { EdgeStatusService } from '../edge/edge-status.service';
import { environment } from '../../environments/environment';
import { CameraLiveViewDialogComponent, CameraLiveViewDialogData } from '../shared/camera-live-view-dialog/camera-live-view-dialog.component';
import { KeyValuePairs } from '../core/interfaces';
import * as moment from 'moment-timezone';

@Injectable()
export class SharedService {
  private latestToastId;

  constructor(private toastService: ToastrService,
              public dialog: MatDialog,
              public store$: Store<AppState>,
              public cameraThumbnailsService: CamerasThumbnailsService,
              public edgeStatusService: EdgeStatusService) {
  }

  public showCustomAlert(message: string, type: MsgBoxType): void {
    const customToast = this.toastService.show(message, null, {
      closeButton: true,
      disableTimeOut: true,
      toastComponent: UiCustomToastComponent,
      positionClass: 'toast-bottom-center-custom',
    });
    this.toastService.remove(this.latestToastId);
    customToast.toastRef.componentInstance.alertType = type;
    customToast.toastRef.componentInstance.title = null;
    customToast.toastRef.componentInstance.message = message;
    customToast.toastRef.componentInstance.toastId = customToast.toastId;
    this.latestToastId = customToast.toastId;
  }

  public getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls)
      .forEach(key => {
        const currentControl = form.controls[key];

        if (currentControl.dirty) {
          if (currentControl.controls) dirtyValues[key] = this.getDirtyValues(currentControl);
          else dirtyValues[key] = currentControl.value;
        }
      });

    return dirtyValues;
  }


  public playback(alert: AlertEntry): void {
    this.store$.pipe(select(CameraSelectors.selectCameraById(alert.cameraId)))
      .subscribe(res => {
        this.store$.dispatch(ArchiveAction.changeArchiveProperty({ property: 'selectedCamera', value: res }));
      });
    this.store$
      .select(LocationSelectors.selectLocationTimezone(alert.selectedCamera.locationId))
      .pipe(take(1))
      .subscribe(async timezone => {
        const data: PlaybackPlayerDialogData = {
          cameraId: alert.cameraId,
          cameraName: alert.eventName,
          duration: 0,
          time: alert.timestamp,
          timezone: timezone ?? moment.tz.guess(),
        };

        const startTime = this.normalizeTimestamp(alert.timestamp - 32000);
        const endTime = this.normalizeTimestamp(Math.min(alert.timestamp + 32000, new Date().getTime()));
        const options: ThumbOptions = {
          clipInSeconds: 7200,
          offsetResInDurations: 60,
          startTime,
          endTime,
          duration: 2000,

        };
        const thumbsData: CameraThumbnailsData = {
          edgeId: alert.edgeId,
          cameraId: alert.cameraId,
          timezone: alert?.selectedCamera?.timezone ?? timezone,
          offsetResInDurations: 60,
        };

        const multiPlaybackData: MultiPlaybackData = {
          base: startTime,
          duration: 2000,
          endTime,
          offsetResInDurations: 1,
          startTime,
          timezone: alert.selectedCamera.timezone,
        };
        this.store$.dispatch(MultiPlaybackActions.setData({ data: multiPlaybackData }));

        this.cameraThumbnailsService.setThumbnailsData(thumbsData);
        // let events: number[];
        // if (alert.timestamp > new Date().getTime() - 80000 && alert?.alertTimeline?.events) {
        //   events = this.getAlertEvents(alert);
        // } else {
        //   const thumbnails = await this.getThumbnails(thumbsData, startTime, endTime);
        //   const base = this.getBaseInLocale(new Date(startTime));
        //   const indexStart = this.cameraThumbnailsService.getEventLocation(startTime, base);
        //   const indexEnd = this.cameraThumbnailsService.getEventLocation(endTime, base);
        //   events = thumbnails[base].slice(indexStart, indexEnd);
        // }

        const dialogData: ThumbnailDialogData = {
          options,
          events: null,
          edgeId: alert.edgeId,
          cameraId: alert.cameraId,
          objects: alert?.thumbnails?.map(thumb => {
            return {
              url: thumb,
              descriptor: false,
            };
          }),
          type: ThumbnailDialogType.ALERT,
          rtl: false,
          defaultThumb: alert.mainThumbnail,
          showObjects: false,
          seconds: true,
          alertName: alert.eventName,
        };

        this.dialog.open(ThumbnailDialogComponent, {
          panelClass: 'thumbnail-dialog-wrapper',
          width: '90vh',
          data: dialogData,
        });
      });
  }

  public normalizeTimestamp(timestamp: number) {
    return this.cameraThumbnailsService.normalizeTimestamp(timestamp);
  }


  async getThumbnails(thumbsData: CameraThumbnailsData, startTime: number, endTime: number): Promise<KeyValuePairs<number[]>> {
    const result: KeyValuePairs<number[]> = {};
    const start = this.getBaseInLocale(new Date(startTime));
    const thumbnailsRaw = await lastValueFrom(this.getThumbnailsRaw(thumbsData, startTime, endTime));
    for(let thumb of thumbnailsRaw) {
      const events: number[] = [];
      for(let index = 0; index < 5400; index++) {
        let val = thumb.events[index];
        if (val) {
        }
        events.push(val & 0x0000000f);
        events.push((val & 0x000000f0) >> (4 * 1));
        events.push((val & 0x00000f00) >> (4 * 2));
        events.push((val & 0x0000f000) >> (4 * 3));
        events.push((val & 0x000f0000) >> (4 * 4));
        events.push((val & 0x00f00000) >> (4 * 5));
        events.push((val & 0x0f000000) >> (4 * 6));
        events.push((val & 0xf0000000) >> (4 * 7));
      }
      result[thumb.base] = events;
    }
    return result;
  }

  getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date);
  }

  getThumbnailsRaw(data: CameraThumbnailsData, startTime: number, endTime: number) {
    data.offsetResInDurations = 45;

    const baseUrl = `${environment.thumbnailsUrl}/thumbnails/${data.edgeId}/${data.cameraId}`;
    const start = this.getBaseInLocale(new Date(startTime));
    const end = this.getBaseInLocale(new Date(endTime));
    return this.cameraThumbnailsService.getThumbnailsByDateFromDb(data.edgeId, data.cameraId, start, end);
  }

  getAlertEvents(alert: AlertEntry) {
    // return this.spreadEvents(alert.alertTimeline.events.slice(0, 2).concat([286331153, 286331153]));
    return this.spreadEvents(alert.alertTimeline.events);
  }


  spreadEvents(eventsRaw: number[]): number[] {
    const events: number[] = [];
    for(let index = 0; index < eventsRaw.length; index++) {
      let val = eventsRaw[index];
      if (val) {
      }
      events.push(val & 0x0000000f);
      events.push((val & 0x000000f0) >> (4 * 1));
      events.push((val & 0x00000f00) >> (4 * 2));
      events.push((val & 0x0000f000) >> (4 * 3));
      events.push((val & 0x000f0000) >> (4 * 4));
      events.push((val & 0x00f00000) >> (4 * 5));
      events.push((val & 0x0f000000) >> (4 * 6));
      events.push((val & 0xf0000000) >> (4 * 7));
    }
    return events;
  }

  public liveStream(alert: AlertEntry, cameraName: string): void {
    const data: CameraLiveViewDialogData = {
      camera: {
        edgeId: alert.edgeId,
        locationId: alert.selectedCamera.locationId,
        cameraId: alert.cameraId,
        name: cameraName,
      },
    };
    this.dialog.open(CameraLiveViewDialogComponent, {
      data,
    });
  }
}
