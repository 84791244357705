import { KeyValuePairs } from '../../core/interfaces';

export namespace DeviceModel {
  export enum DeviceType {
    Speaker,
    GateControl,
  }

  export enum SpeakerBrands {
    TOA = 'TOA',
    Uniview = 'Uniview',
    // Unknown = 'Unknown',
  }

  export type DeviceModel = SpeakerBrands;

  export interface LocationDeviceDocument {
    _id?: string;
    name: string;
    type: DeviceType;
    model: DeviceModel;
    ipAddress: string;
    port?: number;
    username: string;
    password: string;
  }

  export interface DeviceConnectivityMsg extends LocationDeviceDocument {
    locationId: string;
    edgeId: string;
  }

  export interface DeviceAdd extends LocationDeviceDocument {
    locationId: string;
  }

  export type DeviceMap = KeyValuePairs<LocationDeviceDocument>;

  export interface DeviceState {
    testOngoing?: boolean;
    inProgressNum?: number;
    testSuccess?: boolean;
    testError?: boolean;
    testErrorMsg?: string;
  }
}
