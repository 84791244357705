import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeviceOptions, CameraOptions, RetentionOptions, StorageOptions, BoxConfiguration, BoxPrice, LicenseTerms, CameraPrice, Pricing, PricingLocationInfo, PricingCount, AddOnPrice, AddOnOptions, PricingLocation } from '@models/pricing.model';
import { addOnPricing, appleTvLicensePricing, boxConfiguration, boxPricing, cameraPricing, PricingTermToYears, sensorLicensePricing, speakerLicensePricing, swLicensePricing } from '@consts/pricing.const';

@Injectable({
  providedIn: 'root',
})

export class PriceCalculatorService {

  constructor(private http: HttpClient) {
  }

  boxConfiguration: BoxConfiguration[] = boxConfiguration;
  boxPricing: BoxPrice[] = boxPricing;
  swLicensePricing: Record<LicenseTerms, number> = swLicensePricing;
  speakerLicensePricing: Record<LicenseTerms, number> = speakerLicensePricing;
  sensorLicensePricing: Record<LicenseTerms, number> = sensorLicensePricing;
  appleTvLicensePricing: Record<LicenseTerms, number> = appleTvLicensePricing;
  addOnPricing: AddOnPrice[] = addOnPricing;
  cameraPricing: CameraPrice[] = cameraPricing;

  getBoxPrice(storage: StorageOptions, device: DeviceOptions, terms: LicenseTerms): number {
    const elem = this.boxPricing.find((item) => {
      return (item.storage === storage && item.device === device);
    });
    if (elem != null)
      return elem.price[terms];
    return null;
  }

  getCameraPrice(resolution: CameraOptions, terms: LicenseTerms, own = false): number {
    const elem = this.cameraPricing.find((item) => {
      return (item.resolution === resolution);
    });
    if (elem != null) {
      if (own && elem.own != null) {
        return elem.own;
      }
      return elem.price[terms];
    }
    return null;
  }

  getAddOnPrice(type: AddOnOptions): number {
    return addOnPricing.find(item => item.type === type).price;
  }

  getSWLicensePrice(terms: LicenseTerms): number {
    return this.swLicensePricing[terms];
  }

  getSpeakerLicensePrice(terms: LicenseTerms): number {
    return this.speakerLicensePricing[terms];
  }

  getSensorLicensePrice(terms: LicenseTerms): number {
    return this.sensorLicensePricing[terms];
  }

  getAppleTvLicensePrice(terms: LicenseTerms): number {
    return this.appleTvLicensePricing[terms];
  }

  getRetentionPrice(count: PricingCount): number {
    let total = 0;
    const prices = {
      metadataStorage: 19,
      cloudStorage: 99,
      externalStorage: 19,
      snapshotStorage: 9,
    };

    for(let retention of ['metadataStorage', 'cloudStorage', 'externalStorage', 'snapshotStorage']) {
      const price = prices[retention];
      for(let multiplier of [1, 2, 3, 6, 12]) {
        if (!!count[`${retention}${multiplier === 12 ? '365' : (multiplier * 30)}`]) {
          total += count[`${retention}${multiplier === 12 ? '365' : (multiplier * 30)}`] * price * multiplier;
        }
      }
    }
    return total;
  }

  getAdditionalOptionsPrice(count: PricingCount, terms: LicenseTerms) {
    return count.additionalSpeakerLicense * this.getSpeakerLicensePrice(terms) +
      count.additionalSensorLicense * this.getSensorLicensePrice(terms) +
      count.additionalAppleTvLicense * this.getAppleTvLicensePrice(terms) +
      count.additionalAlertStorage * 129 +
      count.additionalEventTagsStorage * 39;
  }

  getVerificationPrice(count: PricingCount) {
    // [Sagi] Review cloud verification pricing
    return count.verificationEvents * 1200 +
      count.verificationGuns * 480 +
      count.verificationFire * 36;
  }

  getBoxConfiguration(device: DeviceOptions, storage: StorageOptions, retention: RetentionOptions, resolution: CameraOptions): BoxConfiguration {
    return this.boxConfiguration.filter((item) => {
      return (retention === item.retention && resolution === item.resolution && item.device === device && item.storage === storage);
    })[0];
  }

  getMaxCameras(device: DeviceOptions, storage: StorageOptions, retention: RetentionOptions, resolution: CameraOptions): number {
    const boxConfiguration = this.getBoxConfiguration(device, storage, retention, resolution);
    return boxConfiguration.maxNumberOfCameras;
  }


  getMaxBoxConfiguration(numOfCameras: number, retention: RetentionOptions, resolution: CameraOptions, includeOrinNano: boolean = false): BoxConfiguration {
    if (numOfCameras <= 0)
      return null;
    const relevantConfigurations = (includeOrinNano ? boxConfiguration.filter((item) => {
        return (retention === item.retention && resolution === item.resolution);
      }) :
      boxConfiguration.filter((item) => {
        return (retention === item.retention && resolution === item.resolution && item.device != DeviceOptions.Nano8GBOrin);
      }))
      .sort((a, b) => {
        return (a.maxNumberOfCameras - b.maxNumberOfCameras);
      });
    for(let i = 0; i < relevantConfigurations.length; i++) {
      if (numOfCameras <= relevantConfigurations[i].maxNumberOfCameras) {
        return relevantConfigurations[i];
      }
    }
    // return the biggest
    return relevantConfigurations[relevantConfigurations.length - 1];
  }

  public getLocationBoxes(locationInfos: PricingLocationInfo[]) {
    let score = 0;
    for(let info of locationInfos) {
      switch (info.resolution) {
        case CameraOptions.Resolution5MP:
          switch (info.retention) {
            case RetentionOptions.Days30:
              score += info.numberOfCameras * 1;
              break;
            case RetentionOptions.Days60:
              score += info.numberOfCameras * 2;
              break;
            case RetentionOptions.Days90:
              score += info.numberOfCameras * 3;
              break;
          }
          break;
        case CameraOptions.Resolution8MP:
          switch (info.retention) {
            case RetentionOptions.Days30:
              score += info.numberOfCameras * 1.5;
              break;
            case RetentionOptions.Days60:
              score += info.numberOfCameras * 3;
              break;
            case RetentionOptions.Days90:
              score += info.numberOfCameras * 4;
              break;
          }
          break;
        case CameraOptions.Fisheye12MP:
          switch (info.retention) {
            case RetentionOptions.Days30:
              score += info.numberOfCameras * 2;
              break;
            case RetentionOptions.Days60:
              score += info.numberOfCameras * 4;
              break;
            case RetentionOptions.Days90:
              score += info.numberOfCameras * 6;
              break;
          }
          break;
      }
    }
    return Math.ceil(score / 12);
  }

  getLocationPricing(location: PricingLocationInfo, term: LicenseTerms): { pricing: Pricing, count: PricingCount } {
    const { numberOfCameras, retention, resolution, numberOfNewCameras } = location;
    const termNum = PricingTermToYears[term];
    let cameraLeft = numberOfCameras;
    let pricing: Pricing = { boxes: 0, license: 0, cameras: 0, total: 0, regularPrice: 0, oneTimePrice: 0, shipping: 0 };
    let count: PricingCount = {
      box2tb: 0,
      box4tb: 0,
      box1u32tb: 0,
      box1u64tb: 0,
      box1u88tb: 0,
      license: numberOfCameras,
      camerasTurret8mp: numberOfNewCameras * (resolution === CameraOptions.Resolution8MP ? 1 : 0),
      camerasTurret5mp: numberOfNewCameras * (resolution === CameraOptions.Resolution5MP ? 1 : 0),
      camerasBullet8mp: 0,
      camerasDome5mp: 0,
      camerasBullet5mp: 0,
      camerasFisheye12mp: 0,
      camerasDome8mp: 0,
      camerasPtz: 0,
      camerasTurret5mpZoom: 0,
      ownCamerasTurret5mp: 0,
      ownCamerasBullet5mp: 0,
      ownCamerasDome5mp: 0,
      ownCamerasTurret8mp: 0,
      ownCamerasBullet8mp: 0,
      ownCamerasDome8mp: 0,
      ownCamerasFisheye12mp: 0,
      ownCamerasPtz: 0,
      ownCamerasTurret5mpZoom: 0,
    };
    let box = 0;
    // while (cameraLeft > 0) {
    //   const item = this.getMaxBoxConfiguration(cameraLeft, retention, resolution);
    //   cameraLeft -= item.maxNumberOfCameras;
    //   box++;
    //
    //   // 2TB box is not a recommendation anymore
    //   const boxPrice = this.getBoxPrice(StorageOptions.Storage4TB, item.device, term);
    //   count.box4tb++;
    //   pricing.boxes += boxPrice * termNum;
    // }
    // pricing.total += pricing.boxes;
    const cameraPrice = this.getCameraPrice(resolution, term);
    pricing.cameras = cameraPrice * numberOfNewCameras * termNum;
    pricing.total += pricing.cameras;

    const licensePrice = this.getSWLicensePrice(term);
    pricing.license = licensePrice * numberOfCameras * termNum;
    pricing.total += pricing.license;
    return { pricing, count };
  }
}
