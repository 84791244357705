import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { alertsSettingsDisplayOptions, alertsSettingsPicInPicOptions, alertsSettingsPicInPicPositionOptions, AlertsV2DisplaySettings, AlertsV2PicInPicPosition } from '@models/alerts-v2.model';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'alert-tile-settings',
  templateUrl: './alert-tile-settings.component.html',
  styleUrls: ['./alert-tile-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertTileSettingsComponent {
  @Input() settingsForm: UntypedFormGroup;

  public alertsV2DisplaySettingsOptions = alertsSettingsDisplayOptions;
  public alertsV2DisplaySettingsPicOptions = alertsSettingsPicInPicOptions;
  public alertsV2PicInPicPositionOptions = alertsSettingsPicInPicPositionOptions;

  public durationOptions: { value: number; label: string }[] = [
    {
      label: '10 seconds',
      value: 10 * 1000,
    },
    {
      label: '30 seconds',
      value: 30 * 1000,
    },
    {
      label: '1 minute',
      value: 1000 * 60,
    },
    {
      label: '2.5 minutes',
      value: 1000 * 60 * 2.5,
    },
  ];

  constructor() {
  }

}
