import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuardService  {
  constructor(private router: Router, private authenticationService: AuthenticationService) { }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // console.log(`[AUTHENTICATION-GUARD-SERVICE]: checking backend login status`);
    const isLoggedIn = this.authenticationService.isLoggedIn();

    const authProviderId = this.authenticationService.getAuthProviderIdFromLocalStorage();

    const errMsg = route?.queryParamMap.get('errMsg')

    if (!isLoggedIn || !authProviderId) {
      return false;
    }

    return new Promise((resolve, reject) => {
      this.authenticationService
        .authenticateToFirebase({ userId: authProviderId })
        .then(res => {
          if (!!res && !!isLoggedIn) {
            resolve(true);
          } else {
            this.authenticationService.logout();
            resolve(false);
          }
        })
        .catch(err => {
          this.authenticationService.logout();
          resolve(false);
        });
    });
  }
}
