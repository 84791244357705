<div class="location-row">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <label fxLayout="row" fxLayoutAlign="start center" class="clickable">
      <mat-checkbox color="primary"
                    [disabled]="location.cameras?.length > maxSelected - selectedCamerasCount && !isLocationSelected()"
                    [checked]="isLocationSelected()"
                    (change)="selectLocation($event,location)"
      >{{location | locationAddress}}</mat-checkbox>
    </label>
    <span class="ui-icon-expand-arrow clickable"
          [class.expanded]="isExpanded"
          (click)="isExpanded = !isExpanded"
    ></span>
  </div>
  <div *ngIf="isExpanded"
       class="cameras-wrapper"
  >
    <!--    <ng-scrollbar>-->
    <div *ngFor="let camera of location.cameras" class="camera-row">
      <div class="camera-details">
        <mat-checkbox color="primary"
                      [id]="camera.edgeOnly.cameraId"
                      [disabled]="maxSelected - selectedCamerasCount === 0 && !selectedCameras[camera.edgeOnly.cameraId]"
                      [checked]="!!selectedCameras[camera.edgeOnly.cameraId]"
                      (change)="selectCamera(camera)"
        ></mat-checkbox>

        <img
            class="snapshot-img"
            [lazyLoad]=" {edgeId:camera.edgeId, cameraId: camera.edgeOnly.cameraId } | cameraBestImagePipe | async"
            [defaultImage]="{edgeId:camera.edgeId, cameraId: camera.edgeOnly.cameraId } | cameraThumbnailImagePipe"
        />
        <label class="camera-name clickable" [for]="camera.edgeOnly.cameraId">{{camera.edgeOnly.name}}</label>
      </div>
      <app-component-status-chip
          [status]="(getCameraPulsationStatus(camera.edgeOnly.cameraId) | async)"
      ></app-component-status-chip>
    </div>
    <!--    </ng-scrollbar>-->
  </div>
</div>
