import { createAction, props } from '@ngrx/store';
import { AppleTvModels } from '@models/apple-tv.models';
import { WallModel } from '@models/wall.model';
import { Socket } from 'socket.io-client';
import { SocketModels } from '../../../socket/socket.model';
import { WallV2Model } from '@models/wall-v2.model';

export const resetToInitialState = createAction('[APPLE-TV] Reset to Initial state');

export const getAppleTv = createAction('[APPLE-TV] Get Apple Tv');
export const getAppleTvSuccess = createAction('[APPLE-TV] Get Apple Tv Success', props<{ documents: AppleTvModels.AppleTvWithWallDocument[] }>());
export const getAppleTvFail = createAction('[APPLE-TV] Get Apple Tv Fail');

export const setQueryStringFilter = createAction('[APPLE-TV] Set Query String Filter', props<{ prop: string; value: string }>());
export const resetEntities = createAction('[APPLE-TV] Reset Entities');

export const startSaveAppleTv = createAction('[APPLE-TV] Start Save Apple TV', props<{ document: Partial<AppleTvModels.AppleTvDocument>, isEdit: boolean }>());
export const saveAppleTvRequest = createAction('[APPLE-TV] Save Apple TV Request', props<{ document: Partial<AppleTvModels.AppleTvDocument>, isEdit: boolean }>());
export const saveAppleTvRequestSuccess = createAction('[APPLE-TV] Save Apple TV Request Success');
export const saveAppleTvRequestFail = createAction('[APPLE-TV] Save Apple TV Request Fail');

export const setIsSaving = createAction('[APPLE-TV] Set Is Saving', props<{ isSaving: boolean }>());

export const startDeleteDevice = createAction('[APPLE-TV] Start Delete Device', props<{ edgeId: string }>());
export const deleteDevice = createAction('[APPLE-TV] Delete Device', props<{ edgeId: string }>());
export const deleteDeviceSuccess = createAction('[APPLE-TV] Delete Device Success', props<{ edgeId: string }>());
export const deleteDeviceFail = createAction('[APPLE-TV] Delete Device Fail');

export const onSocketCreatedSuccess = createAction('[APPLE-TV] On Socket Created Success', props<{ document: AppleTvModels.AppleTvWithWallDocument }>());
export const onSocketCreatedError = createAction('[APPLE-TV] On Socket Created Error', props<{ error: string, authProviderId: string }>());
export const saveAppleTvSetOne = createAction('[APPLE-TV] Save Apple TV Set One ', props<{ document: AppleTvModels.AppleTvWithWallDocument }>());

export const onSocketDeletedSuccess = createAction('[APPLE-TV] On Socket Deleted Success', props<{ document: AppleTvModels.AppleTvWithWallDocument, authProviderId: string }>());
export const onSocketDeletedFail = createAction('[APPLE-TV] On Socket Deleted Fail', props<{ error: string }>());

export const getOneAppleTv = createAction('[APPLE-TV] Get One Apple Tv', props<{ id: string }>());
export const getOneAppleTvSuccess = createAction('[APPLE-TV] Get One Apple Tv Success', props<{ document: AppleTvModels.AppleTvWithWallDocument }>());
export const getOneAppleTvFail = createAction('[APPLE-TV] Get One Apple Tv Fail');


export const getWalls = createAction('[APPLE-TV] Get Walls');
export const getWallsSuccess = createAction('[APPLE-TV] Get Walls Success', props<{ walls: WallV2Model.WallMongoDocument[] }>());
export const getWallsFail = createAction('[APPLE-TV] Get Walls Fail');

export const onSocketConfigureSuccess = createAction('[APPLE-TV] On Socket Configure Success', props<{
  response: SocketModels.ConfigureAppleTv.ConfigureAppleTvResponseData,
  authProviderId: string
}>());
export const onSocketConfigureFail = createAction('[APPLE-TV] On Socket Configure Fail', props<{ error: string, authProviderId: string, edgeId: string }>());
export const updateAppleTvSetOne = createAction('[APPLE-TV] Update Apple TV Set One ', props<{ response: SocketModels.ConfigureAppleTv.ConfigureAppleTvResponseData }>());

export const changeFormValidation = createAction('[APPLE-TV] Change Form Validation ', props<{ isFormValid: boolean }>());

export const getWallFromDevice = createAction('[APPLE-TV] Get Wall From Device', props<{ edgeId: string }>());
export const getWallFromDeviceSuccess = createAction('[APPLE-TV] Get Wall From Device Success');
export const getWallFromDeviceFail = createAction('[APPLE-TV] Get Wall From Device Fail');

export const onSocketGetWallSuccess = createAction('[APPLE-TV] On Socket Configure Success', props<{ wall: WallModel, edgeId: string, isEqual: boolean }>());
export const onSocketGetWallFail = createAction('[APPLE-TV] On Socket Configure Fail', props<{ error: string }>());
export const updateAppleTvWall = createAction('[APPLE-TV] Update Apple Tv Wall', props<{ wall: WallModel, edgeId: string, isEqual: boolean }>());

export const startConfigureAppleTv = createAction('[APPLE-TV] Start Configure Apple TV', props<{ document: Partial<AppleTvModels.AppleTvDocument> }>());
export const rmEdgeIdFromConfigureEdges = createAction('[APPLE-TV] Remove Edge Id from Configure Edges', props<{ edgeId: string }>());

