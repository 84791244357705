import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PreloaderColor } from '@enums/shared.enum';

//todo do es enum
const buttonTypes = {
  default: {
    bg: 'var(--color-background-button-default)',
    color: 'var(--color-text-default)',
    border: 'var(--color-border-button-default-default)',
  },
  primary: {
    bg: 'var(--color-background-button-primary-default)',
    color: 'var(--color-text-inverse)',
    border: 'var(--color-border-button-primary-default)',
  },
  secondary: {
    bg: 'var(--button-bg-secondary)',
    color: 'var(--text-color-text-primary)',
    border: 'var(--button-border-primary)',
  },
  secondaryPressed: {
    bg: 'var(--color-background-button-secondary-pressed)',
    color: 'var(--text-color-text-primary)',
    border: 'var(--button-border-primary)',
  },
  noBorderPrimary: {
    bg: 'transparent',
    color: 'var(--text-color-text-primary)',
    border: '',
  },
  noBorderDefault: {
    bg: 'transparent',
    color: 'black',
    border: '',
  },
};

export enum UIButtonSize {
  Default,
  Button50//height 50
}

@Component({
  selector: 'ui-button',
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiButtonComponent implements AfterViewInit, OnChanges {
  @Output() clickAction: EventEmitter<any> = new EventEmitter();
  @Input() text = '';
  @Input() icon = '';
  @Input() size: UIButtonSize = UIButtonSize.Default;
  /**
   * @deprecated use type instead
   */
  @Input() colorBgr: string;
  /**
   * @deprecated use type instead
   */
  @Input() colorBorder = '';
  @Input() colorIcon = '';
  /**
   * @deprecated use type instead
   */
  @Input() colorText: string;
  @Input() iconSize = '';

  @Input() disabled = false;
  @Input() type: string;
  @Input() loading = false;
  @Input() preloaderColor = PreloaderColor.blue;

  public buttonSizes = UIButtonSize;

  constructor(
    public elRef: ElementRef) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.type) {
      this.elRef.nativeElement.style.backgroundColor = buttonTypes[this.type].bg;
      this.elRef.nativeElement.firstChild.lastChild.style.color = buttonTypes[this.type].color;
      this.elRef.nativeElement.style.borderColor = buttonTypes[this.type].border;
    }
  }

  public click(): void {
    if (!this.loading && !this.disabled) {
      this.clickAction.emit();
    }
  }

  public ngAfterViewInit(): void {
    if (this.colorBgr) {
      this.elRef.nativeElement.style.backgroundColor = this.colorBgr;
    }

    if (this.colorBorder.length) {
      this.elRef.nativeElement.style.borderColor = this.colorBorder;
    }

    if (this.colorIcon.length) {
      if (this.elRef.nativeElement.firstChild.firstChild.style) {
        this.elRef.nativeElement.firstChild.firstChild.style.color = this.colorIcon;
      }
    }

    if (this.colorText) {
      this.elRef.nativeElement.firstChild.lastChild.style.color = this.colorText;
    }

    if (this.iconSize.length) {
      this.elRef.nativeElement.firstChild.firstChild.style.fontSize = `${this.iconSize}px`;
    }

    // if (this.type) {
    //   this.elRef.nativeElement.style.backgroundColor = buttonTypes[this.type].bg;
    //   this.elRef.nativeElement.firstChild.lastChild.style.color = buttonTypes[this.type].color;
    //   this.elRef.nativeElement.style.borderColor = buttonTypes[this.type].border;
    // }

  }
}
