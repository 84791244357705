import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WallState } from '@states/wall/wall.reducer';
import { WallAlert } from '@models/wall.model';

export const selectWallState = createFeatureSelector<WallState>('wallState');

export const selectIsSetLayoutSelected = createSelector(selectWallState, ({ selectedWall, selectedSetIndex }: WallState) => {
  return !!selectedWall.sets[selectedSetIndex].layout;
});

export const selectSelectedSet = createSelector(selectWallState, ({ selectedWall, selectedSetIndex }: WallState) => {
  return selectedWall?.sets[selectedSetIndex];
});

export const selectSelectedSetIndex = createSelector(selectWallState, ({ selectedSetIndex }: WallState) => selectedSetIndex);

export const selectSelectedWall = createSelector(selectWallState, ({ selectedWall }: WallState) => {
  return selectedWall;
});

export const selectCamerasNum = createSelector(selectWallState, ({ selectedWall, selectedSetIndex }: WallState) => {
  return selectedWall?.sets[selectedSetIndex]?.tiles?.filter(tile => tile?.data?.cameraId)?.length;
});

export const selectSets = createSelector(selectWallState, ({ selectedWall }: WallState) => selectedWall.sets);
export const selectSet = createSelector(selectWallState, ({ selectedWall, selectedSetIndex }: WallState) => selectedWall.sets[selectedSetIndex]);

export const selectWalls = createSelector(selectWallState, ({ walls }: WallState) => walls);

export const selectLocalStatus = createSelector(selectWallState, ({ localStatus }: WallState) => localStatus);

export const selectIsExpanded = createSelector(selectWallState, ({ isExpanded }: WallState) => isExpanded);

export const selectAlertsMonitoringFilters = createSelector(selectWallState, ({ alertsMonitoringFilters }: WallState) => alertsMonitoringFilters);
export const selectAlertsMonitoring = createSelector(selectWallState, ({ alerts }: WallState) => alerts);

export const selectAlertByTileId = (tile: number) => createSelector(selectWallState, ({ tilesAlerts }: WallState) => {
  return tilesAlerts[tile] ?? null;
});

export const selectIsFirstLoad = createSelector(selectWallState, ({ isFirstLoad }: WallState) => isFirstLoad);
export const selectIsLoading = createSelector(selectWallState, ({ isLoading }: WallState) => isLoading);
export const selectIsValid = createSelector(selectWallState, ({ isValid }: WallState) => isValid);
export const selectIsDebugMode = createSelector(selectWallState, ({ isDebugMode }: WallState) => isDebugMode);
export const selectFilters = createSelector(selectWallState, ({ filters }: WallState) => filters);
export const selectNextSelectedSetIndex = createSelector(selectWallState, ({ nextSelectedSetIndex }: WallState) => nextSelectedSetIndex);
export const selectIsMuted = createSelector(selectWallState, ({ isMuted }: WallState) => isMuted);

export const selectTileConfiguration = (tile: number) => createSelector(selectWallState, ({ selectedSetIndex, selectedWall }: WallState) => {
  const selectedTiles = selectedWall.sets[selectedSetIndex].tiles;
  return selectedTiles[tile];
});

export const selectAppleTvs = createSelector(selectWallState, ({ appleTvs }: WallState) => appleTvs);
export const selectedIsTileSelected = createSelector(selectWallState, ({ isTileSelected }: WallState) => isTileSelected);

