import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationRowComponent } from './components/location-row/location-row.component';
import { FlexModule } from '@angular/flex-layout';
import { PipesModule } from '../../pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { CameraAlertSelectorModalComponent } from './camera-alert-selector-modal.component';
import { UiKitModule } from '../../shared/ui-kit/ui-kit.module';
import { LetDirective, PushPipe } from '@ngrx/component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertRowComponent } from './components/alert-row/alert-row.component';
import { SharedModule } from '../../shared/shared.module';
import { AlertTileSettingsComponent } from './components/alert-tile-settings/alert-tile-settings.component';
import { CameraTileSettingsComponent } from './components/camera-tile-settings/camera-tile-settings.component';
import { MoreModalComponent } from './components/more-modal/more-modal.component';
import { AlertSelectorComponent } from './components/alert-selector/alert-selector.component';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
  declarations: [
    CameraAlertSelectorModalComponent,
    LocationRowComponent,
    AlertRowComponent,
    AlertTileSettingsComponent,
    CameraTileSettingsComponent,
    MoreModalComponent,
    AlertSelectorComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
    PipesModule,
    MatIconModule,
    UiKitModule,
    MatSelectModule,
    LetDirective, PushPipe,
    LazyLoadImageModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [
    LocationRowComponent,
    AlertTileSettingsComponent,
    AlertSelectorComponent,
  ],
})
export class CameraAlertSelectorModalModule {
}
