<ng-container *ngrxLet="selectedView$;let selectedView">
  <ng-container *ngrxLet="selectDateRange$; let selectDateRange">
    <ng-container *ngrxLet="selectIsLoading$; let loading">
      <ng-container *ngrxLet="selectSelectedCameras$; let selectedCameras">

        <div fxFlexFill fxLayout="column">
          <ng-container *ngIf="!widgetDataInfo">
            <!--    HEADER-->
            <ui-header [breadCrumbs]="breadCrumb">
              <div fxLayout="row" fxLayoutAlign="end center" class="header-buttons">
                <ng-container *ngIf="selectedView">
                  <mat-icon *ngIf="!selectedView.isPublic" class="button" matTooltip="This view available only for you">
                    lock
                  </mat-icon>
                  <mat-icon *ngIf="selectedView.isPublic"
                            class="button"
                            matTooltip="This view available for all users from organization"
                  >
                    lock_open
                  </mat-icon>
                </ng-container>
                <ng-container *ngIf="!selectedView">
                  <!--              <mat-icon class="button" svgIcon="settings"-->
                  <!--                        matTooltip="Configure alerts"-->
                  <!--                        [routerLink]="'../'+'../'+routerSegments.alerts+'/'+routerSegments.main"-->
                  <!--              ></mat-icon>-->
                  <span class="button ui-icon-folder ui-mat-icon-button-16"
                        matTooltip="Open a saved alert view"
                        matTooltipClass="sidebar-tooltip"
                        (click)="openViews()"
                  ></span>
                  <span class="ui-icon-save ui-mat-icon-button-16 button"
                        matTooltip="Save alert view"
                        matTooltipClass="sidebar-tooltip"
                        (click)="openViewNamePopup()"
                  ></span>
                  <ui-button
                    *rbacOrgAllow="[permissions.EventRead]"
                    class="mr-5"
                    type="default"
                    text="Alerts configuration"
                    [routerLink]="'../'+'../'+routerSegments.alerts+'/'+routerSegments.main"
                  ></ui-button>
                  <ui-button *rbacOrgAllow="[permissions.EventCreate]"
                             type="primary"
                             text="Add alert"
                             [routerLink]="'../'+'../'+routerSegments.alertsV2+'/'+routerSegments.templates"
                  ></ui-button>
                </ng-container>
              </div>
            </ui-header>

            <div class="filter-wrapper">
              <!--          FILTERS-->
              <div fxLayout="row wrap" class="filters gap-8" fxLayoutAlign="space-between center">
                <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px">
                  <div class="search-block filter-selector">
                    <ui-selector [options]="selectAutocompleteOptions$|ngrxPush" [optionTemplate]="autocompleteOption"
                                 [multi]="true"
                                 [showSelected]="true"
                                 searchProperty="name"
                                 [value]="selectedView?.eventIds"
                                 [sortCompareWith]="alertEventSortComparator"
                                 valueProp="_id"
                                 placeholder="Search for alert name"
                                 (change)="multiSelectorChange($event)"
                    >
                    </ui-selector>
                    <!--                  <mat-select multiple-->
                    <!--                              class="ui-selector"-->
                    <!--                              placeholder="Search alert by name"-->
                    <!--                              (selectionChange)="multiSelectorChange($event)"-->
                    <!--                  >-->
                    <!--                    <mat-option *ngFor="let option of selectAutocompleteOptions$|async"-->
                    <!--                                [value]="option._id"-->
                    <!--                    >{{ option.name }}-->
                    <!--                    </mat-option>-->
                    <!--                  </mat-select>-->
                  </div>
                  <div class="ui-selector filter-selector camera-selector c-p" fxLayout="row"
                       fxLayoutAlign="space-between center"
                       (click)="selectCamera()"
                  >
                    <span class="mr-10">Camera</span>
                    <!--              <span class="ui-icon-expand-arrow ui-mat-icon-16 camera-exapnd"></span>-->
                    <!--              <img src="assets/icons/arrow_drop_down_big.svg"/>-->
                  </div>
                  <div class="select-wrapper">
                    <mat-select multiple
                                placeholder="Object"
                                class="ui-selector"
                                [value]="selectTrackedObjects$ | ngrxPush"
                                (selectionChange)="changeTrackObject($event)"
                    >
                      <mat-option [value]="analyticClasses.person">Person</mat-option>
                      <mat-option [value]="analyticClasses.vehicle">Vehicle</mat-option>
                      <!--                  <mat-option [value]="analyticClasses.pet">Pet</mat-option>-->
                      <mat-option [value]="analyticClasses.weapon">Weapon</mat-option>
                      <!--                  <mat-option [value]="analyticClasses.fire">Fire</mat-option>-->
                      <!--                  <mat-option [value]="analyticClasses.multiple">Multiple</mat-option>-->
                    </mat-select>
                  </div>


                  <div class="select-wrapper">
                    <mat-select [multiple]="true"
                                class="ui-selector"
                                panelClass="alert-type-selector"
                                [compareWith]="compareFlowTypes"
                                placeholder="Alert type"
                                (selectionChange)="changeDetectionType($event)"
                                [value]="selectFlowTypes$ | ngrxPush"
                    >
                      <mat-option *ngFor="let item of AlertV2TypeGroup"
                                  [value]="{category: +item?.value?.category, flowType: +item?.value?.type}"
                      >
                        <ui-truncated-html>{{ item.name }}</ui-truncated-html>
                      </mat-option>
                    </mat-select>
                  </div>
                  <div class="select-wrapper">
                    <mat-select multiple
                                class="ui-selector"
                                placeholder="Acknowledge"
                                (selectionChange)="changeAcknowledges($event)"
                                [value]="selectAcknowledges$ | ngrxPush"
                    >
                      <mat-option *ngFor="let item of acknowledgeFilter"
                                  [value]="item.value"
                      >
                        {{ item.label }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>

                <div fxLayout="row"
                     fxLayoutAlign="start center"
                     fxLayoutGap="8px"
                     *ngrxLet="selectSelectedUserSettings$;let selectUserSettings"
                >
                  <div class="ui-icon-mute-wrapper">
                    <ng-container *ngIf="selectUserSettings.alertMonitoringMuted">
                               <span
                                 class="ui-icon-mute ui-mat-icon-button-16 delete-icon c-p"
                                 matTooltip="Unmute"
                                 matTooltipClass="sidebar-tooltip"
                                 (click)="toggleMuteAlertsSounds(false)"
                               ></span>
                    </ng-container>
                    <ng-container *ngIf="!selectUserSettings.alertMonitoringMuted">
                               <span
                                 class="ui-icon-speaker ui-mat-icon-button-16 delete-icon c-p selected"
                                 matTooltip="Mute"
                                 matTooltipClass="sidebar-tooltip"
                                 (click)="toggleMuteAlertsSounds(true)"
                               ></span>
                    </ng-container>
                  </div>
                  <div style="margin-right: 10px" class="date-time-range-picker-wrapper">
                    <ui-calendar *ngIf="selectDateRange"
                                 [inputStyle]="inputStyles.noLabel"
                                 [showAdvancedOption]="true"
                                 [selectDateRange]="selectDateRange" (onRangeSelected)="changeDateRange($event)"
                    ></ui-calendar>
                  </div>
                </div>

              </div>
              <!--          FILTER CHIPS-->
              <div fxLayout="row wrap" class="filter-chips-wrapper mt-8">
                <ng-container *ngrxLet="selectEventsLookup$;let eventLookup">
                  <!--                PROTECTION SHOW EMPTY TILES UNTIL EVENTS LOADED-->
                  <ng-container *ngIf="(selectAutocompleteOptions$|async)?.length">
                    <ui-filter-chip *ngFor="let id of selectEventsIds$ | ngrxPush" class="filter-chip"
                                    field="eventIds" [title]="eventLookup[id].name" [value]="id"
                                    (onRemoveClicked)="onFilterRemoveClick($event)"
                    ></ui-filter-chip>
                  </ng-container>

                  <ui-filter-chip *ngFor="let object of selectTrackedObjects$ | ngrxPush" class="filter-chip"
                                  field="trackedObjects" [title]="analyticClassName[object]" [value]="object"
                                  (onRemoveClicked)="onFilterRemoveClick($event)"
                  ></ui-filter-chip>
                  <ng-container *ngIf="!!(Object.values(selectedCameras))?.length">
                    <div [matMenuTriggerFor]="camerasMenu" class="filter-chip camera-chip">
                      <ui-filter-chip
                        class="filter-chip"
                        [title]="(Object.values(selectedCameras))?.length + ' cameras'"
                        [allowDelete]="false"
                      ></ui-filter-chip>
                    </div>
                    <mat-menu #camerasMenu="matMenu" xPosition="after" yPosition="below" class="property-chips-wrapper">
                      <div fxLayout="row wrap" fxLayoutGap="8px grid" class="property-chips">
                        <ng-container *ngrxLet="selectCameras$; let selectCameras">
                          <ui-filter-chip *ngFor="let camera of Object.values(selectedCameras)"
                                          class="filter-chip"
                                          [title]="selectCameras[camera.cameraId].edgeOnly.name"
                                          [allowDelete]="true"
                                          (onRemoveClicked)="onRemoveCamera(camera.cameraId)"
                          ></ui-filter-chip>
                        </ng-container>
                      </div>
                    </mat-menu>
                  </ng-container>
                  <ui-filter-chip
                    *ngFor="let flowType of selectFlowTypes$ | ngrxPush"
                    class="filter-chip"
                    field="flowTypes"
                    [title]="flowName(flowType.category, flowType.flowType)"
                    [value]="flowType"
                    (onRemoveClicked)="onFilterRemoveClick($event)"
                  ></ui-filter-chip>
                  <ui-filter-chip
                    *ngFor="let acknowledge of selectAcknowledges$ | ngrxPush"
                    class="filter-chip"
                    field="acknowledges"
                    [title]="acknowledge?'Acknowledged':'Unacknowledged'"
                    [value]="acknowledge"
                    (onRemoveClicked)="onFilterRemoveClick($event)"
                  ></ui-filter-chip>
                  <ng-container>
                    <ui-filter-chip
                      field="dateRange"
                      [title]="selectDateRange | calendarSelectedValue"
                      [allowDelete]="false"
                    >
                    </ui-filter-chip>
                  </ng-container>
                </ng-container>
              </div>
              <div class="flex-row start-start count w-100">
                <ng-container *ngIf="!loading; else loadCount">
                  <ng-container *ngrxLet="selectAlertsViewCount$; let viewCount">
                    <ng-container *ngrxLet="selectAlertsCount$; let count">
                      <ng-container *ngIf="isAbsoluteTime; else relative">
                        {{ count | number }} Alerts
                      </ng-container>
                      <ng-template #relative>
                        <ng-container *ngIf="viewCount > 0; else noAlerts">
                          Latest {{ viewCount | number }} alerts
                        </ng-container>
                        <ng-template #noAlerts>
                          0 Alerts
                        </ng-template>
                      </ng-template>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-template #loadCount>
                  <div class="flex-row start-center">
                    <ui-preloader class="flex-row start-start"
                                  [color]="preloaderColor.blue"
                                  [diameter]="20"
                    ></ui-preloader>
                  </div>
                </ng-template>
              </div>
            </div>

          </ng-container>

          <!--    CONTENT-->
          <div class="content-wrapper" fxFlex fxLayout="row wrap">

            <div *ngIf="isScrollVisible" class="scroll-top" fxLayout="column" fxLayoutAlign="center center">
              <mat-icon (click)="scrollTop()" svgIcon="arrow-circle-expand-outline"></mat-icon>
              <span *ngIf="newAlertsCount">{{ newAlertsCount }}</span>
            </div>

            <ng-container *ngrxLet="selectAlertsViewMode$;let alerts">
              <div fxFlex #scrollContainer>
                <!--          ARROW TO SCROLL TOP-->

                <ng-container *ngIf="!loading;else loader">
                  <ng-container *ngrxLet="selectEdgeEntities$;let edgePulsationEntities">
                    <ng-container *ngrxLet="selectCameraEntities$;let selectCameraEntities">

                      <cdk-virtual-scroll-viewport *ngIf="alerts?.length"
                                                   (scroll)="onScroll($event)"
                                                   autosize
                                                   [itemSize]="itemSize"
                                                   [maxBufferPx]="itemSize*10"
                                                   [minBufferPx]="itemSize*5"
                                                   [style.height.px]="virtualScrollHeight"
                                                   [style.width]="'100%'"

                      >
                        <!--              CHUNK ARRAY CAUSE VIRTUAL SCROLL SHOW 1 COLUMN ONLY-->
                        <div
                          *cdkVirtualFor="let alert of alerts|chunkArray:(scrollContainer.offsetWidth / 448);trackBy:trackByIdentityChunk let i = index"
                          class="alerts-wrapper" [class.widget]="!!widgetDataInfo"
                        >
                          <div fxLayout="row" fxLayoutAlign="start start">
                            <app-alert-preview *ngFor="let a of alert;let i = index;trackBy:trackByIdentity"
                                               [alert]="a"
                                               [edgePulsationStatus]="edgePulsationEntities[a.edgeId]"
                                               [cameraPulsationStatus]="selectCameraEntities[a?.cameraId]"
                                               [frequencyFilter]="selectDateRange.relative"
                                               [locationLookup]="selectLocationLookup$|ngrxPush"
                                               [cameraLookup]="selectCamerasLookup$|ngrxPush"
                            >

                            </app-alert-preview>
                          </div>
                        </div>


                      </cdk-virtual-scroll-viewport>
                      <div *ngIf="!alerts?.length" fxFlex fxLayout="row" fxLayoutAlign="center center">
                        <ui-no-data [imgSrc]="noData.imgSrc" [title]="noData.title" [description]="noData.description"
                                    [createButtonText]="noData.createButtonText" (onCreate)="goToCreateAlertV2()"
                        >
                        </ui-no-data>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>

            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #autocompleteOption let-option>
  <span class="option-item">
    {{ option.name }}
  </span>
</ng-template>

<ng-template #loader>
  <ui-preloader [color]="preloaderColor.blue"></ui-preloader>
</ng-template>
