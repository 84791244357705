import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LiveStreamModels } from '@models/live-stream.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VisibilityChanged } from '../../directives/visibility-change.directive';
import { PlayerBaseComponent } from '../player-base/player-base.component';
import { Store } from '@ngrx/store';
import { VideoService } from '../../../development/video.service';
import { LiveKitModels } from '@models/livekit.model';
import { BehaviorSubject, catchError, filter, Subject, Subscription, take, takeUntil, timeout } from 'rxjs';
import { AuthenticationService } from '../../../authentication/authentication.service';
import * as uuid from 'uuid';
import { LivekitService } from '../../../development/livekit.service';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';

@UntilDestroy()
@Component({
  selector: 'video-livekit',
  templateUrl: './livekit.component.html',
  styleUrl: './livekit.component.scss',
})
export class LivekitComponent extends PlayerBaseComponent implements OnInit, OnDestroy {

  public liveKitSession: LiveKitModels.LiveKitSession;
  prevResolution: LiveStreamModels.StreamResolution;
  private isDestroyed = false;

  private destroyed = new BehaviorSubject<boolean>(false);
  private destroyed$ = this.destroyed.asObservable();

  private destroySubscription: Subscription;

  constructor(
    private livekitService: LivekitService,
    private authenticationService: AuthenticationService,
    cd: ChangeDetectorRef,
    store$: Store,
    videoService: VideoService) {
    super(store$, cd, videoService);
  }


  ngAfterViewInit(): void {
    this.initPlayer();
  }

  override ngOnDestroy(): void {
    this.destroyed.next(true);
    this.stop();

    super.ngOnDestroy();
  }

  override ngOnInit(): void {
    if (this.autostart) {
      this.play();
    }
    this.initVideoControls();
    super.ngOnInit();
  }

  pause(): void {
    this.video.pause();
  }

  async stop(): Promise<void> {
    console.log('[LiveKit] stop');
    this.setPlaceholder();
    if (!this.liveKitSession) {
      return;
    }
    await this.liveKitDisconnect();

    const stopLiveKitRequest: LiveKitModels.StopLiveKitRequest = {
      edgeId: this.edgeId,
      cameraId: this.cameraId,
      resolution: this.prevResolution ?? this.resolution,
    };
    delete this.liveKitSession;
    this.clearVideo();
    this.clearHealthCheck();
    this.livekitService.stop(stopLiveKitRequest)
      .subscribe(() => {
        console.log('[LiveKit] Sent livekit stop');
      });
  }

  play(): void {
    return this.playLiveKit();
  }

  extend(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  resetState(): void {
    throw new Error('Method not implemented.');
  }

  checkHiddenDocument(visibilityChanged: VisibilityChanged): Promise<void> {
    throw new Error('Method not implemented.');
  }


  async changeResolution(resolution: LiveStreamModels.StreamResolution): Promise<void> {
    console.log('[LiveKit] changeResolution', resolution);
    const isAuto = this.resolution === LiveStreamModels.StreamResolution.AUTO || resolution === LiveStreamModels.StreamResolution.AUTO;

    if (!isAuto) {
      const origRes = this.resolution;
      this.prevResolution = origRes;
      this.resolution = resolution;
      if (origRes === LiveStreamModels.StreamResolution.HQ || this.resolution === LiveStreamModels.StreamResolution.HQ) {
        if (origRes !== resolution) {
          await this.stop();
          this.play();
        }
      } else {
        this.livekitService.changeQuality(this.liveKitSession.room, resolution);
      }
      return;
    }
    this.qualityChange.emit(true);
    this.resolution = resolution;

    this.setLoader.emit(true);
    this.stop();
    this.play();
  }

  public async liveKitDisconnect(): Promise<void> {
    await this.livekitService.disconnect(this.liveKitSession?.room);
  }

  public playLiveKit() {
    const authProviderId = this.authenticationService.getAuthProviderIdFromLocalStorage();
    const hq = this.resolution === LiveStreamModels.StreamResolution.AUTO;
    if (!this.liveKitSession) {
      this.liveKitSession = {
        roomName: `${this.edgeId}-${this.cameraId}${hq ? '-hq' : ''}`,
      };
      const getLiveKitTokenRequest: LiveKitModels.CreateLiveKitTokenRequest = {
        participantName: `${authProviderId}-${uuid.v4()}`,
        edgeId: this.edgeId,
        cameraId: this.cameraId,
        resolution: this.resolution,
      };
      this.livekitService.getToken(getLiveKitTokenRequest)
        .pipe(
          timeout(5000),
          catchError(err => {
            console.log(err);
            // this.fallBackToWebrtc();
            return [];
          }),
          takeUntil(this.destroyed$.pipe(untilDestroyed(this), filter(destroyed => !!destroyed))),
        )
        .subscribe((clientTokenRes) => {
          const clientToken = clientTokenRes?.token;

          this.liveKitSession = {
            clientToken,
            roomName: `${this.edgeId}-${this.cameraId}`,
            room: null,
          };
          console.log(clientToken);
          this.setLoader.emit(true);
          if (this.enableHealthCheck) {
            this.startHealthCheck();
          }
          this.livekitService.connect(clientToken, this.playerObj.nativeElement, this.resolution === LiveStreamModels.StreamResolution.AUTO, this.resolution)
            .then(({ room, play$ }) => {
              if (!this.liveKitSession) {
                return;
              }
              this.liveKitSession.room = room;
              console.log('[LiveKit] Connected to LiveKit');
              play$.pipe(take(1))
                .subscribe(() => {
                  console.log('webrtc given attached livekit stream');
                  this.playVideo();
                });
              if (!this.destroySubscription) {
                this.destroySubscription = this.destroyed$.pipe(untilDestroyed(this), filter(destroyed => !!destroyed), take(1))
                  .subscribe(() => {
                    this.stop();
                  });
              }
            })
            .catch(error => {
              console.error('[LiveKit] Failed to connect to LiveKit', error);
            });
        });
      return;
    }
  }

  public timeUpdate() {
    const timestamp = Date.now();
    this.store$.dispatch(MultiPlaybackActions.setMove({ move: { percentage: 0.5, timestamp } }));
  }
}
