import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Dictionary } from '@ngrx/entity/src/models';
import { EdgeCamera } from '../../../../cameras/camera.model';

@Component({
  selector: 'app-more-modal',
  templateUrl: './more-modal.component.html',
  styleUrls: ['./more-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreModalComponent {

  @Input() cameraIds: string[];
  @Input() camerasLookup: Dictionary<EdgeCamera.CameraItem>;

  @Output() onClickedOutside: EventEmitter<null> = new EventEmitter<null>();

  public clickCounter = 0;

  constructor(private el: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  public closeMoreModalHandler(event) {
    if (!this.el.nativeElement.contains(event.target) && this.clickCounter) {
      this.onClickedOutside.emit();
    }
    this.clickCounter++; // must skip first click
  }
}
