<div class="alert-row" fxLayout="row" fxLayoutAlign="start center">
  <div fxFlex="33" fxLayout="row" fxLayoutAlign="start center">
    <mat-checkbox color="primary" class="alert-name"
                  [checked]="!!selectedAlertEvents[alertEvent._id]"
                  [disabled]="!camerasFilterControl.value?.length"
                  (change)="selectAlert(true)"
    >
      <ui-truncated-html>{{alertEvent.name}}</ui-truncated-html>
    </mat-checkbox>
  </div>
  <div fxFlex="33" fxLayout="row" fxLayoutAlign="start center">
    <mat-select [formControl]="camerasFilterControl"
                class="ui-selector"
                placeholder="Filter cameras"
                multiple
    >
      <mat-option *ngFor="let camera of alertEventCameras$|async" [value]="camera.cameraId">
        {{camerasLookup[camera.cameraId]?.edgeOnly?.name}}
      </mat-option>
    </mat-select>
  </div>
  <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="start center" style="position: relative">
    <ng-container *ngFor="let cameraId of camerasFilterControl.value; let i = index">
              <span *ngIf="i<2" class="camera-chip">
                {{camerasLookup[cameraId]?.edgeOnly?.name ?? 'N/a'}}
              </span>
    </ng-container>

    <span *ngIf="camerasFilterControl.value?.length>2" class="camera-chip c-p" (click)="showMore()">
                 +{{camerasFilterControl.value?.length - 2}} more
    </span>
    <div *ngIf="isShowMore" class="more-modal">
      <app-more-modal [cameraIds]="camerasFilterControl.value"
                      [camerasLookup]="camerasLookup"
                      (onClickedOutside)="closeModal()"
      ></app-more-modal>
    </div>
  </div>
</div>

