import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as moment from 'moment-timezone';
import { SelectedCamera } from '@models/alert-events.model';
import { api } from '@consts/url.const';
import { AlertType, AnalyticClasses, DetectionType } from '@enums/alert-events.enum';
import { AlertMonitoringFilter, AlertMonitoringFilterQuery } from '@models/alert-monitoring.model';
import { CameraStatus } from './heartbeat.service';
import { AnalyticStatus } from '../cameras/camera.model';
import { WallAlertMonitoringFilters } from '@models/wall.model';
import { EventV2Document, FlowTypes, StatusType } from '@models/alerts-v2.model';
import { PulsationModels } from '@models/pulsation.model';

interface AnalyticResultsAlertTimelineItemInterface {
  base: number;
  normalizedTimestamp: number;
  index: number;
  value: number;
  bitIndex: number;
}

interface AnalyticResultsAlertTimelineInterface {
  events: number[];
  values: AnalyticResultsAlertTimelineItemInterface[];
}

export interface AlertEntry {
  _id?: string;
  alertInstanceId?: string;
  edgeId: string;
  cameraId: string;
  timestamp: number;
  selectedCamera: SelectedCamera;
  alertType: AlertType;
  detectionType: DetectionType;
  type: AnalyticClasses;
  id?: number;
  thumbnails?: string[];
  data?: string;
  mainThumbnail: string;
  mainThumbnailTimestamp: number;
  event?: EventV2Document;
  eventId?: string;
  eventName?: string;
  timezone?: string;
  alertMessage?: string;
  alertTimeline?: AnalyticResultsAlertTimelineInterface;
  archivedAt: number;
  archived: boolean;
  cameraStatus?: CameraStatus;
  edgeStatus?: PulsationModels.ComponentStatus;
  analyticStatus?: AnalyticStatus;
  clicked?: boolean;
  idBase?: number;
  idIndex?: number;
  flowType?: FlowTypes;
}

export interface AlertsQuery {
  edgeId?: string;
  cameraId?: string;
  timestamp?: number;
  start?: string | number;
  end?: string | number;
  alertType?: AlertType;
  type?: string;
  id?: number;
}

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  constructor(private http: HttpClient) {
  }

  parseObject(parse = true, timezone?: string) {
    return source$ =>
      source$.pipe(
        map((res: Array<AlertEntry>) => {
          return parse
            ? res.map(item => {
              let data = item.data;

              if (data) {
                try {
                  data = JSON.parse(data);
                } catch (error) {
                  data = item.data;
                }
              }

              if (item['timestamp']) {
                item['day'] = timezone
                  ? moment.tz(item['timestamp'], timezone)
                  .format('ddd MMMM D YYYY, HH:mm:ss') + ` (${timezone})`
                  : new Date(+item['timestamp']);
              }

              const e = !!data ? { ...item, ...{ data } } : { ...item };
              return e;
            })
            : res;
        }),
      );
  }

  getAllAlerts(page: number, size = 20, filter?: AlertsQuery, timezone?: string): Observable<AlertEntry[]> {
    let url = `${environment.apiUrl}/alerts?page=${page}&size=${size}`;

    for(const key in filter) {
      const element = filter[key];
      if (!!element) {
        url = url.concat(`&${key}=${element}`);
      }
    }

    return this.http.get<AlertEntry[]>(url)
      .pipe(this.parseObject(true, timezone));
  }

  public getByFilters(filter: AlertMonitoringFilter): Observable<AlertEntry[]> {
    const query: AlertMonitoringFilterQuery = {
      query: filter.query,
      acknowledges: filter.acknowledges.join(','),
      // alertNamesFilters: filter.alertNamesFilters.join(','),
      // alertTypes: filter.alertNamesFilters.join(','),
      dateRangeV2: JSON.stringify(filter.dateRangeV2),
      timeRange: JSON.stringify(filter.timeRange),
      filters: JSON.stringify(filter.filters),
      flowTypes: JSON.stringify(filter.flowTypes),
      selectedCameras: filter.selectedCameras.join(','),
      trackedObjects: filter.trackedObjects ? filter.trackedObjects.join(',') : '',
      latestTs: filter.latestTs,
      eventIdsFilters: filter.eventIdsFilters ? filter.eventIdsFilters.join(',') : '',
      trackerClass: filter.trackerClass ? filter.trackerClass.map((item) => item.toString())
        .join(',') : '',
    };
    if (filter?.source) {
      query.source = filter.source;
    }
    let params = [];
    for(let key in query) {
      if (!!query[key]) {
        params.push(`${key}=${query[key]}`);
      }
    }
    const url = `${api.alerts.getByFilter}?${params.join('&')}`;
    return this.http.get<AlertEntry[]>(url.trim());
  }

  public countByFilters(filter: AlertMonitoringFilter): Observable<number> {
    return this.http.post<number>(api.alerts.countByFilter, filter);
  }

  public autocomplete(query): Observable<any> {
    return this.http.post(api.alerts.autocomplete, { query });
  }

  public delete(id: string): Observable<any> {
    return this.http.delete(api.alerts.one(id));
  }

  public getActivity(id: string): Observable<any> {
    return this.http.get(api.alerts.activity(id));
  }

  public getAlertMonitoring(filter: WallAlertMonitoringFilters): Observable<AlertEntry[]> {
    return this.http.post<AlertEntry[]>(`${api.alerts.getAlertsMonitoring}`, filter);
  }
}
