import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StorageModel } from '@models/storage.model';
import { StorageActions } from '@states/storage/storage.action-types';
import { EdgeCamera } from '../../cameras/camera.model';
import { StorageSelectors } from '@states/storage/storage.selector-types';
import { catchError, filter, lastValueFrom, of, switchMap, take, tap, timeout } from 'rxjs';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import { selectStorageEventsByEdgeIdCameraIdAndBase, selectStorageEventsByEdgeIdCameraIdAndBaseExist } from '@states/storage/storage.selectors';
import { ThumbnailsSelectors } from '@states/thumbnails/thumbnails.selector-types';
import * as _ from 'lodash';
import { ThumbnailsActions } from '@states/thumbnails/thumbnails.action-types';
import { selectBitsByEdgeIdCameraIdAndBase } from '@states/thumbnails/thumbnails.selectors';
import { selectCameraRetentionById } from '@states/camera/camera.selectors';
import { CameraSelectors } from '@states/camera/camera.selector-types';

const FORCE_REFRESH_THRESHOLD = 1000 * 60 * 10; // 10 minutes
const ALERT_PREV_NEXT_WINDOW = 6000;
const TWO_MINUTES = 2 * 60 * 1000;
const DAYS_25 = 25 * 24 * 60 * 60 * 1000;


@Injectable({
  providedIn: 'root',
})
export class MediaCacheService {

  constructor(private store$: Store, private cameraThumbnailsService: CamerasThumbnailsService) {
  }

  normalizeTimestamp(timestamp: number, thumbnailsDuration?: number) {
    return this.cameraThumbnailsService.normalizeTimestamp(timestamp, thumbnailsDuration);
  }

  getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date);
  }

  // private selectStorageEventsByEdgeIdCameraIdAndRange(selector: { edgeId: string; cameraId: string; start: number; end: number }) {
  //   return this.store$.select(StorageSelectors.selectStorageEventsByEdgeIdCameraIdAndRange(selector)).pipe(take(1))
  // }

  private selectStorageEventsByEdgeIdCameraIdAndBaseExist(selector: { edgeId: string; cameraId: string, base: number }) {
    return this.store$.select(StorageSelectors.selectStorageEventsByEdgeIdCameraIdAndBaseExist(selector))
      .pipe(take(1));
  }

  private selectThumbnailEventsByEdgeIdCameraIdAndBaseExist(selector: { edgeId: string; cameraId: string, base: number }) {
    return this.store$.select(ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndBase(selector))
      .pipe(take(1));
  }

  private selectThumbnailBitsByEdgeIdCameraIdAndBaseExist(selector: { edgeId: string; cameraId: string, base: number }) {
    return this.store$.select(ThumbnailsSelectors.selectBitsByEdgeIdCameraIdAndBase(selector))
      .pipe(take(1));
  }

  public async getBitByTimestamp(edgeId: string, cameraId: string, timestamp: number) {
    const base = this.getBaseInLocale(new Date(timestamp));
    const offset = timestamp - base;
    const bits = await lastValueFrom(this.selectThumbnailBitsByEdgeIdCameraIdAndBaseExist({ edgeId, cameraId, base }));
    if (!bits) {
      return false;
    }
    const totalSeconds = Math.floor(offset / 1000);
    const minute = Math.floor(totalSeconds / 60);
    const secondWithinMinute = totalSeconds % 60;

    // Determine the bit position for the specific 2-second interval
    // Adjusting the bit position calculation to account for the MSB-first ordering
    // const bitPosition = 29 - Math.floor(secondWithinMinute / 2); // Adjusted bit position
    const bitPosition = Math.floor(secondWithinMinute / 2);
    const cellValue = bits[minute];

    // Shift the cell value to the right to get to the correct bit and mask with 1 to check if it's set
    const status = (cellValue >> bitPosition) & 1;

    return Boolean(status);
  }

  private calculateNumEvents(start: number, end: number) {
    return Math.floor((end - start) / 2000);
  }

  public async bitsExistForRange(edgeId: string, cameraId: string, bases: number[]) {
    for(let base of bases) {
      const exist = await lastValueFrom(this.selectThumbnailBitsByEdgeIdCameraIdAndBaseExist({ edgeId, cameraId, base }));
      if (!exist) {
        return false;
      }
    }
    return true;
  }

  public async eventsExistForRange(edgeId: string, cameraId: string, bases: number[], thumbs = false) {
    for(let base of bases) {
      const exist = thumbs ?
        await lastValueFrom(this.selectThumbnailEventsByEdgeIdCameraIdAndBaseExist({ edgeId, cameraId, base })) :
        await lastValueFrom(this.selectStorageEventsByEdgeIdCameraIdAndBaseExist({ edgeId, cameraId, base }));
      if (!exist) {
        return false;
      }
    }
    return true;
  }

  public getBasesForRange(start: number, end: number): number[] {
    const bases = [];
    const baseStart = this.getBaseInLocale(new Date(start));
    const baseEnd = this.getBaseInLocale(new Date(end));
    const numBases = Math.floor((baseEnd - baseStart) / 86400000) + 1;
    for(let i = 0; i < numBases; i++) {
      bases.push(baseStart + i * 86400000);
    }
    return bases;
  }

  // OLD IMPLEMENTATION - SQS
  // public async getStorageStats(selectedCameras: EdgeCamera.CameraItem[], start: number, end: number) {
  //   start = this.normalizeTimestamp(start);
  //   end = this.normalizeTimestamp(end);
  //   const requests: StorageModel.GetStorageStatsRequest[] = [];
  //   const bases = this.getBasesForRange(start, end);
  //   for (let camera of selectedCameras) {
  //     const edgeId = camera.edgeId;
  //     const cameraId = camera.edgeOnly.cameraId;
  //     const exist = await this.eventsExistForRange(edgeId, cameraId, bases);
  //     if (!exist) {
  //       const searchRequestIdx = requests.findIndex(request => request.edgeId === edgeId);
  //       if (searchRequestIdx > -1) {
  //         requests[searchRequestIdx].cameraIds.push(cameraId);
  //       } else {
  //         requests.push({
  //           edgeId,
  //           cameraIds: [cameraId],
  //           start,
  //           end
  //         })
  //       }
  //     }
  //   }
  //   for (let request of requests) {
  //     this.store$.dispatch(StorageActions.getCamerasStorageStats({
  //       request
  //     }))
  //   }
  // }

  public async getStorageStats(selectedCameras: EdgeCamera.CameraItem[], start: number, end: number) {
    start = this.normalizeTimestamp(start, 2000);
    end = this.normalizeTimestamp(end, 2000);
    const lastTenMinutes = Date.now() - FORCE_REFRESH_THRESHOLD;
    let forceFetch = false;
    if (start > lastTenMinutes || end > lastTenMinutes) {
      forceFetch = true;
    }
    const requests: StorageModel.GetStorageStatsRequest[] = [];
    const bases = this.getBasesForRange(start, end);
    const baseToday = this.getBaseInLocale(new Date());
    const isGetToday = bases.includes(baseToday);
    for(let camera of selectedCameras) {
      const edgeId = camera.edgeId;
      const cameraId = camera?.edgeOnly?.cameraId ?? camera?.cameraId;
      const exist = await this.eventsExistForRange(edgeId, cameraId, bases);
      if (!exist || forceFetch) {
        requests.push({
          edgeId,
          cameraId,
          start,
          end,
        });
      }
      if (isGetToday) {
        requests.push({
          edgeId,
          cameraId,
          start: baseToday,
          end: baseToday + 86400000,
        });
      }
    }
    for(let request of requests) {
      const moreThan25 = request.start < Date.now() - DAYS_25;
      const retentionDays = await lastValueFrom(
        this.store$.select(CameraSelectors.selectCameraRetentionById(request.cameraId))
          .pipe(
            timeout(5000),
            filter(retentionDays => !!retentionDays),
            take(1),
            catchError((err) => {
              this.store$.dispatch(StorageActions.getCamerasStorageStats({ request }));
              // this.store$.dispatch(StorageActions.getCamerasHlsStorageStats({ request }));
              return of(25);
            }),
          ),
      );
      if (retentionDays > 30 && moreThan25) {
        this.store$.dispatch(StorageActions.getCamerasStorageStatsAbly({
          request,
        }));
      } else {
        this.store$.dispatch(StorageActions.getCamerasStorageStats({
          request,
        }));
        // this.store$.dispatch(StorageActions.getCamerasHlsStorageStats({
        //   request,
        // }));
      }
    }
  }

  // public async getStorageOldestVids(selectedCameras: EdgeCamera.CameraItem[]) {
  //
  //   const requests: StorageModel.GetStorageStatsRequest[] = [];
  //
  //   for(let camera of selectedCameras) {
  //     const edgeId = camera.edgeId;
  //     const cameraId = camera?.edgeOnly?.cameraId ?? camera?.cameraId;
  //     requests.push({
  //       edgeId,
  //       cameraId,
  //     });
  //   }
  //   for(let request of requests) {
  //     this.store$.dispatch(StorageActions.getCamersOldestVids({
  //       request,
  //     }));
  //   }
  // }


  public selectStats(edgeId: string, cameraId: string, base: number) {
    return this.store$.select(
        StorageSelectors.selectStorageEventsByEdgeIdCameraIdAndBase({ edgeId, cameraId, base }),
      )
      .pipe(take(1));
  }

  public selectThumbnails(edgeId: string, cameraId: string, base: number) {
    return this.store$.select(
        ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndBase({ edgeId, cameraId, base }),
      )
      .pipe(take(1));
  }

  public isTsAlignedTo20Seconds(ts: number): boolean {
    return ts % 20000 === 0;
  }

  public async getNextThumb(edgeId: string, cameraId: string, ts: number) {
    let next20 = this.cameraThumbnailsService.normalizeTimestamp(ts, 20000, true);
    if (next20 === ts) {
      next20 += 20000;
    }
    for(let checkTs = ts + 2000; checkTs < next20; checkTs += 2000) {
      const hasBit = await this.getBitByTimestamp(edgeId, cameraId, checkTs);
      if (hasBit) {
        return checkTs;
      }
    }
    return next20;
  }

  public async getPrevThumb(edgeId: string, cameraId: string, ts: number) {
    let prev20 = this.cameraThumbnailsService.floorTimestamp(ts, 20000);
    if (prev20 === ts) {
      prev20 -= 20000;
    }
    for(let checkTs = ts; checkTs >= prev20; checkTs -= 2000) {
      if (checkTs === ts) {
        continue;
      }
      const hasBit = await this.getBitByTimestamp(edgeId, cameraId, checkTs);
      if (hasBit) {
        return checkTs;
      }
    }
    return prev20;
  }

  public async getNextThumbnail(edgeId: string, cameraId: string, base: number) {
    const now = Date.now();
    const nowBase = this.getBaseInLocale(new Date(now));
    const bases = this.getBasesForRange(base, base + 2 * 86400000);
    for(let base of bases) {
      const stats = await lastValueFrom(this.selectThumbnails(edgeId, cameraId, base));
      const offlineThumbnails = stats?.offlineThumbnails;
      if (!offlineThumbnails?.length) {
        return base;
      } else {
        if (offlineThumbnails[0][0] > 0) {
          return base;
        } else {
          return offlineThumbnails[0][1];
        }
      }
    }
    return null;
  }


  public async noThumbnail(edgeId: string, cameraId: string, ts: number): Promise<{ noThumbnail: boolean; next?: number }> {
    const base = this.getBaseInLocale(new Date(ts));
    const offset = this.normalizeTimestamp(ts - base, 2000);
    const now = Date.now();
    let next;
    ts = this.normalizeTimestamp(ts, 2000);
    if (now - ts < 0) {
      return { noThumbnail: true };
    }

    if (now - ts < TWO_MINUTES) {
      return { noThumbnail: false };
    }

    const stats = await lastValueFrom(this.selectThumbnails(edgeId, cameraId, base));
    if (!stats || !stats?.offlineThumbnails) {
      return { noThumbnail: true };
    }
    const offlineThumbnails = stats?.offlineThumbnails;
    if (!offlineThumbnails) {
      return { noThumbnail: true };
    } else {
      if (!offlineThumbnails?.length) {
        if (ts && !this.isTsAlignedTo20Seconds(offset)) {
          const hasThumbnail = await this.getBitByTimestamp(edgeId, cameraId, ts);
          return { noThumbnail: !hasThumbnail };
        }
        return { noThumbnail: false };
      }

      for(let stat of offlineThumbnails) {
        if (offset >= stat[0] && offset <= stat[1]) {
          next = stat[1] + base;
          if (next === 86400000) {
            next = this.getNextThumbnail(edgeId, cameraId, base + 86400000);
            if (!!next) {
              return { noThumbnail: true, next };
            }
            return { noThumbnail: true };
          }
          return { noThumbnail: true, next };
        }
      }

      if (ts && !this.isTsAlignedTo20Seconds(offset)) {
        const hasThumbnail = await this.getBitByTimestamp(edgeId, cameraId, ts);
        return { noThumbnail: !hasThumbnail };
      }
      return { noThumbnail: false };
    }
  }

  public async isAlert(edgeId: string, cameraId: string, ts: number): Promise<{ isAlert: boolean, replicas?: string[] }> {
    const base = this.getBaseInLocale(new Date(ts));
    const offset = this.normalizeTimestamp(ts - base, 2000);
    const stats = await lastValueFrom(this.selectThumbnails(edgeId, cameraId, base));

    const alertReplicas = stats?.alertReplicas;
    if (alertReplicas && !!alertReplicas[offset]) {
      return { isAlert: true, replicas: alertReplicas[offset] };
    }
    return { isAlert: false };
  }


  public async isOfflineHls(edgeId: string, cameraId: string, ts: number) {
    return this.isOffline(edgeId, cameraId, ts, true);
  }

  public async isSmartStorageHls(edgeId: string, cameraId: string, ts: number): Promise<{ exists: boolean; interval?: number[] }> {
    return this.isSmartStorage(edgeId, cameraId, ts, true);
  }

  public async isOffline(edgeId: string, cameraId: string, ts: number, hls = false) {
    const base = this.getBaseInLocale(new Date(ts));
    const offset = ts - base;
    const stats = await lastValueFrom(this.selectStats(edgeId, cameraId, base));
    const noStorage = hls ? stats?.noStorageHls : stats?.noStorage;
    if (!stats || !noStorage) {
      return false;
    }
    if (!noStorage) {
      return false;
    } else {
      if (!noStorage?.length) {
        return false;
      }
      for(let stat of noStorage) {
        if (offset >= stat[0] && offset <= stat[1]) {
          return true;
        }
      }
      return false;
    }
  }

  public async isSmartStorage(edgeId: string, cameraId: string, ts: number, hls = false): Promise<{ exists: boolean; interval?: number[] }> {
    const base = this.getBaseInLocale(new Date(ts));
    const offset = ts - base;
    const stats = await lastValueFrom(this.selectStats(edgeId, cameraId, base));
    const smartStorage = hls ? stats?.smartStorageHls : stats?.smartStorage;

    if (!stats || !smartStorage) {
      return { exists: false };
    }
    if (!smartStorage) {
      return { exists: false };
    } else {
      if (!smartStorage?.length) {
        return { exists: false };
      }
      for(let stat of smartStorage) {
        if (offset >= stat[0] && offset <= stat[1]) {
          return { exists: true, interval: [stat[0] + base, stat[1] + base] };
        }
      }
      return { exists: false };
    }
  }

  public async getThumbnailBits(selectedCameras: EdgeCamera.CameraItem[], start: number, end: number) {
    const bases = this.getBasesForRange(start, end);
    const baseToday = this.getBaseInLocale(new Date());
    if (end >= baseToday) {
      end = baseToday + 86400000;
    }
    const isGetToday = bases.includes(baseToday);
    const requests: StorageModel.GetThumbnailBitsRequest[] = [];

    for(let camera of selectedCameras) {
      const edgeId = camera.edgeId;
      const cameraId = camera?.edgeOnly?.cameraId ?? camera?.cameraId;
      const exist = await this.bitsExistForRange(edgeId, cameraId, bases);
      if (!exist) {
        requests.push({
          edgeId,
          cameraId,
        });
      }
      if (isGetToday) {
        requests.push({
          edgeId,
          cameraId,
        });
      }

    }
    this.cameraThumbnailsService.getThumbnailBits(requests, start, end);
  }

  public async getThumbnails(selectedCameras: EdgeCamera.CameraItem[], start: number, end: number) {
    start = this.normalizeTimestamp(start);
    end = this.normalizeTimestamp(end);
    const lastTenMinutes = Date.now() - FORCE_REFRESH_THRESHOLD;
    let forceFetch = false;
    if (start > lastTenMinutes || end > lastTenMinutes) {
      forceFetch = true;
    }
    const requests: StorageModel.GetStorageStatsRequest[] = [];
    const bases = this.getBasesForRange(start, end);
    const baseToday = this.getBaseInLocale(new Date());
    const isGetToday = bases.includes(baseToday);
    for(let camera of selectedCameras) {
      const edgeId = camera.edgeId;
      const cameraId = camera?.edgeOnly?.cameraId ?? camera?.cameraId;
      const exist = await this.eventsExistForRange(edgeId, cameraId, bases, true);
      if (!exist || forceFetch) {
        requests.push({
          edgeId,
          cameraId,
          start,
          end,
        });
      }
      if (isGetToday) {
        requests.push({
          edgeId,
          cameraId,
          start: baseToday,
          end: baseToday + 86400000,
        });
      }
    }

    for(let request of requests) {
      this.cameraThumbnailsService.getThumbnails(request, request.start, request.end);
    }
  }


  public async getNextAlertTimestamp(edgeId: string, cameraId: string, ts: number, videoSection = false): Promise<number> {

    const base = this.getBaseInLocale(new Date(ts));
    const offset = this.normalizeTimestamp(ts - base, 2000);
    const stats = await lastValueFrom(this.selectThumbnails(edgeId, cameraId, base));

    // Use Lodash to find the index where the timestamp would fit in the sorted array
    if (stats?.alertReplicas) {
      const alertOffsets = Object.keys(stats.alertReplicas)
        .map(ts => +ts);
      let index = _.sortedIndex(alertOffsets, offset);

      // Check if the index is within the bounds of the array
      if (index < alertOffsets.length - 1) {
        if (alertOffsets[index] + base + (videoSection ? -ALERT_PREV_NEXT_WINDOW : 0) > ts) {
          return alertOffsets[index] + base + (videoSection ? -ALERT_PREV_NEXT_WINDOW : 0);
        } else {
          return alertOffsets[index + 1] + base + (videoSection ? -ALERT_PREV_NEXT_WINDOW : 0);
        }
      } else if (index === alertOffsets.length - 1) {
        if (alertOffsets[index] + base + (videoSection ? -ALERT_PREV_NEXT_WINDOW : 0) > ts) {
          return alertOffsets[index] + base + (videoSection ? -ALERT_PREV_NEXT_WINDOW : 0);
        }
      }
    }

    const nextBase = base + 86400000;
    const nextStats = await lastValueFrom(this.selectThumbnails(edgeId, cameraId, nextBase));
    if (nextStats?.alertReplicas) {
      const alertOffsets = Object.keys(nextStats.alertReplicas);
      if (alertOffsets.length) {
        return +alertOffsets[0] + nextBase + (videoSection ? -ALERT_PREV_NEXT_WINDOW : 0);
      }
    } else {
      await this.cameraThumbnailsService.getThumbnails({ edgeId, cameraId }, nextBase, nextBase + 86400000);
      const nextStats = await lastValueFrom(this.store$.select(
          ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndBase({ edgeId, cameraId, base: nextBase }),
        )
        .pipe(filter(stats => !!stats), take(1)));
      const alertOffsets = Object.keys(nextStats.alertReplicas);
      if (alertOffsets.length) {
        return +alertOffsets[0] + nextBase + (videoSection ? -ALERT_PREV_NEXT_WINDOW : 0);
      }
    }

    // Return null if all offsets are less than the timestamp
    return null;
  }

  public async getPreviousAlertTimestamp(edgeId: string, cameraId: string, ts: number, videoSection = false): Promise<number> {

    const base = this.getBaseInLocale(new Date(ts));
    const offset = this.normalizeTimestamp(ts - base, 2000);
    const stats = await lastValueFrom(this.selectThumbnails(edgeId, cameraId, base));

    // Use Lodash to find the index where the timestamp would fit in the sorted array
    if (stats?.alertReplicas) {
      const alertOffsets = Object.keys(stats.alertReplicas)
        .map(ts => +ts);
      let index = _.sortedIndex(alertOffsets, offset);

      // If index is 0, it means no offset is less than or equal to the timestamp
      if (index === 0) {
        const prevBase = base - 86400000;
        const prevStats = await lastValueFrom(this.selectThumbnails(edgeId, cameraId, prevBase));
        if (prevStats?.alertReplicas) {
          const alertOffsets = Object.keys(prevStats.alertReplicas);
          if (alertOffsets.length) {
            return +alertOffsets[alertOffsets.length - 1] + prevBase + (videoSection ? -ALERT_PREV_NEXT_WINDOW : 0);
          }
        } else {
          await this.cameraThumbnailsService.getThumbnails({ edgeId, cameraId }, prevBase, base);
          const prevStats = await lastValueFrom(this.store$.select(
              ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndBase({ edgeId, cameraId, base }),
            )
            .pipe(filter(stats => !!stats), take(1)));
          const alertOffsets = Object.keys(prevStats.alertReplicas);
          if (alertOffsets.length) {
            return +alertOffsets[alertOffsets.length - 1] + prevBase + (videoSection ? -ALERT_PREV_NEXT_WINDOW : 0);
          }
        }
      }

      return alertOffsets[index - 1] + base + (videoSection ? -ALERT_PREV_NEXT_WINDOW : 0);

    }

    return null;
  }


}
