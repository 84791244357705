import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuardService } from '../../core/authentication-guard.service';
import { routerSegments } from '@consts/routes';
import { NgModule } from '@angular/core';
import { MonitoringViewComponent } from './pages/monitoring-view/monitoring-view.component';
import { MonitoringComponent } from './monitoring.component';
import { ViewListComponent } from './pages/view-list/view-list.component';

const routes: Routes = [
  {
    path: '',
    component: MonitoringComponent,
    canActivate: [AuthenticationGuardService],
    children: [
      {
        path: routerSegments.view,
        component: MonitoringViewComponent,
      },
      {
        path: `${routerSegments.view}/:id`,
        component: MonitoringViewComponent,
      },
      {
        path: routerSegments.viewList,
        component: ViewListComponent,
      },
      {
        path: '',
        redirectTo: routerSegments.view,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})

export class MonitoringRoutingModule {

}
