import { createReducer, on } from '@ngrx/store';
import { VariableConfig, VariableConfiguration, VariableEvent, VariableSettings } from '@models/variable.model';
import { VariableActions } from './variable.action-types';
import { VariableType } from '@enums/variable.enum';
import { SortDirection } from '@angular/material/sort';
import { variableMenuLevel2Validation } from '@consts/menu.const';
import { EdgeCamera } from '../../../cameras/camera.model';
import { SearchSelectionForm } from '@models/search.model';
import { AnalyticClasses } from '@enums/alert-events.enum';

export declare interface VariableState {
  variables: VariableConfig[];
  totalItemsCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  orderDirection: SortDirection;
  query: string;

  //details tab
  variableName: string;
  selectedCameras: EdgeCamera.CameraItem[];
  selectedVariableType: VariableType;
  selectedVariable: VariableConfig;

  settings: VariableSettings;

  configuration?: VariableConfiguration;
  objectSelections: SearchSelectionForm[];
  objectSelectionsFormatted: SearchSelectionForm[];

  // temporary
  selectedPersonObjectType: boolean;

  event?: VariableEvent;

  level2MenuValid: { [key: string]: boolean };
  compoundFormula: string;
  compoundFormulaHTML: string;
  compoundFormulaInvalid: boolean;
  variablesAutoComplete: { _id: string; name: string }[];
}

// export const initialState: VariableState = {
//   variables: null,
//   totalItemsCount: 0,
//   page: 0,
//   perPage: 7,
//   orderBy: 'name',
//   orderDirection: 'asc',
//   query: null,
//   //details tab
//   variableName: null,
//   selectedCamera: null,
//   selectedVariableType: VariableType.search,
//   selectedVariable: null,
//   settings: {
//     periodInMinutes: 1
//   },
//   configuration: null,
//   event: null,
//   level2MenuValid: variableMenuLevel2Validation,
// };

// TODO: REMOVE - FOR DEV PURPOSES
export const initialState: VariableState = {
  variables: null,
  totalItemsCount: 0,
  page: 0,
  perPage: 10,
  orderBy: 'name',
  orderDirection: 'asc',
  query: null,
  //details tab
  variableName: '',
  // selectedCamera: {
  //   cloudOnly: {},
  //   edgeOnly: {
  //     cameraId: '631732efc4b7fc310f14d011',
  //   },
  //   locationId: '63146417eb9c1420f9bf0c01',
  //   snapshot: 'https://thumbnails.lumix.ai/thumbnails/62f4e3ddfd167d8aec2008c8/631732efc4b7fc310f14d011/thumbnail-1673944352000-0-0.jpg'
  // },
  selectedCameras: [],
  selectedVariableType: VariableType.search,
  selectedVariable: null,
  settings: {
    periodInMinutes: 5,
  },
  configuration: null,
  objectSelections: null,
  objectSelectionsFormatted: null,

  // temporary
  selectedPersonObjectType: true,

  event: null,
  level2MenuValid: variableMenuLevel2Validation,
  compoundFormula: null,
  compoundFormulaHTML: `<p></p>`,
  compoundFormulaInvalid: false,
  variablesAutoComplete: [],
};

export const variableStateReducer = createReducer(
  initialState,
  on(VariableActions.resetToInitialState, state => {
    return {
      ...initialState,
      objectSelections: initialState.objectSelections,
      objectSelectionsFormatted: initialState.objectSelectionsFormatted,
    };
  }),
  on(VariableActions.setSelectedCameras, (state, { selectedCameras }) => {
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(VariableActions.setVariables, (state, { variables }) => {
    return {
      ...state,
      variables,
    };
  }),
  on(VariableActions.setTotalItemsCount, (state, { totalItemsCount }) => {
    return {
      ...state,
      totalItemsCount,
    };
  }),
  on(VariableActions.setSelectedVariable, (state, { selectedVariable }) => {
    return {
      ...state,
      ...selectedVariable,
      variableName: selectedVariable.name,
      selectedVariableType: selectedVariable.variableType,
      selectedVariable: selectedVariable,
      selectedCameras: [selectedVariable.selectedCamera],
      selectedPersonObjectType: selectedVariable?.configuration?.object === AnalyticClasses.person ?? true,
    };
  }),
  on(VariableActions.setVariableType, (state, { selectedVariableType }) => {
    return {
      ...state,
      selectedVariableType,
    };
  }),
  on(VariableActions.setVariableName, (state, { variableName }) => {
    return {
      ...state,
      variableName,
    };
  }),
  on(VariableActions.setVariablePeriodInMinutes, (state, { periodInMinutes }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        periodInMinutes,
      },
    };
  }),
  on(VariableActions.addValidMenuPoint, (state, { point }) => {
    return {
      ...state,
      level2MenuValid: {
        ...state.level2MenuValid,
        [point]: true,
      },
    };
  }),
  on(VariableActions.rmValidMenuPoint, (state, { point }) => {
    return {
      ...state,
      level2MenuValid: {
        ...state.level2MenuValid,
        [point]: false,
      },
    };
  }),
  on(VariableActions.setObjectSelection, (state, { objectSelections }) => {
    return {
      ...state,
      objectSelections,
    };
  }),
  on(VariableActions.setObjectSelectionFormatted, (state, { objectSelectionsFormatted }) => {
    return {
      ...state,
      objectSelectionsFormatted,
    };
  }),
  on(VariableActions.setSelectedPersonObjectType, (state, { selectedPersonObjectType }) => {
    return {
      ...state,
      selectedPersonObjectType,
    };
  }),
  on(VariableActions.setQuery, (state, { query }) => {
    return {
      ...state,
      query,
    };
  }),
  on(VariableActions.setPaginationParams, (state, { page, perPage }) => {
    return {
      ...state,
      page,
      perPage,
    };
  }),
  on(VariableActions.setCompoundFormulaHTML, (state, { compoundFormulaHTML }) => {
    return {
      ...state,
      compoundFormulaHTML: compoundFormulaHTML,
    };
  }),
  on(VariableActions.setCompoundFormulaValidation, (state, { compoundFormulaInvalid }) => {
    return {
      ...state,
      compoundFormulaInvalid,
    };
  }),
  on(VariableActions.getVariablesAutocompleteSuccess, (state, { variablesAutoComplete }) => {
    return {
      ...state,
      variablesAutoComplete,
    };
  }),
);
