import { Injectable } from '@angular/core';
import { LogLevel, RemoteParticipant, RemoteTrackPublication, RemoteVideoTrack, Room, RoomConnectOptions, RoomEvent, setLogLevel, Track, VideoPresets, VideoQuality } from 'livekit-client';
import { HttpClient } from '@angular/common/http';
import { LiveKitModels } from '@models/livekit.model';
import { api } from '@consts/url.const';
import { Observable, Subject } from 'rxjs';
import { LiveStreamModels } from '@models/live-stream.model';


@Injectable({
  providedIn: 'root',
})
export class LivekitService {
  private room: Room | undefined;

  constructor(private http: HttpClient) {
    setLogLevel(LogLevel.info);
  }

  getToken(getTokenRequest: LiveKitModels.CreateLiveKitTokenRequest): Observable<LiveKitModels.CreateLiveKitTokenResponse> {
    return this.http.post<LiveKitModels.CreateLiveKitTokenResponse>(api.livekit.getToken, getTokenRequest);
  }

  getParticipants(getParticipantsRequest: LiveKitModels.GetLiveKitParticipantsRequest): Observable<LiveKitModels.GetLiveKitParticipantsResponse> {
    return this.http.get<LiveKitModels.GetLiveKitParticipantsResponse>(api.livekit.getParticipants(getParticipantsRequest));
  }

  stop(stopLiveKitRequest: LiveKitModels.StopLiveKitRequest): Observable<void> {
    return this.http.post<void>(api.livekit.stop, stopLiveKitRequest);
  }

  async connect(token: string, videoElement: HTMLVideoElement, adaptiveStream = false, videoQuality?: LiveStreamModels.StreamResolution): Promise<{ room: Room, play$: Observable<void> }> {
    let videoResolution;

    switch (videoQuality) {
      case 0:
        videoResolution = VideoPresets.h180.resolution; // Low quality
        break;
      case 1:
        videoResolution = VideoPresets.h540.resolution; // Medium quality
        break;
      case 2:
        videoResolution = VideoPresets.h720.resolution; // High quality
        break;
      default:
        videoResolution = VideoPresets.h720.resolution; // Default to high quality
        break;
    }

    const room = new Room({
      // [TODO] Check with Sagi and Oz and remove if not needed
      // videoCaptureDefaults: {
      //   deviceId: undefined,
      //   resolution: VideoPresets.h2160,
      // },
      // publishDefaults: {
      //   dtx: false,
      //   videoSimulcastLayers: [VideoPresets.h1080, VideoPresets.h720],
      //   videoCodec: 'h264',
      // },
      adaptiveStream: adaptiveStream ? { pixelDensity: 'screen' } : undefined,
      dynacast: true,
    });
    const playSubject = new Subject<void>();

    room.prepareConnection(api.livekit.url, token);

    // Register event handlers before connecting
    room.on(RoomEvent.ParticipantConnected, participant => {
      console.log(`[LiveKit] Participant connected: ${participant.identity}`);
      this.subscribeToParticipantTracks(participant as RemoteParticipant, videoElement, playSubject, adaptiveStream, videoQuality);
    });

    room.on(RoomEvent.ParticipantDisconnected, participant => {
      console.log(`[LiveKit] Participant disconnected: ${participant.identity}`);
    });

    room.on(RoomEvent.TrackSubscribed, (track, publication, participant) => {
      console.log(`[LiveKit] Track subscribed: ${track.kind} from ${participant.identity}`);
      if (track.kind === 'video' && videoElement) {
        if (publication.kind === Track.Kind.Video && !adaptiveStream) {
          const quality: VideoQuality = videoQuality as unknown as VideoQuality;
          publication.setVideoQuality(quality);
        }
        this.attachRemoteTrack(track as RemoteVideoTrack, videoElement, playSubject);
      }
    });

    room.on(RoomEvent.TrackUnsubscribed, (track, publication, participant) => {
      console.log(`[LiveKit] Track unsubscribed: ${track.kind} from ${participant.identity}`);
    });

    room.on(RoomEvent.Connected, () => {
      console.log('[LiveKit] Room connected');
    });

    room.on(RoomEvent.Disconnected, () => {
      console.log('[LiveKit] Room disconnected');
    });

    // List of all events you want to listen for
    const roomEvents = Object.values(RoomEvent);

// Subscribe to all room events and log them
    roomEvents.forEach(event => {
      room.on(event, (...args) => {
        console.log(`Room event: ${event}`, args);
      });
    });

    const options: RoomConnectOptions = {};
    console.log('[LiveKit] Connecting to room...');
    await room.connect(api.livekit.url, token, options);
    console.log('[LiveKit] Room connection initiated');

    return { room, play$: playSubject.asObservable() };
  }

  async disconnect(room: Room): Promise<void> {
    if (room) {
      return new Promise<void>(async (resolve, reject) => {
        console.log('[LiveKit] Disconnecting from room...');
        room.removeAllListeners();

        // Stop any local tracks
        room.localParticipant.trackPublications.forEach((publication) => {
          const track = publication.track;
          if (track) {
            track.stop();
            track.detach(); // Detach any elements connected to the track
          }
        });

        try {
          // Wait for the room to disconnect
          await room.disconnect();
          room.removeAllListeners();
          room = null;
          resolve();
        } catch (error) {
          console.error('Error during disconnect:', error);
          reject(error);
        }
      });
    } else {
      // If there's no room, resolve immediately
      return Promise.resolve();
    }
  }

  private subscribeToParticipantTracks(participant: RemoteParticipant, videoElement: HTMLVideoElement, playSubject: Subject<void>, adaptiveStream: boolean, videoQuality?: LiveStreamModels.StreamResolution) {
    participant.trackPublications.forEach(publication => {
      this.subscribeToTrack(publication, videoElement, playSubject, adaptiveStream, videoQuality);
    });

    participant.on(RoomEvent.TrackPublished, publication => {
      this.subscribeToTrack(publication, videoElement, playSubject, adaptiveStream, videoQuality);
    });

    participant.on(RoomEvent.TrackUnpublished, publication => {
      console.log(`[LiveKit] Track unpublished: ${publication.trackSid}`);
    });
  }

  private subscribeToTrack(publication: RemoteTrackPublication, videoElement: HTMLVideoElement, playSubject: Subject<void>, adaptiveStream: boolean, videoQuality?: LiveStreamModels.StreamResolution) {

    if (publication.kind === Track.Kind.Video && !adaptiveStream) {
      const quality: VideoQuality = videoQuality as unknown as VideoQuality;
      publication.setVideoQuality(quality);
    }

    publication.on('subscribed', track => {
      if (track.kind === 'video' && videoElement) {
        this.attachRemoteTrack(track as RemoteVideoTrack, videoElement, playSubject);
      }
    });

    publication.on('unsubscribed', track => {
      if (track.kind === 'video' && videoElement.srcObject) {
        console.log(`[LiveKit] Removing remote track`);
        videoElement.srcObject = null;
      }
    });
  }

  attachRemoteTrack(track: RemoteVideoTrack, videoElement: HTMLVideoElement, playSubject: Subject<void>) {
    console.log(`[LiveKit] Attaching remote track`);
    if (!videoElement.srcObject) {
      track.attach(videoElement);
      // videoElement.srcObject = new MediaStream([track.mediaStreamTrack]);
      videoElement.play()
        .then(() => {
          console.log('[LiveKit] Video is playing');
          playSubject.next();
        })
        .catch(error => {
          console.error(`[LiveKit] Error playing video: ${error}`);
        });
    } else {
      console.log(`[LiveKit] Video element already has a srcObject`);
    }
  }

  public changeQuality(room: Room, videoQuality: LiveStreamModels.StreamResolution) {
    const quality: VideoQuality = videoQuality as unknown as VideoQuality;
    if (room) {
      room.remoteParticipants.forEach((participant) => {
        participant.trackPublications.forEach((track) => {
          track.setVideoQuality(quality);
        });
      });
    }
  }
}
