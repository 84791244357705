import { UiUptimeBarColors, UiUserSettingsDateFormat } from '@enums/shared.enum';
import { HoursFormat } from '@enums/user-settings.enum';

export const uiUptimeBarColors: { [Property in UiUptimeBarColors]: string } = {
  [UiUptimeBarColors.primary]: 'var(--color-primary-500)',
  [UiUptimeBarColors.gray]: 'var(--color-primary-50)',
  [UiUptimeBarColors.red]: 'var(--color-red-500)',
  [UiUptimeBarColors.pink]: 'var(--color-pink-500)',
  [UiUptimeBarColors.green]: 'var(--color-secondary-green-checkmark)',
  [UiUptimeBarColors.offline]: 'var(--color-red-500)',
  [UiUptimeBarColors.online]: 'var(--color-secondary-green-400)',
  [UiUptimeBarColors.green50]: 'var(--color-secondary-green-50)',
};

export const isJson = (str): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const passwordValidationErrors = {
  required: 'Field is required',
  lower: 'Field must contain lower case symbol',
  upper: 'Field must contain upper case symbol',
  specialSymbol: 'Field must contain special symbol',
  digit: 'Field must contain the number',
  length: 'The length of password should be more than 8 symbols',
};

export const SYSTEM_EMAIL = 'support@lumix.ai';

export const hoursFormatMap: { [Property in HoursFormat]: string } = {
  [HoursFormat.hours12]: '12 hours',
  [HoursFormat.hours24]: '24 hours',
};
