import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { WallFiltersRequest, WallModel } from '@models/wall.model';

@Injectable()
export class WallService {
  constructor(private http: HttpClient) {
  }

  public createView(wall: WallModel): Observable<WallModel> {
    return this.http.post<WallModel>(api.walls.crud, wall);
  }

  public saveView(wall: WallModel): Observable<WallModel> {
    return this.http.put<WallModel>(api.walls.one(wall._id), wall);
  }

  public get(filters: Partial<WallFiltersRequest>): Observable<WallModel[]> {
    let query = [];
    if (filters.query) {
      query.push(`query=${filters.query}`);
    }
    if (filters?.isPrivate?.length) {
      query.push(`isPrivate=${filters.isPrivate.join(',')}`);
    }
    if (filters.limit) {
      query.push(`limit=${filters.limit}`);
    }
    if (filters.timestamp) {
      query.push(`timestamp=${filters.timestamp}`);
    }
    const selectedCamerasFilter = Object.values(filters?.selectedCameras ?? {});
    if (selectedCamerasFilter.length) {
      query.push(`selectedCameras=${selectedCamerasFilter.map(camera => camera.cameraId)
        .join(',')}`);
    }
    let queryUrl = api.walls.getByFilters + `?${query.join('&')}`;
    if (typeof filters.layoutType !== 'undefined') {
      queryUrl += `&layoutType=${filters.layoutType}`;
    }
    return this.http.get<WallModel[]>(queryUrl);
  }

  public delete(id: string): Observable<WallModel[]> {
    return this.http.delete<WallModel[]>(api.walls.one(id));
  }

  public one(id: string): Observable<WallModel> {
    return this.http.get<WallModel>(api.walls.one(id));
  }
}
