import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ExternalStorageModels } from '@models/external-storage.models';
import { ExternalStorageActions } from '@states/external-storage/external-storage.action-types';
import { WallModel } from '@models/wall.model';
import { Dictionary } from '@ngrx/entity/src/models';

export interface ExternalStorageState extends EntityState<ExternalStorageModels.ExternalStorageDocument> {
  isInitialLoaded: boolean;
  filters: {
    query: string
  };
  isSaving: boolean;
  selectedExternalStorageDocument: ExternalStorageModels.ExternalStorageDocument;
  isFormValid: boolean;
  externalStorageConnected: boolean;
}

export const adapter: EntityAdapter<ExternalStorageModels.ExternalStorageDocument> = createEntityAdapter<ExternalStorageModels.ExternalStorageDocument>({
  selectId: (document: ExternalStorageModels.ExternalStorageDocument) => document._id,
});


export const initialState: ExternalStorageState = adapter.getInitialState({
  isInitialLoaded: false,
  filters: {
    query: null,
  },
  isSaving: false,
  selectedExternalStorageDocument: null,
  isFormValid: false,
  externalStorageConnected: false,
});

export const externalStorageStateReducer = createReducer(
  initialState,
  on(ExternalStorageActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(ExternalStorageActions.getExternalStorageSuccess, (state, { documents }) => {
    return adapter.setMany(documents, {
      ...state,
      isInitialLoaded: true,
    });
  }),
  on(ExternalStorageActions.setSelectedExternalStorage, (state, { document }) => {
    return {
      ...state,
      selectedExternalStorageDocument: document,
    };
  }),
  on(ExternalStorageActions.setQueryStringFilter, (state, { prop, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [prop]: value,
      },
    };
  }),
  on(ExternalStorageActions.resetEntities, (state) => {
    return {
      ...state,
      entities: initialState.entities,
    };
  }),
  on(ExternalStorageActions.testExternalStorageSuccess, (state) => {
    return {
      ...state,
      externalStorageConnected: true,
    };
  }),
  on(ExternalStorageActions.setIsSaving, (state, { isSaving }) => {
    return {
      ...state,
      isSaving: isSaving,
    };
  }),
  on(ExternalStorageActions.saveExternalStorageSetOne, (state, { document }) => {
    return adapter.addOne(document, {
      ...state,
    });
  }),
  on(ExternalStorageActions.deleteExternalStorageSuccess, (state, { storageId }) => {
    return adapter.removeOne(storageId, {
      ...state,
    });
  }),
  on(ExternalStorageActions.getOneExternalStorageSuccess, (state, { document }) => {
    return {
      ...state,
      selectedExternalStorageDocument: document,
    };
  }),
  on(ExternalStorageActions.changeFormValidation, (state, { isFormValid }) => {
    return {
      ...state,
      isFormValid,
    };
  }),
);
