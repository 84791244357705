<div [formGroup]="settingsForm" class="body">
  <div class="flex-row space-between-center option-row">
    <div class="flex-col selector-wrapper">
      <label>Title color</label>
      <div class="display-selector">
        <input type="color" formControlName="titleColor">
      </div>
    </div>
  </div>
  <div class="flex-row space-between-center option-row">
    <div class="flex-col selector-wrapper">
      <label>Overlay display settings</label>
      <div class="display-selector">
        <label>
          <mat-checkbox color="primary" formControlName="displayCameraName"></mat-checkbox>
          Camera name
        </label>
      </div>
    </div>
  </div>
</div>
