import { createAction, props } from '@ngrx/store';
import { WallAlert, WallModel } from '@models/wall.model';
import { WallCameraLocalStatus } from '@states/wall/wall.reducer';
import { AlertEntry } from '../../../development/alerts.service';
import { AlertOrdering, WallAlertListPosition, WallLayoutType } from '@enums/wall.enum';
import { CameraAlertSelectorResult } from '../../../modals/camera-alert-selector-modal/camera-alert-selector-modal.component';
import { AppleTvModels } from '@models/apple-tv.models';
import { SocketModels } from '../../../socket/socket.model';

export const resetToInitialState = createAction('[Wall] Reset to Initial state');

export const selectLayout = createAction('[Wall] Select Layout', props<{ layoutIndex: number }>());

export const setSelectedWall = createAction(
  '[Wall] Set Selected Wall',
  props<{
    selectedWall: WallModel;
  }>(),
);

export const setSelectedSetIndex = createAction('[Wall] Set Selected Index', props<{ selectedSetIndex: number }>());

export const initLocalStatusArray = createAction('[Wall] Init Local Status Array', props<{ size: number }>());

export const setCameraLocalStatus = createAction(
  '[Wall] Set Camera Local Status',
  props<{ index: number; status: WallCameraLocalStatus }>(),
);

export const addSet = createAction('[Wall] Add Set', props<{ name: string }>());

export const saveWall = createAction('[Wall] Save Wall');

export const sendWall = createAction('[Wall] Start Send Wall To Server');

export const sendWallSuccess = createAction('[Wall] Start Send Wall To Server Success', props<{ id: string }>());

export const setPrivate = createAction('[Wall] Set Private Wall', props<{ isPrivate: boolean }>());

export const setWallName = createAction('[Wall] Set Wall Name', props<{ name: string }>());

export const getWalls = createAction('[Wall] Get Walls');

export const sendGetWallsRequest = createAction('[Wall] Send Get Walls Request');

export const setWalls = createAction('[Wall] Set Walls', props<{ walls: WallModel[] }>());

export const setIsExpanded = createAction('[Wall] Set Is Expanded');

export const getWallById = createAction('[Wall] Get Wall by Id', props<{ id: string }>());

export const sendWallById = createAction('[Wall] Send Wall by Id', props<{ id: string }>());
export const sendWallByIdFail = createAction('[Wall] Send Wall by Id Fail');

export const deleteWallById = createAction('[Wall] Delete Wall By Id', props<{ id: string }>());

export const deleteWallSuccess = createAction('[Wall] Delete Wall Success');

export const deleteCamera = createAction('[Wall] Delete Camera', props<{ cameraIndex: number }>());

export const dropCamera = createAction('[Wall] Drop Camera', props<{ prevDataIndex: number; nextDataIndex: number }>());

//todo remove index from there
export const removeSet = createAction('[Wall] Remove Set', props<{ setIndex: number }>());

export const renameSet = createAction('[Wall] Rename Set', props<{ name: string, setIndex: number }>());

export const changePrivate = createAction('[Wall] Change Private', props<{ isPrivate: boolean; }>());

export const getAlertsMonitoring = createAction('[Wall] Get Alerts Monitoring');

/**
 * For initial backend loading
 */
export const getAlertsMonitoringSuccess = createAction('[Wall] Get Alerts Monitoring Success', props<{
  alerts: { [key: string]: WallAlert },
  tilesAlerts: {
    [key: number]: AlertEntry
  };
}>());

/**
 * For update from socket table only
 */
export const getAlertsMonitoringTableSuccess = createAction('[Wall] Get Alerts Monitoring Table Success', props<{
  alerts: { [key: string]: WallAlert },
}>());

/**
 * For update from socket tiles only
 */
export const getAlertsMonitoringTilesWithDelaySuccess = createAction('[Wall] Get Alerts Monitoring Tiles With Delay Success', props<{
  tilesAlerts: {
    [key: number]: AlertEntry & { selectedAt: number }
  };
  delay: number //debug
}>());
/**
 * For update from socket tiles only
 */
export const getAlertsMonitoringTilesSuccess = createAction('[Wall] Get Alerts Monitoring Tiles Success', props<{
  tilesAlerts: {
    [key: number]: AlertEntry & { selectedAt: number }
  };
  delay: number //debug
}>());
export const getAlertsMonitoringFail = createAction('[Wall] Get Alerts Monitoring Fail');
export const newAlertSocketReceived = createAction('[Wall] New Alert Socket received', props<{ receivedAlerts: AlertEntry[] }>());
export const newAlertSocketReceivedDefault = createAction('[Wall] New Alert Socket received Default', props<{ receivedAlerts: AlertEntry[] }>());
export const newAlertSocketReceivedOrdered = createAction('[Wall] New Alert Socket received Ordered', props<{ receivedAlerts: AlertEntry[] }>());

export const archiveAlert = createAction('[Wall] Archive Alert', props<{ alert: WallAlert, isArchive: boolean }>());
export const archiveAlertSuccess = createAction('[Wall] Archive Alert Success', props<{ eventId: string, alertId: string }>());
export const archiveAlertCanceled = createAction('[Wall] Archive Alert Canceled', props<{ eventId: string, alertId: string }>());
export const archiveAlertFail = createAction('[Wall] Archive Alert Fail');

export const setAlertFilterFrequency = createAction('[Wall] Set Alert Filter Frequency', props<{ frequency: number }>());

export const nextPage = createAction('[Wall] Next Page');
export const setSearchFilters = createAction('[Wall] Set Search Filters', props<{ field: string; value: any }>());
export const rmSearchFilters = createAction('[Wall] Remove Search Filters', props<{ field: string; value: any }>());
export const setLoader = createAction('[Wall] Set Loader', props<{ isLoading: boolean }>());

export const setAlertListPositions = createAction('[Wall] Set Alert List Positions', props<{ position: WallAlertListPosition }>());

export const setRotateDuration = createAction('[Wall] Set Rotate Duration', props<{ duration: number }>());
export const selectAlertSuccess = createAction('[Wall] Select Alert Success', props<{
  tilesAlerts: {
    [key: number]: AlertEntry
  };
  alerts: { [key: string]: WallAlert },
}>());

export const refreshTile = createAction('[Wall] Refresh Tile', props<{ tile: number, alertId: string }>());
export const resetTiles = createAction('[Wall] Reset Tiles to initial state');

export const changeAndSave = createAction('[Wall] Set Rotate And Save', props<{ wall: WallModel }>());

export const setValidation = createAction('[Wall] Set Is Valid', props<{ isValid: boolean }>());
export const runValidation = createAction('[Wall] Run Is Valid');
export const switchDebugMode = createAction('[Wall] Switch Debug Mode');

export const removeSelectedEvent = createAction('[Wall] Remove Selected Event', props<{ positionIndex: number, eventId: string, cameraId: string }>());
export const refreshIsLastPage = createAction('[Wall] Refresh IsLast Page');

export const updatePosition = createAction('[Wall] Update Set Position', props<{ positionIndex: number; selectionResult: CameraAlertSelectorResult }>());

export const saveQuickView = createAction('[Wall] Save Quick View', props<{ name: string }>());
export const resetAlerts = createAction('[Wall] Reset Alerts');
export const refreshAlerts = createAction('[Wall] Refresh Alerts');
export const removeSelectedCameraEvent = createAction('[Wall] Remove Selected Camera Event', props<{ positionIndex: number, cameraId: string }>());


export const clearTileInThreshold = createAction('[Wall] Clean Tile in threshold', props<{
  tile: number;
}>());

export const changeAlertOrdering = createAction('[Wall] Change Alert Ordering', props<{ alertOrdering: AlertOrdering }>());
export const setWallLayoutType = createAction('[Wall] Set Wall Layout Type', props<{ layoutType: WallLayoutType }>());

export const updateExternalAlerts = createAction('[Wall] Update External Alerts', props<{ selectionResult: CameraAlertSelectorResult }>());
export const findAndCleanTileByAlertId = createAction('[Wall] Find and Clean Tile By Alert Id', props<{ alertId: string }>());
export const setIsMuted = createAction('[Wall] Set is Muted', props<{ isMuted: boolean }>());

export const refreshAlertsTiles = createAction('[Wall] Refresh Alert Tiles');
export const refreshAlertsTilesSuccess = createAction('[Wall] Refresh Alert Tiles Success', props<{ alerts: { [key: string]: WallAlert } }>());
export const refreshAlertsTilesFail = createAction('[Wall] Refresh Alert Tiles Fail');
export const checkWebRtcConnections = createAction('[Wall] Check WebRTC Connections');

export const getAppleTv = createAction('[Wall] Get Apple TV');
export const getAppleTvSuccess = createAction('[Wall] Get Apple TV Success', props<{ appleTvs: AppleTvModels.AppleTvDocument[] }>());
export const getAppleTvFail = createAction('[Wall] Get Apple TV Fail');

export const configureAppleTv = createAction('[Wall] Configure Apple TV', props<{ edgeId: string }>());
export const configureAppleTvSuccess = createAction('[Wall] Configure Apple TV Success');

export const updateAppleTvSetOne = createAction('[APPLE-TV] Configure Wall by Apple TV Response ', props<{ configureWallResponse: SocketModels.ConfigureAppleTv.ConfigureAppleTvResponseData }>());
export const updateTileSelected = createAction('[Wall] Update Tile Selected', props<{ isTileSelected: boolean }>());
