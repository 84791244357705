import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { EdgeRowComponent } from '../edge-row/edge-row.component';
import { SelectedCamera } from '@models/alert-events.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { CameraSelectorModalModels } from '../../models/models';
import { Edge } from '../../../../edge/edge.model';

@Component({
  selector: 'camera-selector-location-row',
  standalone: true,
  imports: [
    MatCheckbox,
    NgIf,
    AsyncPipe,
    EdgeRowComponent,
    NgForOf,
    JsonPipe,
  ],
  templateUrl: './location-row.component.html',
  styleUrls: ['./location-row.component.scss', './../../styles/index.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationRowComponent implements OnChanges {
  @Input() location: CameraSelectorModalModels.Location;

  @Input() selectedCameras: Dictionary<SelectedCamera>;
  @Input() isCollapsed: boolean = false;


  @Output() onCameraSelected: EventEmitter<SelectedCamera> = new EventEmitter<SelectedCamera>();
  @Output() onEdgeSelected: EventEmitter<{ locationId: string; edgeId: string, checked: boolean }> = new EventEmitter<{ locationId: string; edgeId: string, checked: boolean }>();
  @Output() onLocationSelected: EventEmitter<{ locationId: string; checked: boolean }> = new EventEmitter<{ locationId: string; checked: boolean }>();

  public collapsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isCollapsed);

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['isCollapsed']) {
      this.collapsed$.next(changes['isCollapsed'].currentValue);
    }
  }

  public toggleLocation() {
    const currentValue = this.collapsed$.getValue();
    this.collapsed$.next(!currentValue);
  }

  public get edges() {
    return Object.values(this.location.edges ?? {});
  }

  public selectCamera(camera: SelectedCamera) {
    this.onCameraSelected.emit(camera);
  }

  public selectEdge(value: { edgeId: string, checked: boolean }) {
    this.onEdgeSelected.emit({ ...value, locationId: this.location._id });
  }

  public get selected() {
    const selectedCameras = Object.keys(this.selectedCameras);
    let locationCameras = Object.values(this.location.locationCameras);
    return locationCameras
      .every(camera => selectedCameras.includes(camera.edgeOnly.cameraId));
  }

  public selectLocation(ev: MatCheckboxChange) {
    this.onLocationSelected.emit({ locationId: this.location._id, checked: ev.checked });
  }

  public trackBy(index: number, edge: Edge.EdgeItem) {
    return edge.edgeId;
  }
}
