import { ActionReducerMap } from '@ngrx/store';
import { AnalyticsState, analyticsStateReducer } from '@states/analytics/analytics.reducer';
import { OrganizationState, organizationStateReducer } from '@states/organization/organization.reducer';
import { SharedState, sharedStateReducer } from '@states/shared/shared.reducer';
import { InviteState, inviteStateReducer } from '@states/invite/invite.reducer';
import MenuState, { menuStateReducer } from '@states/menu/menu.reducer';
import { AlertEventsState, alertEventsStateReducer } from '@states/alert-events/alert-events.reducer';
import { SettingsState, settingsStateReducer } from '@states/settings/settings.reducer';
import { CameraEditState, cameraEditStateReducer } from '@states/camera-edit/camera-edit.reducer';
import { AlertMonitoringState, alertMonitoringStateReducer } from '@states/alert-monitoring/alert-monitoring.reducer';
import { MultiSearchState, multiSearchStateReducer } from '@states/multi-search/multi-search.reducer';
import { EdgeEditState, edgeEditStateReducer } from '@states/edge-edit/edge-edit.reducer';
import { WallState, wallStateReducer } from '@states/wall/wall.reducer';
import { PlaybackState, playbackStateReducer } from '@states/playback/playback.reducer';
import { ArchiveState, archiveStateReducer } from '@states/archive/archive.reducer';
import { GrantedAccessState, grantedAccessStateReducer } from '@states/granted-access/granted-access.reducer';
import { StatsState, statsStateReducer } from '@states/stats/stats.reducer';
import { UserSettingsState, userSettingsStateReducer } from '@states/user-settings/user-settings.reducer';
import { TrackObjectState, trackObjectStateReducer } from '@states/track-object/track-object.reducer';
import { TerminalState, terminalStateReducer } from '@states/terminal/terminal.reducer';
import { GeneralState, generalStateReducer } from '@states/general/general.reducer';
import { ActivityLogState, activityLogStateReducer } from '@states/activity-log/activity-log.reducer';
import { ThumbnailsState, thumbnailsStateReducer } from '@states/thumbnails/thumbnails.reducer';
import { DevTeamState, devTeamStateReducer } from '@states/dev-team/dev-team.reducer';
import { dateTimeRangePickerReducer, DateTimeRangePickerState } from '../shared/ui-kit/ui-datetime-range-picker/store/ui-datetime-range-picker.reducer';
import { VariableState, variableStateReducer } from '@states/variable/variable.reducer';
import { WorkspaceState, workspaceStateReducer } from '@states/workspace/workspace.reducer';
import { CamerasState, camerasStateReducer } from '@states/cameras/cameras.reducer';
import { SearchState, searchStateReducer } from '@states/search/search.reducer';
import { WebRtcState, webRtcStateReducer } from '@states/webrtc/webrtc.reducer';
import { NotificationState, notificationStateReducer } from '@states/notification/notification.reducer';
import { MultiPlaybackState, multiPlaybackStateReducer } from '@states/multi-playback/multi-playback.reducer';
import { PricingState, pricingStateReducer } from '@states/pricing/pricing.reducer';
import { AlertsV2State, alertsV2StateReducer } from '@states/alerts-v2/alerts-v2.reducer';
import { PeopleState, peopleStateReducer } from '@states/people/people.reducer';
import { EdgePulsationState, edgePulsationStatusReducer } from '@states/edge-heartbeat-pulsation/edge-heartbeat-pulsation.reducer';
import { CameraPulsationState, cameraPulsationStatusReducer } from '@states/camera-heartbeat-pulsation/camera-heartbeat-pulsation.reducer';
import { AnalyticPulsationState, analyticPulsationStatusReducer } from '@states/analytic-heartbeat-pulsation/anaytic-heartbeat-pulsation.reducer';
import { StoragePulsationState, storagePulsationStatusReducer } from '@states/storage-heartbeat-pulsation/storage-heartbeat-pulsation.reducer';
import { ApiKeyState, apiKeyStateReducer } from '@states/api-key/api-key.reducer';
import { LocationEditState, locationEditStateReducer } from '@states/location-edit/location-edit.reducer';
import { OrganizationUsersState, organizationUsersStateReducer } from '@states/organization-users/organization-users.reducer';
import { PulsationModels } from '@models/pulsation.model';
import StorageState = PulsationModels.StorageState;
import { storageStateReducer } from '@states/storage/storage.reducer';
import { AlertLogsState, alertLogsStateReducer } from '@states/alert-logs/alert-logs.reducer';
import { DoorsState, doorsStateReducer } from '@states/doors/doors.reducer';
import { CustomEventsState, customEventsStateReducer } from '@states/custom-events/custom-events.reducer';
import { SmartStoragePulsationState, smartStoragePulsationStatusReducer } from '@states/smart-storage-heartbeat-pulsation/smart-storage-heartbeat-pulsation.reducer';
import { SubstreamPulsationState, substreamPulsationStatusReducer } from '@states/substream-heartbeat-pulsation/substream-heartbeat-pulsation.reducer';
import { UserGroupState, userGroupStateReducer } from '@states/user-groups/user-groups.reducer';
import { webRTCActiveSessionReducer, WebrtcActiveSessionState } from '@states/webrtc-active-sessions/webrtc-active-sessions.reducer';
import { VehiclesState, vehiclesStateReducer } from '@states/vehicles/vehicles.reducer';
import { OrgGroupsState, orgGroupsStateReducer } from '@states/org-groups/org-groups.reducer';
import { PermissionGroupState, permissionGroupStateReducer } from '@states/group/group.reducer';
import { permissionsStateReducer } from '@states/permissions/permissions.reducer';
import { DashboardState, dashboardStateReducer } from '@states/dashboard/dashboard.reducer';
import { DeviceStatusState, deviceStatusStateReducer } from '@states/device-status/device-status.reducer';
import { authenticationReducer, AuthenticationState } from '@states/authentication/authentication.reducer';
import { userReducer, UserState } from '@states/user/user.reducer';
import { locationReducer, LocationState } from '@states/location/location.reducer';
import { EdgeState, locationEdgeReducer } from '@states/edge/edge.reducer';
import { CameraState, locationEdgeCameraReducer } from '@states/camera/camera.reducer';
import { CertificationState, certificationStateReducer } from '@states/certifications/certifications.reducer';
import IdentityState, { identityStateReducer } from '@states/identity/identity.reducer';
import { AppleTvState, appleTvStateReducer } from '@states/apple-tv/apple-tv.reducer';
import { ApplePulsationState, applePulsationStatusReducer } from '@states/apple-heartbeat-pulsation/apple-heartbeat-pulsation.reducer';
import { UserDeviceState, userDeviceStateReducer } from '@states/device/device.reducer';
import { IntegrationsState, integrationsStateReducer } from '@states/integrations/integrations.reducer';
import { UserMfaState, userMfaStateReducer } from '@states/mfa/mfa.reducer';
import { FloorState, floorStateReducer } from '@states/floors/floor.reducer';
import { WebhookState, webhookStateReducer } from '@states/webhook/webhook.reducer';
import { DCHPState, dhcpStateReducer } from '@states/dhcp/dhcp.reducer';
import { ExternalStorageState, externalStorageStateReducer } from '@states/external-storage/external-storage.reducer';
import { WallV2State, wallV2StateReducer } from '@states/wall-v2/wall-v2.reducer';

export interface AppState {
  authenticationState: AuthenticationState,
  userState: UserState,
  locationState: LocationState,
  edgeState: EdgeState,
  cameraState: CameraState,
  analyticsState: AnalyticsState;
  organizationState: OrganizationState;
  sharedState: SharedState;
  inviteState: InviteState;
  menuState: MenuState;
  alertEventsState: AlertEventsState;
  variableState: VariableState;
  webRtcSessionsState: WebRtcState;
  camerasState: CamerasState;
  settingsState: SettingsState;
  cameraEditState: CameraEditState;
  alertMonitoringState: AlertMonitoringState;
  multiSearchState: MultiSearchState;
  trackObjectState: TrackObjectState;
  multiPlaybackState: MultiPlaybackState;
  dateTimeRangePickerState: DateTimeRangePickerState;
  thumbnailsState: ThumbnailsState;
  generalState: GeneralState;
  edgeEditState: EdgeEditState;
  playbackState: PlaybackState;
  wallState: WallState;
  archiveState: ArchiveState;
  grantedAccessState: GrantedAccessState;
  statsState: StatsState;
  userSettingsState: UserSettingsState;
  terminalState: TerminalState;
  activityLogState: ActivityLogState;
  devTeamState: DevTeamState;
  workspaceState: WorkspaceState;
  searchState: SearchState;
  notificationState: NotificationState;
  pricingState: PricingState;
  alertsV2State: AlertsV2State;
  peopleState: PeopleState;
  edgePulsationState: EdgePulsationState;
  cameraPulsationState: CameraPulsationState;
  analyticPulsationState: AnalyticPulsationState;
  storagePulsationState: StoragePulsationState;
  smartStoragePulsationState: SmartStoragePulsationState;
  substreamPulsationState: SubstreamPulsationState;
  apiKeyState: ApiKeyState;
  locationEditState: LocationEditState;
  organizationUsersState: OrganizationUsersState;
  storageState: StorageState;
  alertLogsState: AlertLogsState;
  doorsState: DoorsState;
  customEventsState: CustomEventsState;
  userGroupState: UserGroupState;
  webRTCActiveSessionState: WebrtcActiveSessionState;
  vehiclesState: VehiclesState;
  orgGroupsState: OrgGroupsState;
  permissionGroupState: PermissionGroupState;
  permissionsState: PermissionState;
  dashboardState: DashboardState;
  deviceStatusState: DeviceStatusState;
  certificationState: CertificationState;
  identityState: IdentityState;
  appleTvState: AppleTvState;
  applePulsationState: ApplePulsationState;
  userDeviceState: UserDeviceState;
  integrationsState: IntegrationsState;
  userMfaState: UserMfaState;
  webhookState: WebhookState
  floorState: FloorState,
  dhcpState: DCHPState,
  wallV2State: WallV2State,
  externalStorageState: ExternalStorageState
}

export const reducers: ActionReducerMap<any> = {
  authenticationState: authenticationReducer,
  userState: userReducer,
  locationState: locationReducer,
  edgeState: locationEdgeReducer,
  cameraState: locationEdgeCameraReducer,
  analyticsState: analyticsStateReducer,
  organizationState: organizationStateReducer,
  sharedState: sharedStateReducer,
  inviteState: inviteStateReducer,
  menuState: menuStateReducer,
  alertEventsState: alertEventsStateReducer,
  variableState: variableStateReducer,
  webRtcSessionsState: webRtcStateReducer,
  camerasState: camerasStateReducer,
  settingsState: settingsStateReducer,
  cameraEditState: cameraEditStateReducer,
  alertMonitoringState: alertMonitoringStateReducer,
  multiSearchState: multiSearchStateReducer,
  trackObjectState: trackObjectStateReducer,
  multiPlaybackState: multiPlaybackStateReducer,
  dateTimeRangePickerState: dateTimeRangePickerReducer,
  thumbnailsState: thumbnailsStateReducer,
  generalState: generalStateReducer,
  edgeEditState: edgeEditStateReducer,
  wallState: wallStateReducer,
  playbackState: playbackStateReducer,
  archiveState: archiveStateReducer,
  grantedAccessState: grantedAccessStateReducer,
  statsState: statsStateReducer,
  userSettingsState: userSettingsStateReducer,
  terminalState: terminalStateReducer,
  activityLogState: activityLogStateReducer,
  devTeamState: devTeamStateReducer,
  workspaceState: workspaceStateReducer,
  searchState: searchStateReducer,
  notificationState: notificationStateReducer,
  pricingState: pricingStateReducer,
  alertsV2State: alertsV2StateReducer,
  peopleState: peopleStateReducer,
  edgePulsationState: edgePulsationStatusReducer,
  cameraPulsationState: cameraPulsationStatusReducer,
  analyticPulsationState: analyticPulsationStatusReducer,
  storagePulsationState: storagePulsationStatusReducer,
  smartStoragePulsationState: smartStoragePulsationStatusReducer,
  substreamPulsationState: substreamPulsationStatusReducer,
  apiKeyState: apiKeyStateReducer,
  locationEditState: locationEditStateReducer,
  organizationUsersState: organizationUsersStateReducer,
  storageState: storageStateReducer,
  alertLogsState: alertLogsStateReducer,
  doorsState: doorsStateReducer,
  customEventsState: customEventsStateReducer,
  userGroupState: userGroupStateReducer,
  webRTCActiveSessionState: webRTCActiveSessionReducer,
  vehiclesStateReducer: vehiclesStateReducer,
  permissionGroupState: permissionGroupStateReducer,
  orgGroupsState: orgGroupsStateReducer,
  permissionsState: permissionsStateReducer,
  dashboardState: dashboardStateReducer,
  deviceStatusState: deviceStatusStateReducer,
  certificationState: certificationStateReducer,
  identityState: identityStateReducer,
  appleTvState: appleTvStateReducer,
  applePulsationState: applePulsationStatusReducer,
  userDeviceState: userDeviceStateReducer,
  integrationsState: integrationsStateReducer,
  userMfaState: userMfaStateReducer,
  webhookState: webhookStateReducer,
  floorState: floorStateReducer,
  dhcpState: dhcpStateReducer,
  wallV2State: wallV2StateReducer,
  externalStorageState: externalStorageStateReducer,
};
