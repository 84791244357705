import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SearchConfigurationActions from '@states/camera-edit/camera-edit.actions';
import * as MultiSearchActions from '@states/multi-search/multi-search.actions';
import * as SharedActions from '@states/shared/shared.actions';
import { withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { catchError, exhaustMap, Observable, of, share, switchMap } from 'rxjs';
import { AppState } from '../app.state';
import { SearchConfigurationSendModel } from '@models/search-configuration.model';
import { SearchConfigurationService } from '../../development/search-configuration.service';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { CameraService } from '../../development/camera.service';
import { CameraActions } from '@states/camera/camera.action-types';
import { LocationsService } from '../../locations/locations.service';
import { EdgeService } from '../../edge/edge.service';
import { LocationModel } from '../../locations/location.model';
import { CameraCloudOnly, CameraUpdateHlsRequest, CameraUpdateRequest, EdgeCamera } from '../../cameras/camera.model';
import { SessionDataAction } from '@enums/session-data.enum';
import { CameraSearchSettingsService } from '../../development/camera-search-settings.service';
import { CameraSearchSettings } from '@models/camera-search.settings';
import { SearchSelection, SearchSelectionCarProperty, SearchSelectionPersonProperty } from '@models/search.model';
import { PersonSelectionFormFields, SearchObjectTypes, VehicleSelectionFormFields } from '@enums/search.enum';
import { ageTypeRadioValues } from '@consts/alert-events.const';
import { SearchService } from '../../shared/search.service';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import { LocationSelectors } from '@states/location/location.selector-types';
import { sendCameraHlsUpdate } from '@states/camera-edit/camera-edit.actions';
import { UpdateLocationEdgeCameraCloudOnly } from '@states/camera/camera.actions';

@Injectable()
export class SearchConfigurationEffect {
  public pressSave$ = createEffect(() => this.actions$.pipe(ofType(SearchConfigurationActions.editCameraPressSave), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public saveSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.saveSearchConfiguration),
      exhaustMap(() => [SharedActions.setIsSaving({ isSaving: true }), SearchConfigurationActions.sendSearchConfiguration()]),
    ),
  );

  public sendSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendSearchConfiguration),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(
        ([
           ,
           {
             selectedCamera,
             zones,
             zonesExclude,
             definedZones,
             protectiveGear,
             forklift,
             shoppingCart,
             markedIdx,
             objectsToScan,
             selectedSearchConfigurationId,
             licensePlates,
             vehicleMMC,
             privacy,
           },
         ]) => {
          const searchConfiguration: SearchConfigurationSendModel = {
            zones,
            zonesExclude,
            definedZones,
            protectiveGear,
            forklift,
            shoppingCart,
            markedIdx,
            selectedCamera: {
              cameraId: selectedCamera.edgeOnly.cameraId,
              locationId: selectedCamera.locationId,
              edgeId: selectedCamera.edgeId,
            },
            objectsToScan,
            licensePlates,
            vehicleMMC,
            privacy,
          };

          if (!selectedSearchConfigurationId) {
            return this.searchConfigurationService.create(searchConfiguration)
              .pipe(
                switchMap((res) => {
                  // return [
                  //   SharedActions.setIsSaving({ isSaving: false }),
                  //   SharedActions.showMessage({
                  //     success: 'Search configuration has been created',
                  //   }),
                  //   SearchConfigurationActions.getSelectedSearchConfigurationSuccess({
                  //     selectedSearchConfiguration: {
                  //       ...createdSearchConfiguration,
                  //       selectedCamera,
                  //     },
                  //   }),
                  //   SearchConfigurationActions.saveSearchConfigurationSuccess(),
                  // ];
                  return [
                    SharedActions.subscribeToSessionStatus({
                      token: res.token.session,
                      sessionDataAction: SessionDataAction.updateSearchConfiguration,
                      params: {
                        msTimeout: 10000,
                      },
                    }),
                  ];
                }),
                catchError(response => {
                  return [
                    SharedActions.setIsSaving({ isSaving: false }), //loader off
                    this.catchError(response),
                  ];
                }),
              );
          } else {
            return this.searchConfigurationService.update(selectedSearchConfigurationId, searchConfiguration)
              .pipe(
                switchMap((res) => {
                  return [
                    SharedActions.subscribeToSessionStatus({
                      token: res.token.session,
                      sessionDataAction: SessionDataAction.updateSearchConfiguration,
                      params: {
                        msTimeout: 10000,
                      },
                    }),
                  ];
                }),
                catchError(response => {
                  return [
                    SharedActions.setIsSaving({ isSaving: false }), //loader off
                    this.catchError(response),
                  ];
                }),
              );
          }
        },
      ),
      share(),
    ),
  );

  public getSelectedCameraConfiguration$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SearchConfigurationActions.getSelectedCameraVPNConfiguration),
        withLatestFrom(this.store$.pipe(select(CameraSelectors.selectCameraState))),
        switchMap(([{ locationId, edgeId, cameraId }, allCameras]) => {
          return this.searchConfigurationService.getVpn(locationId, edgeId, cameraId)
            .pipe(
              switchMap(res => {
                if (!res) {
                  return of(SharedActions.doNothing());
                }
                return [
                  SharedActions.subscribeToSessionStatus({
                    token: res.token.session,
                    sessionDataAction: SessionDataAction.getCameraVpnConfiguration,
                    params: {
                      msTimeout: 10000,
                    },
                  })];
              }),
              catchError(response => {
                return [
                  SharedActions.setIsSaving({ isSaving: false }), //loader off
                  this.catchError(response),
                ];
              }),
            );
        }),
        share(),
      );
    },
  );

  public getSelectedSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getSelectedSearchConfiguration),
      withLatestFrom(this.store$.pipe(select(CameraSelectors.selectCameraState))),
      switchMap(([{ locationId, edgeId, cameraId }, allCameras]) => {
        return this.searchConfigurationService.getOne(locationId, edgeId, cameraId)
          .pipe(
            switchMap(selectedSearchConfiguration => {
              if (!selectedSearchConfiguration) {
                return of(SharedActions.doNothing());
              }
              return [
                SearchConfigurationActions.getSelectedSearchConfigurationSuccess({
                  selectedSearchConfiguration: {
                    ...selectedSearchConfiguration,
                    selectedCamera: allCameras.entities[selectedSearchConfiguration.selectedCamera.cameraId],
                  },
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.setIsSaving({ isSaving: false }), //loader off
                this.catchError(response),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getCameraById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getCameraById),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState)), this.store$.pipe(select(CameraSelectors.selectCameraState))),
      exhaustMap(([, { selectedCameraId }, allCameras]) => {
        const camera = allCameras.entities[selectedCameraId];
        return [SearchConfigurationActions.setSelectedCamera({ selectedCamera: camera })];
      }),
    ),
  );

  public deleteSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.deleteSearchConfiguration),
      exhaustMap(() => [SharedActions.setIsDeleting({ isDeleting: true }), SearchConfigurationActions.sendDeleteSearchConfiguration()]),
    ),
  );

  public sendDeleteSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendDeleteSearchConfiguration),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([, { selectedCamera, selectedSearchConfigurationId }]) => {
        return this.searchConfigurationService
          .remove(selectedSearchConfigurationId, {
            locationId: selectedCamera.locationId,
            edgeId: selectedCamera.edgeId,
            cameraId: selectedCamera.edgeOnly.cameraId,
          })
          .pipe(
            switchMap(res => {
              return [SharedActions.setIsDeleting({ isDeleting: false }), SearchConfigurationActions.deleteSearchConfigurationSuccess()];
            }),
            catchError(response => {
              return [SharedActions.setIsDeleting({ isDeleting: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getCameraAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getCameraAssets),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([, { selectedCamera }]) => {
        return this.cameraService.get(selectedCamera.edgeId, selectedCamera.edgeOnly.cameraId)
          .pipe(
            switchMap(res => {
              return [
                SearchConfigurationActions.getCameraAssetsSuccess({
                  cameraAssets: res.items,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsDeleting({ isDeleting: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public deleteCameraAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.deleteCameraAssets),
      switchMap(({ cameraAsset }) => {
        return this.cameraService.delete(cameraAsset)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({
                  success: 'Asset has been deleted',
                }),
                SearchConfigurationActions.getCameraAssets(),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsDeleting({ isDeleting: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public notifyCameraAssetsUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.notifyCameraAssetsUpload),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ name }, { selectedCamera }]) => {
        return this.locationsService
          .notifyCameraAssetUploaded({
            locationId: selectedCamera.locationId,
            cameraId: selectedCamera.edgeOnly.cameraId,
            edgeId: selectedCamera.edgeId,
            filename: name,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({
                  success: 'Asset has been uploaded',
                }),
                CameraActions.startSendCameraAssetSubscribeToSessionStatus({
                  token: res.token.session,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsDeleting({ isDeleting: false }), this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getCameraDetailsManually$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getCameraDetailsManually),
      exhaustMap(({ manual, form, cameraId }) => [
        SharedActions.setIsSessionDataLoading({ isSessionDataLoading: true }),
        SearchConfigurationActions.sendGetCameraDetailsManually({
          manual,
          form,
          cameraId,
        }),
      ]),
    ),
  );

  public sendGetCameraDetailsManually$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendGetCameraDetailsManually),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState)), this.store$.pipe(select(state => state.cameraState))),
      switchMap(([{ manual, form, cameraId }, { selectedCamera }, camera]) => {
        if (!selectedCamera) {
          if (!cameraId) {
            return [];
          }
          selectedCamera = camera.entities[cameraId];
        }
        const edgeOnly = selectedCamera.edgeOnly;
        const cameraObj = {
          ...form,
          cameraId: edgeOnly.cameraId,
          profile: 0,
          width: 0,
          height: 0,
        };
        const cameraDetailsRequest: LocationModel.GetCameraDetailsRequest = {
          locationId: selectedCamera.locationId,
          edgeId: selectedCamera.edgeId,
          camera: cameraObj,
          create: !manual,
        };
        return this.edgeService.getCameraDetailsManually(cameraDetailsRequest)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.sendGetCameraDetailsManually,
                  params: {
                    msTimeout: 40000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );
  //

  public cameraUpdatePTZ$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraUpdatePTZ),
      exhaustMap(({ data }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraUpdatePTZ({
          data,
        }),
      ]),
    ),
  );

  public sendCameraUpdatePTZ$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraUpdatePTZ),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ data }, { selectedCamera }]) => {
        const updatedData: CameraUpdateRequest = {
          cameraId: selectedCamera.edgeOnly.cameraId,
          edgeId: selectedCamera.edgeId,
          locationId: selectedCamera.locationId,
          update: {
            ...data,
          },
        };
        return this.edgeService.cameraUpdatePTZ(updatedData)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.sendCameraUpdate,
                  params: {
                    msTimeout: 40000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response), SharedActions.setIsSaving({ isSaving: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public cameraAudioVideoUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraAudioVideoUpdate),
      exhaustMap(({ data }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraAudioVideoUpdate({
          data,
        }),
      ]),
    ),
  );

  public cameraHlsUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraHlsUpdate),
      exhaustMap(({ data }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraHlsUpdate({
          data,
        }),
      ]),
    ),
  );

  public cameraDetailsUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraDetailsUpdate),
      exhaustMap(({ data }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraDetailsUpdate({
          data,
        }),
      ]),
    ),
  );

  public sendCameraAudioVideoUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraAudioVideoUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ data }, { selectedCamera }]) => {
        const updatedData: CameraUpdateRequest = {
          cameraId: selectedCamera.edgeOnly.cameraId,
          edgeId: selectedCamera.edgeId,
          locationId: selectedCamera.locationId,
          update: {
            ...data,
          },
        };
        return this.edgeService.cameraAudioVideoUpdate(updatedData)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.sendCameraUpdate,
                  params: {
                    msTimeout: 40000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response), SharedActions.setIsSaving({ isSaving: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public sendCameraHlsUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraHlsUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ data }, { selectedCamera }]) => {
        const updatedData: CameraUpdateHlsRequest = {
          cameraId: selectedCamera.edgeOnly.cameraId,
          edgeId: selectedCamera.edgeId,
          locationId: selectedCamera.locationId,
          hlsConfig: {
            ...data,
          },
        };
        return this.edgeService.cameraHlsUpdate(updatedData)
          .pipe(
            switchMap(res => {
              const cloudOnly: CameraCloudOnly = {
                ...selectedCamera.cloudOnly,
                hlsConfig: {
                  ...selectedCamera.cloudOnly.hlsConfig,
                  ...data,
                },
              };

              return [
                CameraActions.UpdateLocationEdgeCameraCloudOnly({ cameraId: selectedCamera.edgeOnly.cameraId, cloudOnly }),
                SharedActions.showMessage({
                  success: 'HLS Configuration has been updated',
                }),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response), SharedActions.setIsSaving({ isSaving: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public sendCameraDetailsUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraDetailsUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ data }, { selectedCamera }]) => {
        const updatedData: CameraUpdateRequest = {
          cameraId: selectedCamera.edgeOnly.cameraId,
          edgeId: selectedCamera.edgeId,
          locationId: selectedCamera.locationId,
          update: {
            ...data,
          },
        };
        return this.edgeService.cameraDetailsUpdate(updatedData)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.sendCameraUpdate,
                  params: {
                    msTimeout: 40000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response), SharedActions.setIsSaving({ isSaving: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public cameraUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraUpdate),
      exhaustMap(({ data }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraUpdate({
          data,
        }),
      ]),
    ),
  );

  public sendCameraUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState)),
        this.store$.pipe(select(LocationSelectors.selectAllLocations))),
      switchMap(([{ data }, { selectedCamera }, locations]) => {
        const timezone = locations.find(location => location._id === selectedCamera.locationId).timezone;
        const updatedData: CameraUpdateRequest = {
          cameraId: selectedCamera.edgeOnly.cameraId,
          edgeId: selectedCamera.edgeId,
          locationId: selectedCamera.locationId,
          update: {
            ...data,
            timezone,
          },
        };
        return this.edgeService.cameraUpdate(updatedData)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.sendCameraUpdate,
                  params: {
                    msTimeout: 40000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );


  public cameraStorageUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraStorageUpdate),
      exhaustMap(({ data, camera }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraStorageUpdate({
          data,
          camera,
        }),
      ]),
    ),
  );

  public sendCameraStorageUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraStorageUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState)),
        this.store$.pipe(select(LocationSelectors.selectAllLocations))),
      switchMap(([{ data, camera }, { selectedCamera }, locations]) => {
        if (!selectedCamera) {
          selectedCamera = camera;
        }
        const timezone = locations.find(location => location._id === selectedCamera.locationId).timezone;
        const updatedData: CameraUpdateRequest = {
          cameraId: selectedCamera.edgeOnly.cameraId,
          edgeId: selectedCamera.edgeId,
          locationId: selectedCamera.locationId,
          update: {
            ...data,
            timezone,
          },
        };
        return this.edgeService.cameraStorageUpdate(updatedData)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.sendCameraUpdate,
                  params: {
                    msTimeout: 40000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public saveCameraSearchSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.saveCameraSearchSettings),
      exhaustMap(({ filters }) => [
        SharedActions.setIsSaving({ isSaving: true }),
        SearchConfigurationActions.sendCameraSearchSettings({ filters }),
      ]),
    ),
  );

  public sendCameraSearchSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraSearchSettings),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ filters }, { selectedCamera, cameraSearchSettings }]) => {
        if (!cameraSearchSettings?._id) {
          const data: CameraSearchSettings = {
            selectedCamera: {
              locationId: selectedCamera.locationId,
              edgeId: selectedCamera.edgeId,
              cameraId: selectedCamera.edgeOnly.cameraId,
            },
            filters,
          };
          return this.cameraSearchSettingsService.create(data)
            .pipe(
              switchMap(res => {
                return [
                  SharedActions.showMessage({ success: 'Camera Search Settings has been created' }),
                  SharedActions.setIsSaving({ isSaving: false }),
                ];
              }),
              catchError(response => {
                return [SharedActions.setIsSaving({ isSaving: false }), this.catchError(response)];
              }),
            );
        } else {
          const data: CameraSearchSettings = {
            selectedCamera: {
              locationId: selectedCamera.locationId,
              edgeId: selectedCamera.edgeId,
              cameraId: selectedCamera.edgeOnly.cameraId,
            },
            filters,
          };
          return this.cameraSearchSettingsService.update(data, cameraSearchSettings._id)
            .pipe(
              switchMap(res => {
                return [
                  SharedActions.showMessage({ success: 'Camera Search Settings has been updated' }),
                  SharedActions.setIsSaving({ isSaving: false }),
                ];
              }),
              catchError(response => {
                return [SharedActions.setIsSaving({ isSaving: false }), this.catchError(response)];
              }),
            );
        }
      }),
      share(),
    ),
  );

  public getCameraSearchSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getCameraSearchSettings),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([, { selectedCamera }]) => {
        return this.cameraSearchSettingsService
          .getAll([
            {
              locationId: selectedCamera.locationId,
              edgeId: selectedCamera.edgeId,
              cameraId: selectedCamera.edgeOnly.cameraId,
            },
          ])
          .pipe(
            switchMap(cameraSearchSettings => {
              return [SearchConfigurationActions.getCameraSearchSettingsSuccess({ cameraSearchSettings: cameraSearchSettings[0] })];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getCameraSearchSettingsMultiSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiSearchActions.setSelectedCameras),
      switchMap(({ selectedCameras, noReset }) => {
        if (selectedCameras.length) {
          const mappedCameras = selectedCameras?.map(camera => {
            return {
              locationId: camera?.locationId,
              edgeId: camera?.edgeId,
              cameraId: camera?.edgeOnly?.cameraId,
              markedIdx: camera?.markedIdx,
              zones: camera?.zones,
            };
          });
          return this.cameraSearchSettingsService.getAll(mappedCameras)
            .pipe(
              switchMap(cameraSearchSettings => {
                return [
                  MultiSearchActions.getCamerasSearchSettingsSuccess({
                    cameraSearchSettings: cameraSearchSettings,
                    camerasCountRequested: selectedCameras.length,
                  }),
                ];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        } else {
          return [SharedActions.doNothing()];
        }
      }),
      share(),
    ),
  );

  public startSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MultiSearchActions.setObjectSelection,
        MultiSearchActions.deleteObjectFromSelection,
        MultiSearchActions.deletePropertyFromSelection,
      ),
      withLatestFrom(this.store$.pipe(select(state => state.multiSearchState))),
      switchMap(([, { objectSelections, selectedCameras }]) => {
        const searchSelections: SearchSelection[] = objectSelections?.map(selection => {
          let properties = [];
          let searchSelectionCarProperty: SearchSelectionCarProperty = {};
          const propsKeys = Object.keys(selection.properties ?? {});

          if (selection.type === SearchObjectTypes.PERSON) {
            propsKeys.forEach(propKey => {
              let value = [];
              let colors = [];
              const props = selection.properties[propKey].props;
              switch (propKey) {
                case PersonSelectionFormFields.genderType:
                  const propsKeys = Object.keys(props);
                  propsKeys.forEach(_prop => {
                    if (props[_prop]) {
                      value.push(_prop);
                    }
                  });
                  break;
                case PersonSelectionFormFields.ageType:
                  const ageKeys = Object.keys(props);
                  ageKeys.forEach(_prop => {
                    if (props[_prop]) {
                      value = value.concat(ageTypeRadioValues[_prop]);
                    }
                  });
                  break;
                case PersonSelectionFormFields.footwearType:
                case PersonSelectionFormFields.hairType:
                  colors = (props['colors'] as string[]) ?? [];
                  break;
                case PersonSelectionFormFields.lowerbodyType:
                case PersonSelectionFormFields.upperbodyType:
                  value = (props['type'] as string[]) ?? [];
                  colors = (props['colors'] as string[]) ?? [];
                  break;
                case PersonSelectionFormFields.accessoryType:
                case PersonSelectionFormFields.carryingType:
                  value = (props['type'] as string[]) ?? [];
                  break;
                case PersonSelectionFormFields.protectiveGear:
                  value = (props['type'] as string[]) ?? [];
                  break;
              }
              const prop: SearchSelectionPersonProperty = {
                name: propKey,
                enabled: selection.properties[propKey].enabled,
                operator: selection.properties[propKey].operator,
                value,
                colors,
              };
              properties.push(prop);
            });
          } else if (selection.type === SearchObjectTypes.VEHICLE) {
            const colors = selection.properties[VehicleSelectionFormFields.colors];
            const model = selection.properties[VehicleSelectionFormFields.model];
            const make = selection.properties[VehicleSelectionFormFields.make];
            const types = selection.properties[VehicleSelectionFormFields.type];
            const additionalProperties = selection.properties[VehicleSelectionFormFields.additionalProperty].props;
            const plate = additionalProperties['plate'] as string;
            const region = additionalProperties['region'] as string;

            if (selection.properties[VehicleSelectionFormFields.additionalProperty].enabled) {
              if (plate) {
                searchSelectionCarProperty.plate = plate;
              }
              if (region) {
                searchSelectionCarProperty.region = region;
              }
            }
            if (colors.enabled && colors.value) {
              searchSelectionCarProperty.colors = selection.properties[VehicleSelectionFormFields.colors].value;
            }
            if (model.enabled && model.value) {
              searchSelectionCarProperty.model = selection.properties[VehicleSelectionFormFields.model].value;
            }
            if (make.enabled && make.value) {
              searchSelectionCarProperty.make = selection.properties[VehicleSelectionFormFields.make].value;
            }
            if (types.enabled && types.value) {
              searchSelectionCarProperty.type = this.convertCarType(selection.properties[VehicleSelectionFormFields.type].value);
            }
          } else if (selection.type === SearchObjectTypes.PET) {
          }

          const result = {
            ...selection,
            groupId: selection.groupIdCollapsed ? selection.groupId : [],
            properties: selection.type === SearchObjectTypes.PERSON ? properties : searchSelectionCarProperty,
          };
          if (selection.type === SearchObjectTypes.PET) {
            delete result.properties;
          }
          return result;
        });

        return [MultiSearchActions.setObjectSelectionFormatted({ objectSelectionsFormatted: searchSelections })];
      }),
      share(),
    ),
  );

  public cameraVpnUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraVpnUpdate),
      exhaustMap(({ autoConfigure }) => [
        SharedActions.setIsSaving({ isSaving: true }), // will be canceled in shared effects
        SearchConfigurationActions.sendCameraVpnUpdate({ autoConfigure }),
      ]),
    ),
  );

  public sendCameraVpnUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraVpnUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ autoConfigure }, { selectedCamera, vpnConfiguration }]) => {
        const cameraId = selectedCamera.edgeOnly.cameraId;
        const edgeId = selectedCamera.edgeId;
        const locationId = selectedCamera.locationId;
        return this.searchConfigurationService.updateVpn(locationId, edgeId, cameraId, autoConfigure ? undefined : vpnConfiguration, autoConfigure)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.updateCameraVpnConfiguration,
                  params: {
                    msTimeout: 60000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public cameraVpnReboot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraVpnReboot),
      exhaustMap(({ restartStreamer }) => [
        SearchConfigurationActions.sendCameraVpnReboot({ restartStreamer }),
      ]),
    ),
  );

  public sendCameraVpnReboot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraVpnReboot),
      withLatestFrom(this.store$.pipe(select(state => state.cameraEditState))),
      switchMap(([{ restartStreamer }, { selectedCamera }]) => {
        const cameraId = selectedCamera.edgeOnly.cameraId;
        const edgeId = selectedCamera.edgeId;
        const locationId = selectedCamera.locationId;
        return this.searchConfigurationService.vpnReboot(locationId, edgeId, cameraId, restartStreamer)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.vpnReboot,
                  params: {
                    msTimeout: 60000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public cameraVpnTest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.cameraVpnTest),
      exhaustMap(({ width, height, test }) => [
        SearchConfigurationActions.sendCameraVpnTest({ width, height, test }),
      ]),
    ),
  );

  public sendCameraVpnTest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.sendCameraVpnTest),
      withLatestFrom(
        this.store$.pipe(select(state => state.cameraEditState)),
        this.store$.pipe(select(state => state.edgeEditState)),
      ),
      switchMap(([{ width, height, test }, { selectedCamera }, { selectedEdge }]) => {
        let op: Observable<any>;
        if (test) {
          const edgeId = selectedEdge.edgeId;
          const locationId = selectedEdge.locationId;
          op = this.searchConfigurationService.cameraTest(locationId, edgeId, width, height, test);
        } else {
          const cameraId = selectedCamera.edgeOnly.cameraId;
          const edgeId = selectedCamera.edgeId;
          const locationId = selectedCamera.locationId;
          op = this.searchConfigurationService.vpnTest(locationId, edgeId, cameraId, width, height);
        }
        return op
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.vpnTest,
                  params: {
                    msTimeout: 60000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getHasProtectiveGear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getHasProtectiveGear),
      switchMap(() => {
        return this.searchConfigurationService.hasProtectiveGear()
          .pipe(
            switchMap(res => {
              return [
                SearchConfigurationActions.setOrgProtectiveGear({ orgProtectiveGear: res }),
              ];
            }),
            catchError(response => {
              return [SearchConfigurationActions.setOrgProtectiveGear({ orgProtectiveGear: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public getHasOrgForklift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getHasOrgForklift),
      switchMap(() => {
        return this.searchConfigurationService.hasForklift()
          .pipe(
            switchMap(res => {
              return [
                SearchConfigurationActions.setOrgForklift({ orgForklift: res }),
              ];
            }),
            catchError(response => {
              return [SearchConfigurationActions.setOrgForklift({ orgForklift: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public getHasShoppingCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchConfigurationActions.getHasShoppingCart),
      switchMap(() => {
        return this.searchConfigurationService.hasShoppingCart()
          .pipe(
            switchMap(res => {
              return [
                SearchConfigurationActions.setOrgShoppingCart({ orgShoppingCart: res }),
              ];
            }),
            catchError(response => {
              return [SearchConfigurationActions.setOrgShoppingCart({ orgShoppingCart: false })];
            }),
          );
      }),
      share(),
    ),
  );

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private searchConfigurationService: SearchConfigurationService,
    private cameraService: CameraService,
    private locationsService: LocationsService,
    private edgeService: EdgeService,
    private cameraSearchSettingsService: CameraSearchSettingsService,
    private searchService: SearchService,
    private cameraThumbnailsService: CamerasThumbnailsService,
  ) {
  }

  private convertCarType(type: string[]) {
    const result: string[] = [...type];
    const isCar = type.includes('car');
    const isTruck = type.includes('truck');

    if (isCar) {
      result.push('sedan', 'suv', 'van');
      result.splice(result.indexOf('car'), 1);
    }

    if (isTruck) {
      // result.push('pickup truck', 'big truck');
      result.push('big truck');
      result.splice(result.indexOf('truck'), 1);
    }


    return result;
  }
}
