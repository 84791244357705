<mat-dialog-content>
  <div fxLayout="column" class="h-100" fxFlexFill fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
      <div>{{ data.camera.name }}</div>
      <div class="c-p" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div #wrapper class="w-100" fxFlexFill>
      <app-wall-camera [allowQuality]="true" #wallCamera [camera]="data.camera"></app-wall-camera>
    </div>
  </div>
</mat-dialog-content>
