import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of, share } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from '../app.state';
import * as OrganizationActions from '@states/organization/organization.actions';
import * as SharedActions from '@states/shared/shared.actions';
import * as UserSettingsActions from '@states/user-settings/user-settings.actions';
import { DevTeamActions } from '@states/dev-team/dev-team.action-types';
import * as InviteActions from '@states/invite/invite.actions';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { UserSelectors } from '@states/user/user.selector-types';
import { UserActions } from '@states/user/user.action-types';
import { UserService } from '../../user/user.service';
import { Auth0Service } from '../../authentication/auth0.service';
import { isEmailVerificationEmailRequired, isIpRulesRequired, isUserBlocked } from '../../helpers/error.helpers';
import { AuthResponseFromUrl } from '@enums/shared.enum';
import { auth0ErrorHandler, calculateExpiryDate, JsonParseIfValid, parseJwt } from '../../helpers/common.helpers';
import { AppUser } from '../../user/user.model';
import { Auth0 } from '../../authentication/auth0.model';
import { UserSettingsService } from '../../development/user-settings.service';
import { UtilsService } from '../../edge/utils.service';

declare var smartlook: any;

@Injectable()
export class UserEffects {

  SessionIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SessionIdentity),
      withLatestFrom(this.store.pipe(select(UserSelectors.userProfile))),
      filter(([action, user]) => {
        return !!user._id;
      }),
      switchMap(([_, user]) => {

        const trace = {
          '_id': user._id,
          'authProviderId': user.authProviderId,
          'orgId': user.activeOrg,
          'environment': environment.env,
        };

        if (environment.env === 'production') {

          if (typeof smartlook === 'function') {
            smartlook('identify', user.email, trace);
          }
        }

        if (environment.env === 'production' || environment.env === 'staging-hosted') {
          Sentry.setUser(trace);
        }

        return [];
      }),
      catchError(err => {
        return of(SharedActions.consoleMessage({ warning: 'failed to load smartlook' }));

      }),
    ),
  );


  CreateOrGetUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.CreateOrGetUserProfile),
      withLatestFrom(this.store.pipe(select(UserSelectors.isProfileLoaded))),
      filter(([action, isProfileLoaded]) => {
        return !isProfileLoaded;
      }),
      map(([action, isProfileLoaded]) => action),
      concatMap(action => {
        return this.userService.createOrGetUserProfile(action.userId, action.accessToken)
          .pipe(
            switchMap(res => {

              if (environment.env === 'production') {

                if (typeof smartlook === 'function') {
                  smartlook('identify', res.email, {
                    '_id': res._id,
                    'authProviderId': res.authProviderId,
                    // organizationId
                  });
                }
              }

              if (environment.env === 'production' || environment.env === 'staging-hosted') {
                Sentry.setUser({
                  'email': res.email,
                  '_id': res._id,
                  'authProviderId': res.authProviderId,
                });
              }

              const actions: Action[] = [
                UserActions.CreateOrGetUserProfileSuccess({
                  payload: res,
                }),
                // once user received
                OrganizationActions.getActiveOrganization(),
                OrganizationActions.getUserOrganizations(),
                OrganizationActions.getOrganizationMaxRetentionDays(),
                UserSettingsActions.getMe(),
                // WorkspaceActions.getWorkspaces(),
                // WorkspaceActions.getFavorites(),
                InviteActions.getActiveInvites(),
              ];
              if (res.roles.includes('developer') || res.roles.includes('dev-team')) {
                actions.push(DevTeamActions.getOrganizations());
              }
              return actions;
            }),
            catchError((err: HttpErrorResponse | Error) => {

              let msg = err instanceof HttpErrorResponse ? (err as HttpErrorResponse)?.error?.message : (err as Error)?.message;
              this.authenticationService.logout(msg);

              return of(
                UserActions.CreateOrGetUserProfileFail({
                  message: msg || 'Error occurred',
                }),
              );

            }),
          );
      }),
      catchError(err => {

        let msg = err instanceof HttpErrorResponse ? (err as HttpErrorResponse)?.error?.message : (err as Error)?.message;
        this.authenticationService.logout(msg);

        return of(UserActions.CreateOrGetUserProfileFail({ message: err }));
      }),
    ),
  );

  UpdateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UpdateUserProfile),
      map(action => action),
      concatMap(action => {
        return this.userService.updateUserProfile(action.userId, action.data)
          .pipe(
            map(res => {
              return UserActions.UpdateUserProfileSuccess({ payload: res });
            }),
            catchError((err: HttpErrorResponse) => {
              return of(
                UserActions.UpdateUserProfileFail({
                  message: err?.error?.message || 'Error occurred',
                }),
              );
            }),
          );
      }),
      catchError(err => {
        return of(UserActions.UpdateUserProfileFail({ message: err }));
      }),
    ),
  );

  /**
   * Need to merge with method above
   */
  public updateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      withLatestFrom(this.store.select(UserSelectors.userProfile)),
      switchMap(([, user]) => {
        return this.userService
          .updateUserProfile(user.authProviderId, {
            timezone: user.timezone,
            phone: user.phone,
          })
          .pipe(
            switchMap(res => {
              return [
                UserActions.UpdateUserProfileSuccess({ payload: res }),
                SharedActions.showMessage({
                  success: 'Profile has been updated',
                }),
              ];
            }),
          );
      }),
      catchError(err => {
        return of(UserActions.UpdateUserProfileFail({ message: err }));
      }),
    ),
  );

  public changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changePassword),
      exhaustMap(({ password, token }) =>
        [
          SharedActions.setIsSaving({ isSaving: true }),
          UserActions.changePasswordServer({ password, token }),
        ],
      ),
      share(),
    ),
  );

  public changePasswordServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changePasswordServer),
      switchMap(({ password, token }) =>
        this.userService.changePassword({ password, token })
          .pipe(
            switchMap(() => {
              return [
                UserActions.changePasswordSuccess(),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(err => {
              let errorDescription = auth0ErrorHandler(err);
              return [
                UserActions.changePasswordFail({ error: errorDescription }),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
          ),
      ),
      share(),
    ),
  );


  public resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.resetPassword),
      exhaustMap(({ email }) =>
        [
          SharedActions.setIsSaving({ isSaving: true }),
          UserActions.resetPasswordServer({ email }),
        ],
      ),
      share(),
    ),
  );

  public resetPasswordServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.resetPasswordServer),
      switchMap(({ email }) =>
        this.userService.resetPassword({ email })
          .pipe(
            switchMap(() => {
              return [
                UserActions.resetPasswordSuccess(),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(err => {
              let errorDescription = auth0ErrorHandler(err);
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                UserActions.resetPasswordFail({ error: errorDescription }),
              ];
            }),
          ),
      ),
      share(),
    ),
  );

  public checkResetTokenIsValid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.checkResetTokenIsValid),
      exhaustMap(({ token }) =>
        [
          SharedActions.setIsLoading({ isLoading: true }),
          UserActions.checkResetTokenIsValidServer({ token }),
        ],
      ),
      share(),
    ),
  );

  public checkResetTokenIsValidServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.checkResetTokenIsValidServer),
      switchMap(({ token }) =>
        this.userService.checkTokenIsValid({ token })
          .pipe(
            switchMap(() => {
              return [
                UserActions.checkResetTokenIsValidSuccess(),
                SharedActions.setIsLoading({ isLoading: false }),
              ];
            }),
            catchError(err => {
              return [
                SharedActions.setIsLoading({ isLoading: false }),
                UserActions.checkResetTokenIsValidFail({ error: err?.error?.message }),
              ];
            }),
          ),
      ),
      share(),
    ),
  );


  public getUserMfaStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserMfaStatus),
      switchMap(() =>
        this.userService.getUserMfaStatus()
          .pipe(
            switchMap((res) => {
              return of(UserActions.getUserMfaStatusSuccess({ res }));
            }),
            catchError(err => [SharedActions.consoleMessage({ error: err })]),
          ),
      ),
      share(),
    ),
  );

  public resetPasswordSecured = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.resetPasswordSecured),
      switchMap(() =>
        this.userService.resetPasswordSecured()
          .pipe(
            switchMap(() => {
              return [
                SharedActions.showMessage({ success: 'We have sent you email to reset your password' }),
                UserActions.resetPasswordSuccess(),
              ];
            }),
            catchError(err => {
              return [
                UserActions.resetPasswordFail({ error: err?.error?.message }),
              ];
            }),
          ),
      ),
      share(),
    ),
  );


  public startLoginProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.startLoginProcess),
      switchMap(({ email, password, subUser }) => [
        UserActions.setIsLoginLoading({ isLoginLoading: true }),
        UserActions.loginRequest({ email, password, subUser }),
        ],
      ),
      share(),
    ),
  );


  public loginRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginRequest),
      switchMap(({ email, password, subUser }) =>
        this.userService.login(email, password, subUser)
          .pipe(
            switchMap((authResult) => {
              /**
               * Qr code come in result ONLY if user did not connect google authenticator yet.
               * Otherwise, it will be null.
               */
              if (authResult.mfa) {
                return [
                  UserActions.setIsLoginLoading({ isLoginLoading: false }),
                  UserActions.loginRequestSuccessMfaRequired({ qrCode: authResult.qrCode, mfaToken: authResult.mfaToken, code: authResult.code, authProviderId: authResult.authProviderId }),
                ];
              } else {
                const transformedAuthResult: Auth0.AuthenticationResponse = {
                  expiresIn: undefined, idTokenPayload: undefined, state: '', tokenType: '', ...authResult, idToken: authResult.id_token, accessToken: authResult.access_token,
                  refreshToken: authResult.refresh_token,
                };
                this.auth0Service.afterTokenReceivedSuccess(transformedAuthResult);
              }

              return [
                UserActions.loginRequestSuccess(),
              ];
            }),
            catchError(errRes => {
              const { type, description } = this.loginHttpErrorHandler(errRes);
              return [
                UserActions.setIsLoginLoading({ isLoginLoading: false }),
                UserActions.loginRequestFail({ errorType: type, description: description }),
              ];
            }),
          ),
      ),
      share(),
    ),
  );


  public startSignUpProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.startSignUpProcess),
      switchMap(({ user }) => [
        UserActions.setIsLoginLoading({ isLoginLoading: true }),
          UserActions.signUpRequest({ user }),
        ],
      ),
      share(),
    ),
  );


  public signUpRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.signUpRequest),
      switchMap(({ user }) =>
        this.userService.signUpUser(user)
          .pipe(
            switchMap((authResult) => {
              /**
               * Qr code come in result ONLY if user did not connect google authenticator yet.
               * Otherwise, it will be null.
               */
              const actions: Action[] = [
                SharedActions.showMessage({ success: 'User is successfully created' }),
                UserActions.setIsLoginLoading({ isLoginLoading: false }),
              ];
              if (authResult.mfa) {
                actions.push(
                  UserActions.loginRequestSuccessMfaRequired({ qrCode: authResult.qrCode, mfaToken: authResult.mfaToken, code: authResult.code, authProviderId: authResult.authProviderId }),
                );
              } else {
                const transformedAuthResult: Auth0.AuthenticationResponse = {
                  expiresIn: undefined, idTokenPayload: undefined, state: '', tokenType: '', ...authResult, idToken: authResult.id_token, accessToken: authResult.access_token,
                  refreshToken: authResult.refresh_token,
                };
                this.auth0Service.afterTokenReceivedSuccess(transformedAuthResult);
                actions.push(
                  UserActions.loginRequestSuccess(),
                );
              }

              return actions;
            }),
            catchError(err => {
              return [
                UserActions.setIsLoginLoading({ isLoginLoading: false }),
                UserActions.signUpRequestFail({ description: err?.error?.message ?? 'Unknown error' }),
              ];
            }),
          ),
      ),
      share(),
    ),
  );

  public getSignUpInviteById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getSignUpInviteById),
      switchMap(({ invitedId }) =>
        this.userService.getInvite(invitedId)
          .pipe(
            switchMap((res) => {
              return [
                UserActions.getSignUpInviteByIdSuccess({ invite: res }),
              ];
            }),
            catchError(err => {
              return [
                UserActions.setIsLoginLoading({ isLoginLoading: false }),
                UserActions.signUpRequestFail({ description: err?.error?.message ?? 'Unknown error' }),
              ];
            }),
          ),
      ),
      share(),
    ),
  );

  public startLoginSubUserProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.startLoginSubUserProcess),
      switchMap(({ email, password }) => [
          UserActions.setIsLoginLoading({ isLoginLoading: true }),
          UserActions.subUserLoginRequest({ email, password }),
        ],
      ),
      share(),
    ),
  );


  public subUserLoginRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.subUserLoginRequest),
      switchMap(({ email, password }) =>
        this.userService.login(email, password)
          .pipe(
            switchMap((res) => {

              /**
               * Qr code come in result ONLY if user did not connect google authenticator yet.
               * Otherwise, it will be null.
               */
              if (res.mfa) {
                return [
                  UserActions.setIsLoginLoading({ isLoginLoading: false }),
                  UserActions.loginRequestSuccessMfaRequired({ qrCode: res.qrCode, mfaToken: res.mfaToken, code: res.code, authProviderId: res.authProviderId }),
                ];
              } else {
                const parsedToken = parseJwt(res.id_token);
                const subUser: AppUser.SubUser = { email: parsedToken.userEmail, id: parsedToken.userId, authProviderId: parsedToken.authProviderId };
                return [
                  UserActions.subUserLoginRequestSuccess({ subUser: subUser }),
                ];
              }
            }),
            catchError(err => {
              const { type, description } = this.loginHttpErrorHandler(err);
              return [
                UserActions.setIsLoginLoading({ isLoginLoading: false }),
                UserActions.subUserLoginRequestFail({ errorType: type, description: description }),
              ];
            }),
          ),
      ),
      share(),
    ),
  );


  public startChangeEmailProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.startChangeEmailProcess),
      switchMap(({ email }) => [
          SharedActions.setIsSaving({ isSaving: true }),
          UserActions.changeEmailRequest({ email }),
        ],
      ),
      share(),
    ),
  );

  public changeEmailRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeEmailRequest),
      switchMap(({ email }) =>
        this.userService.changeEmailRequest(email)
          .pipe(
            switchMap((res) => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                UserActions.changeEmailRequestSuccess({ id: res._id }),
                SharedActions.showMessage({ success: 'Confirmation code has been sent to your email address' }),
              ];
            }),
            catchError(err => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                UserActions.changeEmailRequestFail({ error: err?.error?.message ?? 'Unknown error' }),
                SharedActions.showMessage({ error: err?.error?.message ?? 'Unknown error' }),
              ];
            }),
          ),
      ),
      share(),
    ),
  );

  public renewToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.renewToken),
      switchMap(({ orgId }) =>
        this.auth0Service.renewToken(orgId)
          .pipe(
            switchMap((res) => {

              const idTokenPayload = parseJwt(res.id_token);
              const expiresAt = idTokenPayload.exp - idTokenPayload.iat;

              const transformedAuthResult: Auth0.AuthenticationResponse = {
                authProviderId: '',
                expiresAt: calculateExpiryDate(expiresAt),
                scope: '',
                expiresIn: res.expires_in,
                idTokenPayload: idTokenPayload,
                state: res.state,
                tokenType: res.token_type,
                idToken: res.id_token,
                accessToken: res.access_token,
                refreshToken: res.refresh_token,
              };
              const authData: Partial<Auth0.AuthenticationResponse> = {
                ...transformedAuthResult,
                authProviderId: idTokenPayload.sub,
              };
              this.authenticationService.storeSession({
                idToken: authData.idToken!,
                accessToken: authData.accessToken!,
                expiresAt: authData.expiresAt!,
                authProviderId: authData.authProviderId!,
                refreshToken: authData.refreshToken,
              });
              return [
                UserActions.renewTokenSuccess(),
              ];
            }),
            catchError(err => {
              return [
                UserActions.renewTokenFail({ error: err?.error?.message ?? 'Unknown error' }),
              ];
            }),
          ),
      ),
      share(),
    ),
  );


  public startChangePhoneProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.startChangePhoneProcess),
      switchMap(({ phone }) => [
          SharedActions.setIsSaving({ isSaving: true }),
          UserActions.changePhoneRequest({ phone }),
        ],
      ),
      share(),
    ),
  );

  public changePhoneProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changePhoneRequest),
      switchMap(({ phone }) =>
        this.userSettingsService.verifyPhone(phone)
          .pipe(
            switchMap(res => {
              return [
                UserActions.changePhoneRequestSuccess({ id: res['_id'] }),
                SharedActions.showMessage({ success: 'SMS with code sent' }),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(err => [
              UserActions.changePhoneRequestFail({ error: this.utilsService.errMessage(err) }),
              SharedActions.showMessage({ error: this.utilsService.errMessage(err) })]),
          ),
      ),
      share(),
    ),
  );


  public startUpdateUserProcessV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.startUpdateUserProcess),
      exhaustMap(({ user }) => [
        SharedActions.setIsSaving({ isSaving: true }),
        UserActions.updateUserRequest({ user }),
      ]),
    ),
  );


  public updateUserRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserRequest),
      withLatestFrom(this.store.select(UserSelectors.userProfile)),
      switchMap(([{ user }, userProfile]) => {
          return this.userService.updateUserProfile(userProfile.authProviderId, user)
            .pipe(
              switchMap((res) => {
                return [
                  //todo consult with Edan.
                  UserActions.CreateOrGetUserProfileSuccess({ payload: { ...userProfile, ...user } }),
                  SharedActions.setIsSaving({ isSaving: false }),
                  SharedActions.showMessage({ success: 'Data has been updated' }),
                ];
              }),
              catchError(err => [
                SharedActions.showMessage({ error: this.utilsService.errMessage(err) }),
                SharedActions.setIsSaving({ isSaving: false })]),
            );
        },
      ),
      share(),
    ),
  );


  public startSubmit2FaCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.startSubmit2FaCode),
      exhaustMap(({ mfaToken, code, isSubUser, authProviderId, securityCode }) => [
        UserActions.setIsLoginLoading({ isLoginLoading: true }),
        UserActions.submit2FaCodeAndLogin({ code, mfaToken, isSubUser, authProviderId, securityCode }),
      ]),
    ),
  );


  public submit2FaCodeAndLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.submit2FaCodeAndLogin),
      switchMap(({ code, mfaToken, isSubUser, authProviderId, securityCode }) => {
        return this.userService.submitMfaCodeAndLogin(code, mfaToken, authProviderId, securityCode)
            .pipe(
              switchMap((res) => {

                if (isSubUser) {
                  const parsedToken = parseJwt(res.id_token);
                  const subUser: AppUser.SubUser = { email: parsedToken.userEmail, id: parsedToken.userId, authProviderId: parsedToken.authProviderId };
                  return [
                    UserActions.subUserLoginRequestSuccess({ subUser: subUser }),
                  ];
                } else {
                  const transformedAuthResult: Auth0.AuthenticationResponse = {
                    expiresIn: undefined, idTokenPayload: undefined, state: '', tokenType: '', ...res, idToken: res.id_token, accessToken: res.access_token,
                    refreshToken: res.refresh_token,
                  };
                  this.auth0Service.afterTokenReceivedSuccess(transformedAuthResult);
                  return [
                    UserActions.loginRequestSuccess(),
                  ];
                }
              }),
              catchError(err => {

                const { type, description } = this.mfaCodeHttpErrorHandler(err);
                return [
                  UserActions.setIsLoginLoading({ isLoginLoading: false }),
                  UserActions.loginRequestFail({ errorType: type, description: description }),
                ];
              }),
            );
        },
      ),
      share(),
    ),
  );


  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private userService: UserService,
              private authenticationService: AuthenticationService,
              private auth0Service: Auth0Service,
              private userSettingsService: UserSettingsService,
              private utilsService: UtilsService) {
  }

  private loginHttpErrorHandler(errRes: any): { type: AuthResponseFromUrl, description: string, data?: any } {
    const error = JsonParseIfValid<{
      error_description: string,
      error: string
    }>(errRes?.error?.message ?? {});
    let errType: AuthResponseFromUrl = AuthResponseFromUrl.WrongPassword;
    let errDescription = 'Unknown error';
    if (error) {
      const errorResponse = error;
      errDescription = errorResponse.error_description;

      if (!!isEmailVerificationEmailRequired(errorResponse.error, errorResponse.error_description)) {
        errType = AuthResponseFromUrl.EmailVerificationRequired;
      }
      if (!!isIpRulesRequired(errorResponse.error, errorResponse.error_description)) {
        errType = AuthResponseFromUrl.IpRulesRequired;
      }
      if (!!isUserBlocked(errorResponse.error, errorResponse.error_description)) {
        errType = AuthResponseFromUrl.UserBlocked;
        errDescription = 'account is blocked - please contact support@lumana.ai';
      }
    }

    return { type: errType, description: errDescription };
  }

  private mfaCodeHttpErrorHandler(errRes: any): { type: AuthResponseFromUrl, description: string, data?: any } {
    const error = JsonParseIfValid<{
      error_description: string,
      error: string
    }>(errRes?.error?.message ?? {});

    let errType: AuthResponseFromUrl = AuthResponseFromUrl.MFAWrongCode;
    let errDescription = 'Mfa code is incorrect';

    return { type: errType, description: errDescription };
  }
}
