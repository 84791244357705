import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LiveStreamModels } from '@models/live-stream.model';
import { MatSelect } from '@angular/material/select';

export type Resolution = LiveStreamModels.StreamResolution;

@Component({
  selector: 'ui-quality-selector-v2',
  templateUrl: './quality-selector-v2.component.html',
  styleUrls: ['./quality-selector-v2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QualitySelectorV2Component),
      multi: true,
    },
  ],
})
export class QualitySelectorV2Component implements OnInit {

  @ViewChild('selector')
  selector: MatSelect;

  @Output()
  qualityChanged = new EventEmitter<Resolution>();

  @Input()
  scale = true;

  @Input() enableAuto = false;
  @Input() enableSQ = true;
  @Input() disableMQ = false;
  @Input() isControl = false;

  @Input()
  public value: Resolution;

  public Resolution = LiveStreamModels.StreamResolution;

  public loading = false;

  constructor() {
  }

  ngOnInit(): void {
    //if value is not set check scale
    if (isNaN(this.value)) {
      this.value = this.Resolution.AUTO;
    }
  }

  changeQuality(quality: Resolution) {
    this.loading = true;
    this.value = quality;
    this.qualityChanged.emit(quality);
    this.onChange(this.value);
    this.onTouched();
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public writeValue(val: Resolution): void {
    this.value = val;
  }

  private onChange = (value: Resolution) => {
  };
  private onTouched = () => {
  };

}
