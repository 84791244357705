<div fxLayout="column" class="account-settings">
  <div fxLayout="column">
    <div class="account-section" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" class="left-block">
        <span class="label-title">Login settings</span>
        <span class="label-subtitle"> Enable multi factor authentication </span>
      </div>

      <div fxLayout="column" class="right-block">
        <div class="input-field" fxLayout="column">
          <mat-slide-toggle color="primary" [(ngModel)]="settings.password.isMfaEnabled" (ngModelChange)="save()"> </mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="account-section" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" class="left-block">
        <span class="label-title">Sign In</span>
        <span class="label-subtitle"> Enable stay signed in </span>
      </div>

      <div fxLayout="column" class="right-block">
        <div class="input-field" fxLayout="column">
          <mat-slide-toggle color="primary" [(ngModel)]="settings.account.staySignedIn" (ngModelChange)="save()"> </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>

  <ng-container
    *ngIf="(this.user$ | async)?.email && (this.user$ | async)?.identities[0]?.connection === 'Username-Password-Authentication'"
  >
    <div class="account-section" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" class="left-block">
        <span class="label-title">Reset Password</span>
        <span class="label-subtitle"> Protect your account by settings a strong password </span>
      </div>
      <div fxLayout="column" class="right-block">
        <div class="input-field" fxLayout="column">
          <ui-button text="Reset" colorBgr="#4367F6" colorText="white" (clickAction)="resetPassword()"> </ui-button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
