import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertV2Document } from '@models/alerts-v2.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { UiLocationSelectItem } from '@models/ui.model';
import { SelectedCamera } from '@models/alert-events.model';
import { EdgeCamera } from '../../../../cameras/camera.model';
import { AlertEventSelectionResult } from '../alert-row/alert-row.component';
import { WallModelV2 } from '@models/wall.model';
import WallPositionEvent = WallModelV2.WallPositionEvent;

@Component({
  selector: 'app-alert-selector',
  templateUrl: './alert-selector.component.html',
  styleUrls: ['./alert-selector.component.scss'],
})
export class AlertSelectorComponent {

  @Input() byLocationEnabled: boolean = false;
  @Input() availableAlerts: AlertV2Document[];
  @Input() selectEventsLookup: Dictionary<AlertV2Document>;
  @Input() allCamerasByLocation: UiLocationSelectItem[];
  @Input() selectedCameras: { [cameraId: string]: SelectedCamera } = {};
  @Input() selectedAlertEventsV2: { [eventId: string]: WallPositionEvent[] } = {};
  @Input() selectCamerasLookup: Dictionary<EdgeCamera.CameraItem> = {};


  @Output() onAlertSelected: EventEmitter<AlertEventSelectionResult> = new EventEmitter<AlertEventSelectionResult>();
  @Output() onCameraSelected: EventEmitter<SelectedCamera> = new EventEmitter<SelectedCamera>();
  @Output() onLocationSelected: EventEmitter<{ location: UiLocationSelectItem, selected: boolean }> = new EventEmitter<{ location: UiLocationSelectItem, selected: boolean }>();

}
