import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WallV2State } from '@states/wall-v2/wall-v2.reducer';
import { Dictionary } from '@ngrx/entity/src/models';
import { AlertV2Document } from '@models/alerts-v2.model';
import { sortArrByField } from '../../../helpers/common.helpers';

const selectWallV2State = createFeatureSelector<WallV2State>('wallV2State');

export const selectInitialLoaded = createSelector(selectWallV2State, ({ initialLoaded }: WallV2State) => initialLoaded);
export const selectNotEmpty = createSelector(selectWallV2State, ({ notEmpty }: WallV2State) => notEmpty);

export const selectCurrentSet = createSelector(selectWallV2State, ({ selectedWall, selectedSetIndex }: WallV2State) => selectedWall?.sets[selectedSetIndex]);
export const selectWallSets = createSelector(selectWallV2State, ({ selectedWall }: WallV2State) => selectedWall?.sets);
export const selectSelectedSetIndex = createSelector(selectWallV2State, ({ selectedSetIndex }: WallV2State) => selectedSetIndex);
export const selectWallSettings = createSelector(selectWallV2State, ({ selectedWall }: WallV2State) => selectedWall?.settings);
export const selectWallTileSettings = createSelector(selectWallV2State, ({ selectedWall }: WallV2State) => selectedWall?.settings?.tileSettings);
export const selectWallAlertSettings = createSelector(selectWallV2State, ({ selectedWall }: WallV2State) => selectedWall?.settings?.alertSettings);

export const selectWalls = createSelector(selectWallV2State, ({ entities }: WallV2State) => entities);
export const selectSelectedWall = createSelector(selectWallV2State, ({ selectedWall }: WallV2State) => selectedWall);

export const selectTableAlerts = createSelector(selectWallV2State, ({ tableAlerts }: WallV2State) => {
  const result = Object.values(tableAlerts) ?? [];
  return sortArrByField(result, 'timestamp', 'desc');
});
export const selectAlertByTileId = (tile: number) => createSelector(selectWallV2State, ({ tilesAlerts }: WallV2State) => {
  return tilesAlerts[tile] ?? null;
});

export const selectIsSaving = createSelector(selectWallV2State, ({ isSaving }: WallV2State) => isSaving);
export const selectWallsToRemove = createSelector(selectWallV2State, ({ wallsToRemove }: WallV2State) => wallsToRemove);

export const selectAlertEvents = createSelector(selectWallV2State, ({ alertEvents }: WallV2State) => alertEvents);
export const selectAlertEventsIds = createSelector(selectWallV2State, ({ alertEvents }: WallV2State) => {
  const map: Dictionary<AlertV2Document> = {};
  alertEvents.forEach(alert => map[alert._id] = alert);
  return map;
});

export const selectHasWallAlert = createSelector(selectWallV2State, ({ selectedWall }: WallV2State) => selectedWall?.sets.some(set => set.tiles.some(tile => tile.events?.length)));
export const selectIsSelectedWallRemoving = createSelector(selectWallV2State, ({ wallsToRemove, selectedWall }: WallV2State) => {
  if (!!selectedWall && '_id' in selectedWall) {
    return wallsToRemove[selectedWall._id];
  }
  return false;
});

export const selectCameraEventIdEventMap = createSelector(selectWallV2State, ({ cameraEventIdEventMap }: WallV2State) => cameraEventIdEventMap);
export const selectIsMuted = createSelector(selectWallV2State, ({ isMuted }: WallV2State) => isMuted);
export const selectTableAlertsFilters = createSelector(selectWallV2State, ({ tableAlertsFilters }: WallV2State) => tableAlertsFilters);
export const selectTableAlertsLoading = createSelector(selectWallV2State, ({ tableAlertsLoading }: WallV2State) => tableAlertsLoading);
export const selectPlaybacks = createSelector(selectWallV2State, ({ playbacks }: WallV2State) => playbacks);
export const selectPlaybacksStartErrors = createSelector(selectWallV2State, ({ playbackStartErrors }: WallV2State) => playbackStartErrors);

export const selectCamerasNum = createSelector(selectWallV2State, ({ selectedWall, selectedSetIndex }: WallV2State) => {
  return selectedWall?.sets[selectedSetIndex]?.tiles?.filter(tile => tile?.camera?.cameraId)?.length;
});

export const selectListLoading = createSelector(selectWallV2State, ({ listLoading }: WallV2State) => listLoading);

