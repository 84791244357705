<ng-container *ngIf="{
multi:isMulti$|async,
isCollapsed:collapsed$|async
} as conditions"
>
  <ng-container *ngrxLet="filteredCameras$; let filteredCameras">
    <ng-container *ngrxLet="selectLocationsMap$; let locations">
      <div class="ui-modal">
        <div class="header flex-row space-between-center">
          Choose camera{{ conditions.multi ? 's' : '' }}
          <span class="ui-icon-close c-p" mat-dialog-close></span>
        </div>
        <div class="body p-24 flex-col h-100">
          <ui-search placeholder="Search for camera" (onInput)="searchLocations($event)"></ui-search>
          <div class="flex-col h-100">
            <ng-container *ngIf="!searchControl.value">
              <div class="flex-row start-center mt-16 organization gap-16">
                <ng-container *ngrxLet="selectActiveOrganization$; let org">
                  <mat-checkbox
                    type="checkbox"
                    color="primary"
                    name="select-all"
                    [checked]="selected"
                    (change)="selectAllCameras($event)"
                  ></mat-checkbox>
                  <div class="flex-row gap-8 space-between-center w-100">
                    All cameras
                    <span *ngIf="conditions.isCollapsed"
                          class="ui-icon-all-collapsed icon c-p"
                          (click)="toggleExpand()"
                    ></span>
                    <span *ngIf="!conditions.isCollapsed"
                          class="ui-icon-all-expanded icon c-p"
                          (click)="toggleExpand()"
                    ></span>

                  </div>
                </ng-container>
              </div>
              <hr class="hr">
            </ng-container>
            <div class="h-100 gap-8 flex-col" [class.mt-8]="searchControl.value">
              <ng-scrollbar>
                <div class="gap-8 flex-col">


                  <ng-container *ngIf="!searchControl.value">
                    <camera-selector-location-row
                      *ngFor="let location of Object.values(locations.locations);trackBy:trackBy"
                      [location]="location"
                      [isCollapsed]="conditions.isCollapsed"
                      [selectedCameras]="selectedCameras$|async"
                      (onCameraSelected)="selectCamera($event)"
                      (onEdgeSelected)="selectEdge($event)"
                      (onLocationSelected)="selectLocation($event)"
                    ></camera-selector-location-row>
                  </ng-container>


                  <ng-container *ngIf="searchControl.value">
                    <ng-container *ngIf="filteredCameras.length">
                      <camera-selector-camera-row *ngFor="let camera of filteredCameras; trackBy:trackByCameraId"
                                                  [camera]="camera"
                                                  [selectedCameras]="selectedCameras$|async"
                                                  (onCameraSelected)="selectCamera($event)"
                      >
                      </camera-selector-camera-row>
                    </ng-container>

                    <ng-container *ngIf="!filteredCameras.length">
                      <div class="p-15 no-cameras mt-16"> No cameras fit the current search query</div>
                    </ng-container>

                  </ng-container>
                </div>
              </ng-scrollbar>


            </div>
          </div>
        </div>
        <div class="footer flex-row end-center">
          <ui-button type="primary" text="Save" (clickAction)="select()"></ui-button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
