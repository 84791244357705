<div [formGroup]="settingsForm">
  <!--  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="25px">-->
  <!--    <ui-toggle formControlName="sound"></ui-toggle>-->
  <!--    <div>Play sound</div>-->
  <!--  </div>-->
  <!--  <div class="hr w-100"></div>-->
  <div class="flex-row space-between-center option-row">
    <div class="flex-col selector-wrapper">
      <label>Display options</label>
      <mat-select formControlName="display" disableOptionCentering class="ui-selector ui-selector-40">
        <mat-option *ngFor="let display of alertsV2DisplaySettingsOptions" [value]="display.value">
          {{display.label}}
        </mat-option>
      </mat-select>
    </div>
    <div class="flex-col selector-wrapper">
      <label>Title color</label>
      <div class="display-selector">
        <input type="color" formControlName="titleColor">
      </div>
    </div>
  </div>
  <div class="flex-row space-between-center option-row">
    <div class="flex-col selector-wrapper">
      <label>Pic in pic</label>
      <mat-select formControlName="picInPic" disableOptionCentering class="ui-selector ui-selector-40">
        <mat-option *ngFor="let display of alertsV2DisplaySettingsPicOptions" [value]="display.value">
          {{display.label}}
        </mat-option>
      </mat-select>
    </div>
    <div class="flex-col">
      <div>&nbsp;</div>
      <div>at</div>
    </div>
    <div class="flex-col selector-wrapper">
      <label>&nbsp;</label>
      <mat-select formControlName="picInPicPos" disableOptionCentering class="ui-selector ui-selector-40">
        <mat-option *ngFor="let pos of alertsV2PicInPicPositionOptions" [value]="pos.value">
          {{pos.label}}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div class="flex-row space-between-center option-row">
    <div class="flex-col selector-wrapper">
      <label>View duration</label>
      <mat-select formControlName="duration" disableOptionCentering class="ui-selector ui-selector-40">
        <mat-option *ngFor="let duration of durationOptions" [value]="duration.value">
          {{duration.label}}
        </mat-option>
      </mat-select>
    </div>
    <div class="flex-col selector-wrapper end-end">
      <label style="height: 20px"></label>
      <div class="flex-row">
        <ui-toggle formControlName="sound" style="margin-right: 8px"></ui-toggle>
        <div>Play sound</div>
      </div>
    </div>

  </div>
</div>
