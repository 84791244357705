import { AccessoryType, AgeType, AlertSettingSendFrequency, AlertSyncStatus, AlertType, AnalyticClasses, CarMake, CarryingType, CarType, DetectionType, FootwearType, GenderType, HairType, LowerBodyType, ProtectiveGearType, SearchSettings, UpperBodyType } from '@enums/alert-events.enum';
import { AlertEventSettingSchedule } from '@models/alert-events.model';
import { DayOfWeek } from '@enums/shared.enum';
import { PersonSelectionFormFields, VehicleSelectionFormFields } from '@enums/search.enum';

export const ALERT_TYPE_STATUS = 'ALERT_TYPE_STATUS';

export const alertStatusTypes = [
  {
    key: 'CameraStatus',
    value: AlertType.cameraStatus,
    description: 'When a camera status changes',
    isActive: true,
  },
  {
    key: 'EdgeStatus',
    value: AlertType.edgeStatus,
    description: 'When a edge status changes',
    isActive: true,
  },
  {
    key: 'AnalyticStatus',
    value: AlertType.analyticStatus,
    description: 'When camera analytics status changes',
    isActive: true,
  },
];

export const alertTypes = [
  {
    key: 'Analytic',
    value: AlertType.analytic,
    isActive: true,
  },
  ...alertStatusTypes,
  // {
  //   key: 'Motion',
  //   value: AlertType.motion,
  //   description: 'Brief description of what Motion alert type is.',
  //   isActive: false
  // },

  // {
  //   key: 'Tamper',
  //   value: AlertType.tamper,
  //   description: 'Brief description of what Tamper alert type is.',
  //   isActive: false
  // }
];

export const MultipleDetectionTypes = [
  {
    key: 'Appearing',
    value: DetectionType.Apperance,
    description: (object: string) => {
      return `Alert is being sent as soon as multiple objects appear.`;
    },
    isActive: true,
    icon: 'object-appearing',
  },
];

export const WeaponDetectionTypes = [
  {
    key: 'Appearing',
    value: DetectionType.Apperance,
    description: (object: string) => {
      return `Alert is being sent as soon as ${object} appears.`;
    },
    isActive: true,
    icon: 'object-appearing',
  },
];

export const detectionTypes = [
  {
    key: 'Appearing',
    value: DetectionType.Apperance,
    description: (object: string) => {
      return `Alert is being sent as soon as ${object} appears.`;
    },
    isActive: true,
    icon: 'object-appearing',
  },
  {
    key: 'Disappearing',
    value: DetectionType.Disappearing,
    description: (object: string) => {
      return `Alert is being sent as soon as ${object} disappears.`;
    },
    isActive: true,
    icon: 'object-disappearing',
  },
  {
    key: 'Line crossing',
    value: DetectionType.LineCrossing,
    description: (object: string) => {
      return `Alert is being sent when ${object} cross a line.`;
    },
    isActive: true,
    icon: 'object-line-crossing',
  },
  {
    key: 'Traffic Control',
    value: DetectionType.TrafficControl,
    description: (object: string) => {
      return `Alert is being count when ${object} cross a track.`;
    },
    isActive: true,
    icon: 'object-traffic-control',
  },
  {
    key: 'Motion',
    value: DetectionType.MotionScore,
    description: (object: string) => {
      return `Alert is being send when camera notice a movement.`;
    },
    isActive: true,
    icon: 'object-motion',
  },
  {
    key: 'Tampering',
    value: DetectionType.VideoTampering,
    description: (object: string) => {
      return `Alert is being count when ${object} cross a track.`;
    },
    isActive: true,
    icon: 'tampering',
  },
  {
    key: 'Loitering',
    value: DetectionType.Loitering,
    description: (object: string) => {
      return `Alert is being sent when ${object} appears within the zone for a specific period of time.`;
    },
    isActive: true,
    icon: 'object-loitering',
  },
  {
    key: 'Occupancy',
    value: DetectionType.Occupancy,
    description: (object: string) => {
      return `Alert is being sent when a specific number of ${object} appears within the zone.`;
    },
    isActive: true,
    icon: 'object-occupancy',
  },
  {
    key: 'Tail Gating',
    value: DetectionType.Tailgating,
    description: (object: string) => {
      return `Alert is being send as soon as ${object} tailgating happens.`;
    },
    isActive: true,
    icon: 'object-tailgating',
  },
  {
    key: 'License Plate',
    value: DetectionType.LPR,
    description: (object: string) => {
      return `Alert is being sent when movement of the ${object} is detected`;
    },
    isActive: true,
    icon: 'object-vehicle',
  },
  {
    key: 'Movement',
    value: DetectionType.Movement,
    description: (object: string) => {
      return `Alert is being sent when movement of the ${object} is detected`;
    },
    isActive: false,
    icon: 'object-movement',
  },
];

export const genderTypes = [
  {
    key: 'Male',
    value: GenderType.Male,
  },
  {
    key: 'Female',
    value: GenderType.Female,
  },
];
export const ageTypes = [
  {
    key: 'Below 18',
    value: AgeType.Below_18,
  },
  {
    key: '18 - 60',
    value: AgeType.Between_18_And_60,
  },
  {
    key: 'Above 60',
    value: AgeType.Above_60,
  },
];

export const footwearTypes = [
  {
    key: 'LeatherShoes',
    value: FootwearType.LeatherShoes,
  },
  {
    key: 'Shoes',
    value: FootwearType.Shoes,
  },
  {
    key: 'Sandals',
    value: FootwearType.Sandals,
  },
  {
    key: 'Sneaker',
    value: FootwearType.Sneaker,
  },
  {
    key: 'Boots',
    value: FootwearType.Boots,
  },
  {
    key: 'Stocking',
    value: FootwearType.Stocking,
  },
];

export const lowerBodyTypes = [
  {
    key: 'Shorts',
    value: LowerBodyType.Shorts,
  },

  {
    key: 'Trousers',
    value: LowerBodyType.Trousers,
  },
  {
    key: 'Skirt and dress',
    value: LowerBodyType.SkirtAndDress,
  },
  {
    key: 'Not solid',
    value: LowerBodyType.NotSolid,
  },
  // {
  //   key: 'ShortSkirt',
  //   value: LowerBodyType.ShortSkirt,
  // },
  // {
  //   key: 'Casual',
  //   value: LowerBodyType.Casual,
  // },
  // {
  //   key: 'Formal',
  //   value: LowerBodyType.Formal,
  // },
  // {
  //   key: 'Jeans',
  //   value: LowerBodyType.Jeans,
  // },
  // {
  //   key: 'Capri',
  //   value: LowerBodyType.Capri,
  // },
  // {
  //   key: 'HotPants',
  //   value: LowerBodyType.HotPants,
  // },
  // {
  //   key: 'LongSkirt',
  //   value: LowerBodyType.LongSkirt,
  // },
  // {
  //   key: 'Plaid',
  //   value: LowerBodyType.Plaid,
  // },
  // {
  //   key: 'ThinStripes',
  //   value: LowerBodyType.ThinStripes,
  // },
  // {
  //   key: 'Suits',
  //   value: LowerBodyType.Suits,
  // },
];

export const upperBodyTypes = [
  {
    key: 'Logo',
    value: UpperBodyType.Logo,
  },

  {
    key: 'Short Sleeve',
    value: UpperBodyType.Short_Sleeve,
  },
  {
    key: 'Long Sleeve',
    value: UpperBodyType.Long_Sleeve,
  },
  {
    key: 'Not Solid',
    value: UpperBodyType.Not_Solid,
  },
  // {
  //   key: 'Casual',
  //   value: UpperBodyType.Casual,
  // },
  // {
  //   key: 'Formal',
  //   value: UpperBodyType.Formal,
  // },
  // {
  //   key: 'Jacket',
  //   value: UpperBodyType.Jacket,
  // },
  // {
  //   key: 'Plaid',
  //   value: UpperBodyType.Plaid,
  // },
  // {
  //   key: 'ThinStripes',
  //   value: UpperBodyType.ThinStripes,
  // },
  // {
  //   key: 'TShirt',
  //   value: UpperBodyType.TShirt,
  // },
  // {
  //   key: 'Other',
  //   value: UpperBodyType.Other,
  // },
  // {
  //   key: 'VNeck',
  //   value: UpperBodyType.VNeck,
  // },
  //
  // {
  //   key: 'NoSleeve',
  //   value: UpperBodyType.NoSleeve,
  // },
  // {
  //   key: 'Suit',
  //   value: UpperBodyType.Suit,
  // },
  // {
  //   key: 'Sweater',
  //   value: UpperBodyType.Sweater,
  // },
  // {
  //   key: 'ThickStripes',
  //   value: UpperBodyType.ThickStripes,
  // },
];

export const hairTypes = [
  {
    key: 'Long',
    value: HairType.Long,
  },
  {
    key: 'Bald',
    value: HairType.Bald,
  },
  {
    key: 'Short',
    value: HairType.Short,
  },
];

export const accessoryTypes = [
  {
    key: 'Hat',
    value: AccessoryType.Hat,
  },
  {
    key: 'No Hat',
    value: AccessoryType.No_Hat,
  },
  {
    key: 'Glasses',
    value: AccessoryType.Glasses,
  },
  {
    key: 'No Glasses',
    value: AccessoryType.No_Glasses,
  },
  // {
  //   key: 'Muffler',
  //   value: AccessoryType.Muffler,
  // },
  // {
  //   key: 'Nothing',
  //   value: AccessoryType.Nothing,
  // },
  // {
  //   key: 'Sunglasses',
  //   value: AccessoryType.Sunglasses,
  // },
  // {
  //   key: 'Headphone',
  //   value: AccessoryType.Headphone,
  // },
  // {
  //   key: 'HairBand',
  //   value: AccessoryType.HairBand,
  // },
  // {
  //   key: 'Kerchief',
  //   value: AccessoryType.Kerchief,
  // },
];

export const carryingTypes = [
  {
    key: 'Bag',
    value: CarryingType.Bag,
  },
  // {
  //   key: 'No Bag',
  //   value: CarryingType.No_Bag,
  // },
  {
    key: 'Object',
    value: CarryingType.Object,
  },
  // {
  //   key: 'No Object',
  //   value: CarryingType.No_Object,
  // },
  // {
  //   key: 'Backpack',
  //   value: CarryingType.Backpack,
  // },
  // {
  //   key: 'Other',
  //   value: CarryingType.Other,
  // },
  // {
  //   key: 'MessengerBag',
  //   value: CarryingType.MessengerBag,
  // },
  // {
  //   key: 'Nothing',
  //   value: CarryingType.Nothing,
  // },
  // {
  //   key: 'PlasticBags',
  //   value: CarryingType.PlasticBags,
  // },
  // {
  //   key: 'BabyBuggy',
  //   value: CarryingType.BabyBuggy,
  // },
  // {
  //   key: 'ShoppingTro',
  //   value: CarryingType.ShoppingTro,
  // },
  // {
  //   key: 'Umbrella',
  //   value: CarryingType.Umbrella,
  // },
  // {
  //   key: 'Folder',
  //   value: CarryingType.Folder,
  // },
  // {
  //   key: 'LuggageCase',
  //   value: CarryingType.LuggageCase,
  // },
  // {
  //   key: 'Suitcase',
  //   value: CarryingType.Suitcase,
  // },
];

export const carMakes = Object.entries(CarMake)
  .map((k, v) => {
    return { key: k[0], value: k[1] };
  });

// export const carModels = [
//   {
//     key: 'BMW',
//     value: CarMake.BMW,
//   },
//   {
//     key: 'Audi',
//     value: CarMake.Audi,
//   },
//   {
//     key: 'Mercedes',
//     value: CarMake.Mercedes,
//   },
//   {
//     key: 'Volkswagen',
//     value: CarMake.Volkswagen,
//   },
//   {
//     key: 'Opel',
//     value: CarMake.Opel,
//   },
//   {
//     key: 'Ford',
//     value: CarMake.Ford,
//   },
//   {
//     key: 'Renault',
//     value: CarMake.Renault,
//   },
//   {
//     key: 'Skoda',
//     value: CarMake.Skoda,
//   },
//   {
//     key: 'Toyota',
//     value: CarMake.Toyota,
//   },
//   {
//     key: 'Peugeot',
//     value: CarMake.Peugeot,
//   },
//   {
//     key: 'AlfaRomeo',
//     value: CarMake.AlfaRomeo,
//   },
//   {
//     key: 'Chevrolet',
//     value: CarMake.Chevrolet,
//   },
// ];

export const carTypes = Object.entries(CarType)
  .map((k, v) => {
    return { key: k[0], value: k[1] };
  });

// export const carTypes = [
//   {
//     key: 'Small cars',
//     value: CarType.SmallCars,
//   },
//   {
//     key: 'City cars',
//     value: CarType.CityCars,
//   },
//   {
//     key: 'Coupe',
//     value: CarType.Coupe,
//   },
//   {
//     key: 'Cabriolet',
//     value: CarType.Cabriolet,
//   },
//   {
//     key: 'Combi',
//     value: CarType.Combi,
//   },
//   {
//     key: 'Compact',
//     value: CarType.Compact,
//   },
//   {
//     key: 'Minivan',
//     value: CarType.Minivan,
//   },
//   {
//     key: 'Sedan',
//     value: CarType.Sedan,
//   },
//   {
//     key: 'VAN',
//     value: CarType.VAN,
//   },
// ];

export const AlertTypeLookup: { [Property in AlertType]: string } = {
  [AlertType.analytic]: 'Analytic',
  [AlertType.motion]: 'Motion',
  [AlertType.cameraStatus]: 'Camera Status',
  [AlertType.edgeStatus]: 'Core Status',
  [AlertType.analyticStatus]: 'Analytics Status',
  [AlertType.tamper]: 'Tamper',
};

export const DetectionTypeLookup: { [Property in DetectionType]: string } = {
  [DetectionType.Apperance]: 'Appearance',
  [DetectionType.Disappearing]: 'Disappearance',
  [DetectionType.Loitering]: 'Loitering',
  [DetectionType.LineCrossing]: 'Line Crossing',
  [DetectionType.LPR]: 'LPR',
  [DetectionType.FaceDetection]: 'Face Detection',
  [DetectionType.VideoTampering]: 'Tampering',
  [DetectionType.MotionScore]: 'MotionScore',
  [DetectionType.Occupancy]: 'Occupancy',
  [DetectionType.Movement]: 'Movement',
  [DetectionType.TrafficControl]: 'Traffic Control',
  [DetectionType.Tailgating]: 'Tailgating',
  [DetectionType.cameraStatus]: 'Camera Status',
  [DetectionType.edgeStatus]: 'Core Status',
  [DetectionType.analyticStatus]: 'Analytic Status',
};

export const AlertSettingsFrequency = {
  [AlertSettingSendFrequency.everyTimeEvenOccur]: 'Every time',
  [AlertSettingSendFrequency.everyHour]: 'Every hour',
};

export const alertDayTime = [
  '01:00 AM',
  '01:30 AM',
  '02:00 AM',
  '02:30 AM',
  '03:00 AM',
  '03:30 AM',
  '04:00 AM',
  '04:30 AM',
  '05:00 AM',
  '05:30 AM',
  '06:00 AM',
  '06:30 AM',
  '07:00 AM',
  '07:30 AM',
  '08:00 AM',
  '08:30 AM',
  '09:00 AM',
  '09:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '01:00 PM',
  '01:30 PM',
  '02:00 PM',
  '02:30 PM',
  '03:00 PM',
  '03:30 PM',
  '04:00 PM',
  '04:30 PM',
  '05:00 PM',
  '05:30 PM',
  '06:00 PM',
  '06:30 PM',
  '07:00 PM',
  '07:30 PM',
  '08:00 PM',
  '08:30 PM',
  '09:00 PM',
  '09:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
  '11:59 PM',
  '12:00 AM',
  '12:30 AM',
];
export const settingScheduleDefaultFrom = '09:00 PM';
export const settingScheduleDefaultTo = '10:00 AM';

export const settingScheduleAllDayFrom = '12:00 AM';
export const settingScheduleAllDayTo = '11:59 PM';

export const daysSettingSchedule = [
  {
    day: DayOfWeek.monday,
    allDay: true,
    from: settingScheduleDefaultFrom,
    to: settingScheduleDefaultTo,
  },
  {
    day: DayOfWeek.tuesday,
    allDay: true,
    from: settingScheduleDefaultFrom,
    to: settingScheduleDefaultTo,
  },
  {
    day: DayOfWeek.wednesday,
    allDay: true,
    from: settingScheduleDefaultFrom,
    to: settingScheduleDefaultTo,
  },
  {
    day: DayOfWeek.thursday,
    allDay: true,
    from: settingScheduleDefaultFrom,
    to: settingScheduleDefaultTo,
  },
  {
    day: DayOfWeek.friday,
    allDay: true,
    from: settingScheduleDefaultFrom,
    to: settingScheduleDefaultTo,
  },
  {
    day: DayOfWeek.saturday,
    allDay: true,
    from: settingScheduleDefaultFrom,
    to: settingScheduleDefaultTo,
  },
  {
    day: DayOfWeek.sunday,
    allDay: true,
    from: settingScheduleDefaultFrom,
    to: settingScheduleDefaultTo,
  },
];


export const daySettingScheduleV2 = [
  {
    day: DayOfWeek.monday,
    enabled: true,
    times: [{ from: '12:00 AM', to: '12:00 AM' }],
  },
  {
    day: DayOfWeek.tuesday,
    enabled: true,
    times: [{ from: '12:00 AM', to: '12:00 AM' }],
  },
  {
    day: DayOfWeek.wednesday,
    enabled: true,
    times: [{ from: '12:00 AM', to: '12:00 AM' }],
  },
  {
    day: DayOfWeek.thursday,
    enabled: true,
    times: [{ from: '12:00 AM', to: '12:00 AM' }],
  },
  {
    day: DayOfWeek.friday,
    enabled: true,
    times: [{ from: '12:00 AM', to: '12:00 AM' }],
  },
  {
    day: DayOfWeek.saturday,
    enabled: true,
    times: [{ from: '12:00 AM', to: '12:00 AM' }],
  },
  {
    day: DayOfWeek.sunday,
    enabled: true,
    times: [{ from: '12:00 AM', to: '12:00 AM' }],
  },
];

export const settingScheduleDefault: AlertEventSettingSchedule = {
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  saturday: null,
  sunday: null,
};

export const nameDayOfWeek = {
  [DayOfWeek.monday]: 'monday',
  [DayOfWeek.tuesday]: 'tuesday',
  [DayOfWeek.wednesday]: 'wednesday',
  [DayOfWeek.thursday]: 'thursday',
  [DayOfWeek.friday]: 'friday',
  [DayOfWeek.saturday]: 'saturday',
  [DayOfWeek.sunday]: 'sunday',
};

export const analyticClassName: { [Property in AnalyticClasses]: string } = {
  [AnalyticClasses.person]: 'Person',
  [AnalyticClasses.vehicle]: 'Vehicle',
  [AnalyticClasses.pet]: 'Pet',
  [AnalyticClasses.weapon]: 'Weapon',
  [AnalyticClasses.multiple]: 'Multiple',
  [AnalyticClasses.fire]: 'Fire',
};

export const multipleClassesArray = [
  {
    key: AnalyticClasses.person,
    title: analyticClassName[AnalyticClasses.person],
    icon: 'object-icon-person',
  },
  {
    key: AnalyticClasses.vehicle,
    title: analyticClassName[AnalyticClasses.vehicle],
    icon: 'object-vehicle',
  },
  {
    key: AnalyticClasses.pet,
    title: analyticClassName[AnalyticClasses.pet],
    icon: 'object-pet',
  },
];
export const analyticClassesArray = [
  {
    key: AnalyticClasses.person,
    title: analyticClassName[AnalyticClasses.person],
    icon: 'object-icon-person',
  },
  {
    key: AnalyticClasses.vehicle,
    title: analyticClassName[AnalyticClasses.vehicle],
    icon: 'object-vehicle',
  },
  {
    key: AnalyticClasses.pet,
    title: analyticClassName[AnalyticClasses.pet],
    icon: 'object-pet',
  },
  {
    key: AnalyticClasses.weapon,
    title: analyticClassName[AnalyticClasses.weapon],
    icon: 'object-weapon',
    disabled: false,
    beta: true,
  },
  {
    key: AnalyticClasses.multiple,
    title: analyticClassName[AnalyticClasses.multiple],
    icon: 'object-multiple',
    disabled: false,
  },
  {
    key: AnalyticClasses.fire,
    title: analyticClassName[AnalyticClasses.fire],
    icon: 'object-fire',
    disabled: true,
  },
];

export const alertDetectionTypesArray = [
  {
    key: DetectionType.Apperance,
    title: 'Appearing',
  },
  {
    key: DetectionType.Disappearing,
    title: 'Disappearing',
  },
  {
    key: DetectionType.Loitering,
    title: 'Loitering',
  },
  {
    key: DetectionType.LineCrossing,
    title: 'Line Crossing',
  },
  {
    key: DetectionType.TrafficControl,
    title: 'Traffic Control',
  },
  {
    key: DetectionType.LPR,
    title: 'LPR',
  },
  // {
  //   key: DetectionType.FaceDetection,
  //   title: "FaceDetection",
  // },
  {
    key: DetectionType.VideoTampering,
    title: 'Tampering',
  },
  {
    key: DetectionType.MotionScore,
    title: 'Motion',
  },
  {
    key: DetectionType.Occupancy,
    title: 'Occupancy',
  },
  // {
  //   key: DetectionType.Movement,
  //   title: "Movement",
  // },
  {
    key: DetectionType.Tailgating,
    title: 'Tailgating',
  },
  {
    key: DetectionType.cameraStatus,
    title: 'CameraStatus',
  },
  {
    key: DetectionType.edgeStatus,
    title: 'EdgeStatus',
  },
  {
    key: DetectionType.analyticStatus,
    title: 'AnalyticStatus',
  },
];

export const alertSyncStatusString = {
  [AlertSyncStatus.synced]: 'Synced',
  [AlertSyncStatus.notSynced]: 'Not Synced',
};

export const searchSettingsMap = {
  [SearchSettings.genderType]: 'genderType',
  [SearchSettings.ageType]: 'ageType',
  [SearchSettings.footwearType]: 'footwearType',
  [SearchSettings.lowerbodyType]: 'lowerbodyType',
  [SearchSettings.upperbodyType]: 'upperbodyType',
  [SearchSettings.hairType]: 'hairType',
  [SearchSettings.accessoryType]: 'accessoryType',
  [SearchSettings.carryingType]: 'carryingType',
  [SearchSettings.carColor]: 'carColor',
  [SearchSettings.carMake]: 'carMake',
  [SearchSettings.carModel]: 'carModel',
  [SearchSettings.carType]: 'carType',
  [SearchSettings.carAdditionalProperties]: 'carAdditionalProperties',
};

export const searchPropertyMap: { [Property in VehicleSelectionFormFields] } = {
  [VehicleSelectionFormFields.colors]: 'carColor',
  [VehicleSelectionFormFields.plate]: 'carPlate',
  [VehicleSelectionFormFields.make]: 'carMake',
  [VehicleSelectionFormFields.model]: 'carModel',
  [VehicleSelectionFormFields.type]: 'carType',
  [VehicleSelectionFormFields.additionalProperty]: 'carAdditionalProperties',
  exists: undefined,
  plate_old: undefined,
  region: undefined,
};

export const lowerBodyTypeArray: { name: string; value: LowerBodyType }[] = [
  {
    name: 'Shorts',
    value: LowerBodyType.Shorts,
  },
  {
    name: 'Trousers',
    value: LowerBodyType.Trousers,
  },
  {
    name: 'Skirt and Dress',
    value: LowerBodyType.SkirtAndDress,
  },
  {
    name: 'Not Solid',
    value: LowerBodyType.NotSolid,
  },
  {
    name: 'Solid',
    value: LowerBodyType.Solid,
  },
];

export const upperBodyTypeArray: { name: string; value: UpperBodyType }[] = [
  {
    name: 'Short Sleeve',
    value: UpperBodyType.Short_Sleeve,
  },
  {
    name: 'Long Sleeve',
    value: UpperBodyType.Long_Sleeve,
  },
  {
    name: 'Logo',
    value: UpperBodyType.Logo,
  },
  {
    name: 'No Logo',
    value: UpperBodyType.No_Logo,
  },
  {
    name: 'Solid',
    value: UpperBodyType.Solid,
  },
  {
    name: 'Not Solid',
    value: UpperBodyType.Not_Solid,
  },
];

export const accessoryTypeArray: { name: string; value: AccessoryType }[] = [
  {
    name: 'Hat',
    value: AccessoryType.Hat,
  },
  {
    name: 'No Hat',
    value: AccessoryType.No_Hat,
  },
  {
    name: 'Glasses',
    value: AccessoryType.Glasses,
  },
  {
    name: 'No Glasses',
    value: AccessoryType.No_Glasses,
  },
];

export const carryingTypeArray: { name: string; value: CarryingType }[] = [
  {
    name: 'Bag',
    value: CarryingType.Bag,
  },
  // {
  //   name: 'No Bag',
  //   value: CarryingType.No_Bag,
  // },
  {
    name: 'Object',
    value: CarryingType.Object,
  },
  // {
  //   name: 'No Object',
  //   value: CarryingType.No_Object,
  // },
];

export const protectivegearTypeArray: { name: string; value: ProtectiveGearType }[] = [
  {
    name: 'Safety helmet',
    value: ProtectiveGearType.Helmet,
  },
  {
    name: 'No safety helmet',
    value: ProtectiveGearType.No_Helmet,
  },
];

export const ageTypeRadioValues = {
  kids: ['less_18'],
  adults: ['18_to_60', 'over_60'],
};

export const personSelectionFormFieldsTitles: { [Property in PersonSelectionFormFields] } = {
  [PersonSelectionFormFields.genderType]: 'Gender',
  [PersonSelectionFormFields.ageType]: 'Age',
  [PersonSelectionFormFields.footwearType]: 'Footwear',
  [PersonSelectionFormFields.lowerbodyType]: 'Lower body',
  [PersonSelectionFormFields.upperbodyType]: 'Upper body',
  [PersonSelectionFormFields.hairType]: 'Hair',
  [PersonSelectionFormFields.accessoryType]: 'Accessory',
  [PersonSelectionFormFields.carryingType]: 'Carrying',
  [PersonSelectionFormFields.protectiveGear]: 'Protective gear',
};

export const searchSelectorCarTypes: { name: string; value: string }[] = [
  {
    name: 'Sedan',
    value: 'sedan',
  },
  {
    name: 'SUV',
    value: 'suv',
  },
  {
    name: 'Van',
    value: 'van',
  },
  {
    name: 'Pickup Truck',
    value: 'pickup truck',
  },
  {
    name: 'Motorcycle',
    value: 'motorcycle',
  },
  {
    name: 'Motorcycle',
    value: 'motorcycle',
  },
];

// { name: 'Sedan', value: 'sedan' },
// { name: 'SUV', value: 'suv' },
// { name: 'Van', value: 'van' },
// { name: 'Pickup_Truck', value: 'pickup truck' },
// { name: 'Motorcycle', value: 'motorcycle' },
// { name: 'Bus', value: 'bus' },
// { name: 'Big_Truck', value: 'big truck' },
// { name: 'Bicycle', value: 'bicycle' },
