import { BoxConfiguration, BoxPrice, CameraPrice, DeviceOptions, LicenseTerms, CameraOptions, RetentionOptions, StorageOptions, AddOnPrice, AddOnOptions, CameraType } from '@models/pricing.model';

export const PricingTermStr = [
  '1 Year',
  '3 Years',
  '5 Years',
];

export const PricingTermToYears = [
  1,
  3,
  5,
];

export const RetentionOptionsStr = [
  '30 Days',
  '60 Days',
  '90 Days',
  'Cloud Add-ons',
];

export const ResolutionOptionsStr = [
  '5MP',
  '8MP',
  '12MP',
];

export const boxConfiguration: BoxConfiguration[] = [
  { retention: RetentionOptions.Days30, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 12 },
  { retention: RetentionOptions.Days30, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 8 },
  { retention: RetentionOptions.Days30, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 7 },
  { retention: RetentionOptions.Days30, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 5 },
  { retention: RetentionOptions.Days30, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days30, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days60, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 7 },
  { retention: RetentionOptions.Days60, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 5 },
  { retention: RetentionOptions.Days60, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 3 },
  { retention: RetentionOptions.Days60, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days60, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days60, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days90, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 5 },
  { retention: RetentionOptions.Days90, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 3 },
  { retention: RetentionOptions.Days90, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days90, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 1 },
  { retention: RetentionOptions.Days90, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: CameraOptions.Resolution5MP, maxNumberOfCameras: 2 },
  { retention: RetentionOptions.Days90, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: CameraOptions.Resolution8MP, maxNumberOfCameras: 1 },
];

export const boxPricing: BoxPrice[] = [
  {
    storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, price: {
      [LicenseTerms.Years1]: 749,
      [LicenseTerms.Years3]: 699,
      [LicenseTerms.Years5]: 679,
    },
  },
  {
    storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, price: {
      [LicenseTerms.Years1]: 659,
      [LicenseTerms.Years3]: 609,
      [LicenseTerms.Years5]: 589,
    },
  },
  {
    storage: StorageOptions.Storage32TB, device: DeviceOptions.RACK1U32TB, price: {
      [LicenseTerms.Years1]: 4499,
      [LicenseTerms.Years3]: 4199,
      [LicenseTerms.Years5]: 4099,
    },
  },
  {
    storage: StorageOptions.Storage64TB, device: DeviceOptions.RACK1U64TB, price: {
      [LicenseTerms.Years1]: 6799,
      [LicenseTerms.Years3]: 6399,
      [LicenseTerms.Years5]: 6099,
    },
  },
  {
    storage: StorageOptions.Storage88TB, device: DeviceOptions.RACK1U88TB, price: {
      [LicenseTerms.Years1]: 9499,
      [LicenseTerms.Years3]: 8899,
      [LicenseTerms.Years5]: 8499,
    },
  },
];

export const swLicensePricing: Record<LicenseTerms, number> = {
  [LicenseTerms.Years1]: 199,
  [LicenseTerms.Years3]: 169,
  [LicenseTerms.Years5]: 159,
};

export const addOnPricing: AddOnPrice[] = [
  { type: AddOnOptions.Speaker, price: 575 },
  { type: AddOnOptions.CloudStorage30Days, price: 99 },
  { type: AddOnOptions.CloudStorage60Days, price: 198 },
  { type: AddOnOptions.CloudStorage90Days, price: 297 },
  { type: AddOnOptions.CloudStorage180Days, price: 594 },
  { type: AddOnOptions.CloudStorage365Days, price: 1188 },
  { type: AddOnOptions.ExternalStorage30Days, price: 19 },
  { type: AddOnOptions.ExternalStorage60Days, price: 38 },
  { type: AddOnOptions.ExternalStorage90Days, price: 57 },
  { type: AddOnOptions.ExternalStorage180Days, price: 114 },
  { type: AddOnOptions.ExternalStorage365Days, price: 228 },
  { type: AddOnOptions.AlertStorage, price: 129 },
  { type: AddOnOptions.EventTagsStorage, price: 39 },
];

export const speakerLicensePricing: Record<LicenseTerms, number> = {
  [LicenseTerms.Years1]: 199,
  [LicenseTerms.Years3]: 169,
  [LicenseTerms.Years5]: 159,
};

export const sensorLicensePricing: Record<LicenseTerms, number> = {
  [LicenseTerms.Years1]: 199,
  [LicenseTerms.Years3]: 169,
  [LicenseTerms.Years5]: 159,
};

export const appleTvLicensePricing: Record<LicenseTerms, number> = {
  [LicenseTerms.Years1]: 399,
  [LicenseTerms.Years3]: 349,
  [LicenseTerms.Years5]: 319,
};

export const cameraPricing: CameraPrice[] = [
  {
    resolution: CameraOptions.Resolution5MP, price: {
      [LicenseTerms.Years1]: 32,
      [LicenseTerms.Years3]: 29,
      [LicenseTerms.Years5]: 27,
    }, own: 135,
  },
  {
    resolution: CameraOptions.Resolution8MP, price: {
      [LicenseTerms.Years1]: 42,
      [LicenseTerms.Years3]: 39,
      [LicenseTerms.Years5]: 37,
    },
    own: 185,
  },
  {
    resolution: CameraOptions.OpticalZoom5MP, price: {
      [LicenseTerms.Years1]: 54,
      [LicenseTerms.Years3]: 49,
      [LicenseTerms.Years5]: 47,
    },
    own: 235,
  },
  {
    resolution: CameraOptions.Fisheye12MP, price: {
      [LicenseTerms.Years1]: 192,
      [LicenseTerms.Years3]: 179,
      [LicenseTerms.Years5]: 167,
    },
    own: 835,
  },
  {
    resolution: CameraOptions.PTZ, price: {
      [LicenseTerms.Years1]: 182,
      [LicenseTerms.Years3]: 169,
      [LicenseTerms.Years5]: 157,
    },
    own: 785,
  },

];

export const cameraTypeStr: { [Property in CameraType]: string } = {
  [CameraType.Turret5MP]: 'Turret 5MP',
  [CameraType.Dome5MP]: 'Dome 5MP',
  [CameraType.Bullet5MP]: 'Bullet 5MP',
  [CameraType.Turret8MP]: 'Turret 8MP',
  [CameraType.Dome8MP]: 'Dome 8MP',
  [CameraType.Bullet8MP]: 'Bullet 8MP',
  // [CameraType.Fisheye12MP]: 'Fisheye 12MP',
  // [CameraType.PTZ]: 'PTZ',
  // [CameraType.Turret5MPZoom]: 'Turret 5MP Zoom',
};
