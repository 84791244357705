export enum SearchObjectTypes {
  PERSON = 'person',
  VEHICLE = 'vehicle',
  PET = 'pet',
  TEXT = 'text',
  SHOPPING_CART = 'shopping_cart'
}

export enum SearchQueryOperator {
  AND = 'AND',
  OR = 'OR',
}

export enum PersonSelectionFormFields {
  genderType = 'genderType',
  ageType = 'ageType',
  footwearType = 'footwearType',
  lowerbodyType = 'lowerbodyType',
  upperbodyType = 'upperbodyType',
  hairType = 'hairType',
  accessoryType = 'accessoryType',
  carryingType = 'carryingType',
  protectiveGear = 'protectivegearType'
}

export enum VehicleSelectionFormFields {
  colors = 'colors',
  exists = 'exists',
  plate = 'plate',
  plate_old = 'plate_old',
  region = 'region',
  model = 'model',
  make = 'make',
  type = 'type',
  additionalProperty = 'additionalProperties',
}

export enum SearchSelectionFormValueTypes {
  checkbox,
  radio,
  color,
  type,
}
