import { Injectable } from '@angular/core';
import * as auth0 from 'auth0-js';
import { LocalStorageService } from '../core/local-storage.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth0 } from './auth0.model';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { UserService } from '../user/user.service';
import { AppState } from '../store/app.state';
import * as SharedActions from '@states/shared/shared.actions';
import { PublicRoutesRedirect } from '@consts/routes';
import { Observable, of } from 'rxjs';
import { AuthenticationActions } from '@states/authentication/authentication.action-types';
import { AuthResponseFromUrl } from '@enums/shared.enum';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { BYPASS_AUTHENTICAION_TOKEN } from '../core/authentication-interceptor.service';
import { calculateExpiryDate, parseJwt } from '../helpers/common.helpers';
import { isEmailVerificationEmailRequired, isIpRulesRequired } from '../helpers/error.helpers';
import { AppUser } from '../user/user.model';
import { ID_TOKEN_KEY, REFRESH_TOKEN_KEY } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class Auth0Service {

  auth0 = new auth0.WebAuth({
    clientID: environment.auth0.clientID,
    domain: environment.auth0.domain,
    responseType: environment.auth0.responseType,
    audience: environment.auth0.audience,
    redirectUri: environment.auth0.redirectUri,
    scope: environment.auth0.scope,
  });

  tenant = new auth0.WebAuth({
    clientID: environment.auth0.clientID,
    domain: environment.auth0.domain,
    responseType: environment.auth0.responseType,
    audience: environment.auth0.audience,
    redirectUri: environment.auth0.redirectUri,
    scope: environment.auth0.scope,
    prompt: 'none',
  });


  public user$ = this.userService.user$;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private route: ActivatedRoute,
    private http: HttpClient,
  ) {
  }

  isLoggedIn(): boolean {
    const res = !!this.getIdTokenFromLocalStorage() && moment()
      .isBefore(this.getExpiration());
    return res;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  isUserAlreadyExist(error: string, errorDescription: string) {
    return error === 'access_denied' && errorDescription.indexOf('lumix-401') > -1;
  }

  async handleParseHaseResponse(err, res: Auth0.AuthenticationResponse, isSilent = false) {
    if (!!err) {
      console.log(`url hash is not parsed: ${JSON.stringify(err)}`);

      if (!!isEmailVerificationEmailRequired(err.error, err.errorDescription)) {
        this.router.navigate(['/email-verification'], { queryParams: { error: true, description: err.errorDescription } });
      } else if (err?.code === 'login_required') {
        // const errMsg = 'session has expired';
        this.localStorageService.clear();
        this.logout();
      } else {
        this.logout();
      }
    } else if (res && res.idToken) {
      const expiresAt = res.idTokenPayload.exp - res.idTokenPayload.iat;

      this.store.dispatch(
        AuthenticationActions.Login({
          auth: {
            ...res,
            expiresAt: calculateExpiryDate(expiresAt),
            authProviderId: res.idTokenPayload.sub,
          },
          isSilent,
        }),
      );
    }

    return true;

  }

  async extractAuthResponseFromUrlPromise(): Promise<{ type: AuthResponseFromUrl, msg: string, code: number, data?: Auth0.AuthenticationResponse }> {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, res: Auth0.AuthenticationResponse) => {
        if (!!err) {
          console.log(`url hash is not parsed: ${JSON.stringify(err)}`);

          if (!!isEmailVerificationEmailRequired(err.error, err.errorDescription)) {
            resolve({ type: AuthResponseFromUrl.EmailVerificationRequired, msg: err.errorDescription, code: null });
          } else if (!!isIpRulesRequired(err.error, err.errorDescription)) {
            const ipRequiredMessage = err?.errorDescription?.split(',')[1] ?? 'login required';
            resolve({ type: AuthResponseFromUrl.IpRulesRequired, msg: ipRequiredMessage, code: null });
          } else if (err?.code === 'login_required') {
            resolve({ type: AuthResponseFromUrl.LoginRequired, msg: null, code: null });
          } else {
            resolve({ type: AuthResponseFromUrl.LoginRequired, msg: null, code: null });
          }
        } else if (res && res.idToken) {
          resolve({ type: AuthResponseFromUrl.Success, msg: 'Login success', code: null, data: res });
        } else if (!err && !res && this.isLoggedIn()) {
          resolve({ type: AuthResponseFromUrl.Success, msg: 'User is logged in', code: null });
        } else {
          const url = new URL(window.location.href);
          const pathname = url.pathname?.split('/');

          const errMsg = url.searchParams.get('errMsg');

          if (pathname?.length >= 2 && PublicRoutesRedirect.includes(pathname[1])) {
            resolve({ type: AuthResponseFromUrl.Success, msg: null, code: null });
            return;
          } else {
            resolve({ type: AuthResponseFromUrl.LoginRequired, msg: errMsg, code: 403 });
          }
        }
        resolve({ type: AuthResponseFromUrl.Success, msg: null, code: null });
      });
    });
  }


  logout(msg: string = null, code: string = null) {
    let queryParams = '';
    if (msg) {
      queryParams = `?msg=${encodeURIComponent(msg)}`;
    }
    const returnToUrl = `${environment.auth0.redirectUri}${queryParams}`; // replace with your return URL
    window.location.href = `https://${environment.auth0.domain}/v2/logout?client_id=${environment.auth0.clientID}&returnTo=${encodeURIComponent(returnToUrl)}`;
  }

  switchTenant(orgId: string): Observable<Auth0.AuthenticationHTTPResponse> {
    return this.renewToken(orgId);
  }

  async silentAuth(bypass = true, orgId: string): Promise<any> {
    this.auth0.checkSession({
      orgId,
    }, (err, res) => {
      this.handleParseHaseResponse(err, res, bypass);
    });
  }

  public renewToken(orgId?: string): Observable<Auth0.AuthenticationHTTPResponse> {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (!refreshToken) {
      console.error('No refresh token available');
      return of(null);
    }

    const url = `https://${environment.auth0.domain}/oauth/token`;

    const parsedToken = parseJwt(localStorage.getItem(ID_TOKEN_KEY));
    const subUser = parsedToken.subUser;
    let orgIdParam;
    if (orgId) {
      orgIdParam = orgId;
    } else {
      orgIdParam = parsedToken.orgId;
    }

    const params = new HttpParams()
      .set('client_id', environment.auth0.clientID)
      .set('grant_type', 'refresh_token')
      .set('scope', 'openid profile read:current_user')
      .set('audience', environment.auth0.audience)
      .set('subUser', subUser)
      .set('org_id', orgIdParam)
      .set('refresh_token', refreshToken);


    return this.http.post<Auth0.AuthenticationHTTPResponse>(url, params, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      context: new HttpContext().set(BYPASS_AUTHENTICAION_TOKEN, true),
    });
  }


  public login(username: string, password: string, subUser?: AppUser.SubUser): Observable<Auth0.AuthenticationHTTPResponse> {
    const url = `https://${environment.auth0.domain}/oauth/token`;
    const params = new HttpParams()
      .set('client_id', environment.auth0.clientID)
      .set('audience', environment.auth0.audience)
      .set('realm', 'Username-Password-Authentication')
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password')
      .set('subUser', JSON.stringify(subUser))
      .set('scope', 'openid profile email offline_access');
    return this.http.post<Auth0.AuthenticationHTTPResponse>(url, params, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      context: new HttpContext().set(BYPASS_AUTHENTICAION_TOKEN, true),
    });
  }


  public socialLoginGetTokenByCode(code: string): Observable<any> {

    const url = `https://${environment.auth0.domain}/oauth/token`;

    const params = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('client_id', environment.auth0.clientID)
      .set('redirect_uri', environment.auth0.redirectUri)
      .set('code', code);

    return this.http.post<any>(url, params, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      context: new HttpContext().set(BYPASS_AUTHENTICAION_TOKEN, true),
    });

  }

  // login sdk request (option 1 - working)
  selfHostedLogin(username: string, password: string): Observable<boolean> {
    return new Observable((observer) => {
      this.auth0.client.login(
        {
          realm: 'Username-Password-Authentication',
          username,
          password,
          grant_type: 'password',
          scope: 'openid profile email offline_access', // Request offline_access scope
        },
        (err, authResult) => {
          if (err) {
            observer.error(err);
          } else if (authResult && authResult.accessToken && authResult.idToken) {
            this.afterTokenReceivedSuccess(authResult);
            observer.next(true);
            observer.complete();
          }
        },
      );
    });
  }

  socialLogin(provider: string): Observable<void> {
    return new Observable((observer) => {
      this.auth0.authorize(
        {
          responseType: 'code',
          connection: provider, // 'google-oauth2', 'apple'
          scope: 'openid profile email offline_access read:current_user',
        },
        (err, authResult) => {
          if (err) {
            observer.error(err);
          } else if (authResult && authResult.accessToken && authResult.idToken) {
            observer.next();
            observer.complete();
          }
        },
      );
    });
  }


  resetPassword(email: string) {
    this.auth0.changePassword(
      {
        connection: 'Username-Password-Authentication',
        email,
      },
      (err: Error, resp) => {
        if (err) {
          this.store.dispatch(SharedActions.showMessage({ error: err.message || 'Reset password error' }));
        } else {
          this.store.dispatch(SharedActions.showMessage({ success: resp || 'We have sent you email to reset your password' }));
        }
      },
    );
  }

  getUserFromLocalStorage(): string | null {
    return this.localStorageService.getItem('user');
  }

  getExpirationFromLocalStorage(): string | null {
    return this.localStorageService.getItem('expires_at');
  }

  getIdTokenFromLocalStorage(): string | null {
    return this.localStorageService.getItem('id_token');
  }

  getAccessTokenFromLocalStorage(): string | null {
    return this.localStorageService.getItem('access_token');
  }

  getExpiration() {
    const expiration = this.getExpirationFromLocalStorage();
    if (!expiration) {
      // TODO: should force expiration
      return 0;
    } else {
      const expiresAt = JSON.parse(expiration);
      return moment(expiresAt);
    }
  }

  /**
   * Call after login without refresh the page
   * @param authResult
   */
  public afterTokenReceivedSuccess(authResult: Auth0.AuthenticationResponse) {
    const idTokenPayload = parseJwt(authResult.idToken);
    const expiresAt = idTokenPayload.exp - idTokenPayload.iat;
    const authData: Partial<Auth0.AuthenticationResponse> = {
      ...authResult,
      expiresAt: calculateExpiryDate(expiresAt),
      authProviderId: idTokenPayload.sub,
    };
    this.store.dispatch(
      AuthenticationActions.Login({
        auth: authData,
      }),
    );
  }

}
