<ng-container *ngrxLet="selectAllCamerasGroupByLocation$;let allCamerasByLocation">
  <ng-container *ngrxLet="selectEventsLookup$;let selectEventsLookup">
    <ng-container *ngrxLet="selectCamerasLookup$;let cameras">
      <ng-container *ngrxLet="selectActiveOrganization$;let activeOrganization">
        <ng-container *ngrxLet="totalCamerasOrganization$;let totalCamerasOrganization">
          <ng-container *ngIf="{ isSettingsOpen:isSettingsOpen$|async} as conditions">
            <div class="camera-alert-selector" fxFlexFill fxLayout="column">
              <!--      HEADER-->
              <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <ng-container *ngIf="!conditions.isSettingsOpen">
                    Tile view configuration
                  </ng-container>
                  <ng-container *ngIf="conditions.isSettingsOpen">
                    <ng-container *ngIf="!isCamerasVisibleControl.value">
                      <span class="ui-icon-expand-arrow c-p" (click)="closeSettings()"></span>
                      Alert tile settings
                    </ng-container>
                  </ng-container>
                </div>
                <span class="ui-icon-close c-p" mat-dialog-close></span>
              </div>
              <!--      BODY-->
              <div class="body" fxFlex fxLayout="column">

                <ng-container *ngIf="!conditions.isSettingsOpen">
                  <div class="configuration-wrapper">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <ng-container *ngrxLet="filteredOptions$;let filteredOptions">
                        <mat-select *ngIf="filteredOptions.length >=2" class="ui-selector"
                                    [formControl]="isCamerasVisibleControl"

                        >
                          <mat-option *ngFor="let option of filteredOptions"
                                      [value]="option.value"
                          >{{option.label}}</mat-option>
                        </mat-select>
                      </ng-container>
                      <span *ngIf="!isCamerasVisibleControl.value"
                            class="ui-icon-settings ui-mat-icon-button-16 c-p"
                            (click)="openSettings()"
                      ></span>
                    </div>
                    <ng-container *ngIf="!isCamerasVisibleControl.value">
                      <div class="toggle-wrapper">
                        <ui-toggle [formControl]="viewByLocationControl"></ui-toggle>
                        View by location
                      </div>
                    </ng-container>
                  </div>

                  <!--          FILTERS-->
                  <div class="ui-filters-wrapper flex-row">
                    <ui-search [class.w-300]="!isCamerasVisibleControl.value"
                               [placeholder]="isCamerasVisibleControl.value?'Search for cameras':'Search for alerts'"
                               (onInput)="search($event)"
                    ></ui-search>
                    <ng-container *ngIf="!isCamerasVisibleControl.value">
                      <mat-select class="ui-selector"
                                  placeholder="Priority"
                                  [formControl]="alertPriorityFilter"
                                  multiple
                      >
                        <mat-option *ngFor="let priority of priorityFilter"
                                    [value]="priority.value"
                        >{{priority.name}}</mat-option>
                      </mat-select>

                      <mat-select class="ui-selector"
                                  placeholder="Locations"
                                  [formControl]="alertLocationFilter"
                                  multiple
                      >
                        <mat-option *ngFor="let location of selectAllLocations$|ngrxPush"
                                    [value]="location._id"
                        >{{location.name}}</mat-option>
                      </mat-select>

                      <mat-select class="ui-selector"
                                  placeholder="Cameras"
                                  [formControl]="alertCameraFilter"
                                  multiple
                      >
                        <mat-option *ngFor="let camera of selectAllCameras$|ngrxPush"
                                    [value]="camera.edgeOnly.cameraId"
                        >{{camera.edgeOnly.name}}</mat-option>
                      </mat-select>
                    </ng-container>
                  </div>
                  <div *ngIf="isCamerasVisibleControl.value"
                       class="camera-limit-msg"
                  >Select up to {{data.maxSelected}} cameras {{selectedCamerasCount}}/{{data.maxSelected}}
                  </div>

                  <div class="selection-list" fxFlex fxLayout="column" style="height:400px">
                    <!--                    STICKY ALERT HEADER-->
                    <ng-container *ngIf="!viewByLocationControl.value && !isCamerasVisibleControl.value">
                      <div class="alerts-wrapper">
                        <div class="header">
                          <div fxFlex="33" fxLayout="row" fxLayoutAlign="start center">
                            <mat-checkbox color="primary"
                                          (change)="onOrganizationAlertsSelected($event,selectEventsLookup)"
                            >
                              Alert
                            </mat-checkbox>
                          </div>
                          <div fxFlex="33" fxLayout="row" fxLayoutAlign="start center">

                          </div>
                          <div fxFlex="33" fxLayout="row" fxLayoutAlign="start center">
                            Cameras
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <div class="scroll-inner">
                      <div *ngIf="isCamerasVisibleControl.value"
                           fxLayout="row"
                           fxLayoutAlign="start center"
                           class="organization-name"
                      >
                        <mat-checkbox color="primary" #checkbox
                                      [disabled]="totalCamerasOrganization>data.maxSelected"
                                      (change)="onOrganizationSelected($event,allCamerasByLocation)"
                        >
                          {{activeOrganization.orgName}}
                        </mat-checkbox>
                      </div>
                      <div *ngIf="isCamerasVisibleControl.value">
                        <app-location-row *ngFor="let location of allCamerasByLocation"
                                          [location]="location"
                                          [selectedCameras]="selectedCameras"
                                          [totalLocations]="allCamerasByLocation.length"
                                          [maxSelected]="data.maxSelected"
                                          (onCameraSelected)="onCameraSelected($event)"
                                          (onLocationSelected)="onLocationSelected($event)"
                        >
                        </app-location-row>
                      </div>
                      <div *ngIf="!isCamerasVisibleControl.value" class="alerts-wrapper" fxFlex fxLayout="column">
                        <app-alert-selector [byLocationEnabled]="viewByLocationControl.value"
                                            [availableAlerts]="availableAlerts$|ngrxPush"
                                            [selectEventsLookup]="selectEventsLookup"
                                            [allCamerasByLocation]="allCamerasByLocation"
                                            [selectedCameras]="selectedCameras"
                                            [selectedAlertEventsV2]="selectedAlertEventsV2"
                                            [selectCamerasLookup]="selectCamerasLookup$|ngrxPush"
                                            (onAlertSelected)="onAlertSelected($event)"
                                            (onCameraSelected)="onCameraSelected($event)"
                                            (onLocationSelected)="onLocationSelected($event)"
                        ></app-alert-selector>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="conditions.isSettingsOpen">
                  <ng-container *ngIf="isCamerasVisibleControl.value">
                    <camera-tile-settings [settingsForm]="cameraSettingsForm">

                    </camera-tile-settings>
                  </ng-container>
                  <ng-container *ngIf="!isCamerasVisibleControl.value">
                    <alert-tile-settings [settingsForm]="alertSettingsForm">

                    </alert-tile-settings>
                  </ng-container>
                </ng-container>
              </div>
              <!--        FOOTER-->
              <div class="footer">
                <ng-container *ngIf="!conditions.isSettingsOpen">
                  <ui-button type="primary" text="Select" (clickAction)="submit()"></ui-button>
                </ng-container>
                <ng-container *ngIf="conditions.isSettingsOpen">
                  <ng-container *ngIf="isCamerasVisibleControl.value">
                    <ui-button type="primary" text="Save" (clickAction)="saveCameraSettings()"></ui-button>
                  </ng-container>
                  <ng-container *ngIf="!isCamerasVisibleControl.value">
                    <ui-button type="primary" text="Save" (clickAction)="saveAlertSettings()"></ui-button>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
