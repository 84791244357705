import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, withLatestFrom } from 'rxjs';
import { PricingActions } from '@states/pricing/pricing.action-types';
import { AddOnOptions, CameraOptions, DeviceOptions, LicenseTerms, Pricing, PricingCount, StorageOptions } from '@models/pricing.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { PriceCalculatorService } from '../../price-calaulator/price-calculator.service';
import { appleTvLicensePricing, PricingTermToYears } from '@consts/pricing.const';


@Injectable()
export class PricingEffect {

  public calculatePricing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingActions.calculatePricing),
      withLatestFrom(this.store$.pipe(select(state => state.pricingState))),
      switchMap(([, { locations, term, count }]) => {
        const _count: PricingCount = {
          box2tb: 0,
          box4tb: 0,
          box1u32tb: 0,
          box1u64tb: 0,
          box1u88tb: 0,
          camerasTurret5mp: 0,
          camerasBullet5mp: 0,
          camerasDome5mp: 0,
          camerasTurret8mp: 0,
          camerasBullet8mp: 0,
          camerasDome8mp: 0,
          camerasFisheye12mp: 0,
          camerasPtz: 0,
          camerasTurret5mpZoom: 0,
          ownCamerasTurret5mp: 0,
          ownCamerasBullet5mp: 0,
          ownCamerasDome5mp: 0,
          ownCamerasTurret8mp: 0,
          ownCamerasBullet8mp: 0,
          ownCamerasDome8mp: 0,
          ownCamerasFisheye12mp: 0,
          ownCamerasPtz: 0,
          ownCamerasTurret5mpZoom: 0,
          license: 0,
          verificationEvents: count.verificationEvents ?? 0,
          verificationGuns: count.verificationGuns ?? 0,
          verificationFire: count.verificationFire ?? 0,
          additionalSpeaker: count.additionalSpeaker ?? 0,
          additionalSpeakerLicense: count.additionalSpeakerLicense ?? 0,
          additionalSensorLicense: count.additionalSensorLicense ?? 0,
          additionalAppleTvLicense: count.additionalAppleTvLicense ?? 0,
          additionalAlertStorage: count.additionalAlertStorage ?? 0,
          additionalEventTagsStorage: count.additionalEventTagsStorage ?? 0,
          cloudStorage30: count.cloudStorage30 ?? 0,
          cloudStorage60: count.cloudStorage60 ?? 0,
          cloudStorage90: count.cloudStorage90 ?? 0,
          cloudStorage180: count.cloudStorage180 ?? 0,
          cloudStorage365: count.cloudStorage365 ?? 0,
          externalStorage30: count.externalStorage30 ?? 0,
          externalStorage60: count.externalStorage60 ?? 0,
          externalStorage90: count.externalStorage90 ?? 0,
          externalStorage180: count.externalStorage180 ?? 0,
          externalStorage365: count.externalStorage365 ?? 0,
          metadataStorage30: count.metadataStorage30 ?? 0,
          metadataStorage60: count.metadataStorage60 ?? 0,
          metadataStorage90: count.metadataStorage90 ?? 0,
          metadataStorage180: count.metadataStorage180 ?? 0,
          metadataStorage365: count.metadataStorage365 ?? 0,
          snapshotStorage30: count.snapshotStorage30 ?? 0,
          snapshotStorage60: count.snapshotStorage60 ?? 0,
          snapshotStorage90: count.snapshotStorage90 ?? 0,
          snapshotStorage180: count.snapshotStorage180 ?? 0,
          snapshotStorage365: count.snapshotStorage365 ?? 0,
        };
        const pricing: Pricing = {
          boxes: 0,
          boxesRegular: 0,
          license: 0,
          cameras: 0,
          cloud: 0,
          total: 0,
          regularPrice: 0,
          oneTimePrice: 0,
          shipping: 0,
        };
        const box4tbPrice = this.priceCalculatorService.getBoxPrice(StorageOptions.Storage4TB, DeviceOptions.OrinNX16GB, term);
        const box4tbRegularPrice = this.priceCalculatorService.getBoxPrice(StorageOptions.Storage4TB, DeviceOptions.OrinNX16GB, LicenseTerms.Years1);
        for(let location of locations) {
          _count.box4tb += this.priceCalculatorService.getLocationBoxes(location.info);
          pricing.boxes += _count.box4tb * box4tbPrice;
          pricing.boxesRegular += _count.box4tb * box4tbRegularPrice;
          for(let info of location.info) {
            const regularPrice = this.priceCalculatorService.getLocationPricing(info, LicenseTerms.Years1);
            const calc = this.priceCalculatorService.getLocationPricing(info, term);


            pricing.license += calc.pricing.license;
            pricing.cameras += calc.pricing.cameras;
            pricing.total += calc.pricing.total;
            pricing.regularPrice += regularPrice.pricing.total;
            _count.box2tb += 0; //calc.count.box2tb;

            _count.camerasTurret5mp += calc.count.camerasTurret5mp;
            _count.camerasTurret8mp += calc.count.camerasTurret8mp;
            _count.license += calc.count.license;
          }
        }
        const retentionPrice = this.priceCalculatorService.getRetentionPrice(_count);
        const verificationPrice = this.priceCalculatorService.getVerificationPrice(_count);
        const additionalOptionsPrice = this.priceCalculatorService.getAdditionalOptionsPrice(_count, term);

        pricing.regularPrice += (retentionPrice + verificationPrice + additionalOptionsPrice + pricing.boxesRegular) * PricingTermToYears[LicenseTerms.Years1];
        pricing.total += (retentionPrice + verificationPrice + additionalOptionsPrice + pricing.boxes) * PricingTermToYears[term];

        pricing.shipping = this.getShippingPrice(count);

        return of(PricingActions.setPricing({ pricing, count: _count }));
      }),
    ),
  );

  public calculateCountPricing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingActions.calculateCountPricing),
      withLatestFrom(this.store$.pipe(select(state => state.pricingState))),
      switchMap(([, { count, term }]) => {
        // Regular
        const pricing: Pricing = {
          boxes: 0,
          license: 0,
          cameras: 0,
          cloud: 0,
          total: 0,
          oneTimePrice: 0,
          regularPrice: 0,
          shipping: 0,
        };

        const regular: Pricing = {
          boxes: 0,
          license: 0,
          cameras: 0,
          cloud: 0,
          total: 0,
          regularPrice: 0,
          oneTimePrice: 0,
          shipping: 0,
        };

        this.calculatePricing(regular, count, LicenseTerms.Years1);
        this.calculatePricing(pricing, count, term);
        pricing.regularPrice = regular.total;
        pricing.shipping = this.getShippingPrice(count);

        return of(PricingActions.setPricing({ pricing, count }));
      }),
    ),
  );


  constructor(private actions$: Actions, private store$: Store<AppState>, private priceCalculatorService: PriceCalculatorService) {
  }

  private getShippingPrice(count: PricingCount) {
    const itemShippingPrice = 10;
    const price = (
      count.box1u88tb + count.box1u64tb + count.box1u32tb + count.box4tb + count.box2tb +
      count.camerasTurret5mp +
      count.camerasDome5mp +
      count.camerasBullet5mp +
      count.camerasTurret8mp +
      count.camerasDome8mp +
      count.camerasBullet8mp +
      count.camerasFisheye12mp +
      count.camerasPtz +
      count.camerasTurret5mpZoom +
      count.ownCamerasTurret5mp +
      count.ownCamerasDome5mp +
      count.ownCamerasBullet5mp +
      count.ownCamerasTurret8mp +
      count.ownCamerasDome8mp +
      count.ownCamerasBullet8mp +
      count.ownCamerasFisheye12mp +
      count.ownCamerasPtz +
      count.ownCamerasTurret5mpZoom +
      count.additionalSpeaker
    ) * itemShippingPrice;
    return price;
  }

  private getAddOnPrice(addOn: AddOnOptions) {
    return this.priceCalculatorService.getAddOnPrice(addOn);
  }

  private calculatePricing(pricing: Pricing, count: PricingCount, term: LicenseTerms) {
    const box2bPrice = this.priceCalculatorService.getBoxPrice(StorageOptions.Storage2TB, DeviceOptions.OrinNX16GB, term);
    const box4bPrice = this.priceCalculatorService.getBoxPrice(StorageOptions.Storage4TB, DeviceOptions.OrinNX16GB, term);
    const box1U32Price = this.priceCalculatorService.getBoxPrice(StorageOptions.Storage32TB, DeviceOptions.RACK1U32TB, term);
    const box1U64Price = this.priceCalculatorService.getBoxPrice(StorageOptions.Storage64TB, DeviceOptions.RACK1U64TB, term);
    const box1U88Price = this.priceCalculatorService.getBoxPrice(StorageOptions.Storage88TB, DeviceOptions.RACK1U88TB, term);
    const licensePrice = this.priceCalculatorService.getSWLicensePrice(term);
    const speakerLicensePrice = this.priceCalculatorService.getSpeakerLicensePrice(term);
    const sensorPrice = this.priceCalculatorService.getSensorLicensePrice(term);
    const camera5mpPrice = this.priceCalculatorService.getCameraPrice(CameraOptions.Resolution5MP, term);
    const camera8mpPrice = this.priceCalculatorService.getCameraPrice(CameraOptions.Resolution8MP, term);
    const cameraOpticalZoomPrice = this.priceCalculatorService.getCameraPrice(CameraOptions.OpticalZoom5MP, term);
    const cameraFisheyePrice = this.priceCalculatorService.getCameraPrice(CameraOptions.Fisheye12MP, term);
    const cameraPtzPrice = this.priceCalculatorService.getCameraPrice(CameraOptions.PTZ, term);
    const ownCamera5mpPrice = this.priceCalculatorService.getCameraPrice(CameraOptions.Resolution5MP, term, true);
    const ownCamera8mpPrice = this.priceCalculatorService.getCameraPrice(CameraOptions.Resolution8MP, term, true);
    const ownCameraOpticalZoomPrice = this.priceCalculatorService.getCameraPrice(CameraOptions.OpticalZoom5MP, term, true);
    const ownCameraFisheyePrice = this.priceCalculatorService.getCameraPrice(CameraOptions.Fisheye12MP, term, true);
    const ownCameraPtzPrice = this.priceCalculatorService.getCameraPrice(CameraOptions.PTZ, term, true);


    const retentionPrice = this.priceCalculatorService.getRetentionPrice(count);
    const verificationPrice = this.priceCalculatorService.getVerificationPrice(count);
    const additionalOptionsPrice = this.priceCalculatorService.getAdditionalOptionsPrice(count, term);


    pricing.boxes += box2bPrice * count.box2tb + box4bPrice * count.box4tb +
      box1U32Price * count.box1u32tb + box1U64Price * count.box1u64tb + box1U88Price * count.box1u88tb;
    pricing.license += licensePrice * count.license;
    // [Sagi] Need to review
    pricing.cameras +=
      camera5mpPrice * count.camerasTurret5mp +
      camera5mpPrice * count.camerasDome5mp +
      camera5mpPrice * count.camerasBullet5mp +
      camera8mpPrice * count.camerasTurret8mp +
      camera8mpPrice * count.camerasDome8mp +
      camera8mpPrice * count.camerasBullet8mp +
      cameraPtzPrice * count.camerasPtz +
      cameraOpticalZoomPrice * count.camerasTurret5mpZoom +
      cameraFisheyePrice * count.camerasFisheye12mp;

    pricing.oneTimePrice = ownCamera5mpPrice * count.ownCamerasTurret5mp +
      ownCamera5mpPrice * count.ownCamerasDome5mp +
      ownCamera5mpPrice * count.ownCamerasBullet5mp +
      ownCamera8mpPrice * count.ownCamerasTurret8mp +
      ownCamera8mpPrice * count.ownCamerasDome8mp +
      ownCamera8mpPrice * count.ownCamerasBullet8mp +
      ownCameraPtzPrice * count.ownCamerasPtz +
      ownCameraOpticalZoomPrice * count.ownCamerasTurret5mpZoom +
      ownCameraFisheyePrice * count.ownCamerasFisheye12mp +
      this.getAddOnPrice(AddOnOptions.Speaker) * count.additionalSpeaker;

    pricing.shipping = this.getShippingPrice(count);

    pricing.total += pricing.boxes + pricing.license + pricing.cameras;

    pricing.boxes *= PricingTermToYears[term];
    pricing.license *= PricingTermToYears[term];
    pricing.cameras *= PricingTermToYears[term];
    pricing.total *= PricingTermToYears[term];

    pricing.total += (retentionPrice + verificationPrice + additionalOptionsPrice) * PricingTermToYears[term];
  }
}
