import { AlertEntry } from '../../development/alerts.service';
import { AlertOrdering, WallAlertListPosition, WallLayoutType } from '@enums/wall.enum';
import { Dictionary } from '@ngrx/entity/src/models';
import { SelectedCamera } from '@models/alert-events.model';
import { AlertsV2DisplaySettings, AlertsV2PicInPicPosition } from '@models/alerts-v2.model';
import AlertTileConfig = WallModelV2.AlertTileConfig;

/**
 * @deprecated use wall-v2.model instead
 */
export namespace WallModelV2 {

  export enum WallLayout {
    AUTOMATIC,
    BY_LOCATION,
    DYNAMIC,
    GRID_9,
    GRID_6,
    GRID_4,
    GRID_3,
    GRID_2,
    GRID_1,
    GRID_6_VERTICAL,
    GRID_8_VERTICAL,
    GRID_4_1_3,
    GRID_5_1_4,
    GRID_6_1_5,
    GRID_6_2_4,
    GRID_7_3_4,
    GRID_7_1_6,
    GRID_8,
    GRID_9_1_8,
    GRID_10_2_8,
    GRID_10_2_8_v,
    GRID_12_1_11,
    GRID_12_12,
    GRID_13_1_center,
    GRID_13_1_12,
    GRID_16,
    GRID_16_1_15,
    GRID_20,
    GRID_20_2_18,
    GRID_25,
    GRID_30,
    GRID_36,
    GRID_48,
    GRID_14_2_12,
    GRID_15_1_14,
  }

  export const wallLayoutCameraCountV2: { [Property in WallLayout]: number } = {
    [WallLayout.GRID_1]: 1,
    [WallLayout.GRID_2]: 2,
    [WallLayout.GRID_3]: 3,
    [WallLayout.GRID_4]: 4,
    [WallLayout.GRID_4_1_3]: 4,
    [WallLayout.GRID_5_1_4]: 5,
    [WallLayout.GRID_6_1_5]: 6,
    [WallLayout.GRID_6_2_4]: 6,
    [WallLayout.GRID_6_VERTICAL]: 6,
    [WallLayout.GRID_6]: 6,
    [WallLayout.GRID_7_1_6]: 7,
    [WallLayout.GRID_7_3_4]: 7,
    [WallLayout.GRID_8]: 8,
    [WallLayout.GRID_8_VERTICAL]: 8,
    [WallLayout.GRID_9_1_8]: 9,
    [WallLayout.GRID_9]: 9,
    [WallLayout.GRID_10_2_8]: 10,
    [WallLayout.GRID_10_2_8_v]: 10,
    [WallLayout.GRID_12_1_11]: 12,
    [WallLayout.GRID_12_12]: 12,
    [WallLayout.GRID_13_1_center]: 13,
    [WallLayout.GRID_13_1_12]: 13,
    [WallLayout.GRID_16]: 16,
    [WallLayout.GRID_16_1_15]: 16,
    [WallLayout.GRID_20]: 20,
    [WallLayout.GRID_20_2_18]: 20,
    [WallLayout.GRID_25]: 25,
    [WallLayout.GRID_30]: 30,
    [WallLayout.GRID_36]: 36,
    [WallLayout.GRID_48]: 48,
    [WallLayout.GRID_14_2_12]: 14,
    [WallLayout.GRID_15_1_14]: 15,
    0: 0,
    1: 0,
    2: 0,
  };


  export interface WallSet {
    name: string;
    rotateDuration?: number;
    layout: WallLayout;
    tiles?: WallTile[];
  }

  export interface WallTile {
    cameraTileConfig?: CameraTileConfig;
    alertTileConfig?: AlertTileConfig;
    data: WallPosition;
  }

  export interface CameraTileConfig {
    titleColor: string,
    displayCameraName: boolean
  }

  export interface AlertTileConfig {
    sound: boolean,
    display: AlertsV2DisplaySettings;
    titleColor: string;
    picInPic: AlertsV2DisplaySettings;
    picInPicPos: AlertsV2PicInPicPosition;
    duration: number;
  }

  export interface WallCamera {
    edgeId?: string;
    locationId?: string;
    cameraId?: string;
    ready?: boolean;
    err?: boolean;
  }

  export interface WallPosition extends WallCamera {
    events?: WallPositionEvent[];
    camerasEvents?: SelectedCamera[];
  }

  export interface WallPositionEvent extends SelectedCamera {
    eventId: string;
  }
}

export interface WallModel {
  _id?: string;
  alertPosition: WallAlertListPosition;
  isPrivate: boolean;
  name: string;
  rotateDuration: number;
  alertOrdering: AlertOrdering;
  sets: WallModelV2.WallSet[];
  authProviderId?: string;
  timestamp?: number;
  layoutType: WallLayoutType;
  alerts: WallModelV2.WallTile;
  orgId?: string;
  appleTvId?: string;
}

export interface WallAlertMonitoringFilters {
  frequency: number;
  eventCameraIds: { eventId: string, cameraId: string }[];
}


export interface WallAlert extends AlertEntry {
  tile: number;
  alertTileConfig?: AlertTileConfig;
}

export interface WallFilters {
  query: string;
  isPrivate: boolean[];
  layoutType?: WallLayoutType;
  selectedCameras?: Dictionary<SelectedCamera>;
}

export interface WallFiltersRequest extends WallFilters {
  timestamp: number,
  limit: number
}
