import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { noop, Observable, Subscription, take, tap } from 'rxjs';
import { UserSettings } from '../../user/settings.model';
import { AuthenticationService } from '../../authentication/authentication.service';
import { AppUser } from '../../user/user.model';
import { UserSelectors } from '@states/user/user.selector-types';
import { UserActions } from '@states/user/user.action-types';

@Component({
  selector: 'app-password-settings',
  templateUrl: './password-settings.component.html',
  styleUrls: ['./password-settings.component.scss'],
})
export class PasswordSettingsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  settings: UserSettings.Settings = {
    account: {
      staySignedIn: false,
    },
    password: {
      isMfaEnabled: false,
    },
  };

  user$: Observable<AppUser.User>;

  constructor(private store: Store, private authService: AuthenticationService) {}

  ngOnInit(): void {
    const settings$ = this.store.pipe(select(UserSelectors.settings)).subscribe(settings => {
      this.settings = JSON.parse(JSON.stringify({ ...this.settings, ...settings }));
    });
    this.subscriptions.push(settings$);

    this.user$ = this.store.pipe(select(UserSelectors.userProfile));
  }

  ngOnDestroy(): void {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  save() {
    const auth = this.authService.getSession();
    const data: AppUser.UpdateUserProfileData = {
      settings: { ...this.settings },
    };
    this.store.dispatch(UserActions.UpdateUserProfile({ userId: auth.authProviderId!, data }));
  }

  resetPassword() {
    this.store
      .pipe(
        select(UserSelectors.email),
        take(1),
        tap(email => this.authService.resetPassword(email))
      )
      .subscribe(noop);
  }
}
