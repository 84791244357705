import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocationCameraItem, UiLocationSelectItem } from '@models/ui.model';
import { EdgeCamera } from '../../../cameras/camera.model';
import { MatDialog } from '@angular/material/dialog';
import { UiZoneSelectorDialogComponent, UiZoneSelectorDialogData, UiZoneSelectorDialogResult } from '../../ui-kit/ui-zone-selector/ui-zone-selector-dialog/ui-zone-selector-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { KeyValuePairs } from '../../../core/interfaces';
import { AlertEvent } from '@models/alert-events.model';
import { UiLineCrossingDialogComponent } from '../../ui-kit/ui-line-crossing/ui-line-crossing-dialog/ui-line-crossing-dialog.component';
import { UiTrafficControlDialogComponent } from '../../ui-kit/ui-traffic-control/ui-line-crossing-dialog/ui-traffic-control-dialog.component';
import { CameraEventSelectionResult } from '@models/camera.model';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { EdgeSelectors } from '@states/edge/edge.selector-types';

export enum ZoneSelectionType {
  ZONES,
  LINE_CROSSING,
  TRAFFIC_CONTROL,
  MULTIPLE_LINE_CROSSING
}

@UntilDestroy()
@Component({
  selector: 'camera-location-row',
  templateUrl: './location-row.component.html',
  styleUrls: ['./location-row.component.scss'],
})
export class LocationRowComponent implements OnInit {
  @Input() narrow = false;
  @Input() location: UiLocationSelectItem;
  @Input() multi: boolean = false;
  @Input() selectedCameras: { [key: string]: boolean } = {};
  @Input() selectedAlerts: { [key: string]: boolean } = {};
  @Input() isSingleLocation: boolean = false;
  @Input() selectedTimeZone: string;
  @Input() zoneSelection: boolean = false;
  @Input() edgeSelection: boolean = false;
  @Input() cameraEventsLookup: { [key: string]: AlertEvent[] } = {};
  @Input() withAlerts: boolean = false;
  @Input() zoneSelectionType: ZoneSelectionType = ZoneSelectionType.ZONES;

  @Output() onCameraSelected: EventEmitter<EdgeCamera.CameraItem> = new EventEmitter<EdgeCamera.CameraItem>();
  @Output() onEventSelected: EventEmitter<CameraEventSelectionResult> = new EventEmitter<CameraEventSelectionResult>();
  @Output() onZonesSelection: EventEmitter<EdgeCamera.CameraItem> = new EventEmitter<EdgeCamera.CameraItem>();
  @Output() onSelectedAll: EventEmitter<EdgeCamera.CameraItem[]> = new EventEmitter<EdgeCamera.CameraItem[]>();
  @Output() onEdgeSelected: EventEmitter<string[]> = new EventEmitter<string[]>();

  public hidden: boolean = false;
  @Input() isSelectedAll: boolean = false;

  @Input() zonesCache: KeyValuePairs<UiZoneSelectorDialogResult> = {};

  @Input() selectedEdges: string[];

  public edgesHidden: KeyValuePairs<boolean> = {};


  constructor(public dialog: MatDialog, public store$: Store) {
  }

  async initEdges() {
    if (!this.selectedEdges) {
      this.selectedEdges = [];
    }
    const edges = new Set<string>();
    for (let camera of this.location.cameras) {
      edges.add(camera.edgeId);
    }
    this.location.edges = Array.from(edges)
      .map((edgeId) => {
        return {
          id: edgeId,
          name: '',
        };
      });
    for (let edge of this.location.edges) {
      this.store$.select(EdgeSelectors.selectEdgeNameById(edge.id))
        .pipe(take(1))
        .subscribe((name) => {
          edge.name = name;
        });
      this.edgesHidden[edge.id] = false;
    }
  }

  public ngOnInit(): void {
    this.initEdges();
  }

  public edgeSelected(edgeId: string) {
    return this.selectedEdges?.includes(edgeId);
  }

  public addRemove(edgeId: string) {
    // this.selectedEdges.pop();
    // this.selectedEdges.push(edgeId);
    this.selectedEdges = [edgeId];
    this.onEdgeSelected.emit(this.selectedEdges);
  }

  public calculateHeight(array: any[]) {
    if (this.hidden) {
      return {height: '0'};
    }
    const camerasLength = array.length;
    // if (this.isSingleLocation) {
    //   return { height: '478px' };
    // }
    if (camerasLength > 3) {
      return {height: this.narrow ? '171px' : '232.5px'};
    }
    return {height: (this.narrow ? 57 : 77.5) * camerasLength + 'px'};
  }

  // public getEdgeStatus(edgeId: string): Observable<EdgeHeartBeatStatus> {
  //   return this.edgeStatusService.getEdgeStatus(edgeId);
  // }


  public hide() {
    this.hidden = !this.hidden;
  }

  public selectAll(event) {
    this.isSelectedAll = event;
    if (this.isSelectedAll) {
      if (this.withAlerts) {
        const camerasWithAlerts = this.location.cameras.map(camera => {
          const cameraEvents = this.cameraEventsLookup[camera.edgeOnly.cameraId];
          return {
            ...camera,
            alertEvents: cameraEvents ? cameraEvents?.map(event => {
              return {
                eventId: event._id,
                cameraId: camera.edgeOnly.cameraId,
                edgeId: camera.edgeId,
                locationId: camera.locationId,
              };
            }) : [],
          };
        });
        this.onSelectedAll.emit(camerasWithAlerts);
      } else {
        this.onSelectedAll.emit(this.location.cameras);
      }
    } else {
      this.onSelectedAll.emit([]);
    }
  }

  selectCamera(camera: EdgeCamera.CameraItem) {
    if (!this.zonesCache[camera.edgeOnly.cameraId]) {
      this.onCameraSelected.emit(camera);
    } else {
      const update = _.cloneDeep(camera);
      const cached = this.zonesCache[camera.edgeOnly.cameraId];
      update.zones = cached.zones;
      update.markedIdx = cached.markedIdx;
      update.zonesExclude = cached.exclude;
      this.onCameraSelected.emit(update);
    }
  }

  selectAllEdgeCameras(event, edgeId: string) {
    const checked = event.checked;
    const edgeCameras = this.location.cameras.filter((camera) => camera.edgeId === edgeId);
    for (let camera of edgeCameras) {
      if (checked && !this.selectedCameras[camera.edgeOnly.cameraId]) {
        this.selectCamera(camera);
      }
      if (!checked && this.selectedCameras[camera.edgeOnly.cameraId]) {
        this.selectCamera(camera);
      }
    }
  }


  selectZones(camera: EdgeCamera.CameraItem) {
    if (!this.selectedCameras[camera.edgeOnly.cameraId]) {
      return;
    }
    const cameraId = camera.edgeOnly.cameraId;
    const data: UiZoneSelectorDialogData = {
      camera,
      zones: this.zonesCache[cameraId]?.zones,
      markedIdx: this.zonesCache[cameraId]?.markedIdx,
      lineCrossing: this.zonesCache[cameraId]?.lineCrossing,
      trafficControl: this.zonesCache[cameraId]?.trafficControl,
      asLineCrossing: this.zoneSelectionType === ZoneSelectionType.MULTIPLE_LINE_CROSSING,
      exclude: this.zonesCache[cameraId]?.exclude,
    };
    let component: any = UiZoneSelectorDialogComponent;
    switch (this.zoneSelectionType) {
      case ZoneSelectionType.ZONES:
        component = UiZoneSelectorDialogComponent;
        break;
      case ZoneSelectionType.LINE_CROSSING:
        component = UiLineCrossingDialogComponent;
        break;
      case ZoneSelectionType.MULTIPLE_LINE_CROSSING:
      case ZoneSelectionType.TRAFFIC_CONTROL:
        component = UiTrafficControlDialogComponent;
        break;
    }
    this.dialog
      .open(component, {
        data,
        panelClass: 'modal-no-padding',
        maxWidth: '115vh',
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result: UiZoneSelectorDialogResult) => {
        if (!result) {
          return;
        }

        if (this.zoneSelectionType === ZoneSelectionType.ZONES && _.isEmpty(result.zones)) {
          if (this.zonesCache[cameraId]) {
            this.zonesCache = _.omit(this.zonesCache, cameraId);
          }
        } else if (this.zoneSelectionType === ZoneSelectionType.TRAFFIC_CONTROL && !result.trafficControl) {
          if (this.zonesCache[cameraId]) {
            delete this.zonesCache[cameraId];
          }
        } else if (this.zoneSelectionType === ZoneSelectionType.MULTIPLE_LINE_CROSSING && !result.trafficControl) {
          if (this.zonesCache[cameraId]) {
            delete this.zonesCache[cameraId];
          }
        } else {
          this.zonesCache[cameraId] = result;
        }

        const update = _.cloneDeep(camera);
        update.zones = result.zones;
        update.markedIdx = result.markedIdx;
        update.lineCrossing = result.lineCrossing;
        update.trafficControl = result.trafficControl;
        update.zonesExclude = result.exclude;
        console.log(update);
        this.onZonesSelection.emit(update);
      });
  }

  public selectEvent(event: CameraEventSelectionResult): void {
    this.onEventSelected.emit(event);
  }

  public edgeCameras(edgeId: string) {
    return this.location.cameras.filter((camera) => camera.edgeId === edgeId);
  }

  public allEdgeCamerasSelected(edgeId: string) {
    const edgeCameras = this.location.cameras.filter((camera) => camera.edgeId === edgeId);
    return edgeCameras.every((camera) => this.selectedCameras[camera.edgeOnly.cameraId]);
  }


  public trackByEdgeId(index: number, item: { id: string, name: string }) {
    return item.id;
  }

  public trackByCameraId(index: number, item: LocationCameraItem) {
    return item.edgeOnly.cameraId;
  }
}
