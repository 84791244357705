import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OrganizationSelectors } from '../store/states/organization/organization.selector-types';
import { AppState } from '../store/app.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { lastValueFrom, Observable, take } from 'rxjs';
import { PermissionModel } from '@models/permission.model';
import { PermissionSelectors } from '@states/permissions/permissions.selector-types';

@UntilDestroy()
@Directive({
  selector: '[rbacOrgAllow]',
  standalone: true,
})
export class RbacOrgAllowDirective {
  public selectIsDeveloper$: Observable<boolean> = this.store$.pipe(select(OrganizationSelectors.isDeveloper));

  public allowedPermission: PermissionModel.Permissions[];
  isDefaultOrganization: boolean;
  private isDeveloper: boolean = false;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private store$: Store<AppState>) {
    this.store$
      .pipe(
        select(OrganizationSelectors.selectActiveOrganizationAndIsDefaultOrganization),
        untilDestroyed(this),
      )
      .subscribe(res => {
        this.isDefaultOrganization = res.isDefaultOrganization;
        this.showIfUserAuthorized();
      });
    this.selectIsDeveloper$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.isDeveloper = res;
        this.showIfUserAuthorized();
      });
  }

  @Input()
  set rbacOrgAllow(permissions: PermissionModel.Permissions[]) {
    this.allowedPermission = permissions;
    this.showIfUserAuthorized();
  }

  async showIfUserAuthorized() {
    this.viewContainer.clear();
    if (
      !this.allowedPermission ||
      this.allowedPermission.length === 0) {
      return;
    }

    if (this.isDefaultOrganization) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    const isAllowed = this.store$.pipe(select(PermissionSelectors.checkAccessPermissions(this.allowedPermission, [])),
      take(1));
    const isAuthorized = await lastValueFrom(isAllowed);

    if (isAuthorized || this.isDeveloper) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
