import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LivekitService } from '../../../development/livekit.service';
import { LiveKitModels } from '@models/livekit.model';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { LiveStreamModels } from '@models/live-stream.model';

export interface UiParticipantsDialogData {
  edgeId: string;
  cameraId: string;
  showPublisher?: boolean;
  resolution: LiveStreamModels.StreamResolution;
}

export const participantInfoStateStr: { [Property in LiveKitModels.ParticipantInfo_State]: string } = {
  [LiveKitModels.ParticipantInfo_State.JOINING]: 'Joining',
  [LiveKitModels.ParticipantInfo_State.JOINED]: 'Joined',
  [LiveKitModels.ParticipantInfo_State.ACTIVE]: 'Active',
  [LiveKitModels.ParticipantInfo_State.DISCONNECTED]: 'Disconnected',

};

@Component({
  selector: 'ui-participants-dialog',
  templateUrl: './ui-participants-dialog.component.html',
  styleUrl: './ui-participants-dialog.component.scss',
})
export class UiParticipantsDialogComponent implements OnInit {

  public selectIsDeveloper$: Observable<boolean> = this.store$.pipe(select(OrganizationSelectors.isDeveloper));

  public participants: LiveKitModels.ParticipantInfo[];

  public tableTitles = ['Name', 'Identity', 'State', 'Joined at'];
  public tableFields = ['name', 'identity', 'state', 'joinedAt'];


  constructor(
    public store$: Store,
    public livekitService: LivekitService,
    public dialogRef: MatDialogRef<UiParticipantsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UiParticipantsDialogData,
  ) {
  }

  public valueParser(value: string, field: string) {
    switch (field) {
      case 'state':
        return participantInfoStateStr[+value];
      case 'joinedAt':
        return new Date(+value * 1000).toLocaleString();
      default:
        return !!value ? value : 'N/A';
    }
  }

  ngOnInit() {
    const request: LiveKitModels.GetLiveKitParticipantsRequest = {
      edgeId: this.data.edgeId,
      cameraId: this.data.cameraId,
      resolution: this.data.resolution,
    };
    this.livekitService.getParticipants(request)
      .subscribe((participants) => {
        if (this.data.showPublisher) {
          this.participants = participants;
        } else {
          this.participants = participants.filter((participant) => !participant.isPublisher);
        }
      });
  }

  public close() {
    this.dialogRef.close();
  }


}
