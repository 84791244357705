import { Organization, UserOrganizationDropDown } from '@models/organization.model';
import { ActivityLogMethod } from '@models/activity-log';
import { VariableConfig } from '@models/variable.model';
import { EventV2Document } from '@models/alerts-v2.model';
import { WallModel } from '@models/wall.model';

export const emailComparator = (user1: { email: string }, user2: { email: string }) => user1?.email === user2?.email;

export const orgComparator = (org1: Organization, org2: Organization) => org1?.orgId === org2?.orgId;

export const orgUserComparator = (user1: UserOrganizationDropDown, user2: UserOrganizationDropDown) => user1?._id === user2?._id;

export const activityLogMethodComparator = (method1: ActivityLogMethod, method2: ActivityLogMethod) => method1?.id === method2?.id;

export const variableConfigComparator = (config1: VariableConfig, config2: VariableConfig) => config1?._id === config2?._id;

export const alertEventComparator = (ev: EventV2Document, ev2: EventV2Document) => ev?._id === ev2?._id;

export const wallComparator = (ev: WallModel, ev2: WallModel) => {
  return ev?._id === ev2?._id;
};
