<ng-container *ngrxLet="selectLevel1Menu$;let mainMenuItems">
  <ng-container *ngrxLet="selectActiveOrganization$;let activeOrganization">
    <ng-container *ngrxLet="selectUser$;let selectUser">
      <ng-container *ngrxLet="selectCountUnseen$; let unseen">

        <div fxFlexFill fxLayout="row" class="sidebar-v2">
          <div class="menu-level1" fxLayout="column" fxLayoutAlign="space-between center">
            <div fxLayout="column">
              <img class="lumix-logo" src="assets/icons/logo.svg" alt="logo">
              <hr class="hr" style="margin: 16px 24px;">
              <!--    MENU ITEMS-->
              <div *ngFor="let item of mainMenuItems"
                   class="menu-item"
                   [matTooltip]="item.displayName"
                   matTooltipPosition="right"
                   matTooltipClass="sidebar-tooltip"
                   fxLayout="row"
                   fxLayoutAlign="center center"
              >
                <ng-container *ngrxLet="selectActiveOrganization$; let org">
                  <a
                    *rbacAllow="item.roles"
                    [routerLink]="item.disabled ? null : '/' + item.path"
                    routerLinkActive="active"
                    class="menu-link"
                    matRipple
                    matRippleColor="rgba(67, 103, 246, 0.1)"
                  >
                    <span [class]="item.class" class="icon"></span>
                  </a>
                </ng-container>
              </div>

              <div fxLayout="column" *ngIf="swUpdateAvailable" class="sw-update-window">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                  <div fxLayoutAlign="center center" class="icon-16 svg-fill-red">
                    <mat-icon svgIcon="info"></mat-icon>
                  </div>
                  <div class="title">
                    {{ swUpdateTrial ? 'Automatic update failed' : 'Lumana app update required' }}
                  </div>
                </div>
                <ng-container *ngIf="!swUpdateTrial; else failed">
                  <div class="description">A new version is available. Please update now in order to continue
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <ui-button type="primary" text="Update" (clickAction)="reloadPage()"></ui-button>
                  </div>
                </ng-container>
                <ng-template #failed>
                  <div class="description">The automatic update has failed. Please update the version manually.
                  </div>
                  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" class="w-100">
                    <div>Mac</div>
                    <ui-input [disabled]="true" placeholder="Cmd + Shift + R" class="w-100"></ui-input>
                  </div>
                  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" class="w-100">
                    <div>Windows</div>
                    <ui-input [disabled]="true" placeholder="Ctrl + F5" class="w-100"></ui-input>
                  </div>
                </ng-template>

              </div>

            </div>
            <!--        BOTTOM MENU-->
            <div class="bottom-menu"
                 fxLayout="column"
                 fxLayoutAlign="start center"
                 *ngrxLet="isDeveloper$; let isDeveloper"
            >
              <mat-slide-toggle class="mb-16" *ngIf="isDevTeam$|ngrxPush" [formControl]="darkThemeFormControl"
              ></mat-slide-toggle>
              <div class="help-icon-wrapper" fxLayoutAlign="center center" [class.active]="isHelpSection"
                   matTooltip="Help" matTooltipClass="sidebar-tooltip" matTooltipPosition="right"
              >
              <span class="help-icon ui-icon-help icon" (click)="about(isDeveloper)"
              ></span>
              </div>
              <div class="user-icon-wrapper" fxLayoutAlign="center center"
                   matTooltip="User" matTooltipClass="sidebar-tooltip" matTooltipPosition="right"
              >
                <!--            USER SUB MENU-->
                <app-ui-dropdown [height]="userMenu.clientHeight">
                  <div input class="flex-row center-center w-100">
                    <span class="user-icon icon ui-icon-user"
                          [matBadge]="unseen"
                          matBadgePosition="after"
                          matBadgeColor="accent"
                          [matBadgeHidden]="unseen === 0"
                    ></span>
                  </div>

                  <div class="user-menu" #userMenu content>
                    <div class="user-menu-inner">
                      <ng-container *ngIf="selectUser">
                        <div class="menu-title">
                          {{ selectUser.firstname }} {{ selectUser.lastname }}

                        </div>
                        <div class="menu-title">
                          {{ selectUser.email }}
                        </div>
                      </ng-container>
                      <hr class="hr">
                      <div fxLayout="row"
                           fxLayoutAlign="space-between center"
                           class="menu-item"
                           (click)="isNotificationVisible = !isNotificationVisible;"
                      >
                        <div fxLayout="row"
                             fxLayoutAlign="start center"
                        >
                          <span class="icon ui-icon-notifications"></span>
                          Notifications
                        </div>
                        <mat-icon *ngIf="unseen" class="red-dot">fiber_manual_record</mat-icon>
                      </div>
                      <div fxLayout="row"
                           fxLayoutAlign="start center"
                           class="menu-item"
                           [routerLink]="routerSegments.account"
                      >
                        <span class="icon ui-icon-settings"></span>
                        User settings
                      </div>
                      <div fxLayout="row"
                           fxLayoutAlign="start center"
                           class="menu-item logout-button"
                           (click)="logout()"
                      >
                        <span class="icon ui-icon-logout"></span>
                        Logout
                      </div>
                    </div>
                  </div>

                </app-ui-dropdown>


              </div>

              <hr class="hr" style="margin: 8px 24px; width: 20px;">

              <!--      ORG SUB MENU-->

              <div class="active-organization w-100"
                   matTooltip="Organization" matTooltipClass="sidebar-tooltip" matTooltipPosition="right"
              >
                <div class="w-100" style="padding: 8px 0">
                  <app-ui-dropdown [height]="orgManu.clientHeight">
                    <div input class="flex-row center-center w-100">
                      <ui-avatar class="org-avatar"
                                 [name]="activeOrganization?.orgName"
                                 [color]="activeOrganization?.orgColor"
                      ></ui-avatar>
                    </div>

                    <div class="user-menu" #orgManu content>
                      <div class="user-menu-inner">
                        <div class="menu-title">
                          <ui-truncated-html class="truncated-name">
                            {{ activeOrganization?.orgName }}
                          </ui-truncated-html>
                        </div>
                        <hr class="hr">
                        <div fxLayout="row"
                             fxLayoutAlign="start center"
                             class="menu-item"
                             (click)="toggleSwitchOrgMenu(); isOrgMenuOpen=false"
                        >
                          <span class="icon ui-icon-company"></span>
                          Switch organization
                        </div>
                        <div *rbacOrgAllow="[permissions.OrganizationDatabase]"
                             fxLayout="row"
                             fxLayoutAlign="start center"
                             class="menu-item"
                             [routerLink]="routerSegments.database"
                             (click)="isOrgMenuOpen=false"

                        >
                          <span class="icon ui-icon-dashboard"></span>
                          Organization database
                        </div>
                        <div fxLayout="row"
                             fxLayoutAlign="start center"
                             class="menu-item"
                             [routerLink]="routerSegments.settings"
                             (click)="isOrgMenuOpen=false"
                        >
                          <span class="icon ui-icon-settings"></span>
                          Organization settings
                        </div>
                      </div>
                    </div>
                  </app-ui-dropdown>
                </div>


              </div>

            </div>

          </div>
          <!--      SELECT ORGANIZATION -->
          <ng-container *ngrxLet="filteredUserOrganizations$;let organizationsDropdown">
            <div *ngIf="isOrgSelectorOpen"
                 class="sub-menu"
            >
              <div class="overlay" (click)="closeSwitchOrgMenu()">

              </div>
              <div class="sub-menu-inner" style="width:256px">
                <div class="inner">
                  <div fxLayout="row" fxLayoutAlign="start center" class="active-organization organization-item title">
                    <ui-avatar class="org-avatar"
                               [name]="activeOrganization?.orgName"
                               [color]="activeOrganization?.orgColor"
                    ></ui-avatar>
                    <ui-truncated-html class="truncated-name">
                      {{ activeOrganization?.orgName }}
                    </ui-truncated-html>
                  </div>
                  <hr class="hr" style="margin: 12px 8px 12px 8px">
                  <div *ngIf="(selectUserOrganizationsDropdown$|async)?.length>8" class="mt-8">
                    <ui-search placeholder="Search for organization"
                               [heightPx]="40"
                               (onInput)="onSearchOrganizationInput($event)"
                    ></ui-search>
                  </div>
                  <div class="orgnization-list-wrapper">
                    <div fxLayout="row"
                         fxLayoutAlign="start center"
                         class="organization-item list"
                         *ngFor="let organization of organizationsDropdown"
                         (click)="switchOrg(organization.id)"

                    >
                      <ui-avatar class="org-avatar selectable"
                                 [name]="organization.name"
                                 [color]="organization.color"
                                 [fontSize]="10"
                      ></ui-avatar>
                      <ui-truncated-html class="truncated-name">
                        {{ organization.name }}
                      </ui-truncated-html>
                    </div>
                  </div>
                  <hr class="hr" style="margin: 0px 8px 8px 8px">
                  <div class="button-wrapper">
                    <ui-button text="Create new" type="secondary" (click)="createOrganization()">
                    </ui-button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!--          NOTIFICATIONS-->
          <ng-container *ngIf="isNotificationVisible">
            <div class="sub-menu">
              <div class="overlay" (click)="isNotificationVisible = false;">

              </div>
              <div class="sub-menu-inner notification-block">
                <div class="header">
                  <div class="title">
                    Notifications
                  </div>
                  <div fxLayout="row" fxLayoutAlign="space-between center" class="unread-block">
                    <div>
                      <span class="description">Show unread only</span>
                      <ui-toggle [ngModel]="selectUnreadOnly$|ngrxPush"
                                 (ngModelChange)="filterByState($event)"
                      ></ui-toggle>
                    </div>
                    <span class="clear" (click)="clearAllNotifications()">Clear all</span>
                  </div>
                </div>
                <ng-scrollbar class="scrollbar">
                  <div *ngrxLet="selectUserNotifications$;let selectUserNotifications"
                       class="notification-inner"
                       fxFlexFill
                  >
                    <ng-container *ngrxLet="selectNotifications$;let selectNotifications">
                      <div *ngFor="let notification of selectUserNotifications; trackBy:identify"
                           class="notification-wrapper"
                      >
                        <ui-notification-action [userNotification]="notification"
                                                [notifications]="selectNotifications"
                                                (onSeen)="seenNotification($event)"
                                                (onSave)="onDateSet($event)"
                                                (onDelete)="deleteNotification($event)"
                                                (closeBar)="closeNotificationsBar()"
                        >
                        </ui-notification-action>
                      </div>
                    </ng-container>
                  </div>
                </ng-scrollbar>
              </div>
            </div>

          </ng-container>
          <!--    CONTENT OF ROUTES-->
          <div class="root-content-wrapper">
            <router-outlet></router-outlet>
          </div>

        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
