import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ObjectOptions } from '@models/alerts-v2.model';
import { SharedService } from '../../../../development/shared.service';
import * as _ from 'lodash';
import { PersonSelectionFormFields, SearchObjectTypes, SearchQueryOperator, VehicleSelectionFormFields } from '@enums/search.enum';
import { ObjectAttributesService } from '../../../../shared/object-attributes/object-attributes.service';
import { MatDialog } from '@angular/material/dialog';
import { StepObjectsFiltersDialogComponent, StepObjectsFiltersDialogData } from './step-objects-filters-dialog/step-objects-filters-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SearchSelection, SearchSelectionCarProperty, SearchSelectionPersonProperty } from '@models/search.model';
import { ageTypeRadioValues } from '@consts/alert-events.const';
import { filter, Observable, Subscription, take } from 'rxjs';
import { CameraEditSelectors } from '@states/camera-edit/camera-edit.selector-types';
import { select, Store } from '@ngrx/store';
import { GroupModels } from '@models/people.model';
import { VehicleModels } from '@models/vehicle.model';

export const objectsStr: { [Property in ObjectOptions]: string } = {
  [ObjectOptions.people]: 'people',
  [ObjectOptions.vehicles]: 'vehicles',
  [ObjectOptions.pets]: 'pets',
  [ObjectOptions.shopping_carts]: 'shopping carts',

};

export interface SelectedObject {
  type: ObjectOptions;
  strict?: boolean;
  filters?: SearchSelectionPersonProperty[] | SearchSelectionCarProperty;
  form?: AbstractControl;
  excludePeople?: GroupModels.GroupSearchItem[];
  includePeople?: GroupModels.GroupSearchItem[];
  excludeVehicles?: VehicleModels.Vehicle[];
  includeVehicles?: VehicleModels.Vehicle[];
}

export interface StepObjectsData {
  objects: SelectedObject[];
}

export interface StepObjectsSelectorGroupEntry {
  icon: string;
  name: string;
  value: any;
}

export interface StepObjectsSelectorGroup {
  name: string;
  options: StepObjectsSelectorGroupEntry[];
}

@UntilDestroy()
@Component({
  selector: 'step-objects-selector',
  templateUrl: './step-objects-selector.component.html',
  styleUrls: ['./step-objects-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StepObjectsSelectorComponent),
      multi: true,
    },
  ],
})
export class StepObjectsSelectorComponent implements OnInit, ControlValueAccessor, AfterViewInit, OnChanges {

  public objectsStr = objectsStr;

  @Output() change: EventEmitter<SelectedObject[]> = new EventEmitter<SelectedObject[]>();

  @Input() options: any = ObjectOptions;
  @Input() optionsArr: any[];
  @Input() optionsGroups: StepObjectsSelectorGroup[];
  @Input() optionsStr: string[];
  @Input() optionsIcon: string[] = [];
  @Input() selectionStr: string[] = [];
  @Input() disable = false;
  @Input() multiple = false;

  @Input() placeholder: string = '';
  @Input() displayAnd = false;

  filtered;

  public ObjectOptions = ObjectOptions;

  public readonly control: UntypedFormControl = new UntypedFormControl();

  public selectOrgProtectiveGear$: Observable<boolean> = this.store$.pipe(select(CameraEditSelectors.selectOrgProtectiveGear));
  public selectOrgShoppingCarts$: Observable<boolean> = this.store$.pipe(select(CameraEditSelectors.selectOrgShoppingCart));

  @Input() value: any;
  @Input() filterEnabled = false;

  selected = false;

  query: string = '';

  maxChars = 10;

  width = 150;

  public personArray: UntypedFormArray;
  public vehicleArray: UntypedFormArray;
  public petArray: UntypedFormArray;
  public shoppingCartArray: UntypedFormArray;

  public excludePeople?: GroupModels.GroupSearchItem[] = [];
  public includePeople?: GroupModels.GroupSearchItem[] = [];
  public excludeVehicles?: VehicleModels.Vehicle[] = [];
  public includeVehicles?: VehicleModels.Vehicle[] = [];

  public isPersonFilters = false;
  public isVehicleFilters = false;

  public orgProtectiveGear = false;
  public orgShoppingCart = false;

  public personSubscription: Subscription;

  constructor(private elem: ElementRef,
              private fb: UntypedFormBuilder,
              private objectAttributesService: ObjectAttributesService,
              private dialog: MatDialog,
              private store$: Store,
              private sharedService: SharedService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  public get peopleSelected(): boolean {
    return this.control?.value?.includes(`${ObjectOptions.people}`);
  }

  public get vehiclesSelected(): boolean {
    return this.control?.value?.includes(`${ObjectOptions.vehicles}`);
  }

  private _onChange = (value: any | null) => undefined;

  public registerOnChange(fn: (value: Date | null) => void): void {
    this._onChange = fn;
  }

  private _onTouched = () => undefined;

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public checkPersonFilters() {
    let properties;
    if (!this.personArray?.length) {
      return false;
    }
    try {
      properties = this.personArray?.controls[0]['controls']?.properties?.value;
    } catch (e) {
      return false;
    }
    for(let [key, value] of Object.entries(properties)) {
      if (!value['enabled']) {
        continue;
      }
      switch (key) {
        case 'carryingType':
        case 'protectivegearType':
        case 'accessoryType':
          if (!!value['props']?.type?.length) {
            return true;
          }
          break;
        case 'ageType':
          if (!!value['props']?.kids || !!value['props']?.adults) {
            return true;
          }
          break;
        case 'hairType':
        case 'footwearType':
          if (!!value['props']?.colors?.length) {
            return true;
          }
          break;
        case 'genderType':
          if (!!value['props']?.male || !!value['props']?.female) {
            return true;
          }
          break;
        case 'upperbodyType':
        case 'lowerbodyType':
          if (!!value['props']?.colors?.length || !!value['props']?.type?.length) {
            return true;
          }
          break;
      }
    }
    return false;
  }

  public checkVehicleFilters() {
    let properties;
    if (!this.vehicleArray?.length) {
      return false;
    }
    try {
      properties = this.vehicleArray?.controls[0]['controls']['properties']['value'];
    } catch (e) {
      return false;
    }
    for(let [key, value] of Object.entries(properties)) {
      if (!value['enabled']) {
        continue;
      }
      switch (key) {
        // case 'additionalProperties':
        //   if (!!value['props']?.region || !!value['props']?.plate) {
        //     return true;
        //   }
        //   break;
        default:
          if (!!value['value']?.length) {
            return true;
          }
      }
    }

    return false;
  }

  private loadPersonForm(form: any) {
    const searchSelection = this.fb.group({
      type: [],
      operator: SearchQueryOperator.AND,
      properties: this.objectAttributesService.initPersonProperties(this.orgProtectiveGear),
      enabled: true,
      collapsed: false,
    });
    searchSelection.patchValue(form);
    this.personArray.push(searchSelection);
  }

  private loadVehicleForm(form: any) {
    const vehicleSearchSelection = this.fb.group({
      type: [],
      operator: SearchQueryOperator.AND,
      properties: this.objectAttributesService.initVehicleProperties(false),
      enabled: true,
      collapsed: false,
    });
    vehicleSearchSelection.patchValue(form);
    this.vehicleArray.push(vehicleSearchSelection);
  }

  private loadPetForm(form: any) {
    const petSelection = this.fb.group({
      type: [],
      operator: SearchQueryOperator.AND,
      enabled: true,
      collapsed: false,
    });
    petSelection.patchValue(form);
    this.petArray.push(petSelection);
  }

  private loadShoppingCartForm(form: any) {
    const shoppingCartSelection = this.fb.group({
      type: [],
      operator: SearchQueryOperator.AND,
      enabled: true,
      collapsed: false,
    });
    shoppingCartSelection.patchValue(form);
    this.shoppingCartArray.push(shoppingCartSelection);
  }

  initForm() {
    this.value.forEach((form, index) => {
      if (form.type === ObjectOptions.people) {
        this.loadPersonForm(form);
      } else if (form.type === ObjectOptions.vehicles) {
        this.loadVehicleForm(form);
      } else if (form.type === ObjectOptions.pets) {
        this.loadPetForm(form);
      } else if (form.type === ObjectOptions.shopping_carts) {
        this.loadShoppingCartForm(form);
      }
    });
  }

  public optionStr(index: number): string {
    return objectsStr[index];
  }

  public ngOnInit(): void {
    // Init object arrays
    this.vehicleArray = this.fb.array([]);
    this.petArray = this.fb.array([]);
    this.shoppingCartArray = this.fb.array([]);

    if (this.options) {
      this.optionsArr = Object.keys(this.options)
        .filter((v) => !isNaN(Number(v)));

      if (!this.optionsStr?.length) {
        this.optionsStr = Object.values(objectsStr);
      }
    }

    if (this.value || this.value === 0) {
      this.control.setValue(this.value);
      this.selected = true;
    } else {
      this.control.setValue([]);
    }
    this.maxChars = Math.max(...(this.optionsStr.map(el => el.length)));

    if (!this.selectionStr || !this.selectionStr.length) {
      this.selectionStr = this.optionsStr;
    }

    this.selectOrgProtectiveGear$.pipe(untilDestroyed(this))
      .subscribe((orgProtectiveGear) => {
        this.orgProtectiveGear = orgProtectiveGear;
        this.personArray = this.fb.array([]);
        const searchSelection = this.fb.group({
          type: SearchObjectTypes.PERSON,
          operator: SearchQueryOperator.AND,
          properties: this.objectAttributesService.initPersonProperties(this.orgProtectiveGear),
          strict: true,
          enabled: true,
          collapsed: true,
        });
        this.personArray.push(searchSelection);

        if (this.personSubscription) {
          this.personSubscription.unsubscribe();
        }
        this.personSubscription = this.personArray.valueChanges.pipe(untilDestroyed(this))
          .subscribe((value) => {
            this.selected = true;
            this.isPersonFilters = this.checkPersonFilters();
            this.emitChange();
          });

      });

    const vehicleSearchSelection = this.fb.group({
      type: SearchObjectTypes.VEHICLE,
      operator: SearchQueryOperator.AND,
      properties: this.objectAttributesService.initVehicleProperties(true),
      strict: true,
      collapsed: true,
    });
    this.vehicleArray.push(vehicleSearchSelection);

    this.vehicleArray.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.selected = true;
        this.isVehicleFilters = this.checkVehicleFilters();
        this.emitChange();
      });

    this.control.valueChanges.pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.selected = true;
        const fieldsAreValid =
          this.control.valid;
        const value = fieldsAreValid ? this.control.value : null;

        this.emitChange();

        this._onChange(value);
        this._onTouched();
      });

    this.filtered = this.optionsArr;

    if (this.value) {
      this.control.setValue([]);
      for(let object of this.value) {
        this.control.value.push(object.type.toString());
      }
      this.patchPerson();
      this.patchVehicle();
    }
  }

  patchVehicle() {
    const idx = this.value.findIndex((v) => v.type === ObjectOptions.vehicles);
    const filters = this.value[idx]?.filters ?? [];
    const val = this.vehicleArray.controls[0]['controls'].properties.value;
    const patch = {};
    for(let key of Object.keys(filters)) {
      if (filters[key].length) {
        patch[key] = {
          enabled: true,
          value: filters[key],
        };
      }
    }
    this.vehicleArray.controls[0]['controls'].properties.patchValue(patch);
    this.excludeVehicles = _.cloneDeep(this.value[0].excludeVehicles ?? []);
    this.includeVehicles = _.cloneDeep(this.value[0].includeVehicles ?? []);
  }

  patchPerson() {
    const filters = !!this.value[0]?.filters?.length ? this.value[0]?.filters : [];
    const val = this.personArray.controls[0]['controls'].properties.value;
    const formatted = {};
    for(let filter of filters) {
      const props = {};

      const typeFilter = val[filter.name]?.props ? Object.keys(val[filter.name]?.props)
        .includes('type') : false;
      const colorsFilter = val[filter.name]?.props ? Object.keys(val[filter.name]?.props)
        .includes('colors') : false;

      switch (filter.name) {
        case 'ageType':
          props['kids'] = filter.value.includes('less_18');
          props['adults'] = filter.value.includes('18_to_60');
          break;
        default:
          if (typeFilter || colorsFilter) {
            props['type'] = typeFilter ? filter.value : null;
            props['colors'] = colorsFilter ? filter.colors : null;
          } else {
            for(let prop of filter.value) {
              props[prop] = true;
            }
          }

      }
      formatted[filter.name] = {
        enabled: filter.enabled,
        props,
      };
    }
    this.personArray.controls[0]['controls'].properties.patchValue(formatted);
    this.excludePeople = _.cloneDeep(this.value[0].excludePeople ?? []);
    this.includePeople = _.cloneDeep(this.value[0].includePeople ?? []);
  }

  public writeValue(value: any | null): void {
    value = value || null;
    this.control.setValue(value);
  }

  selectFilters(type: ObjectOptions) {
    switch (type) {
      case ObjectOptions.people:
        return this.personArray.controls[0]['controls'].properties.value;
      case ObjectOptions.vehicles:
        return this.vehicleArray[0];
      case ObjectOptions.pets:
        return this.petArray[0];
      case ObjectOptions.shopping_carts:
        return this.shoppingCartArray[0];
    }
  }

  formatObjects() {
    const objectSelections = [..._.cloneDeep(this.personArray.value), ..._.cloneDeep(this.vehicleArray.value), ..._.cloneDeep(this.petArray.value), ..._.cloneDeep(this.shoppingCartArray.value)];
    const searchSelections: SearchSelection[] = objectSelections?.map(selection => {
      let properties = [];
      let searchSelectionCarProperty: SearchSelectionCarProperty = {};
      const propsKeys = Object.keys(selection.properties ?? {});

      if (selection.type === SearchObjectTypes.PERSON) {
        propsKeys.forEach(propKey => {
          let value = [];
          let colors = [];
          const props = selection.properties[propKey]?.props;
          switch (propKey) {
            case PersonSelectionFormFields.genderType:
              const propsKeys = Object.keys(props);
              propsKeys.forEach(_prop => {
                if (props[_prop]) {
                  value.push(_prop);
                }
              });
              break;
            case PersonSelectionFormFields.ageType:
              const ageKeys = Object.keys(props);
              ageKeys.forEach(_prop => {
                if (props[_prop]) {
                  value = value.concat(ageTypeRadioValues[_prop]);
                }
              });
              break;
            case PersonSelectionFormFields.footwearType:
            case PersonSelectionFormFields.hairType:
              colors = (props['colors'] as string[]) ?? [];
              break;
            case PersonSelectionFormFields.lowerbodyType:
            case PersonSelectionFormFields.upperbodyType:
              value = (props['type'] as string[]) ?? [];
              colors = (props['colors'] as string[]) ?? [];
              break;
            case PersonSelectionFormFields.accessoryType:
            case PersonSelectionFormFields.carryingType:
              value = (props['type'] as string[]) ?? [];
              break;
            case PersonSelectionFormFields.protectiveGear:
              value = (props['type'] as string[]) ?? [];
              break;
          }
          const prop: SearchSelectionPersonProperty = {
            name: propKey,
            enabled: selection.properties[propKey].enabled,
            operator: selection.properties[propKey].operator,
            value,
            colors,
          };
          properties.push(prop);
        });
      } else if (selection.type === SearchObjectTypes.VEHICLE) {
        const colors = selection.properties[VehicleSelectionFormFields.colors];
        const model = selection.properties[VehicleSelectionFormFields.model];
        const plate = selection.properties[VehicleSelectionFormFields.plate];
        const make = selection.properties[VehicleSelectionFormFields.make];
        const types = selection.properties[VehicleSelectionFormFields.type];
        const additionalProperties = selection.properties[VehicleSelectionFormFields.additionalProperty]?.props;
        // const plate = additionalProperties['plate'] as string;
        // const region = additionalProperties['region'] as string;

        // if (selection.properties[VehicleSelectionFormFields.additionalProperty].enabled) {
        //   if (plate) {
        //     searchSelectionCarProperty.plate = plate;
        //   }
        //   if (region) {
        //     searchSelectionCarProperty.region = region;
        //   }
        // }
        if (colors.enabled && colors.value) {
          searchSelectionCarProperty.colors = selection.properties[VehicleSelectionFormFields.colors].value;
        }
        if (model.enabled && model.value) {
          searchSelectionCarProperty.model = selection.properties[VehicleSelectionFormFields.model].value;
        }
        if (plate.enabled && plate.value) {
          searchSelectionCarProperty.plate = selection.properties[VehicleSelectionFormFields.plate].value;
        }
        if (make.enabled && make.value) {
          searchSelectionCarProperty.make = selection.properties[VehicleSelectionFormFields.make].value;
        }
        if (types.enabled && types.value) {
          searchSelectionCarProperty.type = selection.properties[VehicleSelectionFormFields.type].value;
        }
      } else if (selection.type === SearchObjectTypes.PET) {
      }

      const result: SearchSelection = {
        ...selection,
        properties: selection.type === SearchObjectTypes.PERSON ? properties : searchSelectionCarProperty,
      };
      if (selection.type === SearchObjectTypes.PET) {
        delete result.properties;
      }

      return result;
    });

    return searchSelections;
  }

  emitChange() {
    const objects: SelectedObject[] = [];
    const formatted: SearchSelection[] = this.formatObjects();
    for(let objectType of this.control.value) {
      const type = +objectType;
      let object: SelectedObject;
      switch (type) {
        case ObjectOptions.shopping_carts:
          object = {
            type,
          };
          break;
        case ObjectOptions.pets:
          object = {
            type,
          };
          break;
        case ObjectOptions.people:
          object = {
            type,
            filters: formatted[objectType]?.properties,
            strict: formatted[objectType]?.strict,
            excludePeople: _.cloneDeep(this.excludePeople),
            includePeople: _.cloneDeep(this.includePeople),
          };
          break;
        case ObjectOptions.vehicles:
          object = {
            type,
            filters: formatted[objectType]?.properties,
            strict: formatted[objectType]?.strict,
            excludeVehicles: _.cloneDeep(this.excludeVehicles),
            includeVehicles: _.cloneDeep(this.includeVehicles),
          };
          break;
      }
      objects.push(object);
    }
    this.change.emit(objects);
  }

  search() {
    if (!!this.query) {
      this.filtered = this.optionsArr.filter(
        (item) => this.optionsStr[item].toLowerCase()
          .includes(this.query.toLowerCase()));
    } else {
      this.filtered = this.optionsArr;
    }

  }

  public ngAfterViewInit(): void {
    let elements = this.elem?.nativeElement?.querySelectorAll('.mat-select-placeholder');
    this.width = elements[0]?.getBoundingClientRect()?.width * 1.2 + 25;
  }

  clearPersonProps() {
    const initVal = this.objectAttributesService.initPersonProperties(this.orgProtectiveGear).value;
    this.personArray.controls[0].get('properties')
      .setValue(initVal);
  }

  clearVehicleProps() {
    const initVal = this.objectAttributesService.initVehicleProperties().value;
    this.vehicleArray.controls[0].get('properties')
      .setValue(initVal);
  }

  public selectionChange(event) {
    if (!event?.value?.includes(ObjectOptions.vehicles)) {
      // this.clearVehicleProps();
    }

    if (!event?.value?.includes(ObjectOptions.people)) {
      // this.clearPersonProps();
    }
  }

  public openFilters(index: ObjectOptions) {
    let type: SearchObjectTypes;
    switch (index) {
      case ObjectOptions.people:
        type = SearchObjectTypes.PERSON;
        break;
      case ObjectOptions.vehicles:
        type = SearchObjectTypes.VEHICLE;
        break;
    }
    const data: StepObjectsFiltersDialogData = {
      selections: this.personArray,
      vehicleArray: this.vehicleArray,
      petArray: this.petArray,
      shoppingCartArray: this.shoppingCartArray,
      single: true,
      type,
      excludePeople: this.excludePeople,
      includeVehicles: this.includeVehicles,
      includePeople: this.includePeople,
      excludeVehicles: this.excludeVehicles,
    };

    this.dialog.open(StepObjectsFiltersDialogComponent, {
        data,
        width: '700px',
        panelClass: 'modal-no-padding',
      })
      .afterClosed()
      .subscribe(() => {
        this.emitChange();

      });
  }

}
