import { AbstractControl } from '@angular/forms';

export namespace ValidatorsHelpers {

  export const lettersNumbersSpaceUnderscoreStringValidation = (control: AbstractControl): { invalid: string } => {
    const chartNameRegex = new RegExp(/[^a-zA-Z0-9-_ ]/g);
    const isNameNotOk = chartNameRegex.test(control.value);
    return !isNameNotOk
      ? null : { invalid: 'Name must contains only letters, numbers, space or underscore' };
  };

  export const lettersNumbersUnderscoreStringValidation = (control: AbstractControl): { invalid: string } => {
    const chartNameRegex = new RegExp(/[^a-zA-Z0-9-_]/g);
    const isNameNotOk = chartNameRegex.test(control.value);
    return !isNameNotOk
      ? null : { invalid: 'Name must contains only letters, numbers or underscore' };
  };
}
