import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiLocationSelectItem } from '@models/ui.model';
import { Observable } from 'rxjs';
import { CamerasService } from '../../../../cameras/cameras.service';
import { SelectedCamera } from '@models/alert-events.model';
import { EdgeCamera } from '../../../../cameras/camera.model';
import { PulsationModels } from '@models/pulsation.model';
import { EdgeStatusService } from '../../../../edge/edge-status.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-location-row',
  templateUrl: './location-row.component.html',
  styleUrls: ['./location-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationRowComponent implements OnInit {

  @Input() location: UiLocationSelectItem;
  @Input() selectedCameras: { [cameraId: string]: SelectedCamera };
  @Input() maxSelected: number;
  @Input() totalLocations: number;
  @Output() onCameraSelected: EventEmitter<SelectedCamera> = new EventEmitter<SelectedCamera>();
  @Output() onLocationSelected: EventEmitter<{ location: UiLocationSelectItem, selected: boolean }> = new EventEmitter<{ location: UiLocationSelectItem, selected: boolean }>();

  public isExpanded = true;

  constructor(private camerasService: CamerasService,
              private edgeStatusService: EdgeStatusService,
  ) {
  }

  ngOnInit(): void {
  }

  public cameraSnapshot(cameraId: string): Observable<any> {
    return this.camerasService.getCameraSnapshot(cameraId);
  }

  public selectCamera(camera: EdgeCamera.CameraItem) {
    this.onCameraSelected.emit({ edgeId: camera.edgeId, cameraId: camera.edgeOnly.cameraId, locationId: camera.locationId });
  }

  public selectLocation(event: MatCheckboxChange, location: UiLocationSelectItem) {
    const isSelected = event.checked;
    this.onLocationSelected.emit({ location, selected: isSelected });
  }

  public getCameraPulsationStatus(cameraId: string): Observable<PulsationModels.ComponentStatus> {
    return this.edgeStatusService.getCameraPulsationStatus(cameraId);
  }

  public isLocationSelected() {
    const locationCameras = this.location.cameras;
    const selectedCameras = Object.keys(this.selectedCameras);
    if (!locationCameras.length) {
      return false;
    }
    return locationCameras.every(locationCamera => selectedCameras.includes(locationCamera.edgeOnly.cameraId));
  }

  get selectedCamerasCount(): number {
    return Object.values(this.selectedCameras)?.length;
  }
}
