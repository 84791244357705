import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderColor } from '@enums/shared.enum';
import * as OrganizationActions from '@states/organization/organization.actions';
import * as InviteActions from '@states/invite/invite.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { Auth0Service } from '../../authentication/auth0.service';
import { OrganizationEffect } from '@effects/organization.effect';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ofType } from '@ngrx/effects';
import { routerSegments } from '@consts/routes';

@UntilDestroy()
@Component({
  selector: 'app-no-auth',
  templateUrl: './no-auth.component.html',
  styleUrls: ['./no-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoAuthComponent implements OnInit {
  public preloaderColor = PreloaderColor;
  public inviteId: string;
  private orgId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store<AppState>,
    private auth0Service: Auth0Service,
    private organizationEffect: OrganizationEffect,
  ) {
  }

  ngOnInit(): void {
    this.organizationEffect.checkIfUserSigned$
      .pipe(untilDestroyed(this), ofType(OrganizationActions.checkIfUserSignedSuccess))
      .subscribe(res => {
        // sessionStorage.setItem('invite', this.inviteId);
        // localStorage.setItem('invite', this.inviteId);
        const access_token = localStorage.getItem('access_token');
        if (!!res.orgId) {
          this.orgId = res.orgId;
          if (!res.user) {
            this.router.navigate([routerSegments.auth, routerSegments.signUp], {
              queryParams: {
                inviteId: this.inviteId,
              },
            });
          } else {
            if (access_token) {
              this.store$.dispatch(InviteActions.acceptInvite({ id: this.inviteId }));
              // this.router.navigate(['settings/organization']);
            } else {
              this.store$.dispatch(InviteActions.acceptInviteNoAuth({ id: this.inviteId }));
            }
          }
        }
      });

    this.organizationEffect.sendAcceptInvite$
      .pipe(
        untilDestroyed(this),
        ofType(InviteActions.sendAcceptSuccess),
      )
      .subscribe(() => {
        sessionStorage.clear();
        this.router.navigate([routerSegments.location]);
      });


    this.organizationEffect.acceptInviteNoAuth$
      .pipe(
        untilDestroyed(this),
        ofType(InviteActions.acceptInviteNoAuthSuccess),
      )
      .subscribe(() => {
        sessionStorage.clear();
        this.router.navigate([routerSegments.auth]);
      });

    this.route.queryParams.pipe()
      .subscribe(res => {
        if (res['login']) {
          this.router.navigate([routerSegments.auth]);
        } else {
          this.inviteId = res['inviteId'];
          this.store$.dispatch(OrganizationActions.checkIfUserSigned({ inviteId: res['inviteId'] }));
        }
      });
  }

  public login() {
    this.router.navigate([routerSegments.auth]);
  }
}
