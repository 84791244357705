import { Room } from 'livekit-client';
import { LiveStreamModels } from '@models/live-stream.model';

export namespace LiveKitModels {
  export interface CreateLiveKitTokenRequest {
    edgeId: string;
    cameraId: string;
    participantName: string;
    ttl?: number;
    canSubscribe?: boolean;
    resolution?: LiveStreamModels.StreamResolution;
  }

  export interface CreateLiveKitTokenResponse {
    token: string;
  }

  export interface LiveKitSession {
    clientToken?: string;
    serverToken?: string;
    roomName: string;
    room?: Room;
  }

  export interface StopLiveKitRequest {
    edgeId: string;
    cameraId: string;
    force?: boolean;
    resolution: LiveStreamModels.StreamResolution;
  }

  export interface GetLiveKitParticipantsRequest {
    edgeId: string;
    cameraId: string;
    resolution: LiveStreamModels.StreamResolution;
  }

  export enum ParticipantInfo_State {
    JOINING = 0,
    JOINED = 1,
    ACTIVE = 2,
    DISCONNECTED = 3,
  }

  export interface ParticipantInfo {
    identity: string;
    state: ParticipantInfo_State;
    joinedAt: string;
    name: string;
    isPublisher?: boolean;
  }

  export type GetLiveKitParticipantsResponse = ParticipantInfo[];
}
