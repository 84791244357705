import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, mergeMap, share, switchMap } from 'rxjs';

import { LocationEditActions } from '@states/location-edit/location-edit.action-types';
import { LocationEditService } from '../../development/location-edit.service';
import * as GrantedAccessAction from '@states/granted-access/granted-access.actions';
import { withLatestFrom } from 'rxjs/operators';
import { DeviceModel } from '@models/device.model';
import { SharedActions } from '@states/shared/shared.action-types';
import { setDeviceState, testSpeakerError } from '@states/location-edit/location-edit.actions';
import { CamerasActions } from '@states/cameras/cameras.action-types';
import { SessionDataAction } from '@enums/session-data.enum';
import { LocationActions } from '@states/location/location.action-types';
import * as _ from 'lodash';

@Injectable()
export class LocationEditEffects {
  public pressSave$ = createEffect(() => this.actions$.pipe(ofType(LocationEditActions.pressSave), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressCreate$ = createEffect(() => this.actions$.pipe(ofType(LocationEditActions.pressCreate), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public addSpeakerSuccess$ = createEffect(() => this.actions$.pipe(ofType(LocationEditActions.addSpeakerSuccess), share(),
    ), {
      dispatch: false,
      useEffectsErrorHandler: false,
    },
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private locationEditService: LocationEditService,
  ) {
  }

  public testSpeaker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationEditActions.testSpeaker),
      withLatestFrom(this.store$.pipe(select(state => state.locationEditState))),
      switchMap(([, {selectedLocationId, selectedLocation, selectedSpeaker}]) => {
        const locationId = selectedLocationId;
        const edges = Object.keys(selectedLocation.edges);
        const actions = [];
        actions.push(LocationEditActions.setDeviceState({deviceStateChange: {testOngoing: true, inProgressNum: edges.length}}));
        for (let edgeId of edges) {
          const request: DeviceModel.DeviceConnectivityMsg = {
            locationId,
            edgeId,
            ...selectedSpeaker,
            port: +selectedSpeaker.port ?? null,
          };
          actions.push(LocationEditActions.testSpeakerSend({request}));
        }
        return actions;
      }),
      catchError(err => [LocationEditActions.testSpeakerError({errorMsg: err})],
      ),
    ),
  );

  public testSpeakerSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationEditActions.testSpeakerSend),
      mergeMap(({request}) => {
        return this.locationEditService.testSpeaker(request)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.testSpeaker,
                  params: {
                    msTimeout: 15000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [
                LocationEditActions.testSpeakerError({errorMsg: response.error}),
              ];
            }),
          );
      }),
    ),
  );

  public testSpeakerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationEditActions.testSpeakerSuccess),
      mergeMap(() => {
        return [SharedActions.doNothing()];
      }),
    ),
  );

  public addSpeaker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationEditActions.addSpeaker),
      withLatestFrom(this.store$.pipe(select(state => state.locationEditState))),
      switchMap(([, {selectedLocationId, selectedLocation, selectedSpeaker}]) => {
        const request: DeviceModel.DeviceAdd = {
          locationId: selectedLocationId,
          ...selectedSpeaker,
          port: +selectedSpeaker.port ?? null,
        };
        return this.locationEditService.addSpeaker(request)
          .pipe(
            switchMap(res => {
              const location = _.cloneDeep(selectedLocation);
              location.devices[res.deviceId] = {
                ...selectedSpeaker,
                _id: res.deviceId,
              };
              return [
                LocationActions.UpdateLocationNoBackendCall({location}),
                LocationEditActions.addSpeakerSuccess(),
              ];
            }),
            catchError(response => {
              return [
                LocationEditActions.addSpeakerError(),
              ];
            }),
          );
      }),
    ),
  );


  public addSpeakerError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationEditActions.addSpeakerError),
      mergeMap(() => {
        return [SharedActions.setIsSaving({isSaving: false}), SharedActions.showMessage({error: 'Error: Failed to add speaker to location, Try again'})];
      }),
    ),
  );

  public deleteSpeaker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationEditActions.deleteSpeaker),
      withLatestFrom(this.store$.pipe(select(state => state.locationEditState))),
      switchMap(([{deviceId}, {selectedLocationId, selectedLocation}]) => {
        return this.locationEditService.deleteSpeaker(selectedLocationId, deviceId)
          .pipe(
            switchMap(res => {
              const location = _.cloneDeep(selectedLocation);
              delete location.devices[deviceId];
              return [
                LocationActions.UpdateLocationNoBackendCall({location}),
                SharedActions.showMessage({success: 'Speaker deleted successfully'}),
                LocationEditActions.deleteSpeakerSuccess(),
              ];
            }),
            catchError(response => {
              return [
                LocationEditActions.addSpeakerError(),
              ];
            }),
          );
      }),
    ),
  );

  public deleteSpeakerError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationEditActions.deleteSpeakerError),
      mergeMap(() => {
        return [SharedActions.setIsSaving({isSaving: false}), SharedActions.showMessage({error: 'Error: Failed to delete speaker from location, Try again'})];
      }),
    ),
  );
}
