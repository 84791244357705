<ng-container *ngrxLet="selectCameraSearchSettingsFilters$; let filter">
  <ng-container *ngrxLet="selectIsDeveloper$ let developer">
    <div [formGroup]="selectedObject" fxFlexFill class="search-properties-selector" [class.panel]="panel">
      <ng-container [ngSwitch]="selectedObject.get('type')?.value">
        <ng-container *ngSwitchCase="searchObjectTypes.PERSON">
          <div fxLayout="column" fxLayoutAlign="end start" class="w-100 person">
            <!--            <div-->
            <!--              style="width: 100%"-->
            <!--            >-->
            <!--              <ng-container *ngIf="showFaceAttribute">-->
            <!--                <div style="position: relative"-->
            <!--                >-->
            <!--                  <attribute-row [panel]="panel"-->
            <!--                                 title="Face"-->
            <!--                                 [hidden]="facesHidden"-->
            <!--                                 [noBoxShadow]="false"-->
            <!--                                 [(ngModel)]="facesHidden"-->
            <!--                                 (ngModelChange)="toggleFace()"-->
            <!--                                 [ngModelOptions]="{standalone: true}"-->
            <!--                                 style="width: 100%"-->
            <!--                  >-->

            <!--                    <div *ngIf="searchPerson" class="person-search-wrapper" fxLayout="column" fxLayoutGap="8px">-->
            <!--                      <div fxLayout="row" fxLayoutAlign="space-between center">-->
            <!--                        <div>Face</div>-->
            <!--                        <div (click)="uploadImage()" class="search-image-button">Search by image</div>-->
            <!--                      </div>-->
            <!--                      <ui-person-selector #personSelector [personId]="selectedObject?.value?.groupId"-->
            <!--                                          (selected)="addGroup($event)"-->
            <!--                      ></ui-person-selector>-->
            <!--                      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px grid" class="face-chips">-->
            <!--                        <ui-filter-chip *ngFor="let group; let i = index; of groups"-->
            <!--                                        [title]="group.name"-->
            <!--                                        [allowDelete]="true"-->
            <!--                                        (onRemoveClicked)="deleteGroup(i)"-->
            <!--                        >-->
            <!--                        </ui-filter-chip>-->

            <!--                      </div>-->
            <!--                    </div>-->

            <!--                  </attribute-row>-->
            <!--                </div>-->
            <!--              </ng-container>-->
            <!--            </div>-->
            <div
              style="width: 100%"
              *ngFor="let property of getKeys(selectedObject.controls['properties']['controls']); let i = index"
              [matTooltip]="filter[property] !== undefined ? (!filter[property] ? 'This property is disabled for this camera.' : null) : null"
            >
              <ng-container>
                <div [formGroup]="selectedObject.controls['properties']['controls'][property]"
                     style="position: relative"
                >

                  <div *ngIf="displayOperator" class="operator operator-selector"
                       [style.pointer-events]="i !== 0 ? 'none' : ''"
                  >
                    <app-search-selector
                      *ngIf="i === 0"
                      label=""
                      [formControl]="$any(selectedObject.controls['operator'])"
                      [searchSelectorOptions]="searchSelectorsOperators"
                    ></app-search-selector>

                    <app-search-selector
                      *ngIf="i !== 0"
                      label=""
                      [disabled]="true"
                      [value]="$any(selectedObject.controls['operator']).value"
                      [searchSelectorOptions]="searchSelectorsOperators"
                    ></app-search-selector>
                  </div>
                  <attribute-row [panel]="panel"
                                 [beta]="selectedObject.controls['properties']['controls'][property].value?.beta"
                                 [class.disabled]="filter[property] !== undefined ? !filter[property] : false"
                                 [title]="personSelectionFormFieldsTitles[property]"
                                 [noBoxShadow]="getKeys(selectedObject.controls['properties']['controls']).length - 1 === i"
                                 formControlName="enabled"
                                 style="width: 100%"
                  >
                    <div
                      [formGroup]="selectedObject.controls['properties']['controls'][property]['controls']['props']"
                      *ngFor="
                      let field of getKeys(selectedObject.controls['properties']['controls'][property]['controls']['props']['controls'])
                    "
                    >
                      <ng-container [ngSwitch]="property">
                        <ng-container *ngSwitchDefault>
                          <mat-checkbox *ngIf="field !== 'colors' && field !== 'type'" [formControlName]="field"
                                        color="primary"
                          >
                            {{ field | titlecase }}
                          </mat-checkbox
                          >

                          <div *ngIf="field === 'colors'" fxLayout="row wrap" fxLayoutAlign=" center"
                               style="margin-top: 5px"
                          >
                            <h4 class="label">Color</h4>
                            <div fxLayout="row wrap">
                              <ui-color-select formControlName="colors"></ui-color-select>
                            </div>
                          </div>

                          <div *ngIf="field === 'type'" fxLayout="row wrap" fxLayoutAlign="start center">
                            <h4 class="label">Type</h4>
                            <div fxLayout="row wrap">
                              <ui-chip-group [chips]="getChipsArray(property)" formControlName="type"></ui-chip-group>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </attribute-row>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="searchObjectTypes.VEHICLE">
          <div fxLayout="column" fxLayoutAlign="end center" class="w-100 person">
            <div style="width: 100%"
                 *ngFor="let property of getKeys(selectedObject.controls['properties']['controls']); let i = index"
            >
              <ng-container *ngIf="!(standalone && property === 'additionalProperties')">
                <div
                  [formGroup]="selectedObject.controls['properties']['controls'][property]"
                  style="position: relative"
                  [matTooltip]="
                filter[searchSettingsMap[property]] !== undefined
                  ? !filter[searchSettingsMap[property]]
                    ? 'This property is disabled for this camera.'
                    : null
                  : null
              "
                >
                  <div *ngIf="displayOperator" class="operator operator-selector"
                       [style.pointer-events]="i !== 0 ? 'none' : ''"
                  >
                    <app-search-selector
                      *ngIf="i === 0"
                      label=""
                      [formControl]="$any(selectedObject.controls['operator'])"
                      [searchSelectorOptions]="searchSelectorsOperators"
                    ></app-search-selector>
                    <app-search-selector
                      *ngIf="i !== 0"
                      label=""
                      [disabled]="true"
                      [value]="$any(selectedObject.controls['operator']).value"
                      [searchSelectorOptions]="searchSelectorsOperators"
                    ></app-search-selector>
                  </div>
                  <attribute-row
                    [panel]="panel"
                    [noBoxShadow]="getKeys(selectedObject.controls['properties']['controls']).length - 1 === i"
                    [class.disabled]="filter[searchSettingsMap[property]] !== undefined ? !filter[searchSettingsMap[property]] : false"
                    [title]="(property === 'additionalProperties' || property === 'plate') ? 'License plate' : (property | titlecase)"
                    formControlName="enabled"
                  >
                    <div
                      *ngIf="property === vehicleSelectionFormFields.colors"
                      fxLayout="row wrap"
                      fxLayoutAlign="start center"
                      fxLayoutGap="15px"
                      class="mb-10"
                    >
                      <h4>Color</h4>
                      <div fxLayout="row wrap">
                        <ui-color-select formControlName="value"></ui-color-select>
                      </div>
                    </div>

                    <div
                      *ngIf="property === vehicleSelectionFormFields.plate"
                      fxLayout="column"
                      fxLayoutAlign="start start"
                      fxLayoutGap="15px"
                      class="mb-10"
                    >
                      <div class="p-r license-plate-input">
                        <ui-input [style]="inputStyle.labelOutside" [whiteBg]="true"
                                  placeholder="Type license plate number "
                                  [validationEnabled]="false"
                                  [(ngModel)]="licensePlateInput"
                                  [ngModelOptions]="{standalone: true}"
                                  (keyup.enter)="licensePlateInput ? addPlate() : null"

                        ></ui-input>
                        <div class="add-button c-p" (click)="addPlate()" [class.disabled]="!licensePlateInput">
                          <span class="ui-icon-plus"></span>
                        </div>
                      </div>
                      <div class="license-plate-description">
                        You can also type only part of the license plate number
                      </div>
                      <div fxLayout="row wrap" fxLayoutGap="8px grid">
                        <ui-filter-chip *ngFor="let plate of plates; let i = index" [title]="plate"
                                        (onRemoveClicked)="removePlate(i)"
                        >
                        </ui-filter-chip>
                      </div>

                    </div>

                    <div
                      *ngIf="property === vehicleSelectionFormFields.make"
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxLayoutGap="15px"
                      class="mb-10"
                    >
                      <div
                        *ngIf="selectedObject.controls['properties']['controls'][property].get('enabled').value"
                        fxLayout="column"
                        style="width: 100%"
                      >
                        <ui-selector
                          placeholder="Select Make"
                          [multi]="true"
                          title="Make"
                          [optionTemplate]="optionCarMakeTemplate"
                          [value]="selectedObject.controls['properties']['controls'][property].get('value').value"
                          [options]="carMadeOptions"
                          (change)="makeChanged($event)"
                          [searchEnabled]="true"
                        >
                        </ui-selector>
                        <div fxLayout="row wrap" class="advanced-search-chips mb-15">
                          <mat-chip-set>
                            <ui-chip-select *ngFor="let option of selectedObject.controls['properties']['controls'][property].get('value').value"
                                            [value]="option"
                                            [selected]="true"
                                            (selectChange)="unselectCarMade(option)"
                            >{{
                                option
                              }}
                            </ui-chip-select>
                          </mat-chip-set>
                        </div>
                      </div>
                    </div>

                    <div
                      *ngIf="property === vehicleSelectionFormFields.model"
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxLayoutGap="15px"
                      class="mb-10"
                    >
                      <div
                        *ngIf="selectedObject.controls['properties']['controls'][property].get('enabled').value"
                        fxLayout="column"
                        style="width: 100%"
                      >
                        <ui-selector
                          placeholder="Select Model"
                          [multi]="true"
                          title="Model"
                          [optionTemplate]="optionCarMakeTemplate"
                          [options]="carModelsOptions"
                          [value]="selectedObject.controls['properties']['controls'][property].get('value').value"
                          (change)="modelChanged($event)"
                          [searchEnabled]="true"
                        >
                        </ui-selector>
                        <div fxLayout="row wrap" class="advanced-search-chips mb-15">
                          <mat-chip-set>
                            <ui-chip-select *ngFor="let option of selectedObject.controls['properties']['controls'][property].get('value').value"
                                            [value]="option"
                                            [selected]="true"
                                            (selectChange)="unselectCarMade(option)"
                            >{{
                                option
                              }}
                            </ui-chip-select>
                          </mat-chip-set>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="property === 'type'" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px"
                         class="mb-10"
                    >
                      <h4>Type</h4>
                      <div fxLayout="row wrap">
                        <ui-chip-group [chips]="searchSelectorCarTypes" formControlName="value"></ui-chip-group>
                      </div>
                    </div>

                    <div
                      *ngIf="property === 'additionalProperties'"
                      [formGroup]="selectedObject.controls['properties']['controls'][property]['controls']['props']"
                    >
                      <div class="flex-col gap-16">
                        <div class="flex-row">
                          <input class="ui-input ui-input-40 w-100"
                                 type="text"
                                 formControlName="region"
                                 placeholder="Region"
                          />
                        </div>
                        <div class="flex-row">
                          <input class="ui-input ui-input-40 w-100"
                                 type="text"
                                 formControlName="plate"
                                 placeholder="Plate number"
                          />
                        </div>
                      </div>
                    </div>


                    <!--                <div *ngIf="property === 'additionalProperty'" fxLayout="row" fxLayoutAlign="start center"-->
                    <!--                     fxLayoutGap="15px"-->
                    <!--                     class="mb-10">-->
                    <!--                  <div-->
                    <!--                    fxLayout="row wrap">-->
                    <!--                    <div-->
                    <!--                      *ngFor="let field of getKeys(selectedObject.controls['properties']['controls'][property]['controls']['props']['controls'])"-->
                    <!--                      [formGroup]="selectedObject.controls['properties']['controls'][property]['controls']['props']">-->
                    <!--                      <ui-input [label]="field" [formControlName]="field"></ui-input>-->
                    <!--                    </div>-->
                    <!--                  </div>-->
                    <!--                </div>-->
                  </attribute-row>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="searchObjectTypes.PET">
          <div class="p-30" fxLayout="row" fxLayoutAlign="center center">No additional attributes available for pets at
                                                                         the moment
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
<ng-template #optionCarMakeTemplate let-option>
  <span class="option-item">
    {{ option }}
  </span>
</ng-template>
