<div fxLayout="column" fxLayoutAlign="start start" fxFill class="step-object-filters-dialog">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>Special features</div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ui-toggle [(ngModel)]="strict" (ngModelChange)="updateStrict()"></ui-toggle>
        <div>Strict</div>
      </div>
      <div fxLayoutAlign="center center" class="c-p icon-18" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </div>
    </div>
  </div>
  <div
    class="flex-col dialog-content"
  >
    <div class="flex-row center-center tabs">
      <div class="flex-row flex center-center" [class.selected]="tab === StepObjectsFiltersTab.Filters"
           (click)="tab = StepObjectsFiltersTab.Filters"
      >Filters
      </div>
      <div class="flex-row flex center-center" [class.selected]="tab === StepObjectsFiltersTab.Exclude"
           (click)="tab = StepObjectsFiltersTab.Exclude"
      >Exclude
      </div>
    </div>
    <ng-container [ngSwitch]="tab">
      <ng-container *ngSwitchCase="StepObjectsFiltersTab.Exclude">
        <div class="flex-col gap-8 exclude-wrapper mt-16">
          <ng-container [ngSwitch]="data?.type">
            <ng-container *ngSwitchCase="SearchObjectTypes.PERSON">
              <div>Search and add people to exclude from the alert</div>
              <ui-person-selector class="w-100"
                                  #personSelector
                                  [selectedIds]="selectedPeople"
                                  placeholder="Search people to exclude from the alert"
                                  (selected)="excludePerson($event)"
                                  [canUploadImage]="false"
              ></ui-person-selector>
              <div fxLayout="column" fxLayoutAlign="start center" class="people-list">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="people-list-header">
                  <div fxFlex="30">Person name</div>
                  <div [style.width.px]="103">&nbsp;</div>
                </div>
                <ng-container *ngIf="!data.excludePeople?.length">
                  <div class="people-list-item flex-row center-center">
                    No people on the list
                  </div>
                </ng-container>
                <div *ngFor="let item of data.excludePeople; let i = index;"
                     class="narrower-input people-list-item"
                     fxLayout="row" fxLayoutAlign="space-between center"
                >
                  <ng-container *ngrxLet="selectPersonById(item.personIds[0]); let person">
                    <ng-container *ngIf="person">
                      <div fxFlex="30" class="flex-row start-center gap-8">
                        <img [lazyLoad]="img(person)" class="person-icon-img">
                        <div>{{ person?.name }}</div>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                        <span class="ui-icon-cross c-p" (click)="delete('excludePeople',i)"></span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>

              </div>
            </ng-container>
            <ng-container *ngSwitchCase="SearchObjectTypes.VEHICLE">
              <div>Search and add vehicles to exclude from the alert</div>
              <ui-vehicle-selector class="w-100"
                                   #personSelector
                                   [selectedIds]="selectedVehicles"
                                   placeholder="Search vehicles"
                                   (selected)="excludeVehicle($event)"
                                   [canUploadImage]="false"
              ></ui-vehicle-selector>
              <div fxLayout="column" fxLayoutAlign="start center" class="people-list">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="people-list-header">
                  <div fxFlex="30">Vehicles</div>
                  <div [style.width.px]="103">&nbsp;</div>
                </div>
                <ng-container *ngIf="!excludeVehicles?.length">
                  <div class="people-list-item flex-row center-center">
                    No vehicles on the list
                  </div>
                </ng-container>
                <cdk-virtual-scroll-viewport [itemSize]="40">
                  <div *cdkVirtualFor="let item of excludeVehicles; let i = index;"
                       class="narrower-input people-list-item"
                       fxLayout="row" fxLayoutAlign="space-between center"
                  >
                    <div fxFlex="30" class="flex-row start-center gap-8">
                      <span class="ui-icon-object-vehicle"></span>
                      <div>{{ item?.plate }}</div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                      <span class="ui-icon-cross c-p" (click)="delete('excludeVehicles',i)"></span>
                    </div>
                  </div>
                </cdk-virtual-scroll-viewport>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="StepObjectsFiltersTab.Filters">
        <div class="flex-col gap-8 exclude-wrapper mt-16">
          <ng-container [ngSwitch]="data?.type">
            <ng-container *ngSwitchCase="SearchObjectTypes.PERSON">
              <div class="fw-600">Face</div>
              <ui-person-selector class="w-100"
                                  #personSelector
                                  [selectedIds]="selectedPeople"
                                  placeholder="Type person name"
                                  (selected)="includePerson($event)"
                                  [canUploadImage]="false"
              ></ui-person-selector>
              <div *ngIf="data.includePeople?.length"
                   fxLayout="column"
                   fxLayoutAlign="start center"
                   class="people-list"
              >
                <div fxLayout="row" fxLayoutAlign="space-between center" class="people-list-header">
                  <div fxFlex="30">Person name</div>
                  <div [style.width.px]="103">&nbsp;</div>
                </div>
                <ng-container *ngIf="!data.includePeople?.length">
                  <div class="people-list-item flex-row center-center">
                    No people on the list
                  </div>
                </ng-container>
                <div *ngFor="let item of data.includePeople; let i = index;"
                     class="narrower-input people-list-item"
                     fxLayout="row" fxLayoutAlign="space-between center"
                >
                  <ng-container *ngrxLet="selectPersonById(item.personIds[0]); let person">
                    <ng-container *ngIf="person">
                      <div fxFlex="30" class="flex-row start-center gap-8">
                        <img [lazyLoad]="img(person)" class="person-icon-img">
                        <div>{{ person?.name }}</div>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                        <span class="ui-icon-cross c-p" (click)="delete('includePeople',i)"></span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>

              </div>
            </ng-container>
            <ng-container *ngSwitchCase="SearchObjectTypes.VEHICLE">
              <div class="fw-600">Saved vehicle license plate</div>
              <ui-vehicle-selector class="w-100"
                                   #personSelector
                                   [selectedIds]="selectedVehicles"
                                   placeholder="Search saved vehicles"
                                   (selected)="includeVehicle($event)"
                                   [canUploadImage]="false"
              ></ui-vehicle-selector>
              <div *ngIf="includeVehicles?.length"
                   fxLayout="column"
                   fxLayoutAlign="start center"
                   class="people-list"
              >
                <div fxLayout="row" fxLayoutAlign="space-between center" class="people-list-header">
                  <div fxFlex="30">Vehicles</div>
                  <div [style.width.px]="103">&nbsp;</div>
                </div>
                <ng-container *ngIf="!includeVehicles?.length">
                  <div class="people-list-item flex-row center-center">
                    No vehicles on the list
                  </div>
                </ng-container>
                <cdk-virtual-scroll-viewport [itemSize]="40">
                  <div *cdkVirtualFor="let item of includeVehicles; let i = index;"
                       class="narrower-input people-list-item"
                       fxLayout="row" fxLayoutAlign="space-between center"
                  >
                    <div fxFlex="30" class="flex-row start-center gap-8">
                      <span class="ui-icon-object-vehicle"></span>
                      <div>{{ item?.plate }}</div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                      <span class="ui-icon-cross c-p" (click)="delete('includeVehicles',i)"></span>
                    </div>
                  </div>
                </cdk-virtual-scroll-viewport>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <app-object-attributes-content
          #objectAttributesContent
          class="w-100 h-100"
          [displayOperator]="false"
          (update)="updateObjects($event)"
          [standalone]="true"
          [standaloneData]="{selections, vehicleArray, petArray, type: data.type, single: true}"
        ></app-object-attributes-content>
      </ng-container>
    </ng-container>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <ui-button colorBgr="#4367F6" colorText="white" text="Done" (clickAction)="save()"></ui-button>
  </div>
</div>
