import { createReducer, on } from '@ngrx/store';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { EdgeCamera } from '../../../cameras/camera.model';
import { MultiPlaybackData } from '@models/multi-playback.model';
import { WallModelV2 } from '@models/wall.model';
import WallLayout = WallModelV2.WallLayout;

export declare interface MultiPlaybackState extends MultiPlaybackData {
  selectedCameras: EdgeCamera.CameraItem[];
  positions: number[],
  cameraEvents: number[][];
  cameraStorageStats: number[][];
  timestamp: number;
  percentage: number;
  layout: WallLayout;
  dragging: boolean
}

export const initialState: MultiPlaybackState = {
  selectedCameras: [],
  positions: [...Array(10)
    .keys()],
  cameraEvents: new Array(16).fill([]),
  cameraStorageStats: new Array(16).fill([]),
  layout: WallLayout.GRID_1,
  timezone: 'GMT',
  timestamp: 0,
  percentage: 0,
  offsetResInDurations: 1,
  startTime: 0,
  endTime: 0,
  duration: 0,
  base: 0,
  dragging: false,
};

export const multiPlaybackStateReducer = createReducer(
  initialState,
  on(MultiPlaybackActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(MultiPlaybackActions.setSelectedCameras, (state, { selectedCameras }) => {
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(MultiPlaybackActions.setData, (state, { data }) => {
    return {
      ...state,
      ...data,
    };
  }),
  on(MultiPlaybackActions.setRange, (state, { startTime, endTime }) => {
    return {
      ...state,
      startTime,
      endTime,
    };
  }),
  on(MultiPlaybackActions.setDragging, (state, { dragging }) => {
    return {
      ...state,
      dragging,
    };
  }),
  on(MultiPlaybackActions.setMove, (state, { move }) => {
    return {
      ...state,
      ...move,
    };
  }),
  on(MultiPlaybackActions.setLayout, (state, { layout }) => {
    const selectedCameras = [...state.selectedCameras].filter((camera) => !!camera);
    const count = selectedCameras.length;
    const layoutCount = WallModelV2.wallLayoutCameraCountV2[layout];
    if (count > layoutCount) {
      return {
        ...state,
        layout,
        positions: [...Array(10)
          .keys()],
        selectedCameras: selectedCameras.splice(0, layoutCount),
      };
    }
    return {
      ...state,
      layout,
      positions: [...Array(10)
        .keys()],
      selectedCameras,
    };
  }),
  on(MultiPlaybackActions.setCameraEvents, (state, { idx, events }) => {
    const cameraEvents = [...state.cameraEvents];
    cameraEvents[idx] = events;
    return {
      ...state,
      cameraEvents,
    };
  }),
  on(MultiPlaybackActions.swapPositions, (state, { idx1, idx2 }) => {
    const positions = [...state.positions];
    const temp = positions[idx1];
    positions[idx1] = positions[idx2];
    positions[idx2] = temp;
    return {
      ...state,
      positions,
    };
  }),
  on(MultiPlaybackActions.setCameraStorageStats, (state, { cameraId, stats }) => {
    const cameraStorageStats = [...state.cameraStorageStats];
    const idx = state.selectedCameras.findIndex((camera) => camera?.cameraId === cameraId || camera?.edgeOnly?.cameraId === cameraId);
    cameraStorageStats[idx] = stats;
    return {
      ...state,
      cameraStorageStats,
    };
  }),
);
