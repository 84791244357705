<!--        CAMERA ROW-->
<div
  class="camera-block"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  [class.disabled-camera]="edgeSelection"
  [class.narrow]="narrow"
  (click)="selectCamera(camera); $event.stopPropagation()"
>
  <img
    #snapshot
    *ngIf="withAlerts"
    class="arrow"
    [class.rotated]="hidden"
    (click)="hideEvents();$event.stopPropagation()"
    src="../../../../assets/input/input-arrow-bottom.svg"
    alt="arrow"
    (error)="snapshot.alt = 'error'"
    [class.hidden]="snapshot.alt === 'error'"
  />
  <!--          CAMERA ROW LEFT BLOCK-->
  <div class="start" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayoutAlign="center center" class="radio">
      <mat-radio-button
        *ngIf="!multi"
        [name]="camera.edgeOnly.name"
        [checked]="selectedCameras[camera.edgeOnly.cameraId]"
        color="primary"
      ></mat-radio-button>
      <mat-checkbox
        type="checkbox"
        color="primary"
        *ngIf="multi"
        (click)="$event.preventDefault()"
        [name]="camera.edgeOnly.name"
        (change)="($event)"
        [checked]="selectedCameras[camera.edgeOnly.cameraId]"
      >
      </mat-checkbox>
    </div>
    <div class="camera-snapshot">
      <img *ngIf="({edgeId:camera.edgeId, cameraId: camera.edgeOnly.cameraId } | cameraBestImagePipe | async) && !imgLoadFailed"
           [lazyLoad]="{edgeId:camera.edgeId, cameraId: camera.edgeOnly.cameraId } | cameraBestImagePipe | async"
           (onStateChange)="imgStateChange($event)"
           #snapshot
      />
      <div *ngIf="!imageLoader && (!({edgeId:camera.edgeId, cameraId: camera.edgeOnly.cameraId } | cameraBestImagePipe | async) || imgLoadFailed)"
           class="flex-row center-center failed-img h-100 w-100"
      >
        <span class="ui-icon-camera-off"></span>
      </div>
    </div>
    <div class="camera-name">
      <ui-truncated-html>
        {{ camera.edgeOnly.name }}
      </ui-truncated-html>
    </div>
  </div>

  <!--          CAMERA ROW RIGHT BLOCK-->
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px" [class.with-alert]="withAlerts">
    <app-component-status-chip iconClass="icon-20"
                               [status]="(getCameraPulsationStatus(camera.edgeOnly.cameraId) | async)"
    ></app-component-status-chip>
    <div fxLayoutAlign="center center"
         *ngIf="zoneSelection"
         [class.opacity-25]="!selectedCameras[camera.edgeOnly.cameraId]"
         class="icon-16 zones-edit-icon"
         [matTooltipDisabled]="!selectedCameras[camera.edgeOnly.cameraId]"
         matTooltip="Select zones"
         matTooltipClass="sidebar-tooltip"
         (click)="selectZones(camera); $event.stopPropagation()"
         [class.svg-fill-primary]="zonesCache[camera.edgeOnly.cameraId]"
         [class.selected]="zonesCache[camera.edgeOnly.cameraId]"
    >
      <span class="ui-icon-edit"></span>
    </div>
    <div *ngIf="withAlerts"
         fxLayoutAlign="center center"
         class="event-toggle"
         [class.rotated]="expandEvents"
         (click)="toggleEvents(); $event.stopPropagation();"
    >
      <span class="ui-icon-expand-arrow"></span>
    </div>
  </div>
</div>

<!--        EVENT LIST-->
<ng-container *ngIf="withAlerts && !hidden && expandEvents">
  <div class="events-container">
    <div *ngFor="let alert of cameraEventsLookup[camera.edgeOnly?.cameraId]"
         (click)="selectEvent(alert); $event.stopPropagation()"
    >
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="camera-block event-block">
        <mat-checkbox
          [name]="alert.name"
          [checked]="selectedAlerts[alert._id]"
          (click)="$event.preventDefault()"
          color="primary"
        ></mat-checkbox>
        <div>{{ alert.name }}</div>
      </div>
    </div>
  </div>
</ng-container>
