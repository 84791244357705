import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedActions } from '@states/shared/shared.action-types';
import { PeopleActions } from '@states/people/people.action-types';
import { GroupModels, GroupStatus } from '@models/people.model';
import { select, Store } from '@ngrx/store';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { filter, map, Observable, startWith } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VehiclesSelectors } from '@states/vehicles/vehicles.selector-types';
import { VehiclesService } from '../../../development/vehicles.service';
import { VehicleModels } from '@models/vehicle.model';
import { VehiclesActions } from '@states/vehicles/vehicles.action-types';

@UntilDestroy()
@Component({
  selector: 'ui-vehicle-selector',
  templateUrl: './ui-vehicle-selector.component.html',
  styleUrls: ['./ui-vehicle-selector.component.scss'],
})
export class UiVehicleSelectorComponent implements OnInit, AfterViewInit {
  @Output() selected = new EventEmitter<VehicleModels.Vehicle>();
  @Output() uploadImage = new EventEmitter<void>();
  @Input() vehicleId: number;
  @Input() disabled = false;
  @Input() beta = false;
  @Input() canUploadImage = false;

  @Input() selectedIds: number[] | any[] = [];

  @Input() placeholder: string = 'Type vehicle plate';

  init = false;

  public vehicles: VehicleModels.Vehicle[] = [];
  public selectVehicles$: Observable<VehicleModels.Vehicle[]> = this.store$.pipe(select(VehiclesSelectors.selectAllVehicles));
  filteredOptions: Observable<VehicleModels.Vehicle[]>;

  public form: UntypedFormGroup = new UntypedFormGroup({
    query: new UntypedFormControl(''),
    groupId: new UntypedFormControl(''),
  });

  constructor(private store$: Store, private vehiclesService: VehiclesService) {

  }

  ngAfterViewInit(): void {
    this._filter('');
  }

  ngOnInit(): void {
    this.triggerGet();
    if (this.disabled) {
      this.form.disable();
    }
    this.filteredOptions = this.form.get('query')
      .valueChanges
      .pipe(
        untilDestroyed(this),
        startWith(''),
        map(value => this._filter(value || '')),
      );

    this.form.get('query')
      .valueChanges
      .pipe(untilDestroyed(this), filter(value => !!value?.id))
      .subscribe(
        (value) => {
          const vehicle: VehicleModels.Vehicle = {
            id: value.id,
            plate: value.plate,
          };
          this.selected.emit(vehicle);
          this.form.patchValue({ query: '' });
        },
      );


    this.selectVehicles$.pipe(untilDestroyed(this), filter(vehicles => !!vehicles.length))
      .subscribe((vehicles) => {
        this.vehicles = vehicles;
        if (this.vehicleId) {
          this.form.patchValue({ query: this.vehicles.find(vehicle => vehicle.id === this.vehicleId) });
        }
      });
  }

  public triggerGet() {
    this.store$.dispatch(SharedActions.setIsLoading({ isLoading: true }));
    this.store$.dispatch(VehiclesActions.getVehicles());
  }


  private _filter(value: string | VehicleModels.Vehicle): VehicleModels.Vehicle[] {
    const val = value as VehicleModels.Vehicle;

    if (val.id) {
      return this.vehicles;
    }
    const str = value as string;
    const filterValue = str?.toLowerCase();

    return this.vehicles.filter(vehicle => vehicle.plate.toLowerCase()
      .includes(filterValue));
  }

  getOptionText(vehicle: VehicleModels.Vehicle) {
    return vehicle?.plate;
  }

  clear() {
    this.form.patchValue({ query: '' });
    this.selected.emit(null);
  }

  initSelector() {
    if (this.init) {
      return;
    }
    this.form.get('query')
      .setValue('.');
    this.form.get('query')
      .setValue('');
    this.init = true;
  }
}
