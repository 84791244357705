import { UserSettings } from './settings.model';
import WallCamera = WallModelV2.WallCamera;
import { WallModelV2 } from '@models/wall.model';

/**
 * @deprecated use WallModelV2 wallLayoutCameraCountV2 instead
 */
export const WallLayoutCameraCount = [
  0,
  0,
  0,
  9,
  6,
  4,
  3,
  2,
  1,
  6,
  8,
  4,
  5,
  6,
  6,
  7,
  7,
  8,
  9,
  10,
  10,
  12,
  12,
  13,
  13,
  16,
  16,
  20,
  20,
  25,
  30,
  36,
  48,
];


/**
 * @deprecated use user.model instead
 */
export declare module AppUser {
  export interface Org {
    orgId: string;
    orgName: string;
    roles?: string[];
  }

  export interface Identity {
    user_id: string;
    provider: string;
    connection: string;
    isSocial: boolean;
  }

  export type LiveViewCamera = WallCamera;

  export interface User {
    _id: string;
    email: string;
    firstname?: string;
    lastname?: string;
    authStrategy: string;
    authProviderId: string;
    identities: Identity[];
    edges?: string[];
    created_at: string;
    updated_at: string;
    roles: string[];
    orgs: Org[];
    orgMap?: { [orgId: string]: Org };
    settings?: UserSettings.Settings;
    activeOrg?: string;
    timezone?: string;
    phone?: string;
    isPhoneVerified?: boolean;
    lastLogin?: number;
  }

  export interface CreateOrGetUserProfileRequest {
    userId: string;
    accessToken: string;
  }

  export interface UpdateUserProfileData {
    firstname?: string;
    lastName?: string;
    settings?: UserSettings.Settings;
    activeOrg?: string;
    timezone?: string;
    phone?: string;
  }

  export interface UpdateUserProfileRequest {
    userId: string;
    data: UpdateUserProfileData;
  }

  export type CreateOrGetUserProfileResponse = User;
  export type UpdateUserProfileResponse = User;

  export interface SubUser {
    email: string,
    id: string;
    authProviderId: string
  }
}






